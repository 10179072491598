import {
  Deal,
  DealPartyRelation,
  Party,
  PartyRelation,
  PartyType
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import {
  nameLogic as partyNameLogic,
  partySubLabel,
  transformDataToNode as transformPartyToNode
} from "../../../party/PartyChartUtils";
import { navigateAction } from "../../../party/PartyTreeActions";
import { emptyAction } from "../../../tree/TreeUtils";
import { ElphiNode, NodeAction } from "../../../tree/types/Tree.types";

const transformDealToNode = (props: {
  data: Deal;
  label: string;
  sublabel: string;
  nodeActions?: NodeAction<Deal | Party>[];
  uniqueKey?: string;
}): ElphiNode<Deal | Party> => {
  const { data, label, sublabel, nodeActions, uniqueKey } = props;
  return {
    id: data.id,
    data,
    children: [],
    label,
    sublabel,
    nodeActions: nodeActions || [],
    nodeKey: uniqueKey || uuid()
  };
};
const dealPartyRelationPrint = (relation?: DealPartyRelation) => {
  return relation?.relationRoleType && relation?.relationRoleType.length > 0
    ? `${relation?.relationRoleType.join(", ")}`
    : "n/a";
};
export const generateTree = (props: {
  dealId: string;
  dealState: EntityState<Deal>;
  dealPartyRelationState: EntityState<DealPartyRelation>;
  partyState: EntityState<Party>; //PartySliceState;
  partyRelationState: EntityState<PartyRelation>;
  dealNodeActions: NodeAction<Deal | Party>[];
  depth1PartyNodeActions: NodeAction<Deal | Party>[];
  setSelectedParty: (id: string) => void;
}) => {
  const {
    dealId,
    dealState,
    dealPartyRelationState,
    partyState,
    partyRelationState,
    dealNodeActions,
    depth1PartyNodeActions
  } = props;

  const deal = dealState.entities[dealId];
  const rootPartyNode = (deal &&
    dealState &&
    transformDealToNode({
      data: deal,
      label: nameLogic(),
      sublabel: subLabelLogic(deal),
      nodeActions: dealNodeActions,
      uniqueKey: `$_${deal.id}`
    })) || {
    id: "$",
    children: [],
    data: null,
    label: nameLogic(),
    sublabel: subLabelLogic(deal),
    nodeKey: "$"
  };

  const thisDealPartyRelations =
    Object.values(dealPartyRelationState?.entities)?.filter?.(
      (entity) => entity && entity.dealId === dealId
    ) ?? [];

  const thisDealParties = thisDealPartyRelations
    .filter(
      (dpr) =>
        dpr && partyState.entities[dpr?.partyId]?.PartyType === PartyType.Entity
    )
    .map(
      (relation) =>
        (relation && partyState.entities[relation?.partyId]) ?? ({} as Party)
    );

  const thisDealPartyNodes = thisDealParties.map((party) =>
    transformPartyToNode(
      party,
      partyNameLogic(party),
      dealPartyRelationPrint(
        dealPartyRelationState.entities[`${dealId}_${party.id}`]
      ),
      `depth1${party.id}`,
      [
        ...depth1PartyNodeActions,
        navigateAction({
          partyId: party.id,
          callback: (node) => {
            props.setSelectedParty(node.data?.id || "");
          }
        })
      ]
    )
  );
  if (rootPartyNode) rootPartyNode.children = thisDealPartyNodes;

  const queue = [...thisDealPartyNodes];
  const previouslyGeneratedRelations: string[] = [];
  while (queue.length !== 0) {
    const currentParty = queue.pop();
    const childrenRelations =
      Object.values(partyRelationState?.entities)?.filter?.(
        (child) =>
          currentParty &&
          child?.parentId === currentParty.id &&
          !(
            child &&
            child.id &&
            previouslyGeneratedRelations.includes(child.id)
          )
      ) ?? [];
    childrenRelations.forEach((relation) => {
      relation && relation.id && previouslyGeneratedRelations.push(relation.id);
      const childParty = relation && partyState.entities[relation?.childId];
      const childNode =
        childParty &&
        transformPartyToNode(
          childParty,
          `${partyNameLogic(childParty)} | ${dealPartyRelationPrint(
            dealPartyRelationState.entities[`${dealId}_${childParty.id}`]
          )}`,
          `${partySubLabel(childParty, relation)}`,
          relation.id,
          [
            emptyAction,
            navigateAction({
              partyId: childParty.id,
              callback: (node) => {
                props.setSelectedParty?.(node?.data?.id || "");
              }
            })
          ]
        );
      currentParty &&
        currentParty.children &&
        childNode &&
        currentParty.children.push(childNode);
      childParty && childNode && queue.push(childNode);
    });
  }
  return rootPartyNode;
};

const nameLogic = () => {
  return "Deal";
};

const subLabelLogic = (deal?: Deal) => {
  return `loan:${deal?.LoanIdentifier || "n/a"}`;
};
