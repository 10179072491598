import {
  AggregationFocusType,
  AppraisalCoordinatorType,
  DeepPartial,
  FieldType,
  FloodInsurancePremiumPaymentType,
  PropertyInsurancePremiumPaymentType,
  QualityRatingType,
  StateCode,
  StateName
} from "@elphi/types";
import { BaseSchemaProperty } from "@elphi/types/entities/base";
import {
  limaAppraisalType,
  limaConditionRating,
  limaConstructionBudgetAssessment,
  limaLeaseStatus,
  limaPropertyType
} from "@elphi/types/entities/lima";
import { LimaPropertyFields } from "@elphi/types/entities/lima/property";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import {
  EntityFormFieldSpecs,
  InputBuilderFieldSpecs
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaPropertyFieldSpecs,
  baseSchemaPropertySpecs
} from "../../../base/spec-files/property/property.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { BaseSpec } from "../../../types";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";

const limaUnitFieldSpecs = {
  CorporateLeaseIndicator: {
    fieldKey: ["CorporateLeaseIndicator"],
    fieldType: FieldType.Boolean,
    label: "Corporate Lease?",
    options: booleanOptions
  },
  PaymentVerificationIndicator: {
    fieldKey: ["PaymentVerificationIndicator"],
    fieldType: FieldType.Boolean,
    label: "Payment Verification",
    options: booleanOptions
  },
  LeaseStatusType: modifyFromSpec({
    fieldSpecs: baseSchemaPropertySpecs.LeaseStatusType,
    changes: {
      options: createOptionsFromMap(limaLeaseStatus)
    }
  })
};

type LimaPropertyFieldSpecs = EntityFormFieldSpecs<LimaPropertyFields>;
export type FullLimaPropertyFieldSpecs = DeepPartial<
  BaseSchemaPropertyFieldSpecs,
  InputBuilderFieldSpecs<any>
> &
  LimaPropertyFieldSpecs;

const limaPropertyFieldsSpecs: BaseSpec<LimaPropertyFieldSpecs> = {
  aggregations: {
    BridgeLTVPercent: {
      thirdParty: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "thirdParty"],
        fieldType: FieldType.Percentage,
        label: "Bridge Plus - Loan to Value (%)"
      },
      override: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Bridge Plus - Loan to Value (%)"
      },
      focused: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AllocatedLoanAmount: {
      calculated: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Allocated Loan Amount"
      },
      override: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "override"],
        fieldType: FieldType.Money,
        label: "Allocated Loan Amount"
      },
      focused: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    PricingEngineLTARVRatePercent: {
      calculated: {
        fieldKey: [
          "aggregations",
          "PricingEngineLTARVRatePercent",
          "calculated"
        ],
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) (%)"
      },
      override: {
        fieldKey: ["aggregations", "PricingEngineLTARVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) (%)"
      },
      focused: {
        fieldKey: ["aggregations", "PricingEngineLTARVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    EstimatedDelayedPurchaseProceeds: {
      calculated: {
        fieldKey: [
          "aggregations",
          "EstimatedDelayedPurchaseProceeds",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Estimated Delayed Purchase Proceeds"
      },
      override: {
        fieldKey: [
          "aggregations",
          "EstimatedDelayedPurchaseProceeds",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Estimated Delayed Purchase Proceeds"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "EstimatedDelayedPurchaseProceeds",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  Appraisal: {
    AppraisalCoordinator: {
      fieldKey: ["Appraisal", "AppraisalCoordinator"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Coordinator",
      options: createOptionsFromMap(AppraisalCoordinatorType)
    },
    InternalValuationEligibilityIndicator: {
      fieldKey: ["Appraisal", "InternalValuationEligibilityIndicator"],
      fieldType: FieldType.Boolean,
      label: "L1C Val Eligible?",
      options: booleanOptions
    },
    QualityRatingType: {
      fieldKey: ["Appraisal", "QualityRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Quality",
      options: createOptionsFromMap(QualityRatingType)
    },
    AppraisalType: modifyFromSpec({
      fieldSpecs: baseSchemaPropertySpecs.Appraisal.AppraisalType,
      changes: {
        options: createOptionsFromMap(limaAppraisalType)
      }
    }),
    ConditionRatingType: modifyFromSpec({
      fieldSpecs: baseSchemaPropertySpecs.Appraisal.ConditionRatingType,
      changes: {
        options: createOptionsFromMap(limaConditionRating)
      }
    })
  },
  LeasedFinancedUnits: {
    unit0: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit0", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit0",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit0", "LeaseStatusType"]
      }
    },
    unit1: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit1", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit1",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit1", "LeaseStatusType"]
      }
    },
    unit2: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit2", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit2",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit2", "LeaseStatusType"]
      }
    },
    unit3: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit3", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit3",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit3", "LeaseStatusType"]
      }
    },
    unit4: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit4", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit4",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit4", "LeaseStatusType"]
      }
    },
    unit5: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit5", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit5",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit5", "LeaseStatusType"]
      }
    },
    unit6: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit6", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit6",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit6", "LeaseStatusType"]
      }
    },
    unit7: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit7", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit7",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit7", "LeaseStatusType"]
      }
    },
    unit8: {
      CorporateLeaseIndicator: {
        ...limaUnitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit8", "CorporateLeaseIndicator"]
      },
      PaymentVerificationIndicator: {
        ...limaUnitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit8",
          "PaymentVerificationIndicator"
        ]
      },
      LeaseStatusType: {
        ...limaUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit8", "LeaseStatusType"]
      }
    }
  },
  RentalLTVPercent: {
    fieldKey: ["RentalLTVPercent"],
    fieldType: FieldType.Percentage,
    label: "Rental - Loan to Value (%)"
  },
  AnnualCapitalExpenditure: {
    fieldKey: ["AnnualCapitalExpenditure"],
    fieldType: FieldType.Money,
    label: "Annual Capital Expenditure"
  },
  CEMARefinanceIndicator: {
    fieldKey: ["CEMARefinanceIndicator"],
    fieldType: FieldType.Boolean,
    label: "CEMA Refinance?",
    options: booleanOptions
  },
  CondoCertificateComments: {
    fieldKey: ["CondoCertificateComments"],
    fieldType: FieldType.RichText,
    label: "Condo Certificate Comments"
  },
  CondoCertificateFeesCollectedIndicator: {
    fieldKey: ["CondoCertificateFeesCollectedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Condo Cert. Fees Collected?",
    options: booleanOptions
  },
  ContractExecutionDate: {
    fieldKey: ["ContractExecutionDate"],
    fieldType: FieldType.Date,
    label: "Contract Execution Date"
  },
  FloodInsurance: {
    DeductibleAmount: {
      fieldKey: ["FloodInsurance", "DeductibleAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Deductible"
    },
    DwellingCoverageAmount: {
      fieldKey: ["FloodInsurance", "DwellingCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Dwelling Coverage Amount"
    },
    InsurancePremiumPaymentType: {
      fieldKey: ["FloodInsurance", "InsurancePremiumPaymentType"],
      fieldType: FieldType.SingleSelect,
      label: "Flood Insurance Premium Payment",
      options: createOptionsFromMap(FloodInsurancePremiumPaymentType)
    },
    PolicyExpirationDate: {
      fieldKey: ["FloodInsurance", "PolicyExpirationDate"],
      fieldType: FieldType.Date,
      label: "Flood Insurance Policy Expiration Date"
    },
    PolicyNumber: {
      fieldKey: ["FloodInsurance", "PolicyNumber"],
      fieldType: FieldType.String,
      label: "Flood Insurance Policy Number"
    }
  },
  FloodInsuranceAgency: {
    ContactPointEmailValue: {
      fieldKey: ["FloodInsuranceAgency", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Flood Insurance Agency Email Address"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["FloodInsuranceAgency", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Flood Insurance Agency Phone Number"
    },
    FullName: {
      fieldKey: ["FloodInsuranceAgency", "FullName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency"
    },
    RepresentativeFullName: {
      fieldKey: ["FloodInsuranceAgency", "RepresentativeFullName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agent"
    },
    AddressLineText: {
      fieldKey: ["FloodInsuranceAgency", "AddressLineText"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Address Line 1"
    },
    AddressUnitIdentifier: {
      fieldKey: ["FloodInsuranceAgency", "AddressUnitIdentifier"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Address Line 2"
    },
    CityName: {
      fieldKey: ["FloodInsuranceAgency", "CityName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency City"
    },
    CountyName: {
      fieldKey: ["FloodInsuranceAgency", "CountyName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency County"
    },
    PostalCode: {
      fieldKey: ["FloodInsuranceAgency", "PostalCode"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Zip Code"
    },
    StateCode: {
      fieldKey: ["FloodInsuranceAgency", "StateCode"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateCode),
      label: "Flood Insurance Agency State Code"
    },
    StateName: {
      fieldKey: ["FloodInsuranceAgency", "StateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateName),
      label: "Flood Insurance Agency State Name"
    }
  },
  MortgageeContactFullName: {
    fieldKey: ["MortgageeContactFullName"],
    fieldType: FieldType.String,
    label: "Mortgagee Contact"
  },
  MortgageeContactPointEmailValue: {
    fieldKey: ["MortgageeContactPointEmailValue"],
    fieldType: FieldType.Email,
    label: "Mortgagee Email Address"
  },
  MortgageeContactPointTelephoneValue: {
    fieldKey: ["MortgageeContactPointTelephoneValue"],
    fieldType: FieldType.Phone,
    label: "Mortgagee Phone Number"
  },
  MortgageeFullName: {
    fieldKey: ["MortgageeFullName"],
    fieldType: FieldType.String,
    label: "Mortgagee"
  },
  PropertyDetailComments: {
    fieldKey: ["PropertyDetailComments"],
    fieldType: FieldType.RichText,
    label: "Property Detail Comments"
  },
  PropertyInsurance: {
    DeductibleAmount: {
      fieldKey: ["PropertyInsurance", "DeductibleAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Deductible"
    },
    DwellingCoverageAmount: {
      fieldKey: ["PropertyInsurance", "DwellingCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Dwelling Coverage Amount"
    },
    InsurancePremiumPaymentType: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumPaymentType"],
      fieldType: FieldType.SingleSelect,
      label: "Property Insurance Premium Payment",
      options: createOptionsFromMap(PropertyInsurancePremiumPaymentType)
    },
    LiabilityCoverageAmount: {
      fieldKey: ["PropertyInsurance", "LiabilityCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Liability Coverage Amount"
    },
    PolicyExpirationDate: {
      fieldKey: ["PropertyInsurance", "PolicyExpirationDate"],
      fieldType: FieldType.Date,
      label: "Property Insurance Expiration Date"
    },
    PropertyInsurancePolicyIdentifier: {
      fieldKey: ["PropertyInsurance", "PropertyInsurancePolicyIdentifier"],
      fieldType: FieldType.String,
      label: "Property Insurance Policy Number"
    },
    RentalLossCoverageAmount: {
      fieldKey: ["PropertyInsurance", "RentalLossCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Loss of Rents Coverage Amount"
    },
    ReplacementCoverageIndicator: {
      fieldKey: ["PropertyInsurance", "ReplacementCoverageIndicator"],
      fieldType: FieldType.Boolean,
      label: "Property Insurance Replacement Coverage",
      options: booleanOptions
    },
    BuilderRiskInsurance: {
      fieldKey: ["PropertyInsurance", "BuilderRiskInsurance"],
      fieldType: FieldType.Money,
      label: "Builder's Risk Insurance"
    }
  },
  PropertyInsuranceAgency: {
    InsuranceAgentFullName: {
      fieldKey: ["PropertyInsuranceAgency", "InsuranceAgentFullName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agent"
    },
    ContactPointEmailValue: {
      fieldKey: ["PropertyInsuranceAgency", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Property Insurance Agency Email Address"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["PropertyInsuranceAgency", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Property Insurance Agency Phone Number"
    },
    FullName: {
      fieldKey: ["PropertyInsuranceAgency", "FullName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency"
    },

    AddressLineText: {
      fieldKey: ["PropertyInsuranceAgency", "AddressLineText"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Address Line 1"
    },
    AddressUnitIdentifier: {
      fieldKey: ["PropertyInsuranceAgency", "AddressUnitIdentifier"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Address Line 2"
    },
    CityName: {
      fieldKey: ["PropertyInsuranceAgency", "CityName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency City"
    },
    CountyName: {
      fieldKey: ["PropertyInsuranceAgency", "CountyName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency County Name"
    },
    PostalCode: {
      fieldKey: ["PropertyInsuranceAgency", "PostalCode"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Zip Code"
    },
    StateCode: {
      fieldKey: ["PropertyInsuranceAgency", "StateCode"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateCode),
      label: "Property Insurance Agency State Code"
    },
    StateName: {
      fieldKey: ["PropertyInsuranceAgency", "StateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateName),
      label: "Property Insurance Agency State Name"
    }
  },
  PSAAddendumIndicator: {
    fieldKey: ["PSAAddendumIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Addendum",
    options: booleanOptions
  },
  PSABuyerBorrowerMismatchIndicator: {
    fieldKey: ["PSABuyerBorrowerMismatchIndicator"],
    fieldType: FieldType.Boolean,
    label: "Buyer and Borrower Mismatch?",
    options: booleanOptions
  },
  PSAExtensionFormIndicator: {
    fieldKey: ["PSAExtensionFormIndicator"],
    fieldType: FieldType.Boolean,
    label: "Extension Form",
    options: booleanOptions
  },
  PSASellerOwnerRecordMismatchIndicator: {
    fieldKey: ["PSASellerOwnerRecordMismatchIndicator"],
    fieldType: FieldType.Boolean,
    label: "Seller and Owner of record mismatch?",
    options: booleanOptions
  },
  InternalL1CRefinanceIndicator: {
    fieldKey: ["InternalL1CRefinanceIndicator"],
    fieldType: FieldType.Boolean,
    label: "Internal L1C Refinance?",
    options: booleanOptions
  },
  defaultInterestPaymentsOrFeesNotedIndicator: {
    fieldKey: ["defaultInterestPaymentsOrFeesNotedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Default Interest Payments or Fees Noted?",
    options: booleanOptions
  },
  ConstructionBudgetAssessment: modifyFromSpec({
    fieldSpecs: baseSchemaPropertySpecs.ConstructionBudgetAssessment,
    changes: {
      options: createOptionsFromMap(limaConstructionBudgetAssessment)
    }
  }),
  LeaseStatusType: modifyFromSpec({
    fieldSpecs: baseSchemaPropertySpecs.LeaseStatusType,
    changes: {
      options: createOptionsFromMap(limaLeaseStatus)
    }
  }),
  PropertyType: modifyFromSpec({
    fieldSpecs: baseSchemaPropertySpecs.PropertyType,
    changes: {
      options: createOptionsFromMap(limaPropertyType)
    }
  })
};

const overriddenBaseSchemaPropertySpecs: DeepPartial<
  typeof baseSchemaPropertySpecs
> = {
  PropertyInspection: {
    ConstructionAnalystFullName: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.ConstructionAnalystFullName,
      changes: {
        label: "Construction Analyst"
      }
    }),
    InspectionRequestOrderedDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.InspectionRequestOrderedDate,
      changes: {
        label: "Date Order Requested PruVan"
      }
    }),
    InspectionDocumentsReceivedDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection
          .InspectionDocumentsReceivedDate,
      changes: {
        label: "All Docs Received Date"
      }
    }),
    InspectionInitialDelayReasonStatusType: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection
          .InspectionInitialDelayReasonStatusType,
      changes: {
        label: "Initial Inspection Delay Reasons"
      }
    }),
    InspectionInitialRequestDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.InspectionInitialRequestDate,
      changes: {
        label: "Initial Request Date"
      }
    }),
    InspectionOrderProcessedDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.InspectionOrderProcessedDate,
      changes: {
        label: "Inspection Processed Date"
      }
    }),
    InspectionOrderReceivedDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.InspectionOrderReceivedDate,
      changes: {
        label: "Inspection Received Date"
      }
    }),
    InspectionReportDueDate: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.PropertyInspection.InspectionReportDueDate,
      changes: {
        label: "Report Due Date"
      }
    }),
    InspectorFullName: modifyFromSpec({
      fieldSpecs: baseSchemaPropertySpecs.PropertyInspection.InspectorFullName,
      changes: {
        label: "Inspector Name"
      }
    })
  }
};

const pathsToOmitFromBase: DotNestedKeys<BaseSchemaProperty>[] = [
  "PropertyType",
  "LeaseStatusType",
  "ConstructionBudgetAssessment",
  "Appraisal.AppraisalType",
  "Appraisal.ConditionRatingType",
  "LeasedFinancedUnits.unit0.LeaseStatusType",
  "LeasedFinancedUnits.unit1.LeaseStatusType",
  "LeasedFinancedUnits.unit2.LeaseStatusType",
  "LeasedFinancedUnits.unit3.LeaseStatusType",
  "LeasedFinancedUnits.unit4.LeaseStatusType",
  "LeasedFinancedUnits.unit5.LeaseStatusType",
  "LeasedFinancedUnits.unit6.LeaseStatusType",
  "LeasedFinancedUnits.unit7.LeaseStatusType",
  "LeasedFinancedUnits.unit8.LeaseStatusType",
  "AllocatedLoanAmount",
  "ConstructionHoldbackAmount",
  "GroundLeaseExpirationDate",
  "ClosingCostsAmount",
  "aggregations.CostBasisAmount",
  "aggregations.LoanToValueRatePercent",
  "aggregations.AsIsLoanToValueRatePercent",
  "aggregations.LTARVRatePercent",
  "aggregations.LTTCRatePercent",
  "aggregations.MonthlyTaxEscrowAmount",
  "aggregations.MonthlyInsuranceEscrowAmount",
  "aggregations.MonthlyEscrowPaymentsAmount",
  "aggregations.ConstructionBudgetHoldbackPercent",
  "InitialFundedAmount",
  "CityMonthlyTaxAmount",
  "CountyMonthlyTaxAmount",
  "SpecialAssessmentMonthlyTaxAmount",
  "FloodInsurance.MonthlyInsurancePremiumAmount",
  "PropertyInsurance.MonthlyInsurancePremiumAmount"
];

const limaPropertySpecs: FullLimaPropertyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaPropertySpecs,
    paths: pathsToOmitFromBase
  }),
  overriddenBaseSchemaPropertySpecs,
  limaPropertyFieldsSpecs,
  integrationSpecs.property.FloodCertification.ServiceLink.spec,
  integrationSpecs.property.PropertyReport.SiteX.spec,
  integrationSpecs.property.RentalData.RentRange.spec
);

export const fullLimaPropertySpecs = createSpecWithFieldMeta({
  spec: limaPropertySpecs
});
