import { Button, Flex } from "@chakra-ui/react";
import { StatusCode } from "@elphi/types";
import { elphiTheme } from "../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../constants/common";
import { ModalContainer } from "../../../modal-container/ModalContainer";
import { ProvidersFormStepContent } from "../wizard/steps/provider-step-content/ProvidersFormStepContent";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../wizard/steps/provider-step-content/providersFormStep.utils";
import { EditModalProps } from "./ServiceProviderEditModal";
import { useServiceProviderEditModalHooks } from "./serviceProviderModal.hooks";

export const PartyServiceProviderEditModal = (props: EditModalProps) => {
  const { isShow, onClose } = props;
  const {
    companyEditForm,
    branchEditForm,
    repEditForm,
    isProviderFormValid,
    selectedDeal,
    selectedConfiguration,
    handleProviderFormValidation,
    updateHandler,
    isLoading
  } = useServiceProviderEditModalHooks(props);

  const {
    onChange: onChangeCompanyState,
    state: companyState,
    setState: setCompanyState,
    selectedProviderId: selectedCompanyId,
    setSelectedProviderId: setSelectedCompanyId
  } = companyEditForm;

  const {
    onChange: onChangeBranchState,
    state: branchState,
    setState: setBranchState,
    selectedProviderId: selectedBranchId,
    setSelectedProviderId: setSelectedBranchId
  } = branchEditForm;

  const {
    onChange: onChangeRepState,
    state: repState,
    setState: setRepState,
    selectedProviderId: selectedRepId,
    setSelectedProviderId: setSelectedRepId
  } = repEditForm;

  const handleOnSubmit = () => {
    if (
      !selectedConfiguration ||
      !isProviderFormValid ||
      !companyEditForm.state?.id ||
      !branchEditForm.state?.id ||
      !repEditForm.state.id
    ) {
      return;
    }

    updateHandler({
      dealId: selectedDeal ? selectedDeal?.id : EMPTY,
      entityIds: [],
      entityType: selectedConfiguration.entityType,
      company: getCompanyTemplate(companyEditForm.state),
      branch: getBranchTemplate(branchEditForm.state),
      rep: getRepTemplate(repEditForm.state)
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        onClose();
      }
    });
  };

  return (
    <ModalContainer
      maxWidth={1110}
      isShow={isShow}
      onCloseModal={onClose}
      header={"Edit a Service Provider"}
      body={
        <Flex direction="column" gap="14px">
          <ProvidersFormStepContent
            companyForm={{
              isReadOnly: true,
              state: companyState,
              onChangeState: onChangeCompanyState,
              setState: (selected) => {
                setCompanyState(selected);
              },
              selectedProviderId: selectedCompanyId,
              setSelectedProviderId: (id) => {
                setSelectedCompanyId(id);
              }
            }}
            branchForm={{
              isReadOnly: true,
              state: branchState,
              onChangeState: onChangeBranchState,
              setState: (selected) => {
                setBranchState(selected);
              },
              selectedProviderId: selectedBranchId,
              setSelectedProviderId: (id) => {
                setSelectedBranchId(id);
              }
            }}
            repForm={{
              isReadOnly: true,
              state: repState,
              onChangeState: onChangeRepState,
              setState: (selected) => {
                setRepState(selected);
              },
              selectedProviderId: selectedRepId,
              setSelectedProviderId: (id) => {
                setSelectedRepId(id);
              }
            }}
            onFormValidation={handleProviderFormValidation}
            showAttached={false}
          />
          <Button
            {...elphiTheme.components.light.button.primary}
            isDisabled={!isProviderFormValid}
            isLoading={isLoading}
            onClick={handleOnSubmit}
            alignSelf={"flex-end"}
          >
            Save
          </Button>
        </Flex>
      }
    />
  );
};
