import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useBoolean } from "@chakra-ui/react";
import { useEffect } from "react";
import { elphiTheme } from "../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../hooks/rolodexConfiguration.hooks";
import { ConfigurationModal } from "./modal/ConfigurationModal";
import { ConfigurationSearch } from "./search/ConfigurationSearch";
import { ConfigurationTable } from "./table/ConfigurationTable";

export const ServiceDomainConfiguration = () => {
  const [
    showConfigurationModal,
    { on: onOpenConfigurationModal, off: onCloseConfigurationModal }
  ] = useBoolean();

  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();

  useEffect(() => {
    setSelectedConfiguration(EMPTY);
  }, []);

  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <ConfigurationSearch
            currentValue={
              selectedConfiguration ? selectedConfiguration.id : EMPTY
            }
            onSelect={setSelectedConfiguration}
          />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-conf-btn"
            onClick={onOpenConfigurationModal}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create Configuration
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"}>
        <ConfigurationTable />
      </Flex>
      <ConfigurationModal
        isShow={showConfigurationModal}
        onClose={onCloseConfigurationModal}
      />
    </Box>
  );
};
