import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import {
  ActionListWrapper,
  ComponentWrapper,
  TreeNodeLabel,
  TreeNodeLayout,
  TreeNodeSublabel,
  TreeNodeWrapper
} from "./style-components/NodeLabelComponents";
import { TreeNodeProps } from "./types/Tree.types";

const Node = <T extends unknown>(props: TreeNodeProps<T>) => {
  const handleNodeClick = () => {
    props.operations.setSelectedNode(props.node);
    props.handleNodeSelect(props.node);
  };

  const isSelected = useMemo(() => {
    return props.operations.selectedNodeKey === props.node.nodeKey;
  }, [props.operations.selectedNodeKey, props.node.nodeKey, props.node.id]);

  const isDuplicate = useMemo(() => {
    return props.operations.selectedNodeId === props.node.id;
  }, [props.operations.selectedNodeId, props.node.id]);

  const optionalLabelFormat = useMemo(
    () =>
      props.node.label && props.node.sublabel ? (
        <>
          <TreeNodeLabel>{props.node.label}</TreeNodeLabel>
          <TreeNodeSublabel>{props.node.sublabel}</TreeNodeSublabel>
        </>
      ) : null,
    [props.node.label, props.node.sublabel]
  );

  const actions = useMemo(
    () => (
      <Flex p="5px" h="100%">
        {props.nodeActions.map((action, i) => (
          <Box key={i} p="5px">
            <Tooltip hasArrow label={action.tooltip} placement="top">
              <Box
                onClick={
                  action?.isDisabled?.(props.node)
                    ? undefined
                    : () => action?.onClick?.(props.node)
                }
              >
                {!action?.isDisabled?.(props.node) ? (
                  action.component
                ) : (
                  <div></div>
                )}
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Flex>
    ),
    [props.operations.selectedNodeKey, props.nodeActions]
  );

  return (
    <TreeNodeWrapper
      // variant={
      //   isSelected ? "selected" : isDuplicate ? "duplicate" : "unselected"
      // }
      onClick={handleNodeClick}
      bg={
        isSelected
          ? elphiTheme.components.light.treeNode.selected.bg
          : isDuplicate
          ? elphiTheme.components.light.treeNode.duplicate.bg
          : elphiTheme.components.light.treeNode.unselected.bg
      }
      boxShadow={"dark-lg"}
    >
      <TreeNodeLayout>
        <ComponentWrapper>
          {(props?.customComponent && props?.customComponent(props.node)) ||
            optionalLabelFormat}
        </ComponentWrapper>
        <ActionListWrapper>{actions}</ActionListWrapper>
      </TreeNodeLayout>
    </TreeNodeWrapper>
  );
};

export default Node;
