import { Flex, ListItem, OrderedList, Spinner, Text } from "@chakra-ui/react";
import { RolodexServiceProvider, ServiceProviderType } from "@elphi/types";
import { Fragment, ReactNode, useEffect, useMemo } from "react";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { useRolodexBranchRepRelation } from "../../../../hooks/rolodexBranchRepRelation.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { ModalContainer } from "../../../modal-container/ModalContainer";
import { ServiceProviderNode } from "../../service-provider/tree/serviceProviderTree.utils";
import {
  ServiceProviderTypeMap,
  buildOption
} from "../utils/serviceProvider.utils";

export const ServiceProviderDeleteModal = (props: {
  isShow: boolean;
  onClose: () => void;
  selectedServiceProvider: RolodexServiceProvider;
}) => {
  const { isShow, onClose, selectedServiceProvider } = props;
  const label = buildOption(selectedServiceProvider)?.label || NOT_AVAILABLE;
  const {
    serviceProviderState,
    cascadeDeleteHandler,
    cascadeDeleteResponse,
    resetPreprocessed,
    preprocessed
  } = useServiceProviderHooks();

  useEffect(() => {
    if (isShow) {
      cascadeDeleteHandler({
        id: selectedServiceProvider.id,
        preprocess: true
      });
    } else {
      resetPreprocessed();
    }
  }, [isShow]);

  const listItems = useMemo(() => {
    return preprocessed.serviceProviderIds.map((id) => {
      const item = serviceProviderState.entities[id];
      if (!item) {
        return (
          <ListItem key={id} fontWeight={"bold"}>
            {id}
          </ListItem>
        );
      }
      if (item.id === selectedServiceProvider.id) {
        return <Fragment key={id}></Fragment>;
      }
      const label = buildOption(item)?.label || NOT_AVAILABLE;
      return (
        <ListItem key={id} fontWeight={"bold"}>
          {label} - {ServiceProviderTypeMap[item.type]}
        </ListItem>
      );
    });
  }, [preprocessed.serviceProviderIds, serviceProviderState.entities]);

  const onConfirm = () => {
    cascadeDeleteHandler({ id: selectedServiceProvider.id, preprocess: false });
  };

  const showNotes = preprocessed.serviceProviderIds.length > 1;

  return (
    <ModalContainerWrapper
      header={<>{`Deleting Service Provider: ${label}`}</>}
      isShow={isShow}
      onClose={onClose}
      preprocessed={preprocessed}
      onConfirm={onConfirm}
      isLoading={
        cascadeDeleteResponse.isLoading || cascadeDeleteResponse.isFetching
      }
    >
      <>
        <Flex>
          Are you sure you want to permanently delete
          <Text fontWeight={"bold"} mx={2}>
            {label}
          </Text>
          from the system?
        </Flex>
        {showNotes && (
          <Flex my={6}>
            <Text fontWeight={"bold"} mr={2}>
              Note:
            </Text>
            This action will also permanently delete from the system the
            following:
          </Flex>
        )}
        <OrderedList>{listItems}</OrderedList>
        <Text fontWeight={"bold"} color={"red.500"} mt={10} mb={2}>
          This action is irreversible. Are you sure you want to proceed with
          this action?
        </Text>
      </>
    </ModalContainerWrapper>
  );
};

export const BranchRepRelationRemoveModal = (props: {
  isShow: boolean;
  onClose: () => void;
  selectedNode: ServiceProviderNode;
}) => {
  const { isShow, onClose, selectedNode } = props;
  const relationId = selectedNode?.branchRepRelationId || EMPTY;
  const { serviceProviderState } = useServiceProviderHooks();
  const {
    cascadeDeleteHandler,
    cascadeDeleteResponse,
    resetPreprocessed,
    preprocessed
  } = useRolodexBranchRepRelation();

  useEffect(() => {
    if (isShow) {
      cascadeDeleteHandler({ id: relationId, preprocess: true });
    } else {
      resetPreprocessed();
    }
  }, [isShow, relationId]);

  const [branchId, representativeId] = relationId.split("_");
  const branchProvider = serviceProviderState.entities[branchId];
  const repProvider = serviceProviderState.entities[representativeId];
  const companyProvider =
    serviceProviderState.entities[
      branchProvider?.type === ServiceProviderType.Branch
        ? branchProvider.companyId
        : EMPTY
    ];

  const companyLabel = companyProvider
    ? buildOption(companyProvider)?.label
    : NOT_AVAILABLE;
  const branchLabel = branchProvider
    ? buildOption(branchProvider)?.label
    : NOT_AVAILABLE;
  const repLabel = repProvider
    ? buildOption(repProvider)?.label
    : NOT_AVAILABLE;

  const onConfirm = () => {
    cascadeDeleteHandler({ id: relationId, preprocess: false });
  };

  const showNotes =
    preprocessed.serviceProviderIds.length > 0 &&
    preprocessed.serviceProviderIds.length > 0;

  const listItems = useMemo(() => {
    return preprocessed.serviceProviderIds.map((id) => {
      const item = serviceProviderState.entities[id];
      if (!item) {
        return (
          <ListItem key={id} fontWeight={"bold"}>
            {id}
          </ListItem>
        );
      }
      const label = buildOption(item)?.label || NOT_AVAILABLE;
      return (
        <ListItem key={id} fontWeight={"bold"}>
          {label} - {ServiceProviderTypeMap[item.type]}
        </ListItem>
      );
    });
  }, [preprocessed.serviceProviderIds, serviceProviderState.entities]);

  return (
    <ModalContainerWrapper
      header={`Removing Service Provider Relation: ${repLabel} - ${branchLabel}`}
      isShow={isShow}
      onClose={onClose}
      preprocessed={preprocessed}
      onConfirm={onConfirm}
      isLoading={
        cascadeDeleteResponse.isLoading || cascadeDeleteResponse.isFetching
      }
    >
      <>
        <Flex>
          Are you sure you want to remove
          <Text fontWeight={"bold"} mx={2}>
            {repLabel}
          </Text>
          from
          <Text fontWeight={"bold"} mx={2}>
            {branchLabel} - {companyLabel}
          </Text>
        </Flex>
        {showNotes && (
          <Flex my={6}>
            <Text fontWeight={"bold"} mr={2}>
              Note:
            </Text>
            This action will also permanently delete from the system the
            following:
          </Flex>
        )}
        <OrderedList>{listItems}</OrderedList>
        {showNotes && (
          <Text fontWeight={"bold"} color={"red.500"} mt={10} mb={2}>
            This action is irreversible. Are you sure you want to proceed with
            this action?
          </Text>
        )}
      </>
    </ModalContainerWrapper>
  );
};

const ModalContainerWrapper = (props: {
  isShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  header: ReactNode | string;
  preprocessed: {
    serviceProviderIds: string[];
    relationIds: string[];
  };
  children: ReactNode;
}) => {
  const {
    isShow,
    onClose,
    header,
    preprocessed,
    isLoading,
    onConfirm,
    children
  } = props;

  const haveProcessed =
    !!preprocessed.relationIds.length ||
    !!preprocessed.serviceProviderIds.length;

  const isLoadingPreprocessed = isLoading && !haveProcessed;

  return (
    <ModalContainer
      maxWidth={1100}
      isShow={isShow}
      onCloseModal={onClose}
      header={header}
      body={<>{isLoadingPreprocessed ? <Spinner /> : <>{children}</>}</>}
      submit={{
        onConfirm: onConfirm,
        showClose: true,
        isDisabled: isLoadingPreprocessed,
        isLoading: isLoading && haveProcessed
      }}
    />
  );
};
