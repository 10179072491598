import { Box, Tooltip } from "@chakra-ui/react";
import { FieldType, Task, TaskPendingReviewType } from "@elphi/types";
import ScrollableSections from "../ScrollableSections";
import { OnChangeInput, SectionHeader } from "../form-builder/FormBuilder";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { TaskFormState } from "./common.task.types";

type PosTaskActionContainerProps = {
  selectedTask: Task;
  state: TaskFormState;
  onChange: (v: OnChangeInput) => void;
};

const taskPendingReviewOptionMap: {
  [p in TaskPendingReviewType]: string;
} = {
  [TaskPendingReviewType.Borrower]: "Borrower",
  [TaskPendingReviewType.Lender]: "Lender",
  [TaskPendingReviewType.None]: "None"
} as const;

export const PosTaskActionContainer = (props: PosTaskActionContainerProps) => {
  const { state, selectedTask, onChange } = props;
  const pendingReviewOfOptions = selectedTask?.availablePendingReviewOf?.map(
    (k) => {
      return {
        label: taskPendingReviewOptionMap[k],
        value: k
      };
    }
  );
  return (
    <Box bgColor={"white"}>
      <ScrollableSections
        customKey={"pos-actions"}
        sections={[
          {
            header: <SectionHeader header={"POS Actions"} />,
            body: (
              <Tooltip
                label={
                  "Indicate who is responsible for reviewing or revising the submission. This status will be visible in the POS."
                }
                hasArrow
                placement={"right-start"}
              >
                <Box w={"250px"}>
                  <StyledInputBuilder
                    options={pendingReviewOfOptions}
                    label={"Pending Review Of"}
                    onChange={(e) => {
                      onChange({
                        fieldKey: ["pendingReviewOf"],
                        fieldType: FieldType.SingleSelect,
                        value: e.target.value
                      });
                    }}
                    isClearable={true}
                    isValid={true}
                    fieldType={FieldType.SingleSelect}
                    currentValue={state.tasks[selectedTask.id]?.pendingReviewOf}
                  />
                </Box>
              </Tooltip>
            )
          }
        ]}
      />
    </Box>
  );
};
