// Need to use the React-specific entry point to allow generating React hooks
import {
  Deal,
  DealPartyRelation,
  DealProperty,
  DealTableRowData,
  DealUser,
  LOSUser,
  Party,
  Property
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isEmpty } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import { OmitCreate } from "../../../firebase/firebase.types";
import serviceBuilder from "../builders/api.builder";
import { dealPartyEntityAdapter } from "../deal-party-relation/dealPartyRelation.adapter";
import { dealPropertyEntityAdapter } from "../deal-property-relation/dealPropertyRelation.adapter";
import { dealUserEntityAdapter } from "../deal-user-relation/dealUserRelation.adapter";
import { losUserEntityAdapter } from "../los-user/losUser.adapter";
import { partyEntityAdapter } from "../party/party.adapter";
import { propertyEntityAdapter } from "../property/property.adapter";
import { dealEntityAdapter } from "./deal.adapter";

// Define a service using a base URL and expected endpoints
export const baseDealApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "dealApi",
  tagTypes: ["Deal", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "deal"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: dealEntityAdapter
  })
});

export type PaginateV2Response = {
  nextCursor?: string;
  hasMore?: boolean;
  page: {
    deal: Deal[];
    dealUser: DealUser[];
    party: Party[];
    dealParty: DealPartyRelation[];
    properties: Property[];
  };
};
export const RECORD_NOT_EXISTS_RESPONSE = "Record does not exist";

export const dealApi = baseDealApi.injectEndpoints({
  endpoints: (builder) => ({
    paginateV2: builder.query<
      PaginateV2Response,
      {
        limit?: string;
        cursor?: string;
        userId?: string;
        dealId?: string;
        milestone?: Deal["DealMetadata"]["milestone"][];
        lender?: Deal["LenderIdentifier"][];
        estimatedClosingDateRange?: { from?: string; to?: string };
        milestoneOp: "in" | "not-in";
        lenderOp: "in" | "not-in";
      }
    >({
      query: (r) => {
        const urlCursor = r.cursor ? `cursor=${r.cursor}` : null;
        const urlLimit = r.limit ? `limit=${r.limit}` : null;
        const urlUser = r.userId ? `user_id=${r.userId}` : null;
        const urlDeal = r.dealId ? `deal_id=${r.dealId}` : null;

        const urlMilestoneOp = `milestone_op=${r.milestoneOp}`;
        const urlLenderOp = `lender_op=${r.lenderOp}`;
        const urlMilestone = r.milestone?.length
          ? `milestone=${r.milestone.join(";")}`
          : null;
        const urlLender = r.lender?.length
          ? `lender=${r.lender.join(";")}`
          : null;

        const estimatedClosingDateRangeValues = !isEmpty(
          r?.estimatedClosingDateRange
        )
          ? [r.estimatedClosingDateRange?.from, r.estimatedClosingDateRange?.to]
          : null;

        const urlDateRange = estimatedClosingDateRangeValues?.length
          ? `estimated_closing_date_range=${estimatedClosingDateRangeValues.join(
              ";"
            )}`
          : null;

        const queryParams = [
          urlCursor,
          urlLimit,
          urlUser,
          urlDeal,
          urlMilestone,
          urlLender,
          urlMilestoneOp,
          urlLenderOp,
          urlDateRange
        ]
          .filter(removeEmpty)
          .join("&");
        const query = queryParams ? `?${queryParams}` : "";
        const url = `/v2/paginate${query}`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<Deal>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: Deal[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        const adapter = dealEntityAdapter.addMany(
          dealEntityAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          hasMore: response.hasMore,
          nextCursor: response.nextCursor
        };
      }
    }),
    getDealBatch: builder.query<{ deals: EntityState<Deal> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: Deal[] }) => {
        const adapter = dealEntityAdapter.addMany(
          dealEntityAdapter.getInitialState(),
          response.results
        );
        return { deals: adapter };
      }
    }),
    getAdditionalDataForDeals: builder.query<
      DealTableRowData,
      { ids: string[] }
    >({
      query: (ids) => {
        return {
          url: `/get-additional-data-for-deals`,
          method: "POST",
          body: ids
        };
      }
    }),
    getDealQuote: builder.query<
      { deal: Deal; pricingValidationErrors: string },
      { id: string }
    >({
      query: (r) => {
        return {
          url: `quote/${r.id}`,
          method: "GET"
        };
      }
    }),
    getDealQuoteData: builder.query<Deal, { id: string }>({
      query: (r) => {
        return {
          url: `quote/getQuoteData/${r.id}`,
          method: "GET"
        };
      }
    }),
    updateDealQuote: builder.mutation<Deal, { id: string; dealQuoteData: any }>(
      {
        query: (r) => {
          return {
            url: `quote/update`,
            method: "PUT",
            body: r
          };
        }
      }
    ),
    updateRateLock: builder.mutation<
      Deal,
      { quoteId: string; startDate: string; endDate: string }
    >({
      query: (r) => {
        return {
          url: `quote/set-ratelock`,
          method: "PUT",
          body: r
        };
      }
    }),
    getRateLock: builder.query<{ result: string }, { id: string }>({
      query: (r) => {
        return {
          url: `quote/get-ratelock/${r.id}`,
          method: "GET"
        };
      }
    }),
    postRateLock: builder.mutation<
      Deal,
      { quoteId: string; startDate: string; endDate: string }
    >({
      query: (r) => {
        return {
          url: `quote/set-ratelock`,
          method: "POST",
          body: r
        };
      }
    }),
    dealParties: builder.query<
      {
        relations: EntityState<DealPartyRelation>;
        parties: EntityState<Party>;
      },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/deal-parties`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealPartyRelation[]; parties: Party[] };
      }) => {
        const partyAdapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results.parties
        );
        const dealPartyAdapter = dealPartyEntityAdapter.addMany(
          dealPartyEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealPartyAdapter,
          parties: partyAdapter
        };
      }
    }),
    addProperties: builder.mutation<
      {
        relations: string[];
        properties: string[];
      },
      {
        dealId: string;
        properties: ({ id: string } | OmitCreate<Property>)[];
      }
    >({
      query: (r) => {
        return {
          url: `/add-properties`,
          method: "POST",
          body: { dealId: r.dealId, properties: r.properties }
        };
      }
    }),
    dealProperties: builder.query<
      {
        relations: EntityState<DealProperty>;
        properties: EntityState<Property>;
      },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/deal-properties`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealProperty[]; properties: Property[] };
      }) => {
        const propertyAdapter = propertyEntityAdapter.addMany(
          propertyEntityAdapter.getInitialState(),
          response.results.properties
        );
        const dealPropertyAdapter = dealPropertyEntityAdapter.addMany(
          dealPropertyEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealPropertyAdapter,
          properties: propertyAdapter
        };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { deals: ({ id: string } & Partial<Deal>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    uploadPortfolioFile: builder.mutation<
      { properties: string[]; dealProperty: string[] },
      { file: File; dealId: string }
    >({
      query: (r) => {
        const formData = new FormData();
        formData.append("file", r.file);
        return {
          url: `/upload/${r.dealId}`,
          method: "POST",
          body: formData
        };
      }
    }),
    dealUsers: builder.query<
      { relations: EntityState<DealUser>; users: EntityState<LOSUser> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/deal-users`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealUser[]; users: LOSUser[] };
      }) => {
        const losUserAdapter = losUserEntityAdapter.addMany(
          losUserEntityAdapter.getInitialState(),
          response.results.users
        );
        const dealUserAdapter = dealUserEntityAdapter.addMany(
          dealUserEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealUserAdapter,
          users: losUserAdapter
        };
      }
    })
  })
});
