import { Deal } from "@elphi/types";
import { MofinProperty } from "@elphi/types/entities/mofin/property";
import {
  hideFixNFlip,
  hideRental30,
  hideRental30OrEmpty
} from "../../../../components/application/sections/sections.utils";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import {
  appraisalEffectiveDate,
  appraisalForm1007RequiredIndicator,
  appraisalManagementCompanyName,
  appraisalManagementContactPointEmailValue,
  appraisalManagementContactPointTelephoneValue,
  appraisalManagementRepresentativeFullName,
  appraisalOrderedDate,
  appraisalType,
  appraisalValueDerivationType,
  conditionRatingType,
  propertyAccessInformation,
  propertyAreaValue,
  propertyCountyName,
  propertyStructureBuiltYear,
  propertyValuationAmount,
  propertyZoningComplianceRatingType,
  specificZoningClassification,
  subjectToPropertyValuationAmount,
  totalBathroomCount,
  totalBedroomCount
} from "../../base/inputs/property.inputs";
import { addRuleToInput, buildSection } from "../../utils/formBuilder.utils";
import { canBeRebuiltIndicator } from "../inputs/property.inputs";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";
import { MofinSections } from "../types";

const valuationSection = (r: {
  state: Partial<MofinProperty>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Valuation",
    inputs: [
      appraisalValueDerivationType,
      propertyValuationAmount,
      addRuleToInput({
        input: subjectToPropertyValuationAmount,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => !hideRental30OrEmpty(dealState)
          }
        ]
      }),
      appraisalOrderedDate,
      appraisalEffectiveDate,
      appraisalType,
      conditionRatingType,
      propertyAreaValue,
      totalBedroomCount,
      totalBathroomCount,
      propertyStructureBuiltYear,
      propertyCountyName,
      specificZoningClassification,
      propertyZoningComplianceRatingType,
      addRuleToInput({
        input: appraisalForm1007RequiredIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      appraisalManagementRepresentativeFullName,
      appraisalManagementContactPointTelephoneValue,
      appraisalManagementContactPointEmailValue,
      appraisalManagementCompanyName,
      propertyAccessInformation,
      addRuleToInput({
        input: canBeRebuiltIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(dealState)
          }
        ]
      })
    ],
    fieldSpecs: mofinSpecs.property.entitySpecs
  });
};

export const propertySectionBuilders: MofinSections["property"] = {
  valuationSection
};
