import { Box, BoxProps, Progress, Text } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { chakraComponents } from "chakra-react-select";
import { useRef } from "react";
import { OnChangeInput } from "./form-builder/FormBuilder";
import StyledInputBuilder, {
  DropDownIndicatorFirstControl,
  IndicatorsContainer,
  SelectInputBuilderProps
} from "./form-builder/InputBuilder";

export type SearchComponentProps = {
  query?: string;
  options: { label: string; value: string; isDisabled?: boolean }[];
  filterOption?: SelectInputBuilderProps["filterOption"];
  fieldType: FieldType.SingleSelect | FieldType.MultiSelect;
  onSelect:
    | ((item: string) => void)
    | ((item: string[]) => void)
    | ((item: OnChangeInput) => void);
  currentValue: string | string[];
  onInputChange?: (newInput: any) => void;
  hasMore?: boolean;
  isLoading: boolean;
  fetchMore?: () => void;
  label?: string;
  labelPosition?: "up" | "down" | "placeHolder";
  isReadOnly?: boolean;
  isDisabled?: boolean;
  hideSelectedOptions?: boolean;
  isClearable?: boolean;
  customComponent?: Partial<{
    [P in keyof typeof chakraComponents]: (props: any) => EmotionJSX.Element;
  }>;
  wrapperStyle?: BoxProps;
};

const SearchComponent = (props: SearchComponentProps) => {
  const ref = useRef<any>(null);
  return (
    <Box w="100%">
      <Box p="10px" {...props.wrapperStyle}>
        {(!props.labelPosition || props.labelPosition === "up") &&
          props.label && (
            <Text pl="3px" fontWeight={"bold"}>
              {props.label}
            </Text>
          )}
        <StyledInputBuilder
          isClearable={props.isClearable}
          forwardRef={ref}
          options={props.options || []}
          filterOption={props?.filterOption}
          label={props.label}
          onChange={(e) => {
            props.onSelect(e.target.value);
          }}
          onInputChange={(v) => {
            props?.onInputChange && props.onInputChange(v);
          }}
          isReadOnly={props.isReadOnly}
          isDisabled={props.isDisabled}
          isValid={true}
          customComponent={
            !props.customComponent
              ? props.fieldType !== FieldType.MultiSelect
                ? {
                    Control: DropDownIndicatorFirstControl,
                    IndicatorsContainer: IndicatorsContainer
                  }
                : {
                    Control: DropDownIndicatorFirstControl,
                    IndicatorsContainer: IndicatorsContainer
                  }
              : props.customComponent
          }
          chakraStyles={{
            valueContainer: (provided, _) => ({
              ...provided,
              p: 0,
              whiteSpace: "nowrap",
              fontSize: "12px",
              textOverflow: "ellipsis"
            }),

            option: (p, _) => {
              return {
                ...p,
                borderWidth: "1px"
                // borderColor: labelColor(label)
              };
            },
            indicatorSeparator: (provided, _) => ({
              ...provided,
              opacity: 0,
              p: 0
            }),
            dropdownIndicator: (prev, { selectProps: _ }) => ({
              ...prev,
              bgColor: "white"

              // "> svg": {
              //   transform: `rotate(${selectProps.menuIsOpen ? -270 : 0}deg)`,
              //   animation: `2s`
              // }
            })
          }}
          fieldType={props.fieldType}
          currentValue={props.currentValue}
          fetchMore={props.fetchMore}
          hasMore={props.hasMore}
          hideSelectedOptions={props.hideSelectedOptions}
        />
        {props.isLoading && (
          <Box minH={"10px"} h="10px" w={ref.current?.offsetWidth}>
            <Progress size="xs" isIndeterminate />
          </Box>
        )}
        {props.labelPosition === "down" && props.label && (
          <Text pl="3px" fontWeight={"light"}>
            {props.label}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default SearchComponent;
