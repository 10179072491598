import { RentalQuoteFields } from "./deal.quote.rental.types";
import { ShortTermQuoteFields } from "./deal.quote.short.types";

export enum QuoteType {
  ShortTerm = "ShortTerm",
  Rental = "Rental"
}

export type DealQuote = {
  quoteType?: QuoteType;
  aggregations: Partial<ShortTermQuoteFields & RentalQuoteFields>;
};
