import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import {
  Asset,
  Deal,
  DiscussionEntityType,
  ElphiEntityType,
  FieldType,
  IntegrationType,
  Party,
  Property,
  RolodexServiceProvider,
  Statement,
  Task,
  TaskType
} from "@elphi/types";
import { DevFeatureFlag, isIntegrationTaskGuard } from "@elphi/utils";
import { useEffect, useState } from "react";
import { AppConfig } from "../../config/appConfig";
import { useAssetHooks } from "../../hooks/asset.hooks";
import useDealHooks from "../../hooks/deal.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePropertyHooks } from "../../hooks/property.hooks";
import useTaskHooks from "../../hooks/task.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";
import DataToOrderInteractiveForm from "./data-to-order/DataToOrderInteractiveForm";
import DataToVerifyInteractiveForm from "./data-to-verify/DataToVerifyInteractiveForm";

import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import { useDealPropertyRelationHooks } from "../../hooks/dealPropertyRelation.hooks";
import { useDealPartyRelationHooks } from "../../hooks/dealpartyrelation.hooks";
import { useInsurancePolicyHooks } from "../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePartyAssetHooks } from "../../hooks/partyasset.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { useServiceProviderHooks } from "../../hooks/rolodexServiceProvider.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import useStatementHooks from "../../hooks/statement.hooks";
import { CreditReportTaskFormContainer } from "../credit-report/CreditReportTaskForm";
import { splitRelationId } from "../deal/utils/table.utils";
import { DiscussionBox } from "../discussion/DiscussionBox";
import { AttachedFieldStatusComponet } from "../form-builder/AttachedFieldStatusComponent";
import { PosTaskActionContainer } from "./PosTaskActionContainer";
import { TaskFileManager } from "./TaskFileManager";
import { TaskInstructionContainer } from "./TaskInstructionContainer";
import { TaskStatusDropDown } from "./TaskStatus";
import { TruncatableList } from "./TaskTable";
import { IntegrationFormContainer } from "./integrations/IntegrationFormContainer";
import { ServiceLinkFloodTaskFormContainer } from "./integrations/service-link/ServiceLinkFloodTaskFormContainer";
import {
  getDealIdentifier,
  getPartyName,
  getPropertyAddress
} from "./taskPrint.utils";
import {
  getFollowupTaskNames,
  getTaskEntityText,
  getTaskRowData
} from "./taskRowData.utils";

export type TaskFormState = {
  tasks: {
    [id: string]: { id: string } & Partial<Task>;
  };
  deals?: { [id: string]: { id: string } & Partial<Deal> };
  parties?: { [id: string]: { id: string } & Partial<Party> };
  properties?: { [id: string]: { id: string } & Partial<Property> };
  assets?: { [id: string]: { id: string } & Partial<Asset> };
  statements?: { [id: string]: { id: string } & Partial<Statement> };
  serviceProviders?: {
    [id: string]: { id: string } & Partial<RolodexServiceProvider>;
  };
};

export const TaskFormContainer = (props: { snapshotId?: string }) => {
  return props.snapshotId ? (
    <TaskFormSnapshotContainer snapshotId={props.snapshotId} />
  ) : (
    <TaskFormLiveStateContainer />
  );
};
const TaskFormSnapshotContainer = (props: { snapshotId: string }) => {
  const { snapshotDataState } = useSnapshotHooks();
  const { taskState } = useTaskHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  return (
    <TaskForm
      snapshotId={props.snapshotId}
      selectedTask={
        (taskState?.selectedId &&
          snapshot.taskState &&
          snapshot.taskState.entities[taskState.selectedId]) ||
        undefined
      }
    />
  );
};
const TaskFormLiveStateContainer = () => {
  const { selectedTask, updateBatch: updateTaskBatch } = useTaskHooks();
  return (
    <TaskForm
      selectedTask={selectedTask || undefined}
      updateBatch={updateTaskBatch}
    />
  );
};
const TaskForm = (props: {
  selectedTask?: Task;
  snapshotId?: string;
  updateBatch?: ReturnType<typeof useTaskHooks>["updateBatch"];
}) => {
  const { selectedTask, updateBatch: updateTaskBatch } = props;
  const [tabIndex, setTabIndex] = useState(0);

  const { errorToast, successToast } = useElphiToast();

  useEffect(() => {
    selectedTask &&
      syncState({
        shouldSync: !!selectedTask,
        state: selectedTask,
        statePath: () => {
          return ["tasks", selectedTask.id];
        }
      });
  }, [selectedTask]);

  const updateTaskFormHandler = async (v: Partial<TaskFormState>) => {
    if (!v.tasks || !updateTaskBatch) return null;

    const tasks = Object.keys(v.tasks)
      .map((id) => {
        if (v.tasks) {
          return {
            ...v.tasks[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!tasks.length) return null;
    return await updateTaskBatch({ tasks } as {
      tasks: ({
        id: string;
      } & Partial<Task>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Tasks Updated",
          description: `total tasks updated: ${tasks.length}`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      }
      return r;
    });
  };
  const { partyState } = usePartyHooks();
  const { dealState } = useDealHooks();
  const { propertyState } = usePropertyHooks();
  const { assetState } = useAssetHooks();
  const { statementState } = useStatementHooks();
  const { partyAssetState } = usePartyAssetHooks();
  const { taskState } = useTaskHooks();
  const { creditReportState } = useCreditReportHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { partyRelationState } = usePartyRelationHooks();
  const { serviceProviderState } = useServiceProviderHooks();
  const { insurancePolicyState } = useInsurancePolicyHooks();

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: {
      tasks: {},
      deals: {},
      parties: {},
      properties: {},
      assets: {},
      statements: {},
      serviceProviders: {}
    } as TaskFormState,
    callback: updateTaskFormHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const rowData = getTaskRowData(selectedTask, {
    propertyState,
    partyState,
    partyAssetState,
    assetState,
    statementState,
    taskState,
    selectedDeal: dealState.entities?.[dealState.selectedId || EMPTY],
    creditScoreState: creditReportState,
    dealPropertyState: dealPropertyRelationState,
    dealPartyState: dealPartyRelationState,
    partyRelationState: partyRelationState,
    serviceProviderState,
    insurancePolicyState
  });

  const getTaskDataHeader = (r: { shouldSanitize: boolean }) => {
    const { shouldSanitize } = r;
    if (selectedTask) {
      const text = getTaskEntityText({
        ...rowData,
        shouldSanitize: selectedTask.entityType !== ElphiEntityType.task
      });

      if (selectedTask.entityType === ElphiEntityType.deal) {
        return shouldSanitize ? text : `deal number: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.party) {
        return shouldSanitize ? text : `party: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.property) {
        return shouldSanitize ? text : `property: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.dealParty) {
        const { prefixId: dealId, suffixId: partyId } = splitRelationId(
          selectedTask.entityId
        );
        const loanIdentifier = getDealIdentifier(dealState.entities?.[dealId]);
        const property = getPartyName(partyState.entities?.[partyId]);
        return shouldSanitize
          ? `${loanIdentifier} ${property}`
          : `
          deal number: ${loanIdentifier} | 
          party: ${property}
        `;
      }
      if (selectedTask.entityType === ElphiEntityType.dealProperty) {
        const { prefixId: dealId, suffixId: propertyId } = splitRelationId(
          selectedTask.entityId
        );
        const loanIdentifier = getDealIdentifier(dealState.entities?.[dealId]);
        const property = getPropertyAddress(
          propertyState.entities?.[propertyId]
        );
        return shouldSanitize
          ? `${loanIdentifier} ${property}`
          : `
          deal number: ${loanIdentifier} | 
          property: ${property}
        `;
      }
      if (selectedTask.entityType === ElphiEntityType.partyRelation) {
        const { prefixId: parentId, suffixId: childId } = splitRelationId(
          selectedTask.entityId
        );

        const parentParty = getPartyName(partyState.entities?.[parentId]);
        const childIdParty = getPartyName(partyState.entities?.[childId]);

        return shouldSanitize
          ? `${parentParty} ${childIdParty}`
          : `
          party: ${parentParty} | 
          party: ${childIdParty}
        `;
      }
      if (selectedTask.entityType === ElphiEntityType.asset) {
        return shouldSanitize ? text : `asset: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.statement) {
        return shouldSanitize ? text : `statement: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.serviceProvider) {
        const serviceProvider =
          serviceProviderState.entities?.[selectedTask.entityId];
        return shouldSanitize
          ? text
          : `${serviceProvider?.type || EMPTY}: ${text}`;
      }
      if (selectedTask.entityType === ElphiEntityType.insurancePolicy) {
        const insurancepolicy =
          insurancePolicyState.entities?.[selectedTask.entityId];
        return shouldSanitize
          ? text
          : `${insurancepolicy?.entityType || EMPTY} insurance policy: ${text}`;
      }
    }
    return "";
  };
  const { heightOffsetInPx } = useWindowDimensions();

  const shouldShowCreditReportTaskForm =
    selectedTask?.type === TaskType.Integration &&
    selectedTask?.integrationType === IntegrationType.CreditReport;

  const shouldShowFloodCertificateTaskForm =
    selectedTask?.type === TaskType.Integration &&
    selectedTask?.integrationType === IntegrationType.FloodCertificate;

  const shouldShowTaskFileManager =
    selectedTask?.type === TaskType.Document ||
    selectedTask?.type === TaskType.DocumentOrderStatus;

  const shouldShowOrderStatus =
    selectedTask?.type === TaskType.DocumentOrderStatus ||
    (selectedTask?.type === TaskType.Integration &&
      selectedTask?.integrationType === IntegrationType.CreditReport);

  const shouldShouldShowPosActions = !!selectedTask?.posUserType?.length;

  const taskOnChange = (v: OnChangeInput) => {
    const selectedTaskId = selectedTask?.id;
    if (selectedTaskId) {
      onChange({
        value: v.value,
        fieldType: v.fieldType,
        fieldKey: ["tasks", selectedTaskId, ...v.fieldKey]
      });
    }
  };

  const identityInformation =
    getTaskDataHeader({
      shouldSanitize: true
    }) || NOT_AVAILABLE;

  const taskFormHeader =
    selectedTask?.entityType === ElphiEntityType.task ? (
      <Flex>
        <TruncatableList
          list={getFollowupTaskNames(rowData)}
          shouldShowTruncate={false}
        />
      </Flex>
    ) : (
      getTaskDataHeader({ shouldSanitize: false })
    );
  const commentsField: DotNestedKeys<Task> = "comments";
  const { sections } = useSections();
  const taskFormSections = [sections?.task?.orderStatusSection].map(
    (section) => {
      return section?.({
        state: selectedTask?.id ? state.tasks[selectedTask.id] : {},
        onChange: taskOnChange,
        options: {
          isDisabled: !!props.snapshotId
        }
      });
    }
  );

  return (
    <Box
      h={"100%"}
      w={"100%"}
      boxShadow={"lg"}
      bgColor="gray.200"
      animation={"ease-in-out 2s"}
    >
      <Box bgColor="white">
        <Flex
          alignItems={"center"}
          pl={1}
          pr={3}
          pb={2}
          justifyContent={"space-between"}
        >
          <Text fontSize={"20px"}>{selectedTask && selectedTask.name}</Text>
          <GateKeeper gate={DevFeatureFlag.Esd_3067_Discussion_Per_Entity}>
            {selectedTask && selectedTask.id && (
              <DiscussionBox
                entityId={selectedTask.id}
                entityType={DiscussionEntityType.Task}
                title={selectedTask.name || NOT_AVAILABLE}
              />
            )}
          </GateKeeper>
        </Flex>
        <Divider />
        <Text p="10px" fontSize={"14px"}>
          {taskFormHeader}
        </Text>
        <Divider />
        {selectedTask && selectedTask.id && (
          <Flex justifyContent={"space-between"}>
            <Box p="10px" w="30%">
              <Text fontSize="14px">status</Text>
              <Box>
                <TaskStatusDropDown
                  isDisabled={!!props.snapshotId}
                  selectedTask={selectedTask}
                  state={state}
                  onChange={onChange}
                />
              </Box>
            </Box>
            <Box p="10px" minW="400px" w="70%">
              <Text fontSize="14px">comments</Text>
              <Box display={"flex"} fontSize="16px">
                <Box w={"100%"} fontSize="16px">
                  <StyledInputBuilder
                    fieldType={FieldType.RichText}
                    currentValue={state.tasks[selectedTask.id]?.comments}
                    label="no comments"
                    onChange={(e) => {
                      onChange({
                        fieldKey: ["tasks", selectedTask.id, commentsField],
                        fieldType: FieldType.RichText,
                        value: e.target.value
                      });
                    }}
                  />
                </Box>
                <AttachedFieldStatusComponet
                  onChange={onChange}
                  fieldPath={[commentsField]}
                  prefix={["tasks", selectedTask.id]}
                  state={state}
                />
              </Box>
            </Box>
          </Flex>
        )}
      </Box>
      <Box
        h={heightOffsetInPx(560)}
        overflow={"scroll"}
        borderTopColor={"grey.100"}
        borderTopWidth={"5px"}
      >
        {selectedTask && (
          <>
            {selectedTask.internalInstruction && (
              <TaskInstructionContainer
                title={"Internal Instructions"}
                instructions={selectedTask.internalInstruction}
              />
            )}
            {selectedTask.externalInstruction && (
              <TaskInstructionContainer
                title={"External Instructions"}
                instructions={selectedTask.externalInstruction}
              />
            )}
            {shouldShouldShowPosActions && (
              <PosTaskActionContainer
                selectedTask={selectedTask}
                onChange={taskOnChange}
                state={state}
              />
            )}
            {shouldShowCreditReportTaskForm && (
              <CreditReportTaskFormContainer
                selectedTask={selectedTask}
                onChange={onChange}
                snapshotId={props.snapshotId}
                state={state}
                setTabIndex={setTabIndex}
              />
            )}
            {shouldShowFloodCertificateTaskForm && (
              <ServiceLinkFloodTaskFormContainer
                selectedTask={selectedTask}
                snapshotId={props.snapshotId}
              />
            )}
            {shouldShowTaskFileManager && (
              <Box>
                {!!selectedTask && (
                  <TaskFileManager
                    selectedTask={{
                      ...selectedTask,
                      identityInformation
                    }}
                    snapshotId={props.snapshotId}
                  />
                )}
              </Box>
            )}
            {selectedTask && isIntegrationTaskGuard(selectedTask) && (
              <IntegrationFormContainer
                selectedTask={selectedTask}
                snapshotId={props.snapshotId}
                state={state}
              />
            )}
            {tabIndex === 0 && (
              <>
                <Box>
                  <DataToOrderInteractiveForm snapshotId={props.snapshotId} />
                </Box>
                {shouldShowOrderStatus && (
                  <Box borderTopColor={"grey.100"} borderTopWidth={"5px"}>
                    <FormBuilder
                      customKey="taskFormSections"
                      isDisabled={!!props.snapshotId}
                      size={{
                        minW: "300px"
                      }}
                      onChange={taskOnChange}
                      sections={taskFormSections}
                    />
                  </Box>
                )}
                <Box borderTopColor={"grey.100"} borderTopWidth={"5px"}>
                  <DataToVerifyInteractiveForm snapshotId={props.snapshotId} />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
