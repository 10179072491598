import { Button, Flex } from "@chakra-ui/react";
import { elphiTheme } from "../../../../assets/themes/elphi.theme.default";
import { ModalContainer } from "../../../modal-container/ModalContainer";

type DataSubmitionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  handleConfirm: () => void;
  modalBody?: React.ReactNode;
  modalTitle?: string;
  maxWidth: number;
};

export const LoanpassModal = (props: DataSubmitionModalProps) => {
  const {
    isOpen,
    onClose,
    handleConfirm,
    isLoading,
    modalTitle,
    modalBody,
    maxWidth
  } = props;
  return (
    <ModalContainer
      maxWidth={maxWidth}
      isShow={isOpen}
      onCloseModal={onClose}
      header={modalTitle}
      body={modalBody}
      customStyles={{ footer: { width: "100%" } }}
      footer={
        <Flex justifyContent={"space-between"}>
          <Button
            isLoading={isLoading}
            onClick={handleConfirm}
            float="right"
            {...elphiTheme.components.light.button.primary}
          >
            Confirm
          </Button>
          <Button
            onClick={onClose}
            float="right"
            {...elphiTheme.components.light.button.secondary}
          >
            Cancel
          </Button>
        </Flex>
      }
    />
  );
};
