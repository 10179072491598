import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useRef } from "react";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { useDealPartyRelationHooks } from "../../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { partyToString } from "../../party/utils/printUtils";
import { useElphiToast } from "../../toast/toast.hook";

export const DeleteDealPartyRelationModal = (props: {
  callback?: (id: string) => void;
  buttonFn?: (props?: {
    onClick?: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
  dealId: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setSelectedParty, partyTabHighlightedParty } = usePartyHooks();
  const { deleteDealPartyRelation, deleteDealPartyRelationResponse } =
    useDealPartyRelationHooks();
  const { successToast, errorToast } = useElphiToast();

  const buttonComponent = props.buttonFn?.({ onClick: onOpen }) ?? null;

  const deleteDealPartyHandler = async () => {
    if (partyTabHighlightedParty) {
      const relationId = `${props.dealId}_${partyTabHighlightedParty.id}`;
      deleteDealPartyRelation(relationId).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Deal-Party Relationship Deleted",
            description: `relation id: ${r.data?.id}`
          });

          props?.callback ? props?.callback?.(r.data.id) : setSelectedParty("");

          onClose();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed To Delete Deal-Party Relationship",
            description: r.data.description
          });
      });
    }
  };
  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {props.buttonFn ? (
        buttonComponent
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Delete Deal-Party Relationship
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Delete Deal-Party Relationship</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {partyTabHighlightedParty && (
              <Text p="10px" fontWeight={"light"}>
                selected party: {partyToString(partyTabHighlightedParty)}
              </Text>
            )}
            <Text p="10px" fontWeight={"bold"}>
              Are you sure you want to delete the relationship between this Deal
              and Party?
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                {...elphiTheme.components.light.button.primary}
                onClick={() => {
                  deleteDealPartyHandler();
                }}
                isLoading={deleteDealPartyRelationResponse.isLoading}
              >
                Yes, delete
              </Button>
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
