import { pick } from "lodash";
import {
  baseLOCStatus,
  basePartyMilestone
} from "../../base/party/baseParty.enumeration";

export const limaPartyMilestone = {
  ...pick(basePartyMilestone, ["NewBorrower"]),
  AdditionalInfoNeeded: "Initial Borrower Review - Additional Info Needed",
  BorrowerApproved: "Borrower Approved",
  BorrowerApprovedPendingRecert: "Borrower Approved - Pending Recert",
  BorrowerDenied: "Borrower Denied",
  Expired: "Expired",
  InitialBorrowerReview: "Initial Borrower Review",
  PreSubmissionBorrower: "Pre-Submission Borrower",
  Prohibited: "Prohibited",
  Resubmitted: "Resubmitted",
  Suspended: "Suspended"
} as const;

export const limaLOCStatusType = {
  ...pick(baseLOCStatus, ["None", "Denied", "NewBorrower"]),
  Approval: "Approval",
  ConditionalApproval: "Conditional Approval",
  Expired: "Expired",
  InProcessAppUnderwriting: "In Process - App UW",
  InProcessBorrowerUnderwriting: "In Process - Borrower UW",
  InProcessSubReview: "In Process - Sub Review",
  Prohibited: "Prohibited",
  Resubmitted: "Resubmitted",
  SubmissionRejected: "Submission Rejected",
  SubmittedForExceptionApproval: "Submitted for Exception Approval",
  SubmittedForLargeLineApproval: "Submitted for Large Line Approval",
  Suspended: "Suspended"
} as const;

export const limaFixNFlipNBridgePlusTier = {
  N1: "1",
  N2: "2",
  N3: "3",
  N4: "4",
  N5: "5"
} as const;

export const limaOperationsDepartment = {
  EastCoastOperations: "East Coast Operations",
  WestCoastOperations: "West Coast Operations",
  StrategicAccounts: "Strategic Accounts",
  LenderFinanceOperations: "Lender Finance Operations"
} as const;
export const limaConcentrationReviewOutcome = {
  Green: "Green",
  Yellow: "Yellow",
  Red: "Red"
} as const;
