import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { RootState } from "../../../redux/store";

import { Party, PartyType } from "@elphi/types";
import { AppResponse } from "../../../apis/appResponse.types";
import { useDealPartyRelationHooks } from "../../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import PartySearch from "../../party/PartySearch";
import { useElphiToast } from "../../toast/toast.hook";

export const AddPartyChildModal = (props: {
  buttonFn?: (props: {
    handleOpen: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
  dealId: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedChild, setSelectedChild] = useState<Party | null | "">("");

  const { successToast, errorToast } = useElphiToast();

  const { createDealParty, createDealPartyApiResponse } =
    useDealPartyRelationHooks();

  const { setHighlightedPartyTabParty } = usePartyHooks();

  const propsButton = props.buttonFn && props.buttonFn({ handleOpen: onOpen });

  const dealPartyRelationState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );
  const partyState = useSelector((state: RootState) => state.party);

  const createDealPartyApiResponseHandler = (
    r: AppResponse<{
      relation: any;
      id: string;
    }>
  ) => {
    if (r.status === 200) {
      const relation = r.data?.relation;
      successToast({
        title: "New Deal-Party Relation Created",
        description: `Relation Id: ${relation?.id}`
      });
      onClose();
    }
    if (r.status === 400) {
      errorToast({
        title: "failed to add child",
        description: r.data.description
      });
    }
    return r;
  };
  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Attach party to the deal
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Attach Entity To The Deal</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <PartySearch
              currentValue={(selectedChild && selectedChild?.id) || ""}
              onSelect={(id) => {
                if (id === "") {
                  setSelectedChild("");
                }
                partyState.entities[id] &&
                  setSelectedChild(partyState?.entities?.[id] ?? "");
              }}
              filter={(a) => {
                return a.PartyType === PartyType.Entity;
              }}
            />
            {selectedChild && (
              <Box pt="10px">
                <Button
                  {...elphiTheme.components.light.button.primary}
                  isLoading={createDealPartyApiResponse.isLoading}
                  onClick={() => {
                    if (props.dealId) {
                      const relationId = `${props.dealId}_${selectedChild.id}`;
                      if (dealPartyRelationState.ids.includes(relationId)) {
                        errorToast({
                          title: "Cannot add two of the same child to a parent",
                          description: `Relation id: ${relationId}`
                        });
                      } else {
                        createDealParty(props.dealId, selectedChild.id)
                          .then(createDealPartyApiResponseHandler)
                          .then((r) => {
                            if (r.status === 200) {
                              setHighlightedPartyTabParty(selectedChild.id);
                            }
                          });
                      }
                    }
                  }}
                >
                  Add Entity
                </Button>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
