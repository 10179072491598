import { thirdPartyFieldTemplate } from "../../../../../../types/aggregations";
import { AggregationRentRangeFields } from "../../../../../../types/entities/integrations";

export const getRentalDataRentRangeAggregationTemplate =
  (): AggregationRentRangeFields => {
    return {
      confidenceScore: thirdPartyFieldTemplate(),
      orderStatus: thirdPartyFieldTemplate(),
      errorMessage: thirdPartyFieldTemplate(),
      requestId: thirdPartyFieldTemplate()
    };
  };
