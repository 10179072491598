import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle, extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const darkGrayBorderVariant = definePartsStyle({
  control: defineStyle({
    borderColor: "darkgray"
  })
});

 const checkboxTheme = defineMultiStyleConfig({
  variants: { darkGrayBorderVariant }
});


export const chakraTheme = extendTheme({
    components: {
      Checkbox: checkboxTheme,
      Alert: {
        variants: {
          errorToast: {
            container: {
              color: "white",
              bg: "red.500"
            }
          },
          successToast: {
            container: {
              color: "white",
              bg: "green.500"
            }
          },
          infoToast: {
            container: {
              color: "white",
              bg: "blue.400"
            }
          }
        }
      }  
    },
});