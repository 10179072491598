import { DealUser } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState } from "../redux/store";
import { dealUserRelationSlice } from "../redux/v2/deal-user-relation";
import { dealUserRelationApi } from "../redux/v2/deal-user-relation/dealUserRelation.service";

export const useDealUserRelationHooks = () => {
  const dispatcher = useDispatch();
  const [createBatchApi] = dealUserRelationApi.useBatchCreateMutation();
  const [setBatchApi] = dealUserRelationApi.useBatchSetMutation();
  const [updateBatchApi] = dealUserRelationApi.useBatchUpdateMutation();
  const [deleteBatchApi] = dealUserRelationApi.useBatchDeleteMutation();
  const [getDealUserRelation] = dealUserRelationApi.useLazyGetQuery();
  const [updateDealUserRelationApi] = dealUserRelationApi.useUpdateMutation();
  const [deleteDealUserRelationApi, deleteDealUserRelationResponse] =
    dealUserRelationApi.useDeleteMutation();
  const [getDealUsers] = dealUserRelationApi.useLazyChildrenQuery();
  const [createDealUserRelationApi, createDealUserRelationApiResponse] =
    dealUserRelationApi.useCreateMutation();

  const [useAddUserAssignmentApi, useAddUserAssignmentResponse] =
    dealUserRelationApi.useAddUserAssignmentMutation();

  const [useRemoveUserAssignmentApi, useRemoveUserAssignmentResponse] =
    dealUserRelationApi.useRemoveUserAssignmentMutation();

  const dealUserRelationState = useSelector(
    (state: RootState) => state.dealUserRelation
  );

  const createDealUserRelation = async (newDealUserRelation: DealUser) => {
    return await createDealUserRelationApi(newDealUserRelation).then(
      (response) =>
        responseHandler(
          response as RTKResponse<{
            entities: Array<{ collection: string; id: string }>;
            relation: { collection: string; id: string };
          }>
        )
    );
  };

  const updateDealUserRelation = async (
    updateDealUserRelation: { id: string } & Partial<DealUser>
  ) => {
    return await updateDealUserRelationApi(updateDealUserRelation).then(
      responseHandler
    );
  };

  const deleteDealUserRelation = async (r: string) => {
    const response = await deleteDealUserRelationApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(dealUserRelationSlice.actions.remove(r));
    }
    return response;
  };

  const createBatch = async (r: {
    relations: ({ id: string } & Partial<DealUser>)[];
  }) => {
    const response = await createBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const setBatch = async (r: {
    relations: ({ id: string } & Partial<DealUser>)[];
  }) => {
    const response = await setBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const addUserAssignment = async (r: {
    dealId: string;
    dealUserIds: string[];
  }) => {
    const response = await useAddUserAssignmentApi(r);
    return responseHandler(
      response as RTKResponse<{
        ids: string[];
      }>
    );
  };

  const removeUserAssignment = async (r: {
    dealId: string;
    dealUserIds: string[];
  }) => {
    const response = await useRemoveUserAssignmentApi(r);
    return responseHandler(
      response as RTKResponse<{
        ids: string[];
      }>
    );
  };

  const updateBatch = async (r: {
    relations: ({ id: string } & Partial<DealUser>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const deleteBatch = async (r: { relations: string[] }) => {
    const response = await deleteBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  return {
    getDealUserRelation,
    getDealUsers,
    createDealUserRelation,
    createDealUserRelationApiResponse,
    updateDealUserRelation,
    deleteDealUserRelation,
    deleteDealUserRelationResponse,
    dealUserRelationState,
    createBatch,
    updateBatch,
    deleteBatch,
    setBatch,
    addUserAssignment,
    useAddUserAssignmentResponse,
    removeUserAssignment,
    useRemoveUserAssignmentResponse
  };
};
