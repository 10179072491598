import { documentGenerationApi } from "../redux/v2/document-generation";

export const useDocumentGenerationHooks = () => {
  const [generateDocuments, generateDocumentsResponse] =
    documentGenerationApi.useGenerateMutation();

  const [generateDocumentsFromSource, generateDocumentsFromSourceResponse] =
    documentGenerationApi.useGenerateDocumentsFromSourceMutation();

  return {
    generateDocuments,
    generateDocumentsResponse,
    generateDocumentsFromSource,
    generateDocumentsFromSourceResponse
  };
};

export default useDocumentGenerationHooks;
