import { AggregationType } from "@elphi/types";

import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";

export const condoCertificateFeesCollectedIndicator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.CondoCertificateFeesCollectedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const condoCertificateComments = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.CondoCertificateComments?.fieldKey,
  validation: stringValidation
});

export const defaultInterestPaymentsOrFeesNotedIndicator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.defaultInterestPaymentsOrFeesNotedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const bridgeLTVPercent = buildInput({
  calculationType: AggregationType.ThirdParty,
  fieldKey:
    limaSpecs.property?.entitySpecs.aggregations.BridgeLTVPercent?.focused
      ?.fieldKey,
  validation: percentValidation
});

export const annualCapitalExpenditure = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.AnnualCapitalExpenditure?.fieldKey,
  validation: moneyValidation
});

export const propertyDetailComments = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.PropertyDetailComments?.fieldKey,
  validation: stringValidation
});

export const internalL1CRefinance = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.InternalL1CRefinanceIndicator?.fieldKey,
  validation: booleanValidation
});

export const contractExecutionDate = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.ContractExecutionDate?.fieldKey,
  validation: dateValidation
});
export const psaExtensionFormIndicator = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.PSAExtensionFormIndicator?.fieldKey,
  validation: booleanValidation
});

export const psaAddendumIndicator = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.PSAAddendumIndicator?.fieldKey,
  validation: booleanValidation
});

export const psaSellerOwnerRecordMismatchIndicator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PSASellerOwnerRecordMismatchIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const PSABuyerBorrowerMismatch = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PSABuyerBorrowerMismatchIndicator?.fieldKey,
  validation: booleanValidation
});

export const mortgageeFullName = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.MortgageeFullName?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactFullName = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.MortgageeContactFullName?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactPointTelephone = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.MortgageeContactPointTelephoneValue
      ?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactPointEmailValue = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.MortgageeContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const rentalLTVPercent = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.RentalLTVPercent?.fieldKey,
  validation: percentValidation
});

export const cemaRefinanceIndicator = buildInput({
  fieldKey: limaSpecs.property?.entitySpecs.CEMARefinanceIndicator?.fieldKey,
  validation: booleanValidation
});

export const qualityRatingType = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.Appraisal.QualityRatingType?.fieldKey,
  validation: singleSelectValidation
});

export const appraisalCoordinator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.Appraisal.AppraisalCoordinator?.fieldKey,
  validation: singleSelectValidation
});

export const internalValuationEligibilityIndicator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.Appraisal
      .InternalValuationEligibilityIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyInsuranceDwellingCoverageAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.DwellingCoverageAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceLiabilityCoverageAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.LiabilityCoverageAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceReplacementCoverageIndicator = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance
      .ReplacementCoverageIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyInsuranceRentalLossCoverageAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.RentalLossCoverageAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const propertyInsurancePremiumPaymentType = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance
      .InsurancePremiumPaymentType?.fieldKey,
  validation: singleSelectValidation
});

export const propertyInsurancePolicyIdentifier = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance
      .PropertyInsurancePolicyIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyInsurancePolicyExpirationDate = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.PolicyExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const propertyInsuranceDeductibleAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.DeductibleAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceAgentFullName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .InsuranceAgentFullName?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceFullName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency.FullName?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceContactPointTelephone = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceContactPointEmail = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const propertyInsuranceAddressLineText = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceAddressUnitIdentifier = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .AddressUnitIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceCityName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency.CityName?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceStateCode = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const propertyInsurancePostalCode = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsuranceAgency.PostalCode
      ?.fieldKey,
  validation: numberValidation
});

export const propertyInsuranceBuilderRiskInsurance = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.PropertyInsurance.BuilderRiskInsurance
      ?.fieldKey,
  validation: moneyValidation
});

export const floodInsuranceDwellingCoverageAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsurance.DwellingCoverageAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const floodInsurancePremiumPaymentType = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsurance.InsurancePremiumPaymentType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const floodInsurancePolicyNumber = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsurance.PolicyNumber?.fieldKey,
  validation: stringValidation
});

export const floodInsurancePolicyExpirationDate = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsurance.PolicyExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const floodInsuranceDeductibleAmount = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsurance.DeductibleAmount?.fieldKey,
  validation: moneyValidation
});

export const floodInsuranceFullName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.FullName?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceRepresentativeFullName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.RepresentativeFullName
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceContactPointTelephoneValue = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceContactPointEmailValue = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.ContactPointEmailValue
      ?.fieldKey,
  validation: emailValidation
});

export const floodInsuranceAddressLineText = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceAddressUnitIdentifier = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.AddressUnitIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceCityName = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.CityName?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceStateCode = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const floodInsurancePostalCode = buildInput({
  fieldKey:
    limaSpecs.property?.entitySpecs.FloodInsuranceAgency.PostalCode?.fieldKey,
  validation: numberValidation
});

export const calculatedAllocatedLoanAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    limaSpecs.property?.entitySpecs.aggregations.AllocatedLoanAmount?.focused
      ?.fieldKey,
  validation: moneyValidation
});

export const pricingEngineLTARVRatePercent = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    limaSpecs.property?.entitySpecs.aggregations.PricingEngineLTARVRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation
});

export const estimatedDelayedPurchaseProceeds = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    limaSpecs.property?.entitySpecs.aggregations
      .EstimatedDelayedPurchaseProceeds?.focused?.fieldKey,
  validation: moneyValidation
});
