import { AggregationType } from "@elphi/types";
import {
  dateValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";

export const overridePartyValidationSettingsIndicator = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.entityPartyFieldSpecs
      .OverridePartyValidationSettingsIndicator?.fieldKey,
  validation: singleSelectValidation
});

export const crmId = buildInput({
  fieldKey: limaSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});

export const individualPaymentTokenId = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs.PaymentTokenId
      ?.fieldKey,
  validation: stringValidation
});

export const individualCrmId = buildInput({
  fieldKey: limaSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});

export const locExpirationDateAggregation = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.basePartyFieldSpecs.aggregations
      .LOCExpirationDate?.focused?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: dateValidation
});

export const dateConcentrationReview = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs
      .DateConcentrationReview?.fieldKey,
  validation: dateValidation
});
export const dateNextConcentrationReview = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs
      .DateNextConcentrationReview?.fieldKey,
  validation: dateValidation
});
export const concentrationReviewOutcome = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ConcentrationReviewOutcome?.fieldKey,
  validation: singleSelectValidation
});
export const concentrationReviewActionItems = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ConcentrationReviewActionItems?.fieldKey,
  validation: stringValidation
});
