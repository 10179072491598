import { FirestoreTimestampFieldUnionType } from "@elphi/types";
import firebase from "../firebase/firebaseConfig";
import { TimestampField } from "./firebase.types";

export const printDateTime = (
  v: FirestoreTimestampFieldUnionType | string | undefined
): string | undefined => {
  if (v === undefined) {
    return "";
  }
  if (typeof v === "string") {
    return v;
  }
  return getTimestampFromFirestoreObject(v as TimestampField)
    ?.toDate()
    .toLocaleString();
};

export const getTimestampFromFirestoreObject = (
  v: TimestampField
): firebase.firestore.Timestamp | undefined => {
  if (typeof v === "object") {
    const timestampObject = v as TimestampField;
    const timestamp = timestampObject._seconds
      ? {
          seconds: timestampObject._seconds,
          nanoseconds: timestampObject._nanoseconds!
        }
      : {
          seconds: timestampObject.seconds!,
          nanoseconds: timestampObject.nanoseconds!
        };
    return new firebase.firestore.Timestamp(
      timestamp.seconds,
      timestamp.nanoseconds
    );
  }
};
