import { Party, PartyType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";

export const partyToString = (party: Party) => {
  if (party.PartyType === PartyType.Individual) {
    return `${party.PartyType} | ${party.TaxpayerIdentifierType}:${
      party.TaxpayerIdentifierValue
    } | ${party.FirstName} ${party.MiddleName || ""} ${party.LastName}`;
  } else if (party.PartyType === PartyType.Entity) {
    return `${party.PartyType} | EIN:${party.TaxpayerIdentifierValue} | ${party.FullName}`;
  }
};

export const partyName = (party: Partial<Party>) => {
  if (party?.PartyType === PartyType.Individual) {
    return [party?.FirstName, party?.MiddleName, party?.LastName]
      .filter(removeEmpty)
      .join(" ");
  } else if (party?.PartyType === PartyType.Entity) {
    return `${party?.FullName}`;
  }
  return "N/A";
};
