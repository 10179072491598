import { DealProperty } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { dealPropertyRelationSlice } from "./dealPropertyRelation.slice";
export const dealPropertyRelationAction: ActionDispatcher<DealProperty> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: DealProperty[]) => void;
    update?: (r: Update<DealProperty>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = dealPropertyRelationSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

