import { Box, Button, Flex } from "@chakra-ui/react";
import { ElphiTrxStatus, LabelValue } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../constants/common";
import { useIntegrationCenterHooks } from "../../../hooks/integrationCenter.hooks";
import { EmptyFileIcon, FileIcon } from "../../file-uploader/Icons";
import { WindWhiteIcon } from "../../icons";
import { TaskFormContainer } from "../../task/TaskForm";
import { IntegrationCenterFilter } from "./IntegrationCenterFilter";
import { IntegrationBoard } from "./integration-task-board/Board";
import { BoardFilters } from "./integration-task-board/BoardFilters";
import { BulkOrderConfirmModal } from "./integration-task-board/BulkOrderConfirmModal";
import {
  IntegrationBoardFilterType,
  IntegrationTaskBoardFilters
} from "./integrationCenter.types";
import {
  buildOptionFilters,
  filterData,
  initializedFilterState,
  selectFilters,
  selectedBottomFilters,
  selectedTopFilters
} from "./utils/integrationCenter.utils";

const fileIconOptions: LabelValue<ReactNode, boolean>[] = [
  {
    value: true,
    label: <FileIcon boxSize="7" pr="5px" pt="5px" />
  },
  {
    value: false,
    label: <EmptyFileIcon boxSize="7" pr="5px" pt="5px" />
  }
];

export const IntegrationCenter = () => {
  const {
    taskState,
    setSelectedTask,
    prepareTaskCards,
    bulkOrderHandler,
    bulkOrderResponse,
    entityTasksResponse,
    entityTasksApi,
    dealPropertyRelationState,
    propertyState,
    selectedDealId
  } = useIntegrationCenterHooks();

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<
    Record<keyof IntegrationTaskBoardFilters, string[]>
  >(initializedFilterState);

  const [selectedStatuses, setSelectedStatuses] = useState<ElphiTrxStatus[]>(
    []
  );

  const handleFilterChange = (
    filterName: keyof IntegrationTaskBoardFilters,
    selected: string[]
  ) => {
    setFilters((prev) => ({ ...prev, [filterName]: selected }));
  };

  const dealPropertyIds = useMemo(() => {
    return dealPropertyRelationState.ids.filter(
      (id) =>
        dealPropertyRelationState?.entities?.[id]?.dealId === selectedDealId
    );
  }, [selectedDealId, dealPropertyRelationState]);

  const propertyIds = useMemo(() => {
    return dealPropertyIds.map((id) => {
      const propertyId = dealPropertyRelationState?.entities?.[id]?.propertyId;
      return propertyId && propertyState?.entities?.[propertyId]?.id;
    });
  }, [propertyState, dealPropertyRelationState, dealPropertyIds]);
  const relationshipIds = useMemo(() => {
    return [...propertyIds, ...dealPropertyIds, selectedDealId].filter(
      removeEmpty
    );
  }, [selectedDealId, propertyIds, dealPropertyIds]);

  useEffect(() => {
    entityTasksApi({ ids: relationshipIds }, true);
  }, [relationshipIds]);

  const taskCards = useMemo(
    () => prepareTaskCards(relationshipIds),
    [relationshipIds, taskState.entities]
  );

  const { selectedId } = taskState;

  const optionFilters = useMemo(
    () => buildOptionFilters(taskCards),
    [taskCards]
  );

  const topFilters = useMemo(
    () => selectFilters(selectedTopFilters, optionFilters),
    [optionFilters]
  );
  const bottomFilters = useMemo(
    () => selectFilters(selectedBottomFilters, optionFilters),
    [optionFilters]
  );

  const filteredCards = useMemo(
    () => filterData(taskCards, filters, selectedId),
    [taskCards, filters, selectedId]
  );

  const handleClearFilters = () => {
    setFilters(initializedFilterState);
  };

  const handleBulkOrder = () => {
    setConfirmModal(true);
  };

  const onCloseModal = () => {
    setConfirmModal(false);
  };

  const onSubmitModal = () => {
    const hashSet = new Set([...selectedStatuses]);
    const taskIds = filteredCards
      .filter((x) => x?.elphiStatus && hashSet.has(x.elphiStatus))
      .map((x) => x.id);
    bulkOrderHandler(taskIds).then((_) => {
      onCloseModal();
    });
  };

  const handleOnSelectedStatuses = (selected: ElphiTrxStatus[]) => {
    setSelectedStatuses(selected);
  };

  const isDisabled =
    filteredCards.length === 0 || !filteredCards.every((x) => x.isReadyToOrder);

  return (
    <Box>
      <Flex direction={"column"}>
        <Flex pb={"10px"}>
          <Button
            mr={1}
            iconSpacing={0}
            leftIcon={<WindWhiteIcon w={6} h={6} mt={2} />}
            onClick={handleBulkOrder}
            isDisabled={isDisabled}
            color="white"
            {...elphiTheme.components.light.button.scaleChart}
          >
            Bulk Order
          </Button>
          <BoardFilters
            filterOptions={topFilters}
            onChange={handleFilterChange}
            state={filters}
          />
          <Button
            mr={1}
            background={"transparent"}
            fontSize={14}
            fontWeight={"normal"}
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
        </Flex>
        <Flex pb={"10px"}>
          <Box lineHeight={10}>
            <IntegrationCenterFilter
              filterType={IntegrationBoardFilterType.CheckboxGroup}
              title={EMPTY}
              options={fileIconOptions}
              selected={filters["containFiles"]}
              onChange={(selected) => {
                handleFilterChange("containFiles", selected);
              }}
            />
          </Box>
          <BoardFilters
            filterOptions={bottomFilters}
            onChange={handleFilterChange}
            state={filters}
          />
        </Flex>
      </Flex>
      <Flex w="100%">
        <Box w={selectedId ? "40%" : "100%"} overflow={"auto"}>
          <IntegrationBoard
            filteredCards={filteredCards}
            taskCards={taskCards}
            selectedTaskId={selectedId}
            setSelected={setSelectedTask}
            isLoading={entityTasksResponse.isFetching}
          />
        </Box>
        <Box w={selectedId ? "60%" : "0%"}>
          {selectedId && <TaskFormContainer />}
        </Box>
      </Flex>
      <BulkOrderConfirmModal
        isShow={confirmModal}
        onClickOrder={onSubmitModal}
        isLoading={bulkOrderResponse.isLoading}
        onCloseModal={onCloseModal}
        filteredCards={filteredCards}
        taskCards={taskCards}
        onSelectedStatuses={handleOnSelectedStatuses}
        selectedStatuses={selectedStatuses}
      />
    </Box>
  );
};
