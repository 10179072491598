import { createSlice } from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { partyApi } from "../party/party.service";
import { dealPartyEntityAdapter } from "./dealPartyRelation.adapter";
import { dealPartyRelationApi } from "./dealPartyRelation.service";

export const dealPartyRelationSlice = createSlice({
  name: "dealPartyRelation",
  initialState: dealPartyEntityAdapter.getInitialState(),
  reducers: {
    update: dealPartyEntityAdapter.updateOne,
    remove: dealPartyEntityAdapter.removeOne,
    add: dealPartyEntityAdapter.addOne,
    upsert: dealPartyEntityAdapter.upsertOne,
    upsertMany: dealPartyEntityAdapter.upsertMany,
    removeMany: dealPartyEntityAdapter.removeMany,
    updateMany: dealPartyEntityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(dealPartyRelationApi)(builder);
    builder.addMatcher(
      dealPartyRelationApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.partyDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.dealParties.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.dealParty.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page.dealParty, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.dealParty.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page.dealParty, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getAdditionalDataForDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.dealParty.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.dealParty, "id")
        };
      }
    );
  }
});
