import {
  DocumentConfiguration,
  DocumentEntityType
} from "../../../../types/entities/documentConfiguration.types";

export const getDocumentConfigurationTemplate = () => {
  const documentConfiguration: DocumentConfiguration = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    autoGenerate: "" as DocumentConfiguration["autoGenerate"],
    documentEntity: "" as DocumentEntityType,
    documentRuleTemplateId: "",
    templateId: "",
    name: "",
    status: "" as DocumentConfiguration["status"],
    documentPackageType: []
  };
  return documentConfiguration;
};
