import { Box, Button, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  BooleanSelectType,
  Deal,
  FieldType,
  IntegrationTask,
  IntegrationTaskTemplate,
  StatusCode
} from "@elphi/types";
import {
  ElphiTrxStatus,
  WireInsuranceFile
} from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
import { getFocusedData } from "@elphi/utils";
import { concat, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { elphiTheme } from "../../../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../../../config/appConfig";
import { useSections } from "../../../../forms/schemas/providers/SectionsProvider";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useWireInsuranceTaskHooks } from "../../../../hooks/wireInsuranceTask.hooks";
import { TaskFormState } from "../../../../shared/types/task.types";
import ScrollableSections from "../../../ScrollableSections";
import FormBuilder, {
  OnChangeInput,
  SectionHeader
} from "../../../form-builder/FormBuilder";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { RotateLeft, WindWhiteIcon } from "../../../icons";
import { useElphiToast } from "../../../toast/toast.hook";
import { createOptionsFromMap } from "../../../utils/formUtils";
import { IntegrationTaskHeaderForm } from "../IntegrationTaskHeaderForm";
import {
  BUTTON_TOOLTIP_MESSAGES,
  DealStructureState,
  ServiceOption
} from "./wireInsurance.type";

type IntegrationFormContainerProps<
  T extends IntegrationTask = IntegrationTask
> = {
  snapshotId?: string;
  state?: TaskFormState;
} & IntegrationTaskTemplate<T>;
type WireInsuranceTaskFormProps = {} & IntegrationFormContainerProps;

export const WireInsuranceTaskForm = ({
  snapshotId,
  selectedTask,
  state
}: WireInsuranceTaskFormProps) => {
  const [sectionValidationState, setSectionValidationState] = useState<{
    [index: string]: boolean;
  }>({});
  const { dealState: dealReduxState, updateBatch } = useDealHooks();
  const {
    getWireInsuranceTask,
    handleOnCreateOrderCertificate,
    createFundingShieldApiResponse,
    handleOnRetryOrderCertificate,
    retryFundingShieldApiResponse,
    getOrderStatusTooltip
  } = useWireInsuranceTaskHooks();
  const currentTask = getWireInsuranceTask(
    selectedTask,
    WireInsuranceVendorType.FundingShield
  )!;

  const { errorToast, successToast } = useElphiToast();
  const { sections } = useSections();

  const [dealSelection, setDealSelection] = useState<ServiceOption>(
    ServiceOption.OrderCertificate
  );

  const selectedDeal =
    currentTask?.entityId &&
    (dealReduxState?.entities[currentTask?.entityId] as Deal);

  const updateDealsHandler = async (diff: Partial<DealStructureState>) => {
    if (!diff.deals) return null;
    const deals = Object.keys(diff.deals)
      .map((id) => {
        if (diff.deals && diff.deals[id]) {
          return {
            ...diff.deals[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!deals.length) return null;
    return (
      updateBatch &&
      (await updateBatch({ deals } as {
        deals: ({
          id: string;
        } & Partial<Deal>)[];
      }).then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deals Updated",
            description: `${r.data.batch.length} deals updated`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update deals",
            description: r.data.description
          });
        }
        return r;
      }))
    );
  };

  const {
    onChange: dealOnChange,
    state: dealState,
    syncState: syncDealState
  } = useFormBuilderStateHandler({
    initialState: { deals: {} },
    callback: updateDealsHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    selectedDeal &&
      syncDealState({
        shouldSync: !!selectedDeal,
        state: selectedDeal,
        statePath: () => {
          if (selectedDeal) {
            return ["deals", selectedDeal.id];
          }
        }
      });
  }, [selectedDeal]);

  const selectedDealState =
    (selectedDeal && (dealState.deals[selectedDeal.id] as Deal)) ||
    ({} as Deal);

  const isValidDocument = (key: WireInsuranceFile) =>
    !isEmpty(
      currentTask?.mapIntegrationFileKeys?.WireInsuranceCertificate?.[key]
    );
  const checkDocumentsValidations = () => {
    const stateNYIndicator = getFocusedData(
      selectedDealState?.aggregations?.Integrations?.WireInsuranceCertificate
        ?.FundingShield.StateNYIndicator
    );

    const areAlwaysValidDocumentsPresent =
      isValidDocument(WireInsuranceFile.uploadErrorsAndOmissionsPolicy) &&
      isValidDocument(WireInsuranceFile.uploadWiresInstructions);

    const isAllDocumentsValid =
      areAlwaysValidDocumentsPresent && !stateNYIndicator
        ? isValidDocument(WireInsuranceFile.uploadCrimesPolicy) &&
          isValidDocument(WireInsuranceFile.uploadClosingProtectionLetter)
        : stateNYIndicator === BooleanSelectType.true
        ? isValidDocument(WireInsuranceFile.uploadCrimesPolicy)
        : isValidDocument(WireInsuranceFile.uploadClosingProtectionLetter);

    return areAlwaysValidDocumentsPresent && isAllDocumentsValid;
  };

  //we only need the first section (index 0) to be valid in the form to enable the order button
  const isAllValid = sectionValidationState[0] && checkDocumentsValidations();

  const dealOnChangeBatch = (v: OnChangeInput) => {
    selectedDeal &&
      !snapshotId &&
      dealOnChange({
        fieldType: v.fieldType,
        fieldKey: ["deals", selectedDeal.id, ...v.fieldKey],
        value: v.value
      });
  };

  const isFailedInitialization = (): boolean => {
    const { elphiTrxStatus, failedLabel, transactionId } = currentTask;
    return (
      elphiTrxStatus === ElphiTrxStatus.Failed &&
      failedLabel === "failedInitialization" &&
      !transactionId
    );
  };

  const shouldDisable =
    !isAllValid || !dealSelection || !currentTask.isReadyToOrder;

  const wireInsuranceFundingShieldSections =
    sections?.integrations?.deal?.WireInsuranceCertificate?.FundingShield;

  const wireInsuranceOrderSection =
    wireInsuranceFundingShieldSections?.wireInsuranceOrderSection?.({
      state: selectedDealState,
      onChange: dealOnChangeBatch,
      selectedTask
    }) || undefined;

  const wireInsuranceSection = [
    wireInsuranceFundingShieldSections?.wireInsuranceVerifySection,
    wireInsuranceFundingShieldSections?.wireInsuranceOrderStatusSection
  ].map((section) =>
    section?.({
      state: selectedDealState,
      onChange: dealOnChangeBatch
    })
  );

  return (
    <>
      <ScrollableSections
        customKey={"wireInsuranceTaskForm"}
        sections={[
          {
            header: (
              <SectionHeader
                header={
                  <IntegrationTaskHeaderForm
                    task={selectedTask}
                    elphiTrxStatus={currentTask.elphiTrxStatus}
                    getOrderStatusTooltip={getOrderStatusTooltip}
                  />
                }
              />
            ),
            body: (
              <Flex>
                <Box w="250px" mr="50px">
                  <StyledInputBuilder
                    options={createOptionsFromMap(ServiceOption)}
                    label="Choose Service"
                    onChange={(e) => setDealSelection(e.target.value)}
                    isClearable={true}
                    isValid={true}
                    fieldType={FieldType.SingleSelect}
                    currentValue={dealSelection}
                  />
                </Box>
                <Tooltip
                  label={
                    !!dealSelection
                      ? !isAllValid
                        ? BUTTON_TOOLTIP_MESSAGES.validationError
                        : BUTTON_TOOLTIP_MESSAGES?.[currentTask?.elphiTrxStatus]
                      : BUTTON_TOOLTIP_MESSAGES.chooseService
                  }
                >
                  <Button
                    onClick={() =>
                      handleOnCreateOrderCertificate(
                        dealSelection,
                        selectedDeal,
                        currentTask.id
                      )
                    }
                    isLoading={createFundingShieldApiResponse.isLoading}
                    isDisabled={shouldDisable}
                    iconSpacing={0}
                    leftIcon={<WindWhiteIcon boxSize="8" pt="8px" />}
                    color="white"
                    {...elphiTheme.components.light.button.scaleChart}
                  >
                    Order
                  </Button>
                </Tooltip>
                {currentTask?.elphiTrxStatus === ElphiTrxStatus.Failed && (
                  <Tooltip label={BUTTON_TOOLTIP_MESSAGES.retryToOrder}>
                    <IconButton
                      ml={5}
                      isLoading={retryFundingShieldApiResponse.isLoading}
                      variant={"ghost"}
                      aria-label="retry-button"
                      onClick={() =>
                        isFailedInitialization()
                          ? handleOnCreateOrderCertificate(
                              dealSelection,
                              selectedDeal,
                              currentTask.id
                            )
                          : handleOnRetryOrderCertificate(
                              selectedDeal,
                              currentTask.id
                            )
                      }
                      icon={<RotateLeft boxSize="10" pr="1px" pt="5px" />}
                    />
                  </Tooltip>
                )}
              </Flex>
            )
          }
        ]}
      />
      <>
        {state?.tasks && (
          <FormBuilder
            customKey="wireInsuranceSection"
            isDisabled={!!snapshotId}
            size={{
              minW: "300px"
            }}
            setSectionValidationState={setSectionValidationState}
            showOptional
            onChange={dealOnChangeBatch}
            sections={concat(wireInsuranceOrderSection, wireInsuranceSection)}
          />
        )}
      </>
    </>
  );
};
