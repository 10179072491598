import {
  BaseEntity,
  FirebaseFilter,
  PartyGroup,
  Status,
  StatusCode
} from "@elphi/types";
import { removeEmpty, removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";

import { EntityId } from "@reduxjs/toolkit";
import { intersection } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import {
  PartyGroupSliceState,
  partyGroupApi,
  partyGroupSlice
} from "../redux/v2/party-group";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";
import { useFilter } from "./filter.hooks";

export const usePartyGroup = () => {
  const dispatch = useDispatch();
  const SHOULD_FETCH_ALL = true;
  const { errorToast, successToast } = useElphiToast();
  const partyGroupState = useSelector((state: RootState) => state.partyGroup);
  const { buildFireStoreFilters } = useFilter<PartyGroup, PartyGroupSliceState>(
    partyGroupState
  );

  const selectPartyGroup = (id: string) =>
    dispatch(partyGroupSlice.actions.selectId({ id }));
  const unSelectPartyGroup = (id: string) =>
    dispatch(partyGroupSlice.actions.unSelectId({ id }));
  const selectAllPartyGroup = () =>
    dispatch(partyGroupSlice.actions.selectAllIds());
  const selectPartyGroupIds = (ids: string[]) =>
    dispatch(partyGroupSlice.actions.selectIds({ ids }));
  const unSelectAllPartyGroup = () =>
    dispatch(partyGroupSlice.actions.unSelectAll());
  const setPartyGroupId = (id: string) =>
    dispatch(partyGroupSlice.actions.setPartyGroupId({ id }));

  const [getPartyGroup, getPartyGroupApiResponse] =
    partyGroupApi.useLazyGetQuery();

  const [searchApi, searchResponse] = partyGroupApi.useLazySearchQuery();

  const [updateBatchApi, updateBatchResponse] =
    partyGroupApi.useUpdateBatchMutation();

  const [getBatchApi, getBatchResponse] = partyGroupApi.useLazyGetBatchQuery();
  const [createApi, createResponse] = partyGroupApi.useCreateMutation();

  const createPartyGroup = async (
    r: Pick<PartyGroup, "name" | "description">
  ) => {
    await createApi({
      name: r.name,
      description: r.description,
      status: Status.Active
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "party group created",
            description: `party group: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create party group",
            description: r.data?.description
          });
        }
      });
  };

  const clonePartyGroup = async (
    r: Pick<PartyGroup, "name" | "description">
  ) => {
    return await createPartyGroup(r);
  };

  const handleUpdateBatchApiResponse = async (apiCall) => {
    return await apiCall.then(responseHandler).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Party groups Updated",
          description: `total Party groups updated: ${r.data?.batch?.length}`
        });
      }
      if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description: r.data?.description
        });
      }
      return r;
    });
  };

  const currentCombination = partyGroupState.filters.currentCombination;
  const isDataFiltered = !!currentCombination;
  const { selectedIds, filters, ids: partyGroupIds } = partyGroupState;
  const filteredIds = filters.combinations[currentCombination].ids;

  const partyGroupIdsToUpdate = selectedIds
    ? isDataFiltered
      ? intersection(selectedIds, filteredIds)
      : selectedIds
    : isDataFiltered
    ? filteredIds
    : partyGroupIds;

  const buildUpdatePayload = (r: {
    ids: EntityId[];
    updateDetails: Partial<PartyGroup>;
  }) => {
    return r.ids.reduce((acc, id) => {
      acc[id] = r.updateDetails;
      return acc;
    }, {} as { [id: string]: Partial<PartyGroup> });
  };
  const updatePartyGroupSelectionsBatch = async (
    r: Omit<Partial<PartyGroup>, keyof BaseEntity<object>>
  ) => {
    const shouldUpdateAll = partyGroupState.isAllChecked;
    if (SHOULD_FETCH_ALL) {
      const updatedSelected = buildUpdatePayload({
        ids: partyGroupIdsToUpdate,
        updateDetails: r
      });
      return await updatePartyGroupBatch(updatedSelected);
    }

    if (!shouldUpdateAll && partyGroupState.selectedIds) {
      const updatedSelected = buildUpdatePayload({
        ids: partyGroupState.selectedIds,
        updateDetails: r
      });
      return await updatePartyGroupBatch(updatedSelected);
    }

    if (shouldUpdateAll) {
      const payload = {
        excludedIds: partyGroupState.excludedIds.map(String),
        updateDetails: r,
        filters: buildFireStoreFilters()
      };

      return await handleUpdateBatchApiResponse(updateBatchApi(payload));
    }
  };

  const updatePartyGroupBatch = async (r: {
    [id: string]: Partial<PartyGroup>;
  }) => {
    const payload = removeEmptyValues(r);

    if (!payload?.length) {
      return null;
    }

    return await handleUpdateBatchApiResponse(
      updateBatchApi({ partyGroups: payload })
    );
  };

  const totalPartyGroup = isDataFiltered
    ? partyGroupState.filters.combinations[currentCombination].ids.length
    : partyGroupState.ids.length;

  const dataRank: FieldsWeight<PartyGroup> = {
    name: 30
  };

  const rankedSort = (query: string) => {
    return Object.values(partyGroupState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);

      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  return {
    getPartyGroup,
    getPartyGroupApiResponse,
    getBatchApi,
    getBatchResponse,
    selectPartyGroup,
    setPartyGroupId,
    unSelectPartyGroup,
    selectAllPartyGroup,
    selectPartyGroupIds,
    unSelectAllPartyGroup,
    partyGroupState,
    createPartyGroup,
    createResponse,
    clonePartyGroup,
    updatePartyGroupSelectionsBatch,
    updatePartyGroupBatch,
    updateBatchResponse,
    searchApi,
    searchResponse,
    dataRank,
    rankedSort,
    totalPartyGroup,
    SHOULD_FETCH_ALL,
    partyGroupIdsToUpdate
  };
};

export const usePartyGroupFilter = () => {
  const { partyGroupState } = usePartyGroup();
  const { buildFireStoreFilters, setCombination, setFilterCombinationDetails } =
    useFilter<PartyGroup, PartyGroupSliceState>(partyGroupState);
  const currentFilter = partyGroupState.filters.current;
  const [filters, setFilters] = useState<FirebaseFilter[]>([]);

  useEffect(() => {
    const filters = buildFireStoreFilters();
    setFilters(filters);
  }, [partyGroupState.filters.currentCombination]);

  const setCurrentFilter = (filter: {
    values: PartyGroupSliceState["filters"]["current"];
  }) => {
    setCombination(
      filter.values,
      partyGroupSlice.actions.setFilterAndCombination
    );
  };

  const currentCombination = useSelector((state: RootState) => {
    const filters = state.partyGroup.filters;
    return filters.combinations[filters.currentCombination];
  });

  const filteredItems = useMemo(() => {
    const names = new Set(currentFilter.name);
    const statuses = new Set(currentFilter.status);

    const filtered = partyGroupState.ids
      .map((id) => {
        const entity = partyGroupState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { name, status } = entity;
        if (
          (names.size === 0 || (names && names.has(name))) &&
          (statuses.size === 0 || (status && statuses.has(status)))
        ) {
          return {
            ...entity
          };
        }
        return undefined;
      })
      .filter(removeEmpty);

    return filtered;
  }, [currentFilter, partyGroupState.entities]);

  useEffect(() => {
    setFilterCombinationDetails(
      { ids: filteredItems.map((x) => x.id) },
      partyGroupSlice.actions.setFilterCombinationDetails
    );
  }, [filteredItems]);

  const setPagingInfo = (info: Partial<FilterCombinationState[string]>) => {
    setFilterCombinationDetails(
      info,
      partyGroupSlice.actions.setFilterCombinationDetails
    );
  };

  return {
    setCurrentFilter,
    filters,
    currentCombination,
    setPagingInfo
  };
};
