import { IntegrationTaskTemplate } from "@elphi/types";
import { WireInsuranceCertificateOrderTask } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { useWireInsuranceTaskHooks } from "../../../../hooks/wireInsuranceTask.hooks";
import { TaskStatusIconToolTip } from "../../TaskStatusIconToolTip";
type WireInsuranceTaskIconProps =
  {} & IntegrationTaskTemplate<WireInsuranceCertificateOrderTask>;

export const WireInsuranceTaskIcon = ({
  selectedTask
}: WireInsuranceTaskIconProps) => {
  const { getOrderStatusTooltip } = useWireInsuranceTaskHooks();

  return (
    <TaskStatusIconToolTip
      selectedTask={selectedTask}
      getOrderStatusTooltip={getOrderStatusTooltip}
    />
  );
};
