import DOMPurify from "dompurify";
import { DOMPURIFY_CONFIG } from "./quillInstanceBuilder";

export const sanitizeEditorData = (editorData: {
  content: string;
  mentioned: string[];
  htmlContent: string;
}) => {
  try {
    const sanitizedHtmlContent = DOMPurify.sanitize(
      editorData.htmlContent,
      DOMPURIFY_CONFIG
    );

    const sanitizedHtml = !sanitizedHtmlContent.trim()
      ? "[Content removed for security reasons]"
      : sanitizedHtmlContent;

    const parsedContent = JSON.parse(editorData.content);

    if (parsedContent.ops) {
      parsedContent.ops = parsedContent.ops.map((op: any) => {
        if (typeof op.insert === "string") {
          const sanitizedInsert = DOMPurify.sanitize(op.insert);
          return { ...op, insert: sanitizedInsert || "[Removed]" };
        }
        return op;
      });
    }

    return {
      content: JSON.stringify(parsedContent),
      mentioned: editorData.mentioned,
      htmlContent: sanitizedHtml
    };
  } catch (error) {
    console.error("Error sanitizing editor data:", error);
    return editorData;
  }
};
