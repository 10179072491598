import { FileInfo, StorageProvider } from "./storage.types";

export type BoxFileInfo = FileInfo<
  StorageProvider.Box,
  { folderId: string; fileId: string; fileName: string }
>;
export type BoxSDKFileUploadResponse = {
  total_count: number;
  entries: {
    type: string;
    id: string;
    name: string;
    parent: {
      type: string;
      id: string;
    };
    file_version: { type: string; id: string; sha1: string };
  }[];
};
export type BoxSDKMetadataUpdateResponse = {
  audience: string;
  documentType: string;
  status: string;
  author: string;
};
export type BoxSDKMetadataSchema = {
  id: string;
  templateKey: string;
  displayName: string;
  hidden: boolean;
  fields: MetadataTemplateField[];
};

type MetadataOp = "add" | "replace" | "remove" | "test" | "move" | "copy";

type BaseUpdateMetadataRecord<TOp extends MetadataOp> = {
  op: TOp;
  path: string;
  value: string | string[] | number | number[];
};
type UpdateMetadataFrom = BaseUpdateMetadataRecord<"move" | "copy"> & {
  from: string;
};
type UpdateNewMetadata = BaseUpdateMetadataRecord<
  "add" | "replace" | "remove" | "test"
>;

export type UpdateFileMetaDataRecord = UpdateMetadataFrom | UpdateNewMetadata;

type MetadataEnumOption = {
  key: string;
};
export type MetadataTemplateField = {
  type: MetadataFieldType;
  key: string;
  displayName: string;
  options?: MetadataEnumOption[];
};
export enum MetadataFieldType {
  STRING = "string",
  ENUM = "enum",
  NUMBER = "float",
  DATE = "date",
  MULTI_SELECT = "multiSelect"
}
