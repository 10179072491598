import { Radio, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import useWindowDimensions from "../../../../../hooks/windowDimensions";
import { ElphiTable } from "../../../../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../../../../table/table.types";
import { ElphiLocationResponse } from "../propertyReport.type";

type SiteXPropertiesListProps = {
  locations: ElphiLocationResponse[];
  onSelectRow: (item: ElphiLocationResponse) => void;
};

export const SiteXPropertiesList = ({
  locations,
  onSelectRow
}: SiteXPropertiesListProps) => {
  const { heightOffsetInPx } = useWindowDimensions();
  const [selectedRow, setSelectedRow] = useState<number>();

  const tableHeaders: ElphiTableProps["header"] = [
    {
      index: 0,
      data: "",
      type: ElphiCellType.String,
      maxWidth: "50px",
      minWidth: "50px"
    },
    {
      index: 1,
      data: "Row Index",
      type: ElphiCellType.String,
      maxWidth: "100px",
      minWidth: "100px"
    },
    {
      index: 2,
      data: "Address Line 1",
      type: ElphiCellType.String,
      maxWidth: "200px",
      minWidth: "200px"
    },
    {
      index: 3,
      data: "Address Line 2",
      type: ElphiCellType.String,
      maxWidth: "150px",
      minWidth: "150px"
    },
    {
      index: 4,
      data: "City Name",
      type: ElphiCellType.String,
      maxWidth: "150px",
      minWidth: "150px"
    },
    {
      index: 5,
      data: "State Code",
      type: ElphiCellType.String,
      maxWidth: "100px",
      minWidth: "100px"
    },
    {
      index: 6,
      data: "Zip Code",
      type: ElphiCellType.String,
      maxWidth: "100px",
      minWidth: "100px"
    },
    {
      index: 7,
      data: "Assessors Parcel Number (APN)",
      type: ElphiCellType.String,
      maxWidth: "200px",
      minWidth: "200px"
    },
    {
      index: 8,
      data: "FIPS",
      type: ElphiCellType.String,
      maxWidth: "100px",
      minWidth: "100px"
    }
  ];

  const tableRows: ElphiTableProps["rows"] = useMemo(() => {
    return locations?.flatMap((location, i) => {
      return {
        index: String(i),
        selected: selectedRow === i,
        cells: [
          {
            index: 0,
            data: (
              <Radio
                isChecked={selectedRow === i}
                onChange={() => {
                  onSelectRow(location);
                  setSelectedRow(i);
                }}
              />
            ),
            type: ElphiCellType.Element,
            maxWidth: "50px",
            minWidth: "50px"
          },
          {
            index: 1,
            data: (
              <Text align={"center"} fontSize={14}>
                {i + 1}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          },
          {
            index: 2,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.addressLineText}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "200px",
            minWidth: "200px"
          },
          {
            index: 3,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.addressUnitIdentifier}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 4,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.cityName}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 5,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.stateCode}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          },
          {
            index: 6,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.postalCode}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 7,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.assessorParcelNumber}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "200px",
            minWidth: "200px"
          },
          {
            index: 8,
            data: (
              <Text align={"center"} fontSize={14}>
                {location?.fips}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          }
        ]
      };
    });
  }, [selectedRow, locations]);

  return (
    <ElphiTable
      isLoading={false}
      minHeight={heightOffsetInPx(500)}
      maxHeight={heightOffsetInPx(500)}
      header={tableHeaders}
      rows={tableRows}
      rowsCount={1}
    />
  );
};
