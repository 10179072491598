import { thirdPartyFieldTemplate } from "../../../../../../types/aggregations";
import { AggregationLoanPassPricingFields } from "../../../../../../types/entities/integrations";

export const getLoanpassPricingTemplate =
  (): AggregationLoanPassPricingFields => {
    return {
      adjustedPrice: thirdPartyFieldTemplate(),
      adjustedRate: thirdPartyFieldTemplate(),
      appraisedValue: thirdPartyFieldTemplate(),
      baseLoanAmount: thirdPartyFieldTemplate(),
      citizenshipType: thirdPartyFieldTemplate(),
      decisionCreditScore: thirdPartyFieldTemplate(),
      expirationDate: thirdPartyFieldTemplate(),
      investorName: thirdPartyFieldTemplate(),
      loanPurpose: thirdPartyFieldTemplate(),
      lockPeriod: thirdPartyFieldTemplate(),
      lockStatus: thirdPartyFieldTemplate(),
      mortgageLatePayments: thirdPartyFieldTemplate(),
      pipelineRecordId: thirdPartyFieldTemplate(),
      pipelineRecordName: thirdPartyFieldTemplate(),
      prepaymentPenaltyTerm: thirdPartyFieldTemplate(),
      priceAdjustments: thirdPartyFieldTemplate(),
      productName: thirdPartyFieldTemplate(),
      propertyType: thirdPartyFieldTemplate(),
      requestDate: thirdPartyFieldTemplate(),
      stateCode: thirdPartyFieldTemplate(),
      stipulations: thirdPartyFieldTemplate(),
      totalLLPA: thirdPartyFieldTemplate()
    };
  };
