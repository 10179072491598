import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, keyframes } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useClickOutsideHook } from "../../hooks/clickOutside.hooks";

type OptionProps = {
  top?: number | string;
  width?: number | string;
  height?: string | number;
};

type RightSideBarProps = {
  show: boolean;
  children?: React.ReactNode;
  options?: OptionProps;
  onClose?: () => void;
  title?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  enableCloseClickOutside?: boolean;
  showCloseButton?: boolean;
};

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const RightSideBar = ({
  show,
  children,
  options,
  onClose,
  title,
  footer,
  enableCloseClickOutside = true,
  showCloseButton = true
}: RightSideBarProps) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(show);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { top = 0, width = "25%", height = "100%" } = options || {};

  const onCloseModal = () => {
    onClose?.();
    setShowSideBar(false);
  };

  useEffect(() => {
    setShowSideBar(show);
  }, [show]);

  useClickOutsideHook({
    ref: sidebarRef,
    onClickOutside: onCloseModal,
    enableCloseClickOutside,
    enableDoubleClickOutside: true,
    onDoubleClickOutside: onCloseModal
  });

  return (
    showSideBar && (
      <Flex
        ref={sidebarRef}
        height={height}
        position="absolute"
        top={top}
        right="0"
        bottom="0"
        width={width}
        backgroundColor="white"
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        padding="4"
        zIndex={999}
        animation={show ? `${slideIn} 0.3s ease-out forwards` : "none"}
        display={showSideBar ? "flex" : "none"}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          {(title || showCloseButton) && (
            <Flex
              pb="10px"
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex>{title && title}</Flex>
              <Flex>
                {showCloseButton && (
                  <CloseIcon cursor="pointer" onClick={onCloseModal} />
                )}
              </Flex>
            </Flex>
          )}
        </Box>
        <Box flex="1" overflowY="auto">
          {children}
        </Box>
        {footer && <Box pt="10px">{footer}</Box>}
      </Flex>
    )
  );
};
