import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  AccountAsset,
  Asset,
  AssetType,
  FieldType,
  Party,
  getAssetTemplate
} from "@elphi/types";
import { useEffect, useState } from "react";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { useAssetHooks } from "../../hooks/asset.hooks";
import FormBuilder from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";
import { AssetQuerySearch, assetQueryType } from "./AssetQuerySearch";

const CreateAssetForm = (props: {
  createAsset?: (newAsset: Asset) => void;
  onSuccess?: () => void;
  isLoading?: boolean;
  selectedParty: Partial<Party>;
}) => {
  const { selectedParty } = props;
  const { addAssetToParty, addAssetToPartyResponse } = useAssetHooks();
  const { errorToast, successToast } = useElphiToast();
  const { sections } = useSections();

  const { onChange: assetTypeOnChange, state: assetTypeState } =
    useFormBuilderStateHandler<Pick<Asset, "type">>({
      initialState: {
        type: "" as AssetType
      }
    });
  const { onChange: accountOnChange, state: accountState } =
    useFormBuilderStateHandler<AccountAsset>({
      initialState: getAssetTemplate(AssetType.Account)
    });

  const currentState = accountState;

  const isStateValidForCreation = !assetTypeState.type;

  const createAssetHandler = async () => {
    const newAsset = { ...currentState, ...assetTypeState };
    const selectedPartyId = (selectedParty && selectedParty?.id) || "";
    return (await assetTypeState.type) && props?.createAsset
      ? props?.createAsset(newAsset)
      : selectedPartyId &&
          addAssetToParty({ partyId: selectedPartyId, asset: newAsset }).then(
            (r) => {
              if (r.status === 200) {
                successToast({
                  title: "Asset Created",
                  description: `new asset: ${r.data.asset.id}`
                });
                props?.onSuccess && props?.onSuccess();
              }
              r.status === 400 &&
                errorToast({
                  title: "Failed to create asset",
                  description: r.data.description
                });
              return r;
            }
          );
  };

  const { asset: assetTypeSection } = sections || {};
  const assetTypeInfoSection = assetTypeSection?.assetInfoSection?.({
    state: assetTypeState as Partial<Asset>,
    onChange: assetTypeOnChange,
    options: { hideAttachedComponent: true }
  });
  const createAccountSection = assetTypeSection?.createAccountSection?.({
    state: accountState,
    onChange: accountOnChange,
    options: { hideAttachedComponent: true }
  });

  return (
    <>
      <Box>
        <FormBuilder
          customKey="assetInfoSection"
          elphiView="form"
          onChange={assetTypeOnChange}
          sections={[assetTypeInfoSection]}
        />
        {assetTypeState.type === AssetType.Account ? (
          <FormBuilder
            customKey="createAccountSection"
            elphiView="form"
            onChange={accountOnChange}
            sections={[createAccountSection]}
          />
        ) : null}
      </Box>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => createAssetHandler()}
        isDisabled={isStateValidForCreation}
        isLoading={addAssetToPartyResponse.isLoading || props?.isLoading}
      >
        Create
      </Button>
    </>
  );
};

export const CreateAssetModal = (props: { selectedParty: Partial<Party> }) => {
  const { selectedParty } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAssets, setSelectedAssets] = useState([] as string[]);
  const { createRelations, createRelationsResponse } = useAssetHooks();
  const { successToast, errorToast } = useElphiToast();

  useEffect(() => setSelectedAssets([]), [isOpen]);

  const createRelationsHandler = async (
    relations: { partyId: string; assetId: string }[]
  ) => {
    return await createRelations(relations).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "New relations created",
          description: `${r.data?.length} relations created`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to create new relations",
          description: r.data.description
        });
      }
      return r;
    });
  };

  return (
    <Box>
      <>
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Add an Asset
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minWidth={"600px"}>
            <ModalHeader>New Asset</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AssetQuerySearch
                fieldType={FieldType.MultiSelect}
                onSelect={(v: string[]) => {
                  setSelectedAssets(v);
                }}
                label={"Existing assets"}
                labelPosition={"up"}
                currentValue={selectedAssets}
                selectedParty={selectedParty}
                query={assetQueryType.noPartyAssets}
              />
              {!selectedAssets.length && (
                <Box>
                  <Text pb="10px" fontWeight={"bold"}>
                    Or
                  </Text>
                  <CreateAssetForm
                    onSuccess={onClose}
                    selectedParty={selectedParty}
                  />
                </Box>
              )}
              {selectedAssets && selectedAssets.length > 0 && (
                <Box pt="10px">
                  <Button
                    {...elphiTheme.components.light.button.primary}
                    isLoading={createRelationsResponse.isLoading}
                    isDisabled={
                      !selectedParty || createRelationsResponse.isLoading
                    }
                    onClick={() => {
                      const request = selectedAssets.map((assetId) => ({
                        assetId,
                        partyId: selectedParty.id as string
                      }));
                      createRelationsHandler(request).then(
                        (r) => r.status === 200 && onClose()
                      );
                    }}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
};
