import { Badge, Flex, Heading, Tooltip } from "@chakra-ui/react";
import { ElphiTrxStatus, Task } from "@elphi/types";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT,
  ELPHI_TRX_STATUS_TOOLTIP_MESSAGES
} from "./integration.type";
type IntegrationTaskHeaderFormProps = {
  task: Task;
  elphiTrxStatus: ElphiTrxStatus;
  getOrderStatusTooltip?: (
    task: Task,
    showOnlyError?: boolean
  ) => string | JSX.Element | undefined;
};

export const IntegrationTaskHeaderForm = ({
  task,
  elphiTrxStatus,
  getOrderStatusTooltip
}: IntegrationTaskHeaderFormProps) => {
  const tooltipLabel = getOrderStatusTooltip?.(task, true);
  return (
    <Flex alignItems={"center"}>
      <Heading>Actions</Heading>
      <Tooltip hasArrow={true} label={tooltipLabel} placement={"top-end"}>
        <Badge
          fontSize={12}
          bgColor={
            elphiTrxStatus && ELPHI_TRX_STATUS_COLOR?.[elphiTrxStatus]
              ? ELPHI_TRX_STATUS_COLOR[elphiTrxStatus]
              : ELPHI_TRX_STATUS_COLOR?.default
          }
          borderColor={
            elphiTrxStatus && ELPHI_TRX_STATUS_COLOR?.[elphiTrxStatus]
              ? ELPHI_TRX_STATUS_COLOR[elphiTrxStatus]
              : ELPHI_TRX_STATUS_COLOR?.default
          }
          ml={5}
          color={
            ELPHI_TRX_STATUS_COLOR_TEXT[
              elphiTrxStatus || ElphiTrxStatus.Pending
            ]
          }
        >
          {
            ELPHI_TRX_STATUS_TOOLTIP_MESSAGES[
              elphiTrxStatus || ElphiTrxStatus.Pending
            ]
          }
        </Badge>
      </Tooltip>
    </Flex>
  );
};
