import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import { allowedSpecialCharsList } from "@elphi/utils";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { InvalidIssue } from "../types/types";

const invalidIssuesMessageMap: { [key in InvalidIssue]: string } = {
  [InvalidIssue.UnmatchedPasswords]: "Your passwords don't match",
  [InvalidIssue.InvalidChars]: `Your password contains some invalid characters.\nPlease only use letters, numbers, and these special characters: ${allowedSpecialCharsList}`
};

export const InvalidIssues = ({
  invalidIssues
}: {
  invalidIssues?: InvalidIssue[];
}) => {
  return (
    <Box>
      {invalidIssues && invalidIssues.length > 0 && (
        <UnorderedList spacing={1}>
          {invalidIssues.map((issue, i) => (
            <ListItem
              key={`${i}-${issue}`}
              color={elphiTheme.colors.light.notifications.error}
            >
              {invalidIssuesMessageMap[issue].split("\n").map((line, j) => (
                <span key={`${j}-${line}`}>
                  {line}
                  <br />
                </span>
              ))}
            </ListItem>
          ))}
        </UnorderedList>
      )}
    </Box>
  );
};
