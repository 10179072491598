import { useCallbackRef } from "@chakra-ui/react";
import { DealPartyRelation } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { usePartyHooks } from "../hooks/party.hooks";
import { RootState } from "../redux/store";
import { dealPartyRelationApi } from "../redux/v2/deal-party-relation/dealPartyRelation.service";
export const useDealPartyRelationHooks = () => {
  const [deleteDealPartyRelationApi, deleteDealPartyRelationResponse] =
    dealPartyRelationApi.useDeleteMutation();
  const [updateDealPartyRelationApi, updateDealPartyRelationResponse] =
    dealPartyRelationApi.useUpdateMutation();
  const [createDealPartyApi, createDealPartyApiResponse] =
    dealPartyRelationApi.useCreateMutation();

  const {
    getTreeApi,
    getPartyBatch,
    partyState,
    partyTabHighlightedParty,
    setHighlightedPartyTabParty
  } = usePartyHooks();

  const [
    updateDealPartyRelationBatchApi,
    updateDealPartyRelationBatchResponse
  ] = dealPartyRelationApi.useBatchUpdateMutation();
  const dealPartyRelationState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );

  const updateBatch = async (r: {
    relations: ({ id: string } & Partial<DealPartyRelation>)[];
  }) => {
    return await updateDealPartyRelationBatchApi(r).then(responseHandler);
  };
  const deleteDealPartyRelation = async (id: string) => {
    return await deleteDealPartyRelationApi(id).then(responseHandler);
  };
  const updateDealPartyRelation = async (
    r: { id: string } & Partial<DealPartyRelation>
  ) => {
    return await updateDealPartyRelationApi(r).then(responseHandler);
  };
  const createDealParty = async (dealId: string, partyId: string) => {
    const dealParty = {
      dealId,
      partyId,
      id: `${dealId}_${partyId}`,
      index: ""
    };
    return await createDealPartyApi(dealParty as DealPartyRelation).then(
      (r: any) => responseHandler<{ relation: { id: string } }>(r)
    );
  };

  const dealPartyRelationAddCallbackHandler = useCallbackRef(
    (r: Partial<DealPartyRelation>[]) => {
      const partiesId = r.map((d) => d.partyId).filter(removeEmpty);
      const missingIds = partiesId
        .filter((p) => p && !partyState.ids.includes(p))
        .filter(removeEmpty);
      missingIds.length && getPartyBatch(missingIds);
      getTreeApi(partiesId);
    },
    [partyState]
  );

  const dealPartyRelationRemoveCallbackHandler = useCallbackRef(
    (r: EntityId[]) => {
      !!partyTabHighlightedParty &&
        r.some((removedDealPartyId) =>
          removedDealPartyId.toString().endsWith(partyTabHighlightedParty.id)
        ) &&
        setHighlightedPartyTabParty("");
    },
    [partyState]
  );

  return {
    dealPartyRelationAddCallbackHandler,
    dealPartyRelationRemoveCallbackHandler,
    updateBatch,
    updateDealPartyRelationBatchResponse,
    deleteDealPartyRelation,
    deleteDealPartyRelationResponse,
    updateDealPartyRelation,
    updateDealPartyRelationResponse,
    createDealParty,
    createDealPartyApiResponse,
    dealPartyRelationState
  };
};
