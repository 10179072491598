import { Box, Button, Flex, Text } from "@chakra-ui/react";
import {
  FieldType,
  RolodexConfiguration,
  ServiceProviderEntityType
} from "@elphi/types";
import { useEffect } from "react";
import { elphiTheme } from "../../../../../../assets/themes/elphi.theme.default";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import FormBuilder from "../../../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../../../form-builder/InputBuilder";
import { ModalContainer } from "../../../../../modal-container/ModalContainer";
import { createSelectOptions } from "../../../../../utils/formUtils";
import {
  initialStateFields,
  serviceProviderEntityTypeOptionMap
} from "./modal.utils";

export const ConfigurationModal = (props: {
  isShow: boolean;
  onClose: () => void;
}) => {
  const { onChange, state, setState } = useFormBuilderStateHandler<
    Partial<Pick<RolodexConfiguration, "name" | "fields" | "entityType">>
  >({
    initialState: {
      fields: initialStateFields
    }
  });

  useEffect(() => {
    if (!props.isShow) {
      setState({
        fields: initialStateFields
      });
    }
  }, [props.isShow]);

  const {
    createConfiguration,
    cloneConfiguration,
    createResponse,
    selectedConfiguration
  } = useRolodexConfiguration();

  const handleSubmitClick = () => {
    if (state?.entityType && state?.fields && state?.name) {
      createConfiguration({
        entityType: state.entityType,
        fields: state.fields,
        name: state.name
      }).finally(() => {
        props.onClose();
      });
    }
  };

  const handleCloneClick = () => {
    if (state.entityType && state.fields && state.name) {
      cloneConfiguration({
        entityType: state.entityType,
        name: state.name
      }).finally(() => {
        props.onClose();
      });
    }
  };
  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onClose}
      header={"Rolodex Configuration"}
      body={
        <>
          {selectedConfiguration && (
            <Text>Cloning: {selectedConfiguration.name}</Text>
          )}
          <FormBuilder
            customKey="createRolodexConfigurationForm"
            elphiView="form"
            onChange={onChange}
            sections={[
              {
                inputs: [
                  {
                    label: "Service Domain Name",
                    labelPosition: "up",
                    fieldType: FieldType.String,
                    fieldKey: ["name"],
                    currentValue: state.name,
                    isValid: !!state.name
                  },
                  {
                    label: "Service Provider Entity",
                    labelPosition: "up",
                    fieldType: FieldType.SingleSelect,
                    fieldKey: ["entityType"],
                    currentValue: state.entityType,
                    isValid: !!state.entityType,
                    options: createSelectOptions(
                      ServiceProviderEntityType,
                      serviceProviderEntityTypeOptionMap
                    )
                  }
                ]
              }
            ]}
          />
        </>
      }
      footer={
        <>
          <Flex>
            <Box p="5px">
              <Button
                isLoading={!!selectedConfiguration && createResponse.isLoading}
                onClick={handleCloneClick}
                isDisabled={!selectedConfiguration || !state.name}
                float="right"
                {...elphiTheme.components.light.button.secondary}
              >
                Clone
              </Button>
            </Box>
            <Box p="5px">
              <Button
                isLoading={!selectedConfiguration && createResponse.isLoading}
                onClick={handleSubmitClick}
                isDisabled={!!selectedConfiguration || !state.name}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Submit New
              </Button>
            </Box>
          </Flex>
        </>
      }
    />
  );
};
