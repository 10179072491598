import { Input, InputProps } from "@chakra-ui/react";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerContainer } from "./DatePickerContainer";
type MultiDatePickerProps = {
  value?: string[];
  onChange?: (e: {
    target: {
      value: string[];
    };
  }) => void;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  dateFormat?: string;
  inputDateFormat?: string;
  maxSelectDates?: number;
} & InputProps;

export const MultiDatePicker = ({
  value,
  onChange,
  isRequired,
  isReadOnly,
  isDisabled,
  placeholder,
  maxSelectDates,
  dateFormat = DATES_FORMATS.YYYY_MM_DD,
  inputDateFormat = DATES_FORMATS.DD_MM_YYYY_WITH_SLASH,
  ...inputProps
}: MultiDatePickerProps) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    const dateValue: string[] | undefined =
      value &&
      typeof value === "string" &&
      elphiDate(value, { format: dateFormat }).isValid()
        ? [value]
        : value?.length
        ? value
        : undefined;

    const dateObjects =
      isArray(dateValue) &&
      dateValue?.reduce<Date[]>((dates, dateString) => {
        const date = elphiDate(dateString, { format: dateFormat });
        if (date.isValid()) {
          dates.push(date.toDate());
        }
        return dates;
      }, []);

    !!dateObjects && setSelectedDates(dateObjects);
  }, [value]);

  const handleChange = (date: Date) => {
    const isMaxSelectDates =
      !!maxSelectDates && maxSelectDates < selectedDates?.length + 1;
    const isIncludeDate = selectedDates.some(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );

    const currentDates = isIncludeDate
      ? selectedDates.filter(
          (selectedDate) => selectedDate.getTime() !== date.getTime()
        )
      : isMaxSelectDates
      ? selectedDates
      : [...selectedDates, date];

    setSelectedDates(currentDates);

    const formattedDates = currentDates.map((date) =>
      elphiDate(date).format(dateFormat)
    );
    onChange?.({ target: { value: formattedDates } });
  };

  return (
    <DatePickerContainer>
      <DatePicker
        readOnly={isReadOnly || isDisabled}
        onChange={handleChange}
        dateFormat={dateFormat}
        required={isRequired}
        placeholderText={placeholder || "Select dates"}
        highlightDates={selectedDates}
        value={selectedDates
          .map((date) => elphiDate(date).format(inputDateFormat))
          .join(", ")}
        customInput={<Input w={500} {...inputProps} />}
      />
    </DatePickerContainer>
  );
};
