import { Box, Flex } from "@chakra-ui/react";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { CustomCollapse } from "../../../../../common-components";
import { SectionGenerator } from "../../../../../forms/schemas/types";
import FormBuilder, {
  OnChangeInput,
  Section
} from "../../../../form-builder/FormBuilder";
const LOANPASS_WIDTH_CUSTOM_SECTIONS = "380px";
const LOANPASS_HEIGHT_CUSTOM_SECTIONS = "250px";

export const LoanpassSection = (props: {
  section?: Section;
  onChange: (e: OnChangeInput) => void;
  searchComponent?: React.ReactNode;
  customKey: string;
}) => {
  const { customKey, section, onChange } = props;
  const sections = [section].filter(removeEmpty);

  return (
    <Flex
      borderRadius="10px"
      p="10px"
      direction="column"
      justifyItems={"start"}
    >
      <Box pl="10px">{props.searchComponent}</Box>
      <FormBuilder
        customKey={`${customKey}-section`}
        elphiView="form"
        onChange={onChange}
        sections={sections}
        inputsWrapperProps={{
          width: LOANPASS_WIDTH_CUSTOM_SECTIONS,
          gap: 4
        }}
        size={{
          minW: LOANPASS_WIDTH_CUSTOM_SECTIONS
        }}
      />
    </Flex>
  );
};

export const LoanpassCollapseSection = (props: {
  title: string;
  customBody: React.ReactNode;
  shouldShow: boolean;
  maxHeight?: string;
}) => {
  const { title, maxHeight } = props;
  return (
    <CustomCollapse
      options={{
        titleContainer: { w: LOANPASS_WIDTH_CUSTOM_SECTIONS },
        containerBorderColor: "gray.200",
        shouldShow: props.shouldShow,
        maxHeight: maxHeight || LOANPASS_HEIGHT_CUSTOM_SECTIONS,
        withScroll: true
      }}
      title={title}
      customBody={
        <Box mr={8} w={LOANPASS_WIDTH_CUSTOM_SECTIONS} h={maxHeight}>
          {props.customBody}
        </Box>
      }
    />
  );
};

export type LoanpassSectionsProps<T extends object = {}> = {
  sectionBuilders?: SectionGenerator<T>;
  shouldShow: boolean;
  dealId: string;
};
