import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Text
} from "@chakra-ui/react";
import { ElphiTrxStatus } from "@elphi/types";
import { useEffect, useMemo } from "react";
import { elphiTheme } from "../../../../assets/themes/elphi.theme.default";
import { ModalContainer } from "../../../modal-container/ModalContainer";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT
} from "../../../task/integrations/integration.type";
import { BulkOrderConfirmModalProps } from "../integrationCenter.types";

export const BulkOrderConfirmModal = (props: BulkOrderConfirmModalProps) => {
  const {
    taskCards,
    filteredCards,
    isLoading,
    isShow,
    onClickOrder,
    onCloseModal,
    onSelectedStatuses,
    selectedStatuses
  } = props;

  useEffect(() => {
    if (isShow) {
      onSelectedStatuses([ElphiTrxStatus.Pending]);
    }
  }, [isShow]);

  const taskCardsByStatus = useMemo(() => {
    return {
      [ElphiTrxStatus.Pending]: taskCards.filter(
        (x) => !x.elphiStatus || x.elphiStatus === ElphiTrxStatus.Pending
      ),
      [ElphiTrxStatus.InProcess]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.InProcess
      ),
      [ElphiTrxStatus.Success]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Success
      ),
      [ElphiTrxStatus.Failed]: taskCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Failed
      )
    };
  }, [taskCards]);

  const filteredCardsByStatus = useMemo(() => {
    return {
      [ElphiTrxStatus.Pending]: filteredCards.filter(
        (x) => !x.elphiStatus || x.elphiStatus === ElphiTrxStatus.Pending
      ),
      [ElphiTrxStatus.InProcess]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.InProcess
      ),
      [ElphiTrxStatus.Success]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Success
      ),
      [ElphiTrxStatus.Failed]: filteredCards.filter(
        (x) => x.elphiStatus === ElphiTrxStatus.Failed
      )
    };
  }, [filteredCards]);

  const totalSelectedCount = selectedStatuses.reduce((acc, status) => {
    if (filteredCardsByStatus[status]) {
      acc += filteredCardsByStatus[status].length;
    }
    return acc;
  }, 0);

  const handleSelectChange = (value: ElphiTrxStatus) => {
    onSelectedStatuses(
      selectedStatuses
        ? selectedStatuses.includes(value)
          ? selectedStatuses.filter((option) => option !== value)
          : [...selectedStatuses, value]
        : [value]
    );
  };

  return (
    <ModalContainer
      isShow={isShow}
      header={"Confirm Bulk Order"}
      body={
        <>
          <Box>
            <Text>Order Services with the following Integration Statuses:</Text>
          </Box>
          <Box mt={4}>
            <CheckboxGroup
              value={selectedStatuses}
              onChange={(values: ElphiTrxStatus[]) =>
                onSelectedStatuses(values)
              }
            >
              {Object.keys(ElphiTrxStatus).map((option, i) => {
                const key = ElphiTrxStatus[option];
                return (
                  <Checkbox
                    mr={2}
                    key={i}
                    value={key}
                    onChange={() => handleSelectChange(key as ElphiTrxStatus)}
                  >
                    <Badge
                      fontSize={"12px"}
                      fontWeight={"700"}
                      color={ELPHI_TRX_STATUS_COLOR_TEXT[key]}
                      bgColor={ELPHI_TRX_STATUS_COLOR[key]}
                    >
                      <Text>
                        {key} {filteredCardsByStatus[key].length}/
                        {taskCardsByStatus[key].length}
                      </Text>
                    </Badge>
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </Box>
          <Box mt={4}>
            <Text>
              You are about to bulk order
              <Text
                as={"span"}
                pr={1}
                pl={1}
                fontWeight={"bold"}
                fontSize={"20px"}
              >
                {totalSelectedCount}
              </Text>
              third-party services.
            </Text>
          </Box>
          <Box mt={4}>
            <Text>You can’t undo this action.</Text>
          </Box>
          <Box mt={4}>
            <Text fontWeight={"bold"}>Are you sure you want to proceed?</Text>
          </Box>
        </>
      }
      onCloseModal={onCloseModal}
      footer={
        <>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={"15px"}
            isLoading={isLoading}
            onClick={onClickOrder}
            isDisabled={selectedStatuses.length === 0}
          >
            Confirm
          </Button>
          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};
