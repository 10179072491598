import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  AggregationFundingShieldFields,
  FundingShieldFlatFields
} from "../../../../../../types/entities/integrations";

export const getFundingShieldWireInsuranceCertAggregationTemplate =
  (): AggregationFundingShieldFields => {
    return {
      TransactionId: thirdPartyFieldTemplate(),
      TransactionStatus: thirdPartyFieldTemplate(),
      StateNYIndicator: aggregationFieldTemplate()
    };
  };

export const getFundingShieldWireInsuranceCertTemplate =
  (): FundingShieldFlatFields => {
    return {
      rushOrderIndicator: "" as BooleanSelectType,
      userEmail: "",
      userFirstName: "",
      userLastName: ""
    };
  };
