import { ElphiFieldsTemplateType, StatusCode } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { fieldsTemplateApi } from "../redux/v2/fields-templates";

export const useFieldsTemplateHooks = () => {
  const { errorToast } = useElphiToast();

  const [getFieldsTemplateApi, getFieldsTemplateApiResponse] =
    fieldsTemplateApi.useLazyGetFieldsTemplateQuery();

  const fetchFieldsTemplate = async (r: {
    templateType: ElphiFieldsTemplateType;
  }) => {
    await getFieldsTemplateApi({ templateType: r.templateType }, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get fields template",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const filedTempalteState = useSelector(
    (state: RootState) => state.fieldsTemplate
  );

  return {
    fetchFieldsTemplate,
    getFieldsTemplateApiResponse,
    filedTempalteState
  };
};
