import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { useRef, useState } from "react";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { OmitCreate } from "../../firebase/firebase.types";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import useDealHooks from "../../hooks/deal.hooks";
import { usePropertyHooks } from "../../hooks/property.hooks";
import FormBuilder from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";
import { NoRelationPropertySearch } from "./PropertySearch";

const CreatePropertyForm = (props: {
  createProperty: (newProperty: OmitCreate<Property>) => void;
  isLoading: boolean;
}) => {
  const { onChange, state } = useFormBuilderStateHandler<OmitCreate<Property>>({
    initialState: {} as OmitCreate<Property>
  });
  const { sections } = useSections();

  const createPropertyHandler = () => {
    const newProperty = state;
    props.createProperty(newProperty);
  };

  const isStateValidForCreation =
    state.Address?.CityName &&
    state.Address?.StateName &&
    state.Address?.StateCode;

  const createPropertySection = [
    sections?.property?.createPropertySection?.({
      state,
      options: { hideAttachedComponent: true }
    })
  ];

  return (
    <>
      <Box>
        <FormBuilder
          customKey="createPropertySection"
          elphiView="form"
          onChange={onChange}
          sections={createPropertySection}
        />
      </Box>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => createPropertyHandler()}
        isDisabled={!isStateValidForCreation}
        isLoading={props.isLoading}
      >
        Create
      </Button>
    </>
  );
};

export const AddPropertyModal = (props: {
  buttonFn?: (handleOpen: () => void) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { successToast, errorToast } = useElphiToast();

  const { addDealProperties, addDealPropertiesApiResponse } = useDealHooks();
  const { selectedDeal } = useDealHooks();
  const propsButton = props.buttonFn && props.buttonFn(onOpen);

  const addDealPropertiesHandler = (
    properties: ({ id: string } | OmitCreate<Property>)[]
  ) => {
    if (selectedDeal) {
      addDealProperties({
        dealId: selectedDeal.id,
        properties: properties
      }).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "new properties added successfully",
            description: `${r.data?.properties.length} properties added`
          });
          setSelectedProperties([]);
          onClose();
        }
        if (r.status === 400) {
          errorToast({
            title: "failed to add property",
            description: r.data.description
          });
        }
      });
    }
  };

  const finalRef = useRef<HTMLDivElement | null>(null);
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Add Property
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Add Property</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text pb="10px" fontWeight={"bold"}>
              Deal: {selectedDeal && selectedDeal.LoanIdentifier}
            </Text>

            <NoRelationPropertySearch
              onSelect={(v) => {
                setSelectedProperties([...v]);
              }}
              currentValue={selectedProperties}
            />
            {!selectedProperties.length && (
              <Box>
                <Text pb="10px" fontWeight={"bold"}>
                  Or
                </Text>
                <CreatePropertyForm
                  createProperty={(property) =>
                    addDealPropertiesHandler([property])
                  }
                  isLoading={addDealPropertiesApiResponse.isLoading}
                />
              </Box>
            )}
            {selectedProperties && selectedProperties.length > 0 && (
              <Box pt="10px">
                <Button
                  {...elphiTheme.components.light.button.primary}
                  isLoading={addDealPropertiesApiResponse.isLoading}
                  isDisabled={!selectedDeal}
                  onClick={() => {
                    addDealPropertiesHandler(
                      selectedProperties.map((id) => {
                        return { id };
                      })
                    );
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export const CreatePropertyModal = (props: {
  buttonFn?: (handleOpen: () => void) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { successToast, errorToast } = useElphiToast();

  const { createProperty, createPropertyApiResponse } = usePropertyHooks();
  const propsButton = props.buttonFn && props.buttonFn(onOpen);

  const createPropertyHandler = (property: Property) => {
    createProperty(property).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "New Property Created",
          description: `${r.data?.id}`
        });
        onClose();
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to Add Property",
          description: r.data.description
        });
      }
    });
  };

  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Create Property
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Add Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreatePropertyForm
              createProperty={(property) =>
                createPropertyHandler({
                  ...property,
                  id: "",
                  index: "",
                  createdAt: "",
                  modifiedAt: ""
                })
              }
              isLoading={createPropertyApiResponse.isLoading}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
