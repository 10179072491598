import {
  BooleanSelectType,
  FieldType,
  limaPartyBaseFieldsToOmit
} from "@elphi/types";
import {
  limaFixNFlipNBridgePlusTier,
  limaLOCStatusType
} from "@elphi/types/entities/lima";
import {
  LimaIndividualPartyFields,
  LimaTINIndividualPartyFields,
  limaConcentrationReviewOutcome
} from "@elphi/types/entities/lima/party";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  BaseSchemaIndividualPartyFieldSpecs,
  baseSchemaIndividualPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";
import { limaCommonPartySpecs } from "./party.fields";

type LimaIndividualPartyFieldSpecs = EntityFormFieldSpecs<
  LimaIndividualPartyFields & LimaTINIndividualPartyFields
>;

export type FullLimaSchemaIndividualPartyFieldSpecs =
  Partial<BaseSchemaIndividualPartyFieldSpecs> & LimaIndividualPartyFieldSpecs;

const limaIndividualPartyFieldSpecs: LimaIndividualPartyFieldSpecs = {
  FixNFlipNBridgePlusTier: modifyFromSpec({
    fieldSpecs: baseSchemaIndividualPartyFieldSpecs.FixNFlipNBridgePlusTier,
    changes: {
      label: "Short Term Tier",
      options: createOptionsFromMap(limaFixNFlipNBridgePlusTier)
    }
  }),
  PaymentTokenId: {
    fieldKey: ["PaymentTokenId"],
    fieldType: FieldType.String,
    label: "Payment Token ID"
  },
  OverridePartyValidationSettingsIndicator: {
    fieldKey: ["OverridePartyValidationSettingsIndicator"],
    fieldType: FieldType.SingleSelect,
    label: "Override Party Validation Settings?",
    options: createOptionsFromMap(BooleanSelectType)
  },
  LOCStatusType: modifyFromSpec({
    fieldSpecs: baseSchemaIndividualPartyFieldSpecs.LOCStatusType,
    changes: {
      label: "Line of Credit (LOC) Status",
      options: createOptionsFromMap(limaLOCStatusType)
    }
  }),
  DateConcentrationReview: {
    fieldKey: ["DateConcentrationReview"],
    fieldType: FieldType.Date,
    label: "Date of Concentration Review"
  },
  DateNextConcentrationReview: {
    fieldKey: ["DateNextConcentrationReview"],
    fieldType: FieldType.Date,
    label: "Date of Next Concentration Review"
  },
  ConcentrationReviewActionItems: {
    fieldKey: ["ConcentrationReviewActionItems"],
    fieldType: FieldType.String,
    label: "Concentration Review Action Items"
  },
  ConcentrationReviewOutcome: {
    fieldKey: ["ConcentrationReviewOutcome"],
    fieldType: FieldType.SingleSelect,
    label: "Concentration Review Outcome",
    options: createOptionsFromMap(limaConcentrationReviewOutcome)
  }
};

const limaIndividualPartySpecs: FullLimaSchemaIndividualPartyFieldSpecs =
  mergeSpecs(
    omitFromSpec({
      obj: baseSchemaIndividualPartyFieldSpecs,
      paths: limaPartyBaseFieldsToOmit
    }),
    limaCommonPartySpecs,
    limaIndividualPartyFieldSpecs
  );

export const fullLimaSchemaIndividualPartySpecs = createSpecWithFieldMeta({
  spec: limaIndividualPartySpecs
});
