import { LOSUser } from "@elphi/types";
import { get } from "lodash";

export const getLOSUserTitle = (user: LOSUser, filterRoles: string[]) => {
  let userTitle = "";
  if (!!user?.roles) {
    const filteredRoles = user.roles.filter((role) =>
      filterRoles.includes(role.value)
    );
    userTitle = namesToContractFormat<typeof filteredRoles>({
      list: filteredRoles,
      adapter: (role: { label: string; value: string }) => role.label
    });
  }
  return userTitle;
};

const namesToContractFormat = <T>(r: {
  list: T;
  fields?: {
    [order: string]: string[];
  };
  adapter?: (v: any) => string;
}) => {
  const { list, fields, adapter } = r;
  let listString: string = "";
  if (Array.isArray(list)) {
    list.forEach((entity, index) => {
      if (fields) {
        Object.keys(fields)
          .sort((a, b) => (a > b ? 1 : -1))
          .map((orderKey) => {
            const fieldpath = fields[orderKey];
            listString = listString + ` ${get(entity, fieldpath, "")}`;
          });
      } else if (adapter) {
        listString = listString + ` ${adapter(entity)}`;
      } else if (typeof entity === "string") {
        listString = listString + ` ${entity}`;
      }
      if (list.length > 2 && index === list.length - 2) {
        listString = listString + ", and";
      } else if (list.length <= 2 && index === list.length - 2) {
        listString = listString + " and";
      } else if (list.length > 2 && index !== list.length - 1) {
        listString = listString + ", ";
      }
    });
  }
  return listString.trim();
};
