import { UserData } from "../shared";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

export function updateUserData(userData: UserData) {
  return { type: UPDATE_USER_DATA, userData };
}

export function resetUserData() {
  return { type: RESET_USER_DATA };
}

export function updatePermissions(permissions: string[]) {
  return { type: UPDATE_PERMISSIONS, data: permissions };
}
