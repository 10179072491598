import { Box, chakra } from "@chakra-ui/react";

export const ActionListWrapper = chakra(Box, {
  baseStyle: {
    float: "right",
    display: "flex",
    alignItems: "center"
  }
});
export const TreeNodeSublabel = chakra(Box, {
  baseStyle: {
    color: "gray",
    p: "5px"
  }
});
export const TreeNodeWrapper = chakra(Box, {
  baseStyle: {
    minWidth: "200px",
    minHeight: "60px",
    display: "inline-block",
    fontSize: "1em",
    borderRadius: "10px",
    textAlign: "left",
    fontWeight: "600",
    cursor: "pointer"
  }
});

export const TreeNodeLayout = chakra(Box, {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    height: "100%"
  }
});
export const TreeNodeLabel = chakra(Box, {
  baseStyle: {
    p: "5px",
    whiteSpace: "nowrap"
  }
});

export const ComponentWrapper = chakra(Box);
