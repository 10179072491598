import { DocumentTemplateResponse } from "@elphi/types";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createSlice
} from "@reduxjs/toolkit";
import { difference, keyBy, union } from "lodash";
import { EMPTY } from "../../../constants/common";
import { FieldGroupSliceState } from "../field-group";
import { FiltersState } from "../types/stateWithFilter.types";
import {
  initCombination,
  setCombinationInfo,
  setFilterAndCombination
} from "../utils/stateFilter.utils";
import { documentTemplateAdapter } from "./documentTemplate.adapter";
import { documentTemplateApi } from "./documentTemplate.service";

export type DocumentTemplateSliceState =
  EntityState<DocumentTemplateResponse> & {
    filters: FiltersState<
      DocumentTemplateResponse,
      "name" | "status" | "templateCategory" | "stateCode"
    >;
    selectedIds: EntityId[];
    excludedIds: EntityId[];
    selectedSearchResultId?: EntityId;
    isAllChecked: boolean;
  };

const initialState: DocumentTemplateSliceState = {
  ids: [],
  entities: {},
  selectedIds: [],
  excludedIds: [],
  selectedSearchResultId: undefined,
  isAllChecked: false,
  filters: {
    current: {
      name: [],
      status: [],
      templateCategory: [],
      stateCode: []
    },
    currentCombination: "",
    combinations: {
      [EMPTY]: initCombination()
    }
  }
};

export const documentTemplateSlice = createSlice({
  name: "documentTemplate",
  initialState: documentTemplateAdapter.getInitialState(initialState),
  reducers: {
    update: documentTemplateAdapter.updateOne,
    remove: documentTemplateAdapter.removeOne,
    add: documentTemplateAdapter.addOne,
    upsert: documentTemplateAdapter.upsertOne,
    upsertMany: (state, action: { payload: DocumentTemplateResponse[] }) => {
      const upsertIds = action.payload.map((x) => x.id);
      const diff = difference(upsertIds, state.ids);
      documentTemplateAdapter.upsertMany(state, action.payload);
      if (state.isAllChecked) {
        state.selectedIds.push(...diff);
      }
    },
    removeMany: (state, action: { payload: EntityId[] }) => {
      const ids = action.payload;
      documentTemplateAdapter.removeMany(state, ids);

      if (state.isAllChecked) {
        state.selectedIds = state.selectedIds.filter((id) => !ids.includes(id));
      }
    },
    updateMany: documentTemplateAdapter.updateMany,
    selectIds: (state, action: PayloadAction<{ ids: EntityId[] }>) => {
      state.selectedIds.push(...action.payload.ids);
      state.excludedIds = difference(state.excludedIds, action.payload.ids);
      if (!state.excludedIds.length) {
        state.isAllChecked = true;
      }
    },
    unSelectId: (state, action: PayloadAction<{ id: EntityId }>) => {
      const filtered = state.selectedIds.filter(
        (id) => id !== action.payload.id
      );
      if (state.selectedIds.length > filtered.length && state.isAllChecked) {
        state.excludedIds.push(action.payload.id);
      }

      state.selectedIds = filtered;
    },
    selectAllIds: (state) => {
      state.selectedIds = state.ids;
      state.excludedIds = [];
      state.isAllChecked = true;
    },
    unSelectAll: (state) => {
      state.selectedIds = [];
      state.excludedIds = [];
      state.isAllChecked = false;
    },
    setDocumentTemplateId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedSearchResultId = action.payload.id;
    },
    setFilterAndCombination: (
      state,
      action: PayloadAction<{
        combination: string;
        filters: FieldGroupSliceState["filters"];
      }>
    ) => setFilterAndCombination(state, action),
    setFilterCombinationDetails: (
      state,
      action: PayloadAction<
        FieldGroupSliceState["filters"]["combinations"][string]
      >
    ) => setCombinationInfo(state, action)
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      documentTemplateApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.map((v) => v.id)
        );

        state.entities = {
          ...state.entities,
          ...keyBy(payload.page, "id")
        };
      }
    );
    builder.addMatcher(
      documentTemplateApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.documentTemplates.ids);
        state.entities = {
          ...state.entities,
          ...payload.documentTemplates.entities
        };
      }
    );
    builder.addMatcher(
      documentTemplateApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        const { results } = payload;
        state.ids = union(state.ids, results.ids);
        state.entities = { ...state.entities, ...results.entities };
      }
    );
  }
});
