import {
  ElphiFieldsTemplateResponse,
  ElphiFieldsTemplateType
} from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { fieldsTemplateAdapter } from "./fieldsTemplate.adapter";

export const baseFieldsTemplateApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "fieldsTemplateApi",
  tagTypes: ["fieldsTemplate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "fields-template"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: fieldsTemplateAdapter
  })
});

export const fieldsTemplateApi = baseFieldsTemplateApi.injectEndpoints({
  endpoints: (builder) => ({
    getFieldsTemplate: builder.query<
      ElphiFieldsTemplateResponse,
      { templateType: ElphiFieldsTemplateType }
    >({
      query: (r) => {
        return {
          url: `/type/${r.templateType}`,
          method: "GET"
        };
      }
    })
  })
});
