import { ElphiEntityType, SystemSetup } from "../../../types";
import {
  dealTemplates,
  partyTemplates,
  propertyTemplates
} from "./entities-templates";
import { GetTemplateArgs, TemplateMap } from "./types";

const templatesMap: TemplateMap = {
  [ElphiEntityType.deal]: dealTemplates,
  [ElphiEntityType.property]: propertyTemplates,
  [ElphiEntityType.party]: partyTemplates
};

const resolveEntityTemplate = (templateArg: GetTemplateArgs) => {
  const { entityType } = templateArg;
  switch (entityType) {
    case ElphiEntityType.deal:
    case ElphiEntityType.property:
      return templatesMap[entityType];

    case ElphiEntityType.party:
      return templatesMap[ElphiEntityType.party]?.[templateArg.partyType];
    default:
      throw new Error("Invalid entity type");
  }
};

export const getEntityTemplate = <T extends object>(r: GetTemplateArgs): T => {
  const { entityType, systemSetup } = r;
  const entityTemplates = resolveEntityTemplate(r);
  const getSystemSetupTemplate = entityTemplates?.[systemSetup];
  if (!getSystemSetupTemplate) {
    const getElphiTemplate = entityTemplates?.[SystemSetup.Elphi];
    if (!getElphiTemplate) {
      throw new Error(
        `No template found for entity type: ${entityType} and system setup: ${systemSetup} and no default template for Elphi`
      );
    }
    const template = getElphiTemplate() || {};
    return template as T;
  }
  const template = getSystemSetupTemplate?.();
  if (!template) {
    throw new Error(
      `No template found for entity type: ${entityType} and system setup: ${systemSetup}`
    );
  }
  return template as T;
};
