import { DealProperty, FieldType } from "@elphi/types";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

type BaseDealPropertyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealProperty>
>;

export type DealPropertyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealProperty>
>;

const baseDealPropertyFieldSpecs: BaseDealPropertyFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  dealId: {
    fieldKey: ["dealId"],
    fieldType: FieldType.String,
    label: "Deal ID"
  },
  propertyId: {
    fieldKey: ["propertyId"],
    fieldType: FieldType.String,
    label: "Property ID"
  }
};

const dealPropertyFieldBaseSpecs: DealPropertyFieldSpecs = {
  ...baseDealPropertyFieldSpecs
};

export const dealPropertyFieldSpecs = createSpecWithFieldMeta({
  spec: dealPropertyFieldBaseSpecs
});
