export enum FieldType {
  Array = "array",
  Boolean = "boolean",
  CreditScore = "credit-score",
  Date = "date",
  DateRange = "date-range",
  Decimal = "decimal",
  Email = "email",
  FourDigit = "four-digit",
  Integer = "integer",
  Money = "money",
  MultiCheckbox = "multi-checkbox",
  MultiDate = "multi-date",
  MultiSelect = "multi-select",
  Number = "number",
  Percentage = "percentage",
  PercentageThreeDecimal = "percentage-three-decimal",
  Phone = "phone",
  RadioSelect = "radio-select",
  RichText = "rich-text",
  SignedMoney = "signed-money",
  SingleCheckbox = "single-checkbox",
  SingleSelect = "single-select",
  String = "string",
  Switch = "switch",
  Year = "year"
}

export const SINGLE_TO_MULTI_INPUT: { [singleInput in FieldType]?: FieldType } =
  {
    [FieldType.SingleSelect]: FieldType.MultiSelect,
    [FieldType.Date]: FieldType.MultiDate,
    [FieldType.SingleCheckbox]: FieldType.MultiCheckbox
  };

export enum BooleanSelectType {
  true = "Yes",
  false = "No"
}
export enum SwitchValueType {
  Active = "active",
  Disabled = "disabled"
}

export type BaseFieldFormat<TType, TValue> = {
  type: TType;
  value: TValue;
};

// export type DefaultField = StringField;
// export type StringField = BaseFieldFormat<FieldType.String, string>;
// export type NumberField = BaseFieldFormat<FieldType.Number, number>;
// export type ListField<T> = BaseFieldFormat<FieldType.Array, Array<T>>;
// export type MoneyField = BaseFieldFormat<FieldType.Money, number>;
// export type SingleSelectField<T> = BaseFieldFormat<
//   FieldType.SingleSelect,
//   { optionsKey: string; value: Array<T> }
// >;
// export type MultiSelectField<T> = BaseFieldFormat<
//   FieldType.MultiSelect,
//   { optionsKey: string; value: Array<T> }
// >;
// export type PercentageField = BaseFieldFormat<FieldType.Percentage, number>;
// export type DateField = BaseFieldFormat<FieldType.Date, string>;
// export type IntegerField = BaseFieldFormat<FieldType.Integer, number>;
// export type PostalCodeField = BaseFieldFormat<FieldType.PostalCode, string>;
// export type EINField = BaseFieldFormat<FieldType.EIN, string>;
// export type SSNField = BaseFieldFormat<FieldType.SSN, string>;
// export type CreditScoreField = BaseFieldFormat<FieldType.CreditScore, string>;
// export type YearField = BaseFieldFormat<FieldType.Year, string>;
// export type MonthYearField = BaseFieldFormat<FieldType.MonthYear, string>;
// export type MultiSelectCreatableField<T> = BaseFieldFormat<
//   FieldType.MultiSelectCreatable,
//   { optionsKey: string; value: Array<T> }
// >;
// export type PhoneNumberField = BaseFieldFormat<FieldType.PhoneNumber, string>;
// export type BooleanField = BaseFieldFormat<FieldType.Boolean, boolean>;
