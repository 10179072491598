import { take } from "lodash";
import Quill from "quill";
import { RichTextMentionList } from "./richText.types";

const ALLOWED_TAGS = [
  "a",
  "b",
  "strong",
  "i",
  "em",
  "u",
  "s",
  "strike",
  "p",
  "br",
  "ul",
  "ol",
  "li",
  "h1",
  "h2",
  "h3",
  "h4",
  "blockquote",
  "span", // Important for inline styles (color, background)
  "div", // For alignment
  "img",
  "br"
];

const ALLOWED_ATTRIBUTES = [
  "href",
  "target",
  "style",
  "class",
  "src",
  "alt",
  "width",
  "height",
  "align",
  "data-mention-id",
  "data-denotation-char",
  "data-value"
];
const FORBID_ATTR = ["onerror", "onclick", "onload", "onmouseover"];
export const DOMPURIFY_CONFIG = {
  ALLOWED_TAGS: ALLOWED_TAGS,
  ALLOWED_ATTR: ALLOWED_ATTRIBUTES,
  FORBID_ATTR: FORBID_ATTR,
  USE_PROFILES: { html: true }
};
export const buildViewModeInstance = (r: {
  ref: HTMLDivElement;
  placeholder: string;
}) => {
  return new Quill(r.ref, {
    theme: "snow",
    placeholder: r.placeholder,
    readOnly: true,
    modules: {
      toolbar: null
    }
  });
};

export const buildRichModeInstance = (r: {
  ref: HTMLDivElement;
  placeholder: string;
  mentionList?: RichTextMentionList;
}) => {
  const mentionList = r.mentionList || [];
  const quill = new Quill(r.ref, {
    theme: "snow",
    placeholder: r.placeholder,
    modules: {
      toolbar: toolbarContainer,
      mention: mentionContainer(mentionList)
    },
    formats
  });
  return quill;
};

// Not support upload image for now
// Should be implemented in server side
const toolbarContainer = [
  [{ header: [1, 2, 3, 4, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link"], //image
  ["clean"]
];

const mentionContainer = (mentionList: RichTextMentionList) => {
  return {
    mentionDenotationChars: ["@"],
    source: (
      searchTerm: string,
      renderList: (mentionList: RichTextMentionList, searchTerm: any) => void,
      mentionChar: string
    ) => {
      if (mentionChar !== "@") return;
      if (searchTerm.length === 0) {
        renderList(take(mentionList, 10), searchTerm);
        return;
      }
      const filtered = mentionList.filter((item) => {
        return item.value.some((v) =>
          v.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      renderList(filtered, searchTerm);
    }
  };
};

const formats = [
  "background",
  "bold",
  "color",
  "font",
  //"code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "mention"
  //"code-block",
  //"formula"
  //"image"
  //"video"
];
