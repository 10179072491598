import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProvider,
  StatusCode
} from "@elphi/types";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../constants/common";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../wizard/steps/provider-step-content/providersFormStep.utils";
import { EditModalProps } from "./common/modal.common.types";

export const useServiceProviderCreateForm = <
  T extends RolodexServiceProvider
>() => {
  const [selectedProviderId, setSelectedProviderId] = useState<string>(EMPTY);
  const { onChange, state, setState } = useFormBuilderStateHandler<Partial<T>>({
    initialState: {}
  });

  return {
    onChange,
    state,
    setState,
    selectedProviderId,
    setSelectedProviderId
  };
};

const useServiceProviderEditForm = <T extends RolodexServiceProvider>() => {
  const { serviceProviderState, getByIdHandler } = useServiceProviderHooks();
  const [selectedProviderId, setSelectedProviderId] = useState<string>(EMPTY);
  const { onChange, state, setState } = useFormBuilderStateHandler<Partial<T>>({
    initialState: {}
  });

  useEffect(() => {
    if (selectedProviderId) {
      const provider = serviceProviderState.entities[selectedProviderId] as
        | T
        | undefined;
      if (!provider) {
        getByIdHandler({ id: selectedProviderId });
      }
      if (provider) {
        setState(cloneDeep(provider));
      }
    }
  }, [selectedProviderId, serviceProviderState.entities[selectedProviderId]]);

  return {
    onChange,
    state,
    setState,
    selectedProviderId,
    setSelectedProviderId
  };
};

export const useServiceProviderEditModalHooks = (props: EditModalProps) => {
  const { isShow, onClose, data, dealId } = props;
  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();
  const { updateHandler, upsertResponse, selectedDeal } =
    useServiceProviderHooks();
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [isProviderFormValid, setIsProviderFormValid] =
    useState<boolean>(false);

  const companyEditForm = useServiceProviderEditForm<CompanyServiceProvider>();

  const branchEditForm = useServiceProviderEditForm<BranchServiceProvider>();

  const repEditForm =
    useServiceProviderEditForm<RepresentativeServiceProvider>();

  useEffect(() => {
    if (isShow) {
      setSelectedConfiguration(data.domainConfigurationId);
      setSelectedEntities(data?.dealEntities?.[dealId]?.entityIds || []);
      companyEditForm.setSelectedProviderId(data.companyId);
      branchEditForm.setSelectedProviderId(data.branchId);
      repEditForm.setSelectedProviderId(data.representativeId);
    }
  }, [isShow, data]);

  const handleProviderFormValidation = (isValid: boolean) => {
    setIsProviderFormValid(isValid);
  };

  const handleOnSubmit = () => {
    if (
      !selectedConfiguration ||
      !selectedEntities.length ||
      !selectedDeal ||
      !isProviderFormValid ||
      !companyEditForm.state?.id ||
      !branchEditForm.state?.id ||
      !repEditForm.state.id
    ) {
      return;
    }

    updateHandler({
      dealId: selectedDeal.id,
      entityIds: selectedEntities,
      entityType: selectedConfiguration.entityType,
      company: getCompanyTemplate(companyEditForm.state),
      branch: getBranchTemplate(branchEditForm.state),
      rep: getRepTemplate(repEditForm.state)
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        onClose();
      }
    });
  };

  const isLoading = upsertResponse.isLoading;
  return {
    selectedConfiguration,
    selectedEntities,
    setSelectedEntities,
    selectedDeal,
    isProviderFormValid,
    companyEditForm,
    branchEditForm,
    repEditForm,
    handleProviderFormValidation,
    handleOnSubmit,
    updateHandler,
    isLoading
  };
};
