import { Box, Link } from "@chakra-ui/react";
import {
  AuditEvent,
  AuditEventEntityType,
  AuditEventOperation,
  ElphiEntityType
} from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import {
  entityMap,
  entityRelationMap,
  fieldTypeMap,
  getDiscussionPrettyIdentifier,
  getFieldLabel
} from "./eventRow.utils";
import { buildRelationUrls, buildUrl } from "./eventRowLinkBuilder.utils";

export const EntityPath = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { operation, fieldType } = event;
  const prettyIdentifier = EntityPrettyIdentifier({ event });
  const specs = getSpecs() || {};
  if (
    operation === AuditEventOperation.Created ||
    operation === AuditEventOperation.Deleted
  ) {
    return <Box>{prettyIdentifier}</Box>;
  }
  if (operation === AuditEventOperation.Updated) {
    const fieldLabel = getFieldLabel({ event, specs }) || NOT_AVAILABLE;
    const fieldPrettyType = fieldType ? fieldTypeMap[fieldType] : NOT_AVAILABLE;
    return (
      <Box>
        <EntityPrettyIdentifier event={event} /> | {fieldLabel} |{" "}
        {fieldPrettyType}
      </Box>
    );
  }
  return <>{NOT_AVAILABLE}</>;
};

const EntityPrettyIdentifier = (props: { event: AuditEvent }) => {
  const { event } = props;
  if (entityMap[event.entityType]) {
    return <EntityLinkUrl {...event} />;
  }
  if (entityRelationMap[event.entityType]) {
    return <EntityRelationLinkUrl {...event} />;
  }
  return <>{NOT_AVAILABLE}</>;
};

export const DiscussionPath = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { fieldType } = event;
  const prettyIdentifier = getDiscussionPrettyIdentifier(event);
  const fieldPrettyType = fieldType ? fieldTypeMap[fieldType] : NOT_AVAILABLE;
  return <Box>{`${prettyIdentifier} | Discussion | ${fieldPrettyType}`}</Box>;
};

const EntityLinkUrl = (props: {
  entityId: string;
  entityType: ElphiEntityType | AuditEventEntityType;
  entityPrettyIdentifier?: string[];
}) => {
  const { selectedOrgId } = useOrgHooks();
  const hrefUrl = buildUrl({
    ...props,
    selectedOrgId: selectedOrgId?.toString() || EMPTY
  });
  const displayText = `${entityMap[props.entityType]} ${
    props.entityPrettyIdentifier?.join(" ") || NOT_AVAILABLE
  }`;
  return (
    <>
      {hrefUrl ? (
        <Link href={hrefUrl} isExternal color={"blue.600"}>
          {displayText}
        </Link>
      ) : (
        <>{displayText}</>
      )}
    </>
  );
};

const EntityRelationLinkUrl = (props: {
  entityId: string;
  entityType: ElphiEntityType | AuditEventEntityType;
  entityPrettyIdentifier?: string[];
}) => {
  const { selectedOrgId } = useOrgHooks();
  const [leftLink, rightLink] = buildRelationUrls({
    ...props,
    selectedOrgId: selectedOrgId?.toString() || EMPTY
  });
  const [leftDesc, rightDesc] = props?.entityPrettyIdentifier || [];
  return (
    <>
      {leftLink ? (
        <Link href={leftLink} isExternal color={"blue.600"}>
          {leftDesc || NOT_AVAILABLE}
        </Link>
      ) : (
        <>{leftDesc || NOT_AVAILABLE}</>
      )}
      {" | "}
      {rightLink ? (
        <Link href={rightLink} isExternal color={"blue.600"}>
          {rightDesc || NOT_AVAILABLE}
        </Link>
      ) : (
        <>{rightDesc || NOT_AVAILABLE}</>
      )}
    </>
  );
};
