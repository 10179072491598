import { Box, SystemStyleObject, Text } from "@chakra-ui/react";
import { LabelValue } from "@elphi/types";
import StyledInputBuilder from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";

type ChecklistInputBuilderProps = {
  title: string;
  fieldType: FieldType;
  onSetState: Function;
  options: LabelValue[] & {
    icon?: React.ReactElement;
    isChecked?: boolean;
    isDisabled?: boolean;
    tooltipText?: string;
  };
  selectData?: string | string[];
  marginBottom?: number;
  isDisabled?: boolean;
  headerStyle?: SystemStyleObject;
};
export const ChecklistInputBuilder = ({
  title,
  fieldType,
  onSetState,
  options,
  selectData,
  isDisabled,
  headerStyle,
  marginBottom = 0
}: ChecklistInputBuilderProps) => (
  <Box marginBottom={marginBottom}>
    <Text fontWeight={"bold"} sx={headerStyle}>
      {title}
    </Text>
    <StyledInputBuilder
      size={{ maxW: "fit-content" }}
      isDisabled={isDisabled}
      fieldType={fieldType}
      onChange={(e) => {
        onSetState(e.target.value);
      }}
      customFlexDir={"column"}
      currentValue={selectData}
      options={options}
    />
  </Box>
);
