import { combineReducers } from "redux";
import {
  RESET_USER_DATA,
  UPDATE_PERMISSIONS,
  UPDATE_USER_DATA
} from "./actions";
import { adminApi } from "./v2/admin/admin.service";
import { assetApi, assetSlice } from "./v2/asset";
import { auditEventSlice, baseAuditEventApi } from "./v2/audit-event";
import { auditEventGroupApi } from "./v2/audit-event-group";
import { auditEventGroupSlice } from "./v2/audit-event-group/auditEventGroup.slice";
import { creditReportApi, creditReportSlice } from "./v2/credit-report";
import { dealCalendarSlice } from "./v2/deal-calendar";
import { dealCalendarApi } from "./v2/deal-calendar/dealCalendar.service";
import { dealPartyRelationApi } from "./v2/deal-party-relation/dealPartyRelation.service";
import { dealPartyRelationSlice } from "./v2/deal-party-relation/dealPartyRelation.slice";
import { dealPropertyRelationApi } from "./v2/deal-property-relation/dealPropertyRelation.service";
import { dealPropertyRelationSlice } from "./v2/deal-property-relation/dealPropertyRelation.slice";
import { dealUserRelationApi } from "./v2/deal-user-relation/dealUserRelation.service";
import { dealUserRelationSlice } from "./v2/deal-user-relation/dealUserRelation.slice";
import { dealApi } from "./v2/deal/deal.service";
import { dealSlice } from "./v2/deal/deal.slice";
import {
  discussionEntityApi,
  discussionEntitySlice
} from "./v2/discussion-entity";
import { documentConfigurationApi } from "./v2/document-configuration/documentConfiguration.service";
import { documentConfigurationSlice } from "./v2/document-configuration/documentConfiguration.slice";
import { documentGenerationApi } from "./v2/document-generation";
import { documentPackageOrderApi } from "./v2/document-package-order/documentPackageOrder.service";
import { documentPackageOrderSlice } from "./v2/document-package-order/documentPackageOrder.slice";
import { documentRuleApi } from "./v2/document-rule/documentRule.service";
import { documentRuleSlice } from "./v2/document-rule/documentRule.slice";
import {
  documentTemplateApi,
  documentTemplateSlice
} from "./v2/document-template";
import { fieldGroupApi, fieldGroupSlice } from "./v2/field-group";
import { fieldsTemplateApi, fieldsTemplateSlice } from "./v2/fields-templates";
import {
  insurancePolicyApi,
  insurancePolicySlice,
  insurancePolicyViewApi,
  insurancePolicyViewSlice,
  propertyInsurancePolicyApi
} from "./v2/insurance-policy";
import { fundingShieldApi } from "./v2/integration/funding-shield/funding-shield.service";
import { loanpassApi } from "./v2/integration/loanpass/loanpass.service";
import { loanpassSlice } from "./v2/integration/loanpass/loanpass.slice";
import { rentRangeApi } from "./v2/integration/rent-range/rentRange.service";
import { serviceLinkFloodApi } from "./v2/integration/service-link/service-link-flood.service";
import { siteXApi } from "./v2/integration/site-x/site-x.service";
import { losSettingsApi } from "./v2/los-settings/losSettings.service";
import { losUserApi } from "./v2/los-user/losUser.service";
import { losUserSlice } from "./v2/los-user/losUser.slice";
import { navigationSlice } from "./v2/navigation";
import {
  notificationConfigurationApi,
  notificationConfigurationSlice
} from "./v2/notification-configuration";
import {
  notificationUserApi,
  notificationUserSlice
} from "./v2/notification-user";
import { orgApi, orgSlice } from "./v2/organization";
import { partyAssetApi, partyAssetSlice } from "./v2/party-asset-relation";
import { partyGroupApi, partyGroupSlice } from "./v2/party-group";
import { partyRelationApi } from "./v2/party-relation/partyRelation.service";
import { partyRelationSlice } from "./v2/party-relation/partyRelation.slice";
import {
  partyUserRelationApi,
  partyUserRelationSlice
} from "./v2/party-user-relation";
import {
  partyValidationConfigApi,
  partyValidationConfigSlice
} from "./v2/party-validation-configuration";
import { partyApi } from "./v2/party/party.service";
import { partySlice } from "./v2/party/party.slice";
import {
  posDealUserApi,
  posDealUserPartyViewSlice,
  posUserApi,
  posUserSlice
} from "./v2/pos";
import { progressBarSlice } from "./v2/progress-bar/progressBar.slice";
import { propertyApi } from "./v2/property/property.service";
import { propertySlice } from "./v2/property/property.slice";
import { roleApi, roleSlice } from "./v2/role";
import { roleGroupApi, roleGroupSlice } from "./v2/role-group";
import {
  branchRepRelationApi,
  branchRepRelationSlice,
  serviceDomainConfigurationApi,
  serviceDomainConfigurationSlice,
  serviceProviderApi,
  serviceProviderSlice,
  serviceProviderViewApi,
  serviceProviderViewSlice
} from "./v2/rolodex/";
import { snapshotApi, snapshotSlice } from "./v2/snapshot";
import { statementApi } from "./v2/statement/statement.service";
import { statementSlice } from "./v2/statement/statement.slice";
import { tableManagerSlice } from "./v2/table-manager/tableManager.slice";
import { taskConfigurationApi } from "./v2/task-configuration/taskConfiguration.service";
import { taskConfigurationSlice } from "./v2/task-configuration/taskConfiguration.slice";
import {
  taskInstructionApi,
  taskInstructionSlice
} from "./v2/task-instruction";
import { taskRuleApi } from "./v2/task-rule/taskRule.service";
import { taskRuleSlice } from "./v2/task-rule/taskRule.slice";
import { taskTemplateApi } from "./v2/task-template/taskTemplate.service";
import { taskTemplateSlice } from "./v2/task-template/taskTemplate.slice";
import { taskApi } from "./v2/task/task.service";
import { taskSlice } from "./v2/task/task.slice";

const initialUserData = {
  role: "guest",
  name: "Unauthorized Guest",
  id: ""
};

// todo: refactor to include slices
function userData(
  state = initialUserData,
  action: { type: string; userData: any }
) {
  switch (action.type) {
    case UPDATE_USER_DATA: {
      return {
        ...state,
        ...action.userData
      };
    }
    case RESET_USER_DATA: {
      return initialUserData;
    }
    default: {
      return state;
    }
  }
}

function permissions(state = {}, action: { type: string; data: string[] }) {
  switch (action.type) {
    case UPDATE_PERMISSIONS: {
      return action.data;
    }
    default: {
      return state;
    }
  }
}

export const rtkMiddleware = [
  partyApi.middleware,
  dealApi.middleware,
  partyRelationApi.middleware,
  dealPartyRelationApi.middleware,
  propertyApi.middleware,
  losUserApi.middleware,
  dealPropertyRelationApi.middleware,
  dealUserRelationApi.middleware,
  partyUserRelationApi.middleware,
  taskApi.middleware,
  taskRuleApi.middleware,
  taskTemplateApi.middleware,
  taskConfigurationApi.middleware,
  documentConfigurationApi.middleware,
  documentRuleApi.middleware,
  documentPackageOrderApi.middleware,
  partyAssetApi.middleware,
  assetApi.middleware,
  statementApi.middleware,
  snapshotApi.middleware,
  creditReportApi.middleware,
  adminApi.middleware,
  roleApi.middleware,
  orgApi.middleware,
  serviceLinkFloodApi.middleware,
  documentGenerationApi.middleware,
  fundingShieldApi.middleware,
  siteXApi.middleware,
  rentRangeApi.middleware,
  loanpassApi.middleware,
  baseAuditEventApi.middleware,
  dealCalendarApi.middleware,
  serviceDomainConfigurationApi.middleware,
  partyGroupApi.middleware,
  serviceProviderApi.middleware,
  serviceProviderViewApi.middleware,
  branchRepRelationApi.middleware,
  partyValidationConfigApi.middleware,
  insurancePolicyApi.middleware,
  propertyInsurancePolicyApi.middleware,
  insurancePolicyViewApi.middleware,
  fieldGroupApi.middleware,
  fieldsTemplateApi.middleware,
  documentTemplateApi.middleware,
  roleGroupApi.middleware,
  auditEventGroupApi.middleware,
  notificationConfigurationApi.middleware,
  notificationUserApi.middleware,
  discussionEntityApi.middleware,
  taskInstructionApi.middleware,
  losSettingsApi.middleware,
  posUserApi.middleware,
  posDealUserApi.middleware
];
const rootReducer = combineReducers({
  [orgApi.reducerPath]: orgApi.reducer,
  [orgSlice.name]: orgSlice.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [partyApi.reducerPath]: partyApi.reducer,
  [partySlice.name]: partySlice.reducer,
  [dealApi.reducerPath]: dealApi.reducer,
  [dealSlice.name]: dealSlice.reducer,
  [partyRelationApi.reducerPath]: partyRelationApi.reducer,
  [partyRelationSlice.name]: partyRelationSlice.reducer,
  [dealPartyRelationApi.reducerPath]: dealPartyRelationApi.reducer,
  [dealPartyRelationSlice.name]: dealPartyRelationSlice.reducer,
  [propertyApi.reducerPath]: propertyApi.reducer,
  [propertySlice.name]: propertySlice.reducer,
  [losUserApi.reducerPath]: losUserApi.reducer,
  [losUserSlice.name]: losUserSlice.reducer,
  [dealPropertyRelationApi.reducerPath]: dealPropertyRelationApi.reducer,
  [dealPropertyRelationSlice.name]: dealPropertyRelationSlice.reducer,
  [dealUserRelationApi.reducerPath]: dealUserRelationApi.reducer,
  [dealUserRelationSlice.name]: dealUserRelationSlice.reducer,
  [partyUserRelationApi.reducerPath]: partyUserRelationApi.reducer,
  [partyUserRelationSlice.name]: partyUserRelationSlice.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [taskSlice.name]: taskSlice.reducer,
  [taskRuleApi.reducerPath]: taskRuleApi.reducer,
  [taskRuleSlice.name]: taskRuleSlice.reducer,
  [taskTemplateApi.reducerPath]: taskTemplateApi.reducer,
  [taskTemplateSlice.name]: taskTemplateSlice.reducer,
  [taskConfigurationApi.reducerPath]: taskConfigurationApi.reducer,
  [taskConfigurationSlice.name]: taskConfigurationSlice.reducer,
  [documentGenerationApi.reducerPath]: documentGenerationApi.reducer,
  [documentConfigurationApi.reducerPath]: documentConfigurationApi.reducer,
  [documentConfigurationSlice.name]: documentConfigurationSlice.reducer,
  [documentRuleApi.reducerPath]: documentRuleApi.reducer,
  [documentRuleSlice.name]: documentRuleSlice.reducer,
  [documentPackageOrderApi.reducerPath]: documentPackageOrderApi.reducer,
  [documentPackageOrderSlice.name]: documentPackageOrderSlice.reducer,
  [assetApi.reducerPath]: assetApi.reducer,
  [assetSlice.name]: assetSlice.reducer,
  [partyAssetApi.reducerPath]: partyAssetApi.reducer,
  [partyAssetSlice.name]: partyAssetSlice.reducer,
  [statementApi.reducerPath]: statementApi.reducer,
  [statementSlice.name]: statementSlice.reducer,
  [snapshotApi.reducerPath]: snapshotApi.reducer,
  [snapshotSlice.name]: snapshotSlice.reducer,
  [creditReportSlice.name]: creditReportSlice.reducer,
  [creditReportApi.reducerPath]: creditReportApi.reducer,
  [navigationSlice.name]: navigationSlice.reducer,
  [serviceLinkFloodApi.reducerPath]: serviceLinkFloodApi.reducer,
  [fundingShieldApi.reducerPath]: fundingShieldApi.reducer,
  [siteXApi.reducerPath]: siteXApi.reducer,
  [tableManagerSlice.name]: tableManagerSlice.reducer,
  [rentRangeApi.reducerPath]: rentRangeApi.reducer,
  [loanpassApi.reducerPath]: loanpassApi.reducer,
  [loanpassSlice.name]: loanpassSlice.reducer,
  [baseAuditEventApi.reducerPath]: baseAuditEventApi.reducer,
  [auditEventSlice.name]: auditEventSlice.reducer,
  [dealCalendarApi.reducerPath]: dealCalendarApi.reducer,
  [dealCalendarSlice.name]: dealCalendarSlice.reducer,
  [serviceDomainConfigurationApi.reducerPath]:
    serviceDomainConfigurationApi.reducer,
  [serviceDomainConfigurationSlice.name]:
    serviceDomainConfigurationSlice.reducer,
  [partyGroupApi.reducerPath]: partyGroupApi.reducer,
  [partyGroupSlice.name]: partyGroupSlice.reducer,
  [serviceProviderApi.reducerPath]: serviceProviderApi.reducer,
  [serviceProviderSlice.name]: serviceProviderSlice.reducer,
  [serviceProviderViewApi.reducerPath]: serviceProviderViewApi.reducer,
  [serviceProviderViewSlice.name]: serviceProviderViewSlice.reducer,
  [branchRepRelationApi.reducerPath]: branchRepRelationApi.reducer,
  [branchRepRelationSlice.name]: branchRepRelationSlice.reducer,
  [partyValidationConfigApi.reducerPath]: partyValidationConfigApi.reducer,
  [partyValidationConfigSlice.name]: partyValidationConfigSlice.reducer,
  [insurancePolicyApi.reducerPath]: insurancePolicyApi.reducer,
  [propertyInsurancePolicyApi.reducerPath]: propertyInsurancePolicyApi.reducer,
  [insurancePolicySlice.name]: insurancePolicySlice.reducer,
  [insurancePolicyViewApi.reducerPath]: insurancePolicyViewApi.reducer,
  [insurancePolicyViewSlice.name]: insurancePolicyViewSlice.reducer,
  [fieldGroupApi.reducerPath]: fieldGroupApi.reducer,
  [fieldGroupSlice.name]: fieldGroupSlice.reducer,
  [fieldsTemplateApi.reducerPath]: fieldsTemplateApi.reducer,
  [documentTemplateApi.reducerPath]: documentTemplateApi.reducer,
  [fieldsTemplateSlice.name]: fieldsTemplateSlice.reducer,
  [documentTemplateSlice.name]: documentTemplateSlice.reducer,
  [roleGroupApi.reducerPath]: roleGroupApi.reducer,
  [roleGroupSlice.name]: roleGroupSlice.reducer,
  [auditEventGroupApi.reducerPath]: auditEventGroupApi.reducer,
  [auditEventGroupSlice.name]: auditEventGroupSlice.reducer,
  [notificationConfigurationApi.reducerPath]:
    notificationConfigurationApi.reducer,
  [notificationConfigurationSlice.name]: notificationConfigurationSlice.reducer,
  [notificationUserApi.reducerPath]: notificationUserApi.reducer,
  [notificationUserSlice.name]: notificationUserSlice.reducer,
  [discussionEntityApi.reducerPath]: discussionEntityApi.reducer,
  [discussionEntitySlice.name]: discussionEntitySlice.reducer,
  [taskInstructionApi.reducerPath]: taskInstructionApi.reducer,
  [taskInstructionSlice.name]: taskInstructionSlice.reducer,
  [losSettingsApi.reducerPath]: losSettingsApi.reducer,
  [progressBarSlice.name]: progressBarSlice.reducer,
  [posDealUserApi.reducerPath]: posDealUserApi.reducer,
  [posDealUserPartyViewSlice.name]: posDealUserPartyViewSlice.reducer,
  [posUserApi.reducerPath]: posUserApi.reducer,
  [posUserSlice.name]: posUserSlice.reducer,
  userData,
  permissions
});

export default rootReducer;
