import { Status } from "../common";
import { BaseEntity } from "../db";
import { ElphiEntityType } from "../entities/elphiEntities.types";
import { DocumentPackageType } from "./documentPackageOrder.types";
import { DocumentTemplate } from "./documentTemplate";

export enum AutoGenerateType {
  Always = "always",
  Conditional = "conditional",
  Never = "never"
}

export enum DocumentEntityType {
  Deal = "deal",
  Party = "party",
  Property = "property",
  PropertyStateCounty = "property-state-county"
}

export type DocumentEntityTypeToEntityKey = {
  [DocumentEntityType.Deal]: ElphiEntityType.deal;
  [DocumentEntityType.Party]: ElphiEntityType.party;
  [DocumentEntityType.Property]: ElphiEntityType.property;
  [DocumentEntityType.PropertyStateCounty]: ElphiEntityType.property;
};

export type DocumentConfiguration = BaseEntity<{
  name: string;
  templateId: string;
  autoGenerate: AutoGenerateType;
  documentEntity: DocumentEntityType;
  documentRuleTemplateId: string;
  status: Status;
  documentPackageType: DocumentPackageType[];
}>;

export type DocConfigurationWithTemplate = DocumentConfiguration & {
  template: DocumentTemplate;
};
