export enum StorageProvider {
  GCP = "gcp",
  Box = "box"
}
type BaseFileData = {
  fileId: string;
};

export type FileInfo<
  TProvider extends StorageProvider,
  TData extends BaseFileData
> = {
  provider: TProvider;
  data: TData;
};

export type UploadRequest<TProvider extends StorageProvider, T> = {
  provider: TProvider;
  payload: T;
};
export type DeleteRequest<TProvider extends StorageProvider, T> = {
  provider: TProvider;
  payload: T;
};
export type InfoRequest<TProvider extends StorageProvider, T> = {
  provider: TProvider;
  payload: T;
};
export type DownloadRequest<TProvider extends StorageProvider, T> = {
  provider: TProvider;
  payload: T;
};
