import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { useSnapshotHooks } from "../../../../hooks/snapshot.hooks";
import useTaskHooks from "../../../../hooks/task.hooks";
import { ServiceLinkFloodTaskForm } from "./ServiceLinkFloodTaskForm";
import { ServiceLinkFloodTaskProps } from "./serviceLinkFloodTask.type";

export const ServiceLinkFloodTaskFormContainer = (
  props: ServiceLinkFloodTaskProps
) => {
  return props.snapshotId ? (
    <ServiceLinkFloodTaskFormSnapshotContainer {...props} />
  ) : (
    <ServiceLinkFloodTaskFormLiveStateContainer {...props} />
  );
};

const ServiceLinkFloodTaskFormSnapshotContainer = (
  props: ServiceLinkFloodTaskProps
) => {
  const { snapshotId } = props;
  const { snapshotDataState } = useSnapshotHooks();
  const { taskState } = useTaskHooks();
  const snapshot = snapshotDataState({ snapshotId: String(snapshotId) });
  return (
    <ServiceLinkFloodTaskForm
      snapshotId={snapshotId}
      selectedTask={
        (taskState?.selectedId &&
          snapshot.taskState &&
          snapshot.taskState.entities[taskState.selectedId]) ||
        undefined
      }
      propertyState={snapshot.propertyState}
    />
  );
};

const ServiceLinkFloodTaskFormLiveStateContainer = (
  props: ServiceLinkFloodTaskProps
) => {
  const { snapshotId, selectedTask } = props;
  const { propertyState } = usePropertyHooks();
  return (
    <ServiceLinkFloodTaskForm
      snapshotId={snapshotId}
      selectedTask={selectedTask}
      propertyState={propertyState}
    />
  );
};
