import { Type } from "@sinclair/typebox";
import { Status } from "../common";
import { StateCode, TemplateCategory } from "../entities";

const DocumentTemplateStateCodeSchema = Type.Union([
  Type.Enum(StateCode),
  Type.Literal("default")
]);

export const DocumentTemplateCreateRequestSchema = Type.Object({
  name: Type.String(),
  stateCode: DocumentTemplateStateCodeSchema,
  templateCategory: Type.Enum(TemplateCategory),
  file: Type.Any()
});

export const DocumentTemplateUpdateRequestSchema = Type.Intersect([
  Type.Object({
    id: Type.String()
  }),
  Type.Partial(
    Type.Object({
      name: Type.String(),
      stateCode: DocumentTemplateStateCodeSchema,
      templateCategory: Type.Enum(TemplateCategory),
      file: Type.Any(),
      status: Type.Enum(Status)
    })
  )
]);

export const DocumentTemplateUpdateBatchRequestSchema = Type.Array(
  Type.Object(
    {
      id: Type.String(),
      name: Type.Optional(Type.String()),
      stateCode: Type.Optional(DocumentTemplateStateCodeSchema),
      status: Type.Optional(Type.Enum(Status))
    },
    { additionalProperties: false }
  )
);

export const DocumentTemplateDownloadUrlGetRequestSchema = Type.Object({
  id: Type.String()
});
