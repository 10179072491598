import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 18 18",
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="18" rx="9" fill={fill} />
        <path
          d="M13.2188 6.42188V9.9375H12.2812V6.89062L9.9375 5.25L7.59375 6.89062V7.59375H6.65625V6.42188L9.9375 4.07812L13.2188 6.42188ZM10.6406 6.65625H10.1719V7.125H10.6406V6.65625ZM9.70312 6.65625H9.23438V7.125H9.70312V6.65625ZM10.6406 7.59375H10.1719V8.0625H10.6406V7.59375ZM9.70312 7.59375H9.23438V8.0625H9.70312V7.59375ZM12.2812 10.875H11.3438C11.3438 10.3125 10.9922 9.80625 10.4672 9.60938L7.57969 8.53125H3.84375V13.6875H6.65625V13.0125L9.9375 13.9219L13.6875 12.75V12.2812C13.6875 11.5031 13.0594 10.875 12.2812 10.875ZM4.78125 12.75V9.46875H5.71875V12.75H4.78125ZM9.92344 12.9422L6.65625 12.0375V9.46875H7.41094L10.1391 10.4859C10.2984 10.5469 10.4062 10.7016 10.4062 10.875C10.4062 10.875 9.47344 10.8516 9.32812 10.8047L8.2125 10.4344L7.91719 11.325L9.03281 11.6953C9.27187 11.775 9.52031 11.8172 9.77344 11.8172H12.2812C12.4641 11.8172 12.6281 11.925 12.7031 12.0797L9.92344 12.9422Z"
          fill={"#2A4365"}
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const HandHouseBlue100Icon = customIcon("#BEE3F8");
