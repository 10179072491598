import { Task } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { taskEntityAdapter as entityAdapter, taskApi } from "./task.service";

export type TaskSliceState = EntityState<Task> & {
  selectedId?: EntityId;
  filteredIds?: EntityId[];
};
export const taskSlice = createSlice({
  name: "task",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined,
    filteredIds: undefined
  }) as TaskSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    filteredIds: (state, action: PayloadAction<{ ids: EntityId[] }>) => {
      state.filteredIds = action.payload.ids;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(taskApi)(builder);
    builder.addMatcher(
      taskApi.endpoints.entityTasks.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.tasks.ids);
        state.entities = { ...state.entities, ...payload.tasks.entities };
      }
    );
  }
});
