import { Asset } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { partyApi } from "../party/party.service";
import { assetEntityAdapter as entityAdapter } from "./asset.adapter";
import { assetApi } from "./asset.service";

export type AssetSliceState = EntityState<Asset> & { selectedId?: EntityId };

export const assetSlice = createSlice({
  name: "asset",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as AssetSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(assetApi)(builder);
    builder.addMatcher(
      assetApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.getBatchPartyAsset.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.assets.ids);
        state.entities = { ...state.entities, ...payload.assets.entities };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.addAssetToParty.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, [payload.asset.id]);
        state.entities = {
          ...state.entities,
          [payload.asset.id]: payload.asset
        };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
