import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip
} from "@chakra-ui/react";
import React from "react";

type Item = {
  icon?: JSX.Element;
  inputBuilder?: React.ReactNode;
  label: string;
  tooltipText?: string;
  stayOpenAfterSelect?: boolean;
  isDisabled?: boolean;
  onSelect?: React.ReactEventHandler<HTMLButtonElement>;
};

type MenuComponentProps = {
  items: Item[];
  icon?: React.ReactElement;
};

export const MenuComponent = ({
  items,
  icon,
  ...props
}: MenuComponentProps) => {
  return (
    <Menu size={"500px"}>
      <MenuButton
        size="xs"
        cursor={"pointer"}
        as={IconButton}
        aria-label="Options"
        icon={icon || <HamburgerIcon />}
        bgColor="white"
        onClick={(e) => e.stopPropagation()}
        {...props}
      />
      <MenuList zIndex={5} minW="250px">
        {items.map((item, index) => (
          <Tooltip
            key={`tooltip_${index}`}
            aria-label={item.label}
            label={item.tooltipText}
            placement={"right-end"}
            hasArrow
          >
            <MenuItem
              key={index}
              closeOnSelect={!item.stayOpenAfterSelect}
              cursor={"pointer"}
              _hover={{
                bgColor: "blue.50",
                opacity: 0.7
              }}
              isDisabled={item.isDisabled}
              disabled={item.isDisabled}
              onClick={item.onSelect}
            >
              <Flex p="5px 10px" alignItems="center" gap="8px">
                {item.icon}
                {item.inputBuilder}
                <Box as="label" fontWeight="400">
                  {item.label}
                </Box>
              </Flex>
            </MenuItem>
          </Tooltip>
        ))}
      </MenuList>
    </Menu>
  );
};
