import { FieldStatus } from "@elphi/types";
import { get } from "lodash";

export type ObjectKeyValidation<
  TObject extends object,
  KPath extends string[]
> = ObjectKey<TObject, KPath> extends never ? never : KPath;
export type ObjectKey<
  TObject extends object,
  KPath extends string[]
> = KPath extends [infer K, ...infer REST]
  ? K extends keyof TObject
    ? TObject[K] extends string | number | string[]
      ? KPath
      : REST extends string[]
      ? REST extends []
        ? KPath
        : TObject[K] extends object
        ? ObjectKey<TObject[K], REST>
        : KPath
      : never
    : never
  : never;

export const getValueFromState = <T extends object, P extends string[]>(r: {
  state: T;
  fieldPath: ObjectKeyValidation<T, P>;
}) => {
  const { state, fieldPath } = r;

  return get(state, fieldPath);
};
export const getFieldStatusFromState = <
  T extends object,
  P extends string[]
>(r: {
  state: T;
  fieldPath: ObjectKeyValidation<T, P>;
}): FieldStatus | undefined => {
  const { state, fieldPath } = r;

  return get(state, ["fieldMeta", ...fieldPath, "status"]);
};
