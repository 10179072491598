import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Progress,
  Text,
  Tooltip,
  useCallbackRef
} from "@chakra-ui/react";
import {
  BaseInsurancePolicy,
  Deal,
  DealPartyRelation,
  DealProperty,
  ElphiEntityType,
  FieldType,
  InsurancePolicyEntityType,
  KeyClosingTaskType,
  PartyRelation,
  RolodexServiceProvider,
  Task,
  TaskPendingReviewType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import {
  concat,
  constant,
  intersection,
  map,
  orderBy,
  times,
  uniq
} from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import { printDateTime } from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import { useAssetHooks } from "../../hooks/asset.hooks";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import useDealHooks from "../../hooks/deal.hooks";
import { useInsurancePolicyHooks } from "../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { useRolodexBranchRepRelation } from "../../hooks/rolodexBranchRepRelation.hooks";
import { useServiceProviderHooks } from "../../hooks/rolodexServiceProvider.hooks";
import {
  SnapshotDataStateFormat,
  useSnapshotHooks
} from "../../hooks/snapshot.hooks";
import useTaskHooks from "../../hooks/task.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { RootState } from "../../redux/store";
import { AssetSliceState } from "../../redux/v2/asset";
import { CreditReportSliceState } from "../../redux/v2/credit-report";
import { DealSliceState } from "../../redux/v2/deal";
import { InsurancePolicySliceState } from "../../redux/v2/insurance-policy";
import { PartySliceState } from "../../redux/v2/party";
import { PartyAssetSliceState } from "../../redux/v2/party-asset-relation";
import { PropertySliceState } from "../../redux/v2/property";
import { ServiceProviderSliceState } from "../../redux/v2/rolodex";
import { StatementSliceState } from "../../redux/v2/statement";
import { TaskSliceState } from "../../redux/v2/task";
import { taskApi } from "../../redux/v2/task/task.service";
import { fromFirebaseIdToLabel } from "../../utils/common";
import {
  AssetName,
  DealPartyName,
  DealPropertyName,
  PartyNames,
  StatementName
} from "../deal/utils/table.utils";
import { EmptyFileIcon, FileIcon } from "../file-uploader/Icons";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { InboxOrange100Icon } from "../icons";
import { ElphiTable } from "../table/ElphiTableComponent";
import {
  FilterCellComponent,
  basicFilterOptionsFormatter
} from "../table/FilterCellComponent";
import {
  CellFilterField,
  CellsFilterStateFlat,
  useFilterHook
} from "../table/hooks/filter.hook";
import {
  ElphiCell,
  ElphiCellType,
  ElphiTableProps
} from "../table/table.types";
import { useElphiToast } from "../toast/toast.hook";
import { filterState } from "../utils/task.utils";
import { OrderStatusTaskIcon } from "./OrderStatusTaskIcon";
import { MultiFileWidgetActionsMenu } from "./TaskFileManager";
import { TaskStatusDropDown } from "./TaskStatus";
import { hasIntegrationType } from "./orderStatus/typeGuards";
import {
  orderStatusStyles,
  rowIndexStyles,
  taskNameStyles,
  taskStatusStyles,
  timestampStyles
} from "./styles/table";
import { posUserTypeOptionMap } from "./task-template/v2/taskTemplate.util";
import {
  TaskFileStatus,
  createTooltipForUserRoles,
  getDocumentStatusFromTask,
  getGenerationTextFromTask,
  getKeyClosingTaskType,
  getKeyClosingTaskTypeFromTask,
  getModifiedAt,
  getTaskStatus,
  getTaskStatusFromTask,
  getTaskTypeText,
  truncatePosUserTypes,
  truncateUserRoles
} from "./taskPrint.utils";
import {
  TaskRowData,
  getFollowupTaskNames,
  getOrderStatusData,
  getOrderStatusOptions,
  getTaskEntityText,
  getTaskRowData,
  taskRowDataToText
} from "./taskRowData.utils";

export const TruncatableList = (props: {
  maxLength?: number;
  list: string[];
  shouldShowTruncate?: boolean;
  truncateSign?: string;
}) => {
  const { list, shouldShowTruncate } = props;
  const maxLength = props.maxLength || 3;

  const truncateSign = props.truncateSign || "...";
  const namesToShow =
    list.length > maxLength && shouldShowTruncate
      ? [list[0], truncateSign, list[list.length - 1]]
      : list;

  return namesToShow.map((taskName, index) => (
    <Flex align="center" key={index}>
      <Text>{taskName}</Text>
      {index !== namesToShow.length - 1 && (
        <ChevronRightIcon mx={3} margin={"5px"} />
      )}
    </Flex>
  ));
};

const TaskEntityColumn = (props: { rowData: TaskRowData }) => {
  const { rowData } = props;

  return (
    <Box>
      {rowData?.task?.entityType === ElphiEntityType.task && (
        <Flex flexWrap={"wrap"} justifyContent="center">
          <TruncatableList
            shouldShowTruncate={true}
            list={getFollowupTaskNames(rowData)}
          />
        </Flex>
      )}
      {rowData?.task?.entityType === ElphiEntityType.property &&
        rowData?.property && (
          <Text align="center" whiteSpace={"normal"}>
            {getTaskEntityText(rowData)}
          </Text>
        )}
      {rowData?.task?.entityType === ElphiEntityType.party &&
        rowData?.party && (
          <Text align="center" whiteSpace={"normal"}>
            {getTaskEntityText(rowData)}
          </Text>
        )}
      {rowData?.task?.entityType === ElphiEntityType.deal && rowData?.deal && (
        <Text align="center" whiteSpace={"normal"}>
          {getTaskEntityText(rowData)}
        </Text>
      )}
      {rowData?.task?.entityType === ElphiEntityType.dealParty &&
        rowData?.dealParty && (
          <DealPartyName deal={rowData?.deal} party={rowData?.party} />
        )}
      {rowData?.task?.entityType === ElphiEntityType.partyRelation &&
        rowData?.partyRelation && (
          <PartyNames parties={[rowData?.parentParty, rowData?.childParty]} />
        )}
      {rowData?.task?.entityType === ElphiEntityType.dealProperty &&
        rowData?.dealProperty && (
          <DealPropertyName deal={rowData?.deal} property={rowData?.property} />
        )}
      {rowData?.task?.entityType === ElphiEntityType.asset &&
        rowData?.asset && (
          <AssetName asset={rowData?.asset} parties={rowData?.assetParties} />
        )}
      {rowData?.task?.entityType === ElphiEntityType.statement &&
        rowData?.statement && (
          <StatementName
            statement={rowData?.statement}
            asset={rowData?.asset}
            parties={rowData?.assetParties}
          />
        )}
      {rowData?.task?.entityType === ElphiEntityType.creditScore &&
        rowData?.creditScore && (
          <Text align="center" whiteSpace={"normal"}>
            {getTaskEntityText(rowData)}
          </Text>
        )}
      {rowData?.task?.entityType === ElphiEntityType.serviceProvider &&
        rowData?.serviceProvider && (
          <Text align="center" whiteSpace={"normal"}>
            {getTaskEntityText(rowData)}
          </Text>
        )}
      {rowData?.task?.entityType === ElphiEntityType.insurancePolicy &&
        rowData?.insurancePolicy && (
          <Text align="center" whiteSpace={"normal"}>
            {getTaskEntityText(rowData)}
          </Text>
        )}
    </Box>
  );
};

const OrderStatusColumn = (props: { rowData: TaskRowData }) => {
  const { rowData } = props;
  const { orderStatus } = getOrderStatusData(rowData) || {};
  return (
    <Box>
      {orderStatus ? (
        <Box>
          <Text textAlign={"center"} whiteSpace={"normal"}>
            {orderStatus}
          </Text>
        </Box>
      ) : (
        <Box>
          <Text textAlign={"center"} whiteSpace={"normal"}>
            {NOT_AVAILABLE}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const PAGE_SIZE = 30;

export const TaskTableContainer = (props: {
  dealId?: string;
  partyId?: string;
  serviceProviderId?: string;
  snapshotId?: string;
}) => {
  return props.snapshotId ? (
    <TaskTableSnapshotContainer {...props} snapshotId={props.snapshotId} />
  ) : (
    <TaskTableLiveStateContainer {...props} />
  );
};
const TaskTableSnapshotContainer = (props: {
  dealId?: string;
  partyId?: string;
  snapshotId: string;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const { setSelectedTask, taskState } = useTaskHooks();
  if (
    !snapshot?.taskState ||
    !snapshot?.dealPropertyState ||
    !snapshot?.dealPartyRelationState ||
    !snapshot?.propertyState ||
    !snapshot?.partyRelationState ||
    !snapshot?.partyState ||
    !snapshot?.assetState ||
    !snapshot?.partyAssetState ||
    !snapshot?.statementState ||
    !snapshot?.creditReportState ||
    !snapshot?.serviceProviderState ||
    !snapshot?.insurancePolicyState
  ) {
    return <Progress size="sm" isIndeterminate />;
  }

  return (
    <TaskTable
      isTaskStatusDisabled
      {...props}
      taskState={snapshot.taskState}
      dealPropertyState={snapshot.dealPropertyState}
      dealPartyState={snapshot.dealPartyRelationState}
      propertyState={snapshot.propertyState}
      partyRelationState={snapshot.partyRelationState}
      partyState={snapshot.partyState}
      assetState={snapshot.assetState}
      partyAssetState={snapshot.partyAssetState}
      statementState={snapshot.statementState}
      creditScoreState={snapshot.creditReportState}
      serviceProviderState={snapshot.serviceProviderState}
      insurancePolicyState={snapshot.insurancePolicyState}
      selectedTask={
        (taskState.selectedId &&
          snapshot.taskState.entities[taskState.selectedId]) ||
        undefined
      }
      selectedDeal={
        (props.dealId && snapshot.dealState?.entities[props.dealId]) ||
        undefined
      }
      setSelectedTask={setSelectedTask}
      isLoading={false}
      idsMap={{
        partyIds: snapshot.partyState.ids,
        dealPropertyIds: snapshot.dealPropertyState.ids,
        propertyIds: snapshot.propertyState.ids,
        partyRelationIds: snapshot.partyRelationState.ids,
        dealPartyIds: snapshot.dealPartyRelationState.ids,
        assetIds: snapshot.assetState.ids,
        partyAssetIds: snapshot.partyAssetState.ids,
        statementIds: snapshot.statementState.ids,
        creditScoreIds: snapshot.creditReportState.ids,
        serviceProviderIds: snapshot.serviceProviderState.ids,
        insurancePolicyIds: snapshot.insurancePolicyState.ids,
        relationshipIds: concat(
          props.dealId || [],
          snapshot.partyState.ids,
          snapshot.dealPropertyState.ids,
          snapshot.propertyState.ids,
          snapshot.partyRelationState.ids,
          snapshot.dealPartyRelationState.ids,
          snapshot.assetState.ids,
          snapshot.partyAssetState.ids,
          snapshot.statementState.ids,
          snapshot.creditReportState.ids,
          snapshot.serviceProviderState.ids,
          snapshot.insurancePolicyState.ids
        )
      }}
    />
  );
};
const TaskTableLiveStateContainer = (props: {
  dealId?: string;
  partyId?: string;
  serviceProviderId?: string;
}) => {
  const [serviceProviderTree, setServiceProviderTree] = useState<
    RolodexServiceProvider[]
  >([]);
  const taskState = useSelector((state: RootState) => state.task);
  const dealPropertyState = useSelector(
    (state: RootState) => state.dealPropertyRelation
  );
  const dealPartyState = useSelector(
    (state: RootState) => state.dealPartyRelation
  );
  const dealState = useSelector((state: RootState) => state.deal);
  const propertyState = useSelector((state: RootState) => state.property);
  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );
  const assetState = useSelector((state: RootState) => state.asset);
  const partyAssetState = useSelector((state: RootState) => state.partyAsset);
  const statementState = useSelector((state: RootState) => state.statement);
  const creditScoreState = useSelector(
    (state: RootState) => state.creditReport
  );

  const { getDealRelations, selectedDeal, getDealParties } = useDealHooks();
  const { partyTreeIds, partyState, getTreeApi, getPartyAssetBatch } =
    usePartyHooks();
  const { getAssetStatements } = useAssetHooks();
  const { getBatchPartyCreditScores } = useCreditReportHooks();
  const { selectedTask, setSelectedTask, setFilteredTask, updateTasksHandler } =
    useTaskHooks();
  const { branchRepState } = useRolodexBranchRepRelation();
  const { insurancePolicyState } = useInsurancePolicyHooks();
  const {
    getBatchHandler,
    serviceProviderState,
    getServiceProviderTreeHandler
  } = useServiceProviderHooks();
  useEffect(() => {
    if (props.serviceProviderId) {
      getServiceProviderTreeHandler({
        serviceProviderId: props.serviceProviderId
      }).then((serviceProviders) => {
        serviceProviders && setServiceProviderTree(serviceProviders);
      });
    }
  }, [props.serviceProviderId]);

  const dealPartyIds = useMemo(() => {
    return dealPartyState.ids.filter(
      (id) => dealPartyState?.entities?.[id]?.dealId === props.dealId
    );
  }, [props.dealId, dealPartyState]);

  const partyIds = useMemo(() => {
    const partyIdsToShow = dealPartyIds.map((id) => {
      const partyId = dealPartyState?.entities?.[id]?.partyId;
      return partyId && partyState.entities?.[partyId]?.id;
    }) as EntityId[];
    if (props.partyId) {
      partyIdsToShow.push(props.partyId);
    }
    return partyTreeIds(partyRelationState, partyIdsToShow as string[]);
  }, [partyRelationState, partyState, dealPartyState, dealPartyIds]);

  const dealPropertyIds = useMemo(() => {
    return dealPropertyState.ids.filter(
      (id) => dealPropertyState?.entities?.[id]?.dealId === props.dealId
    );
  }, [props.dealId, dealPropertyState]);

  const propertyIds = useMemo(() => {
    return dealPropertyIds.map((id) => {
      const propertyId = dealPropertyState?.entities?.[id]?.propertyId;
      return propertyId && propertyState?.entities?.[propertyId]?.id;
    }) as EntityId[];
  }, [propertyState, dealPropertyState, dealPropertyIds]);

  const partyRelationIds = useMemo(() => {
    return partyRelationState.ids.filter((id) => {
      return partyIds?.includes(
        partyRelationState?.entities?.[id]?.parentId || ""
      );
    });
  }, [partyRelationState, partyIds]);

  const partyAssetIds = useMemo(() => {
    if (partyIds.length) {
      return partyAssetState.ids.filter((id) => {
        return partyIds?.includes(
          partyAssetState?.entities?.[id]?.partyId || ""
        );
      });
    }
    return [];
  }, [props.partyId, partyAssetState]);

  const assetIds = useMemo(() => {
    return partyAssetIds.map((id) => {
      const assetId = partyAssetState?.entities?.[id]?.assetId;
      return assetId && assetState?.entities?.[assetId]?.id;
    }) as EntityId[];
  }, [partyAssetIds, partyAssetState, assetState]);

  const statementIds = useMemo(() => {
    return statementState.ids.filter((id) => {
      return assetIds?.includes(statementState?.entities?.[id]?.assetId || "");
    });
  }, [statementState, assetIds]);

  const creditScoreIds: EntityId[] = useMemo(() => {
    return partyIds.length && !!creditScoreState?.entities
      ? (Object.values(creditScoreState?.entities)
          .filter(removeEmpty)
          .filter((cs) => cs.partyId && partyIds.includes(cs.partyId))
          .map((cs) => cs.id) as EntityId[])
      : [];
  }, [props.partyId, partyIds, creditScoreState]);

  const serviceProviderIds = useMemo(() => {
    if (props.dealId) {
      return uniq(
        Object.values(branchRepState.entities)
          .filter((cs) => cs?.dealIds?.includes(props?.dealId || EMPTY))
          .map((cs) => [cs?.companyId, cs?.branchId, cs?.representativeId])
          .flat()
          .filter(removeEmpty)
      );
    }

    return props.serviceProviderId
      ? serviceProviderTree.map((sp) => sp.id)
      : [];
  }, [props.dealId, serviceProviderTree, branchRepState]);

  const insurancePolicyIds = useMemo(() => {
    const dealId = props?.dealId;
    if (!dealId) {
      return [];
    }

    return uniq(
      Object.values(insurancePolicyState.entities)
        .filter((x) => x?.dealIds?.includes(dealId))
        .map((x) => x?.id)
        .flat()
        .filter(removeEmpty)
    );
  }, [props.dealId, insurancePolicyState.entities]);

  const relationshipIds = useMemo(() => {
    return concat(
      props.dealId || [],
      partyIds,
      propertyIds,
      dealPartyIds,
      partyRelationIds,
      dealPropertyIds,
      assetIds,
      statementIds,
      creditScoreIds,
      serviceProviderIds,
      insurancePolicyIds
    );
  }, [
    props.dealId,
    partyIds,
    propertyIds,
    dealPartyIds,
    partyRelationIds,
    dealPropertyIds,
    assetIds,
    statementIds,
    partyAssetIds,
    creditScoreIds,
    serviceProviderIds,
    insurancePolicyIds
  ]);

  useEffect(() => {
    if (auth.currentUser && partyIds.length) {
      getPartyAssetBatch(partyIds).then((response) => {
        if (response.status === 200) {
          getAssetStatements({
            assetIds: response.data?.assets.ids || []
          });
        }
      });
    }
  }, [auth.currentUser, partyIds]);

  useEffect(() => {
    auth.currentUser &&
      partyIds.length &&
      getBatchPartyCreditScores({ partyIds });
  }, [auth.currentUser, partyIds]);

  useEffect(() => {
    if (partyIds.length) {
      getTreeApi(partyIds as string[], true);
    }
  }, [partyIds]);
  useEffect(() => {
    auth.currentUser && getEntityTasks({ ids: relationshipIds }, true);
  }, [auth.currentUser, relationshipIds]);
  useEffect(() => {
    if (auth.currentUser) {
      if (props.dealId) {
        getDealRelations(props.dealId);
        getDealParties([props.dealId]);
      }
    }
  }, [auth.currentUser, props.dealId]);
  const [getEntityTasks, entityTasksResponse] =
    taskApi.useLazyEntityTasksQuery();
  const isLoading =
    //TODO: check if needed
    entityTasksResponse.isUninitialized ||
    entityTasksResponse.isFetching ||
    entityTasksResponse.isLoading;

  useEffect(() => {
    if (serviceProviderIds.length) {
      getBatchHandler(serviceProviderIds);
    }
  }, [serviceProviderIds]);

  return (
    <TaskTable
      {...props}
      taskState={taskState}
      dealPropertyState={dealPropertyState}
      dealPartyState={dealPartyState}
      dealState={dealState}
      propertyState={propertyState}
      partyRelationState={partyRelationState}
      partyState={partyState}
      assetState={assetState}
      partyAssetState={partyAssetState}
      statementState={statementState}
      creditScoreState={creditScoreState}
      serviceProviderState={serviceProviderState}
      insurancePolicyState={insurancePolicyState}
      selectedTask={selectedTask || undefined}
      selectedDeal={selectedDeal || undefined}
      setSelectedTask={setSelectedTask}
      setFilteredTask={setFilteredTask}
      updateTasksHandler={updateTasksHandler}
      isLoading={isLoading}
      idsMap={{
        partyIds,
        dealPropertyIds,
        propertyIds,
        partyRelationIds,
        relationshipIds,
        dealPartyIds,
        assetIds,
        statementIds,
        partyAssetIds,
        creditScoreIds,
        serviceProviderIds,
        insurancePolicyIds
      }}
    />
  );
};

export type TaskWithMetaData = Task & TaskMetadata;

type TaskMetadata = {
  identityInformation?: string | null;
};

const TaskTable = (props: {
  isTaskStatusDisabled?: boolean;
  dealId?: string;
  partyId?: string;
  taskState: TaskSliceState;
  dealPropertyState: EntityState<DealProperty>;
  dealPartyState: EntityState<DealPartyRelation>;
  dealState?: DealSliceState;
  propertyState: PropertySliceState;
  partyRelationState: EntityState<PartyRelation>;
  partyState: Omit<PartySliceState, "searchCursor">;
  assetState: AssetSliceState;
  creditScoreState: CreditReportSliceState;
  partyAssetState: PartyAssetSliceState;
  statementState: StatementSliceState;
  serviceProviderState:
    | ServiceProviderSliceState
    | SnapshotDataStateFormat<RolodexServiceProvider>;
  insurancePolicyState:
    | SnapshotDataStateFormat<BaseInsurancePolicy<InsurancePolicyEntityType>>
    | InsurancePolicySliceState;
  selectedTask?: Task;
  selectedDeal?: Deal;
  updateTasksHandler?: ReturnType<typeof useTaskHooks>["updateTasksHandler"];
  setSelectedTask?: ReturnType<typeof useTaskHooks>["setSelectedTask"];
  setFilteredTask?: ReturnType<typeof useTaskHooks>["setFilteredTask"];
  isLoading: boolean;
  idsMap: {
    partyIds: (string | EntityId)[];
    dealPropertyIds: (string | EntityId)[];
    propertyIds: (string | EntityId)[];
    partyRelationIds: (string | EntityId)[];
    relationshipIds: (string | EntityId)[];
    dealPartyIds: (string | EntityId)[];
    assetIds: (string | EntityId)[];
    statementIds: (string | EntityId)[];
    partyAssetIds: (string | EntityId)[];
    creditScoreIds: (string | EntityId)[];
    serviceProviderIds: (string | EntityId)[];
    insurancePolicyIds?: (string | EntityId)[];
  };
}) => {
  const {
    taskState,
    dealPropertyState,
    dealPartyState,
    dealState,
    propertyState,
    partyRelationState,
    partyState,
    partyAssetState,
    selectedTask,
    updateTasksHandler,
    setSelectedTask,
    setFilteredTask,
    assetState,
    statementState,
    creditScoreState,
    serviceProviderState,
    insurancePolicyState,
    isLoading
  } = props;
  const {
    partyIds,
    propertyIds,
    relationshipIds
    // assetIds,
    // statementIds,
    // partyAssetIds
  } = props.idsMap;
  // const dispatcher = useDispatch();
  const { errorToast, successToast } = useElphiToast();

  const getTaskGenerationText = useCallbackRef(
    (task: Partial<Task>): string => {
      return getGenerationTextFromTask(
        task,
        currentGenerationMemo[task.entityType!]
      );
    }
  );

  useEffect(() => {
    syncState({
      shouldSync: !!taskState,
      state: taskState.entities,
      statePath: () => {
        return ["tasks"];
      }
    });
  }, [taskState]); //TODO: check if taskState.entities is better in this case
  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { tasks: {} as { [id: string]: Task } },
    callback: async (t) => {
      if (!t.tasks || !updateTasksHandler) return null;
      return await updateTasksHandler(t.tasks).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "tasks updated successfully",
            description: `${r.data.batch.length} tasks updated`
          });
        } else if (r?.status === 400) {
          errorToast({
            title: "update tasks failed",
            description: `${r?.data.error} | ${r?.data.description}`
          });
        }
        return !r ? null : r;
      });
    },
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const filterStateAdapters: {
    [P in keyof typeof filterState]?: (task: Task) => string | string[];
  } = useMemo(() => {
    return {
      generation: (task) => {
        return !!getTaskGenerationText ? getTaskGenerationText(task) : EMPTY;
      },
      modifiedAt: getModifiedAt,
      createdAt: (task) => printDateTime(task.createdAt) || NOT_AVAILABLE,
      entityInformation: (task) => {
        const taskText = taskRowDataToText({
          task,
          props
        });
        return taskText;
      },
      keyClosingTaskType: getKeyClosingTaskTypeFromTask,
      documentStatus: getDocumentStatusFromTask,
      taskStatus: getTaskStatusFromTask,
      orderStatus: (task) =>
        getOrderStatusOptions({
          task,
          props
        }),
      type: (task) =>
        getTaskTypeText(
          task.type,
          hasIntegrationType(task) ? task.integrationType : undefined
        )
    };
  }, [
    propertyState,
    dealState,
    partyState,
    dealPartyState,
    dealPropertyState,
    partyRelationState,
    assetState,
    statementState,
    partyAssetState,
    creditScoreState,
    serviceProviderState,
    insurancePolicyState
  ]);

  const {
    setStateDebouncer: tableFilterSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState
  } = useFilterHook<Task, CellsFilterStateFlat<Task>>({
    state: filterState,
    options: {
      debounceRate: 100
    }
  });

  const pageData: Task[] = useMemo(() => {
    if (relationshipIds.length !== 0 && taskState.ids.length !== 0) {
      const tasks = taskState.ids
        .filter((id: string) => {
          const task = taskState?.entities?.[id];
          const isFollowupTask = task?.entityType === ElphiEntityType.task;
          const idToCheck = !isFollowupTask
            ? task?.entityId
            : task.rootTaskEntityId;
          return idToCheck ? relationshipIds.includes(idToCheck) : false;
        })
        .map((id: string) => taskState?.entities?.[id])
        .filter(removeEmpty);

      return !selectedOrderState
        ? tasks
        : tasks.sort((a, b) =>
            a?.index && b?.index && a?.index > b?.index ? -1 : 1
          );
    }
    return [];
  }, [relationshipIds, taskState, state]);

  const currentGenerationMemo = useMemo(() => {
    const currentGeneration: { [P in ElphiEntityType]: string } = {
      deal: "0",
      property: "0",
      party: "0",
      asset: "0",
      statement: "0",
      "deal-party": "0",
      "party-relation": "0",
      "deal-property": "0",
      "credit-score": "0",
      task: "0",
      "service-provider": "0",
      "insurance-policy": "0"
    };
    pageData.forEach((task) => {
      if (
        task?.autoGenerate !== "never" &&
        task?.generation &&
        task?.entityType &&
        currentGeneration[task.entityType] &&
        task.generation > currentGeneration[task.entityType]
      ) {
        currentGeneration[task.entityType] = task.generation;
      }
    });

    return currentGeneration;
  }, [pageData]);

  const [pageIndex, setPageIndex] = useState(1);

  const getFilteredData = () =>
    pageData.filter((task) =>
      Object.entries(tableFilterState).every(([key, filter]) => {
        const adapter = filterStateAdapters[key as keyof typeof filterState];
        if (!filter.query.length) return true;

        let fieldData = task[key];
        fieldData = adapter ? adapter(task) : fieldData;
        return !Array.isArray(fieldData)
          ? filter.query.includes(fieldData)
          : intersection(filter.query, fieldData).length > 0;
      })
    );

  useEffect(() => {
    if (taskDataMemo.length != pageData.length) {
      const filteredData = getFilteredData();
      if (filteredData?.length != pageData.length) {
        setFilteredTask?.(map(filteredData, "id"));
      } else {
        setFilteredTask?.([]);
      }
    } else {
      setFilteredTask?.([]);
    }
  }, [tableFilterState]);

  const taskDataMemo = useMemo(() => {
    const filteredData = getFilteredData();

    let orderByColumn: [string, CellFilterField<Task>] | undefined = undefined;
    if (selectedOrderState?.selectedKey) {
      const columnKey = selectedOrderState.selectedKey;
      orderByColumn = [columnKey, tableFilterState[columnKey]];
    }

    let sortedData = filteredData;

    if (orderByColumn) {
      const [columnName, cellFilterField] = orderByColumn;
      const sortOrder = cellFilterField.orderBy || "asc";
      const adaptedData = filteredData.map((d) => {
        const adapter =
          filterStateAdapters[columnName as keyof typeof filterState];
        let value = d[columnName];
        if (adapter) value = adapter(d);
        else if (Array.isArray(value)) value = value.join(",");
        return {
          ...d,
          [columnName]: typeof value === "string" ? value.trim() : value
        };
      });

      //TODO: improve sorting, not sure why we have double sort and using findIndex which is bad for performance here
      const sortedAdaptedData = orderBy(adaptedData, columnName, sortOrder);
      sortedData = filteredData.sort((a, b) => {
        const aIndex = sortedAdaptedData.findIndex((x) => x.id === a.id);
        const bIndex = sortedAdaptedData.findIndex((x) => x.id === b.id);
        return aIndex - bIndex;
      });
    }

    const paginatedData = sortedData.slice(
      (pageIndex - 1) * PAGE_SIZE,
      pageIndex * PAGE_SIZE
    );

    return paginatedData.map((task: Task) => getTaskRowData(task, props));
  }, [
    pageData,
    taskState,
    partyIds,
    propertyIds,
    pageIndex,
    tableFilterState,
    dealState,
    propertyState,
    partyState,
    dealPartyState,
    dealPropertyState,
    partyRelationState,
    assetState,
    statementState,
    partyAssetState,
    creditScoreState,
    serviceProviderState
  ]);

  type SelectRow = { [s: string]: boolean };
  const resetSelectRowArray = () => {
    const newRow: SelectRow = {};
    taskDataMemo.forEach((t) => {
      if (t.task?.id) {
        newRow[t.task.id] = false;
      }
    });
    return newRow;
  };

  times(taskDataMemo.length, constant(false));
  const [selectRow, setSelectRow] = useState<SelectRow>(resetSelectRowArray());
  useEffect(() => setSelectRow(resetSelectRowArray()), [tableFilterState]);

  const selectAll = () => {
    const newRow = {} as SelectRow;
    Object.keys(selectRow).forEach((key) => {
      newRow[key] = true;
    });
    return newRow;
  };

  const isAllChecked = !Object.values(selectRow).includes(false);
  const checkAll = () =>
    isAllChecked
      ? setSelectRow(resetSelectRowArray())
      : setSelectRow(selectAll());

  const getFilterAdapter = (
    task: Task,
    filterStateKey: keyof typeof filterState
  ) => {
    const adapter = filterStateAdapters[filterStateKey];
    return adapter ? adapter(task) : NOT_AVAILABLE;
  };
  const taskTableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    const rows: ElphiTableProps["rows"] =
      taskDataMemo.flatMap((rowData, i) => {
        const cells: ElphiCell[] = [
          {
            index: -1,
            data: (
              <Flex
                whiteSpace={"normal"}
                overflow="visible"
                onChange={(e) => {
                  e.stopPropagation();
                  rowData.task?.id &&
                    setSelectRow({
                      ...selectRow,
                      [rowData.task?.id]: !selectRow[rowData.task?.id]
                    });
                }}
                flexDirection="row"
                alignItems="center"
              >
                <Checkbox
                  size="lg"
                  mr="20px"
                  isChecked={!!selectRow[rowData?.task?.id || ""]}
                  variant="darkGrayBorderVariant"
                />
                {Object.keys(rowData.task?.documents || {}).length ? (
                  <Tooltip label="Documents were uploaded to this task">
                    <FileIcon boxSize="12" pr="5px" pt="10px" />
                  </Tooltip>
                ) : (
                  <Tooltip label="No documents were uploaded to this task">
                    <EmptyFileIcon boxSize="12" pr="5px" pt="10px" />
                  </Tooltip>
                )}
                <Text ml={"48px"}>{i + 1 + (pageIndex - 1) * PAGE_SIZE}</Text>
              </Flex>
            ),
            type: ElphiCellType.Element,
            maxWidth: rowIndexStyles.maxWidth,
            minWidth: rowIndexStyles.minWidth
          },
          {
            index: 0,
            data: (
              <Flex w="100%" justifyContent={"center"}>
                <Tooltip
                  label={
                    rowData.task?.autoGenerate === "never"
                      ? "manual generated"
                      : rowData.task?.generation ==
                        currentGenerationMemo[rowData!.task!.entityType]
                      ? "latest generation"
                      : "old generation"
                  }
                >
                  <Box
                    w="100%"
                    // borderRadius={"8px"}
                    borderWidth={"2px"}
                    borderColor={
                      rowData.task?.autoGenerate === "never"
                        ? elphiTheme.components.light.task.generation.manual
                        : rowData.task?.generation ==
                          currentGenerationMemo[rowData!.task!.entityType]
                        ? elphiTheme.components.light.task.generation.latest
                        : elphiTheme.components.light.task.generation.old
                    }
                  >
                    <Text align="center" whiteSpace={"normal"}>
                      {rowData?.task && getTaskGenerationText(rowData?.task)}
                    </Text>
                  </Box>
                </Tooltip>
              </Flex>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          },
          {
            index: 1,
            data: (
              <>
                {rowData.task && (
                  <TaskStatusDropDown
                    isDisabled={props.isTaskStatusDisabled}
                    selectedTask={rowData.task}
                    state={state}
                    onChange={onChange}
                  />
                )}
              </>
            ),
            type: ElphiCellType.Element,
            maxWidth: taskStatusStyles.maxWidth,
            minWidth: taskStatusStyles.minWidth
          },
          {
            index: 2,
            data: (
              <Flex justifyContent="space-between" alignItems="center">
                <Center flex={1}>
                  <OrderStatusColumn rowData={rowData} />
                </Center>
                <OrderStatusTaskIcon task={rowData.task} />
              </Flex>
            ),
            type: ElphiCellType.Element,
            maxWidth: orderStatusStyles.maxWidth,
            minWidth: orderStatusStyles.minWidth
          },
          {
            index: 3,
            data: (
              <Text align="center" whiteSpace={"normal"}>
                {rowData.task?.name}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: taskNameStyles.maxWidth,
            minWidth: taskNameStyles.minWidth
          },
          {
            index: 4,
            data: <TaskEntityColumn rowData={rowData} />,
            type: ElphiCellType.Element,
            maxWidth: "350px",
            minWidth: "350px"
          },
          {
            index: 5,
            data: (
              <Box>
                <Text align="center" whiteSpace={"normal"}>
                  {rowData?.task?.entityType}
                </Text>
              </Box>
            ),
            type: ElphiCellType.Element,
            maxWidth: "250px",
            minWidth: "250px"
          },
          {
            index: 6,
            data: (
              <Tooltip
                label={createTooltipForUserRoles(
                  rowData.task?.checklistOf || []
                )}
                placement="left"
              >
                <Text align="center" whiteSpace={"normal"}>
                  {truncateUserRoles(rowData.task?.checklistOf || [])}
                </Text>
              </Tooltip>
            ),
            type: ElphiCellType.Element,
            maxWidth: "400px",
            minWidth: "400px"
          },
          {
            index: 7,
            data: (
              <Text align="center" whiteSpace={"normal"}>
                {truncatePosUserTypes(rowData.task?.posUserType || []) || "-"}
                {rowData.task?.pendingReviewOf ===
                  TaskPendingReviewType.Lender && (
                  <Tooltip
                    label={"Pending lender review"}
                    placement={"top"}
                    hasArrow
                  >
                    <InboxOrange100Icon w={8} h={8} ml={2} />
                  </Tooltip>
                )}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "250px",
            minWidth: "250px"
          },
          {
            index: 8,
            data: (
              <Text align="center" whiteSpace={"normal"}>
                {rowData.task
                  ? getFilterAdapter(rowData.task, "type")
                  : NOT_AVAILABLE}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "250px",
            minWidth: "250px"
          },
          {
            index: 9,
            data: (
              <Text align="center" whiteSpace={"normal"}>
                {getKeyClosingTaskType(rowData.task?.keyClosingTaskType || "")}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "200px",
            minWidth: "200px"
          },
          {
            index: 10,
            data: (
              <Text align="center" whiteSpace={"normal"}>
                {rowData.task?.modifiedAt &&
                  printDateTime(rowData.task?.modifiedAt)}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: timestampStyles.maxWidth,
            minWidth: timestampStyles.minWidth
          }
        ];
        return {
          index: rowData?.task?.index || String(i),
          selected: !!selectedTask && rowData.task?.id === selectedTask.id,
          maxHeight: "73px",
          minHeight: "73px",
          rowOnClick: () => {
            if (!!selectedTask && selectedTask.id === rowData.task?.id) {
              setSelectedTask?.("");
            } else {
              rowData.task?.id && setSelectedTask?.(rowData.task.id);
            }
          },
          cells: cells
        };
      }) || [];
    return rows;
  }, [
    taskDataMemo,
    selectRow,
    // updateTaskResponseApi.isLoading, //TODO: check if needed
    pageIndex,
    currentGenerationMemo
  ]);

  const filterCellFormatter = (v, _) => {
    const getCurrentStatus = (status?: string) => {
      const currentStatus = getTaskStatus(status);
      return {
        label: currentStatus,
        value: currentStatus
      };
    };
    return Array.isArray(v)
      ? v.map((l) => getCurrentStatus(l))
      : getCurrentStatus(v);
  };

  const selectedTasksWithDocument: TaskWithMetaData[] = useMemo(() => {
    return taskDataMemo
      .filter((v) => v.task && v.task.id && selectRow[v.task.id])
      .map((t) => {
        const identityInformation = getTaskEntityText({
          ...t,
          shouldSanitize: true
        });
        return {
          ...t.task!,
          identityInformation
        };
      });
  }, [taskDataMemo, selectRow]);
  const selectedTasksWithDocumentHasDocument = useMemo(() => {
    return selectedTasksWithDocument.some(
      (t) => Object.keys(t?.documents || {}).length
    );
  }, [selectedTasksWithDocument, taskDataMemo, selectRow]);

  const documentStatusFilterCell = (
    <Flex>
      <FilterCellComponent<Task>
        formatter={(_) => {
          return [
            {
              label: <FileIcon boxSize="7" pr="5px" pt="5px" />,
              value: TaskFileStatus.Uploaded
            },
            {
              label: <EmptyFileIcon boxSize="7" pr="5px" pt="5px" />,
              value: TaskFileStatus.NotUploaded
            }
          ];
        }}
        data={pageData}
        fieldKey={"documentStatus"}
        adapter={(task) => getDocumentStatusFromTask(task)}
        setter={tableFilterSetter}
        state={tableFilterState}
        selectedOrderSetter={setSelectedOrderState}
        selectedOrderState={selectedOrderState}
        showSelectAll={false}
        showColumnTitle={false}
        showOrderState={false}
        fieldType={FieldType.MultiCheckbox}
        inputFlexDir={"row"}
      />
    </Flex>
  );

  const taskTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: -1,
        bottomData: documentStatusFilterCell,
        data: (
          <Flex pl="10p" pt="2px" alignItems="center">
            <Button size="sm" mr="5px" w="120px" onClick={checkAll}>
              {isAllChecked ? "Uncheck All" : "Check All"}
              {` (${Object.values(selectRow).filter((r) => r).length})`}
            </Button>
            {
              <Tooltip
                isDisabled={selectedTasksWithDocumentHasDocument}
                label={
                  "Select one or more tasks that have files attached to them to activate this action"
                }
              >
                <Box>
                  <MultiFileWidgetActionsMenu
                    isDeleteDisabled={true}
                    isDisabled={!selectedTasksWithDocumentHasDocument}
                    tasks={selectedTasksWithDocument}
                  />
                </Box>
              </Tooltip>
            }
          </Flex>
        ),
        type: ElphiCellType.Element,
        maxWidth: rowIndexStyles.maxWidth,
        minWidth: rowIndexStyles.minWidth,
        verticalAlign: "bottom"
      },
      {
        index: 0,
        data: (
          <FilterCellComponent<Task>
            data={pageData}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: NOT_AVAILABLE,
                  value: NOT_AVAILABLE
                };
            }}
            adapter={(task) => getTaskGenerationText(task)}
            fieldKey={"generation"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      },
      {
        index: 1,
        data: (
          <FilterCellComponent<Task>
            formatter={filterCellFormatter}
            data={pageData}
            fieldKey={"taskStatus"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: taskStatusStyles.maxWidth,
        minWidth: taskStatusStyles.minWidth
      },
      {
        index: 2,
        data: (
          <FilterCellComponent<Task>
            data={pageData}
            adapter={(task) => getFilterAdapter(task, "orderStatus")}
            formatter={basicFilterOptionsFormatter}
            fieldKey={"orderStatus"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: orderStatusStyles.maxWidth,
        minWidth: orderStatusStyles.minWidth
      },
      {
        index: 3,
        data: (
          <FilterCellComponent<Task>
            data={pageData}
            fieldKey={"name"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: taskNameStyles.maxWidth,
        minWidth: taskNameStyles.minWidth
      },
      {
        index: 4,
        data: (
          <FilterCellComponent<Task>
            formatter={(v, _) => {
              return Array.isArray(v)
                ? v.map((l) => {
                    return {
                      label: l,
                      value: l
                    };
                  })
                : {
                    label: v,
                    value: v
                  };
            }}
            data={pageData}
            adapter={(task) =>
              taskRowDataToText({
                task,
                props
              })
            }
            fieldKey={"entityInformation"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 5,
        data: (
          <FilterCellComponent<Task>
            data={pageData}
            fieldKey={"entityType"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      },
      {
        index: 6,
        data: (
          <FilterCellComponent<Task>
            formatter={(v, _) => {
              if (Array.isArray(v))
                return v.map((l) => {
                  return {
                    label: fromFirebaseIdToLabel(l),
                    value: l
                  };
                });
              else
                return {
                  label: NOT_AVAILABLE,
                  value: NOT_AVAILABLE
                };
            }}
            data={pageData}
            fieldKey={"checklistOf"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "400px",
        minWidth: "400px"
      },
      {
        index: 7,
        data: (
          <FilterCellComponent<Task>
            formatter={(v, _) => {
              if (Array.isArray(v))
                return v.map((l) => {
                  return {
                    label: posUserTypeOptionMap[l] || NOT_AVAILABLE,
                    value: l
                  };
                });
              return [];
            }}
            data={pageData}
            fieldKey={"posUserType"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      },
      {
        index: 8,
        data: (
          <FilterCellComponent<Task>
            adapter={(task) => getFilterAdapter(task, "type")}
            formatter={basicFilterOptionsFormatter}
            data={pageData}
            fieldKey={"type"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      },
      {
        index: 9,
        data: (
          <FilterCellComponent<Task>
            formatter={(v) => {
              const kct = getKeyClosingTaskType(v as KeyClosingTaskType);
              return {
                label: kct,
                value: kct
              };
            }}
            data={pageData}
            fieldKey={"keyClosingTaskType"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "200px",
        minWidth: "200px"
      },
      {
        index: 10,
        data: (
          <FilterCellComponent<Task>
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: NOT_AVAILABLE,
                  value: NOT_AVAILABLE
                };
            }}
            data={pageData}
            adapter={(task) => printDateTime(task.modifiedAt) || NOT_AVAILABLE}
            fieldKey={"modifiedAt"}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      }
    ];
  }, [tableFilterState, pageData, selectRow]);
  const isLoadingAdapter = !taskDataMemo.length && isLoading;
  const taskTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: taskTableHeader,
      rows: taskTableFullDataRows,
      footer: (
        <Box>
          {isLoadingAdapter && <Progress size="xs" isIndeterminate />}
          <Flex w="100%" justifyContent={"flex-start"} mb="20px" mt="10px">
            <Box lineHeight={"40px"}>
              <Text fontSize={"16px"} fontWeight={"bold"}>
                Page: {pageIndex} /{" "}
                {Math.floor(pageData.length / PAGE_SIZE + 1)}
              </Text>
            </Box>
            <Box pl="20px">
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex <= 1}
                onClick={() => {
                  setPageIndex(pageIndex - 1);
                }}
              >
                Prev
              </Button>
            </Box>
            <Box pl="10px" mr={"auto"}>
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex * PAGE_SIZE >= pageData.length}
                onClick={() => {
                  setPageIndex(pageIndex + 1);
                }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      ),
      isLoading: isLoadingAdapter,
      rowsCount: 1
    };
  }, [taskTableFullDataRows, selectRow]);
  const { heightOffsetInPx } = useWindowDimensions();

  return (
    <Box>
      <ElphiTable
        minHeight={heightOffsetInPx(400)}
        maxHeight={heightOffsetInPx(400)}
        header={taskTableProps.header}
        footer={taskTableProps.footer}
        rows={taskTableProps.rows}
        rowsCount={taskTableProps.rowsCount}
        isLoading={isLoadingAdapter}
        rowCursor={"pointer"}
      />
    </Box>
  );
};
