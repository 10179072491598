import { Box, Button, Flex } from "@chakra-ui/react";
import {
  AggregationFocusType,
  AggregationType,
  ElphiEntityType,
  FieldStatus,
  FieldType
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { get, has } from "lodash";
import { EMPTY } from "../../../constants/common";
import { AllEventsMode } from "../../../redux/v2/audit-event";
import { AuditLogBox } from "../../audit-log/AuditLogBox";
import {
  AttachedAggregationFieldComponet,
  AttachedComponentProps
} from "../../form-builder/AttachedAggregationFieldComponent";
import { EntityFormFieldSpecs } from "../../form-builder/field-specs/fields.types";
import { FieldSelectionInteractiveFormState } from "./FieldSelectionInteractiveForm";
import {
  fieldStatusToColor,
  fieldStatusToLetter,
  isAggregationPath,
  nextFieldStatus
} from "./d2v.utils";

export const AttachedFieldSelectionStatusComponet = <T extends object>(
  props: AttachedComponentProps<T> & {
    fieldId: string;
    fieldPath: string[];
  } & { state: FieldSelectionInteractiveFormState } & {
    spec: {
      prefix: ElphiEntityType;
      id: string;
      storeState: EntityState<unknown>;
      fieldSpecs: EntityFormFieldSpecs<any>;
    };
  }
) => {
  const { state, spec } = props;

  const isAggregation = isAggregationPath(props.fieldPath);

  const fieldPath = isAggregation
    ? [...props.fieldPath.slice(0, -1)]
    : [...props.fieldPath];

  const hasMoreThanOneFocus =
    get(props.spec.fieldSpecs, [...fieldPath, "focused", "options"])?.length >
    1;

  const status =
    get(state?.[spec.prefix]?.[spec.id], [
      "fieldMeta",
      ...fieldPath,
      "status"
    ]) ||
    get(spec.storeState.entities[spec.id], [
      "fieldMeta",
      ...fieldPath,
      "status"
    ]);
  const statusChanged = (newStatus: FieldStatus) =>
    props.onChange &&
    props.onChange({
      fieldKey: [spec.prefix, spec.id, "fieldMeta", ...fieldPath, "status"],
      fieldType: FieldType.String,
      value: newStatus
    });

  const aggregations = isAggregation
    ? get(props.spec.fieldSpecs, [...fieldPath], undefined)
    : undefined;

  let aggType: AggregationType | undefined = undefined;
  if (aggregations && hasMoreThanOneFocus) {
    const isThirdParty = has(aggregations, [AggregationFocusType.ThirdParty]);
    const isCalculated = has(aggregations, [AggregationFocusType.Calculated]);
    if (isThirdParty && isCalculated) {
      aggType = AggregationType.ThirdPartyAggregation;
    }
    if (isThirdParty && !isCalculated) {
      aggType = AggregationType.ThirdParty;
    }
    if (!isThirdParty && isCalculated) {
      aggType = AggregationType.Aggregation;
    }
  }

  const attachedComponentProps = {
    isDisabled: props.isDisabled,
    fieldPath: isAggregation
      ? [...props.fieldPath.slice(0, -1)]
      : props.fieldPath,
    prefix: [spec.prefix, spec.id],
    state: props.state,
    onChange: props.onChange || (() => {})
  };

  const label = get(spec.fieldSpecs, [...props.fieldPath, "label"], EMPTY);

  return (
    <Flex>
      {isAggregation && aggType && (
        <AttachedAggregationFieldComponet
          {...attachedComponentProps}
          aggregationType={aggType}
        />
      )}
      <Box pt="8px" pl="4px" pr="4px">
        <Button
          isDisabled={props.isDisabled}
          size="xs"
          float={"right"}
          boxShadow={"md"}
          _hover={{}}
          color={"white"}
          borderRadius={"50%"}
          bgColor={fieldStatusToColor[status || FieldStatus.None]}
          onClick={() => {
            const fieldStatus = nextFieldStatus(status);
            statusChanged(fieldStatus);
          }}
        >
          {fieldStatusToLetter[status || FieldStatus.None]}
        </Button>
      </Box>
      <Box paddingTop={"6px"} paddingLeft={"2px"}>
        <AuditLogBox
          type={AllEventsMode.Field}
          path={props.fieldPath}
          title={label}
          aggregationType={aggType}
          entityId={spec.id}
        />
      </Box>
    </Flex>
  );
};
