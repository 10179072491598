export const EMPTY = "";
export const NONE = "none";
export const NOT_AVAILABLE = "N/A";
export const PROCESSING = "Processing...";
export const DOLLAR = "$";
export const PERCENTAGE = "%";
export const DEFAULT_PAGE_SIZE = 10;
export const TABLE_MIN_WIDTH = "2435px";
export const DEFAULT_HOVER_DEBOUNCE = 300;
export const DEFAULT_INPUT_SEARCH_DEBOUNCE = 800;
