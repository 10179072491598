import { DocumentTemplate, DocumentTemplateResponse } from "@elphi/types";
import { ActionDispatcher, ActionResponseType } from "../types/action.types";
import { documentTemplateSlice } from "./documentTemplate.slice";

const transformToStateObj = (
  r: DocumentTemplate
): DocumentTemplateResponse => ({
  id: r.id,
  fileId: r.fileName,
  fileName: r.fileName,
  folder: r.folderPath,
  fullPath: r.fullPath,
  templateCategory: r.templateCategory,
  name: r.name,
  stateCode: r.stateCode,
  status: r.status,
  index: r.index
});

export const documentTemplateAction: ActionDispatcher<DocumentTemplate> = (
  dispatch
) => {
  const slice = documentTemplateSlice;

  return {
    add: async (r) => {
      const payload = r.map(transformToStateObj);
      const res = dispatch(documentTemplateSlice.actions.upsertMany(payload));
      return {
        type: ActionResponseType.Add,
        payload: res?.payload?.map((d) => ({ id: d.id })) || []
      };
    },
    update: async (r) => {
      const payload = r.map((x) => ({
        id: x.id,
        changes: transformToStateObj(x.changes)
      }));
      const res = dispatch(slice.actions.updateMany(payload));
      return {
        type: ActionResponseType.Update,
        payload: res.payload.map((d) => {
          return { id: d.id };
        })
      };
    },
    remove: async (r) => {
      const res = dispatch(slice.actions.removeMany(r));
      return {
        type: ActionResponseType.Remove,
        payload: res.payload
      };
    }
  };
};
