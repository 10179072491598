import { Type } from "@sinclair/typebox";
import { mofinAppraisalValueDerivationType } from "../../entities/mofin/property/mofinProperty.enumerations";
import { elphiPropertySchema } from "../base/property.typebox";
import { BooleanField, EnumField } from "../utils.typebox";

const AppraisalFieldsSchema = Type.Object({
  AppraisalValueDerivationType: EnumField(mofinAppraisalValueDerivationType)
});

const PropertyFieldsSchema = Type.Object({
  Appraisal: Type.Partial(AppraisalFieldsSchema),
  CanBeRebuiltIndicator: BooleanField
});

export const mofinPropertySchema = Type.Intersect([
  elphiPropertySchema,
  PropertyFieldsSchema
]);
