import { ResponsiveValue, TextProps } from "@chakra-ui/react";
import { Property } from "csstype";
import { SIZE_FIELD } from "./TableRowSizeComponent";
export enum ElphiCellType {
  Element = "element",
  String = "string"
}

 type BaseElphiCell<TType extends ElphiCellType, TData> = {
  index: number;
  type: TType;
  topData?: TData;
  data: TData;
  bottomData?: TData;
  maxWidth?: string;
  minWidth?: string;
  verticalAlign?: string;
  size?: SIZE_FIELD;
  textStyle?: TextProps;
};

type ElphiElementCell = BaseElphiCell<ElphiCellType.Element, JSX.Element>;

type ElphiStringCell = BaseElphiCell<ElphiCellType.String, string>;
export type ElphiCell = ElphiElementCell | ElphiStringCell;

export type ElphiTableProps = {
  minHeight?: string;
  maxHeight?: string;
  header: ElphiCell[];
  rowCursor?: ResponsiveValue<Property.Cursor>;
  rows: {
    index: string;
    cells: ElphiCell[];
    minHeight?: string;
    maxHeight?: string;
    selected?: boolean;
    rowOnClick?: () => void;
  }[];
  rowsCount: number;
  isLoading: boolean;
  options?: {
    isExpanded?: boolean;
  };
  footer?: JSX.Element;
  aggregationFooter?: JSX.Element;
};

export type OrderableTableProps = {
  tableName: string;
  isOrderable?: boolean;
};
