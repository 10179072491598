import { capitalize, isString, last } from "lodash";
import { useLocation } from "react-router";
import {
  NavigationItem,
  NavigationItemDictionary,
  NavigationItemType,
  NavigationPath
} from "../../shared/types/navigation.types";

const isNavigationPath = (
  value: NavigationPath | string
): value is NavigationPath => typeof value !== "string";

const navigationHandler = () => {
  const construct = (items: NavigationItem[]): string =>
    items.map((item) => JSON.stringify(item)).join("_");

  const destructure = (navigationPath: string): NavigationItem[] =>
    navigationPath
      .split("_")
      .map((item) => {
        try {
          const jsonObject = JSON.parse(item);
          return jsonObject as NavigationItem;
        } catch (e) {
          console.error("failed to parse navigation item: " + item);
          return {} as NavigationItem;
        }
      })
      .filter((item) => Object.keys(item).length !== 0);

  const resolveNavigationKey = (navigationPath: string | NavigationPath) => {
    const resolved = isNavigationPath(navigationPath)
      ? construct(navigationPath)
      : destructure(navigationPath).length > 0
      ? navigationPath
      : undefined;
    return resolved;
  };

  const getObjectAsDictionary = (
    navigationPath: string | NavigationPath
  ): NavigationItemDictionary => {
    const location = useLocation();
    if (!navigationPath) {
      navigationPath = capitalize(last(location.pathname?.split("/")));
    }

    const navigationItemDictionary: NavigationItemDictionary = isString(
      navigationPath
    )
      ? { page: navigationPath, tab: NavigationItemType.Page.toString() }
      : navigationPath.reduce(
          (acc, { type, value }) => ({ ...acc, [type]: value }),
          {
            [NavigationItemType.Page]: NavigationItemType.Page.toString(),
            [NavigationItemType.Tab]: NavigationItemType.Page.toString()
          }
        );

    return navigationItemDictionary;
  };

  return {
    construct,
    destructure,
    resolveNavigationKey,
    getObjectAsDictionary
  };
};

export const newTab = (
  url: string,
  options?: {
    features?: string;
  }
) => {
  window.open(url, options?.features || "_blank");
};

export const getURLSearchParams = (
  searchParams?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined
) => new URLSearchParams(searchParams);

export default navigationHandler;
