import { ModalContainer } from "../../modal-container/ModalContainer";
import { PropertyInsurancePolicyModalContent } from "./PropertyInsurancePolicyModalContent";

type PropertyInsurancePolicyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  dealId: string;
  showDomainStep?: boolean;
  branchRepId?: string;
  onPolicyCreated?: (isPolicyOnProcess: boolean) => void;
};

export const PropertyInsurancePolicyModal = (
  props: PropertyInsurancePolicyModalProps
) => {
  const { isOpen, onClose } = props;
  return (
    <ModalContainer
      maxWidth={1110}
      isShow={isOpen}
      onCloseModal={onClose}
      header={"Create an Insurance Policy"}
      body={<PropertyInsurancePolicyModalContent {...props} />}
    />
  );
};
