import {
  AuditEvent,
  AuditEventEntityType,
  AuditEventFieldType,
  DiscussionEntityType
} from "@elphi/types";
import { get } from "lodash";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { DynamicSchema } from "../../../../forms/schemas/types/dynamicSchema.types";
import { auditEntityNameMapper } from "../../../../forms/schemas/utils/mappers";

export const fieldTypeMap = {
  [AuditEventFieldType.Value]: "Value",
  [AuditEventFieldType.Focus]: "Focus",
  [AuditEventFieldType.FieldStatus]: "Field Status",
  [AuditEventFieldType.IntegrationStatus]: "Integration Status",
  [AuditEventFieldType.UploadedFile]: "File Uploaded",
  [AuditEventFieldType.DeletedFile]: "File Deleted",
  [AuditEventFieldType.Message]: "Message",
  [AuditEventFieldType.Reaction]: "Reaction"
} as const;

export const entityMap = {
  [AuditEventEntityType.Asset]: "Asset",
  [AuditEventEntityType.Deal]: "Deal",
  [AuditEventEntityType.Party]: "Party",
  [AuditEventEntityType.Property]: "Property",
  [AuditEventEntityType.Statement]: "Statement",
  [AuditEventEntityType.Task]: "Task",
  [AuditEventEntityType.DiscussionEntity]: "Discussion",
  [AuditEventEntityType.ServiceProvider]: "Service Provider"
} as const;

export const entityRelationMap = {
  [AuditEventEntityType.DealParty]: "Deal Party Relationship",
  [AuditEventEntityType.DealProperty]: "Deal Property Relationship",
  [AuditEventEntityType.DealUser]: "Deal User Relationship",
  [AuditEventEntityType.PartyAsset]: "Party Asset Relationship",
  [AuditEventEntityType.PartyUser]: "Party User Relationship",
  [AuditEventEntityType.PartyRelation]: "Party Relationship"
} as const;

const discussionEntityMap = {
  [DiscussionEntityType.Deal]: "Deal",
  [DiscussionEntityType.Task]: "Task"
} as const;

export const focusBgColorMap = {
  override: "black",
  thirdParty: "pink.500",
  calculated: "gray.200",
  [EMPTY]: "pink.500"
} as const;

export const focusColorMap = {
  override: "white",
  thirdParty: "white",
  calculated: "black",
  [EMPTY]: "black"
} as const;

export const getDiscussionPrettyIdentifier = (event: AuditEvent) => {
  const { entityType, entityPrettyIdentifier } = event;
  if (entityType === AuditEventEntityType.DiscussionEntity) {
    return `${discussionEntityMap[event.discussionEntityType]} ${
      entityPrettyIdentifier?.join(" ") || NOT_AVAILABLE
    }`;
  }
  return NOT_AVAILABLE;
};

export const getFieldLabel = (r: {
  event: AuditEvent;
  specs: Partial<DynamicSchema["specs"]>;
}) => {
  try {
    const { event } = r;
    const { fieldPath } = event;
    const path = getFieldLabelPath(event);
    const entityType = auditEntityNameMapper[event.entityType];
    const specs = entityType && r.specs[entityType];
    const label = get(specs?.entitySpecs, path || EMPTY)?.["label"];
    if (label === NOT_AVAILABLE) {
      return fieldPath;
    }
    return label || fieldPath;
  } catch (error) {
    console.error("getFieldLabel", error);
    return NOT_AVAILABLE;
  }
};

const getFieldLabelPath = (event: AuditEvent) => {
  const { fieldPath, fieldType } = event;
  //since focus field is labeled as Focus, assuming his override field will have the real label name
  if (fieldType === AuditEventFieldType.Focus && fieldPath) {
    return [...fieldPath.split(".").slice(0, -1), "override"]?.join(".");
  }
  return fieldPath;
};
