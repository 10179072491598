import { Text } from "@chakra-ui/react";
import { PosDealUserPartyView } from "@elphi/types";
import { usePosDealUserPartyHooks } from "../../../hooks/posDealUserParty.hooks";
import { ModalContainer } from "../../modal-container/ModalContainer";

export const PublishModal = (props: {
  show: boolean;
  onClose: () => void;
  loanIdentifier: string;
  data: PosDealUserPartyView;
}) => {
  const { handlePublishDeal, publishResponse } = usePosDealUserPartyHooks();
  const handleOnSubmit = () => {
    handlePublishDeal({
      dealId: props.data.dealId,
      userId: props.data.userId
    });
  };
  return (
    <ModalContainer
      header={`Publish Loan: ${props.loanIdentifier} to ${props.data.userName}`}
      isShow={props.show}
      onCloseModal={props.onClose}
      maxWidth={800}
      body={
        <Text color={"red.500"}>
          Note: Publishing allows the user to see the deal and its tasks.
        </Text>
      }
      submit={{
        showClose: true,
        closeTitle: "Cancel",
        onConfirm: handleOnSubmit,
        isLoading: publishResponse.isLoading
      }}
    />
  );
};
