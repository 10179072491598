import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Asset, FieldType, Party } from "@elphi/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { useAssetHooks } from "../../hooks/asset.hooks";
import { RootState } from "../../redux/store";
import { useElphiToast } from "../toast/toast.hook";
import { AssetQuerySearch, assetQueryType } from "./AssetQuerySearch";
import { assetToString } from "./utils/printUtils";

export const RemoveAssetModal = (props: {
  callback?: (ids: string[]) => void;
  buttonFn?: (handleOpen: () => void) => JSX.Element;
  selectedParty: Partial<Party>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    deleteBatchPartyAssetRelation,
    deleteBatchPartyAssetRelationResponse
  } = useAssetHooks();
  const { successToast, errorToast } = useElphiToast();
  const assetState = useSelector((state: RootState) => state.asset);

  const buttonComponent = props.buttonFn?.(onOpen) ?? null;
  const [selectedAsset, setSelectedAsset] = useState([] as Asset[]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    setDeleteConfirmation(false);
    setSelectedAsset([] as Asset[]);
  }, [isOpen]);

  const deleteAssetHandler = async (selectedAsset: Asset[]) => {
    const relations = selectedAsset.map(
      (a) => `${a.id}_${props.selectedParty.id}`
    );
    return await deleteBatchPartyAssetRelation({ relations }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Assets Removed",
          description: `assets: ${r.data.batch.toString()}`
        });
        if (r.data) {
          props?.callback?.(r.data.batch) || setSelectedAsset([] as Asset[]);
        }
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to delete party-asset relationship",
          description: r.data.description
        });
      return r;
    });
  };

  const selectAsset = (r: string[]) => {
    const newSelection = r
      .map((a) => assetState.entities[a] || undefined)
      .filter((a): a is Asset => !!a);
    setSelectedAsset(newSelection);
  };

  const mainModal = (
    <Box>
      <Text fontSize="l" fontWeight="bold">
        Search an Existing Asset
      </Text>
      <AssetQuerySearch
        selectedParty={props.selectedParty}
        onSelect={selectAsset}
        currentValue={selectedAsset.map((a) => a.id)}
        query={assetQueryType.moreThanOnePartyAsset}
        fieldType={FieldType.MultiSelect}
      />
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={() => setDeleteConfirmation(true)}
        >
          Remove Asset
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  const confirmModal = (
    <Box>
      <Text p="10px">
        Are you sure you want to remove{" "}
        {selectedAsset.map((a) => assetToString({ asset: a })).toString()}?
      </Text>
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          isLoading={deleteBatchPartyAssetRelationResponse.isLoading}
          isDisabled={
            !selectedAsset || deleteBatchPartyAssetRelationResponse.isLoading
          }
          onClick={() => {
            selectedAsset &&
              deleteAssetHandler(selectedAsset).then(
                (r) => r.status === 200 && onClose()
              );
          }}
        >
          Yes, remove asset
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={() => setDeleteConfirmation(false)}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Box>
      {props.buttonFn ? (
        buttonComponent
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Remove an Asset
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>
            Remove an Asset
            <Text fontSize="12px" padding={"2px"} marginTop={"8px"}>
              Note: Asset must be attached to at least one party. You can't
              choose to remove an asset that is attached to one party only, but
              you can delete it.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{deleteConfirmation ? confirmModal : mainModal}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
