import { Box, Flex, Text } from "@chakra-ui/react";
import { AggregationType } from "@elphi/types";
import { getFocusedData } from "@elphi/utils";
import { get } from "lodash";
import { AllEventsMode } from "../../redux/v2/audit-event";
import { fieldTypeValidation } from "../application/sections/formTemplate.utils";
import { AuditLogBox } from "../audit-log/AuditLogBox";
import { AttachedAggregationFieldComponet } from "../form-builder/AttachedAggregationFieldComponent";
import { AttachedFieldStatusComponet } from "../form-builder/AttachedFieldStatusComponent";
import {
  InputBuilderAttachedComponentWrapper,
  OnChangeInput
} from "../form-builder/FormBuilder";
import StyledInputBuilder, {
  StrictUnionInputBuilderProps
} from "../form-builder/InputBuilder";
import { InputBuilderFieldSpecs } from "../form-builder/field-specs/fields.types";
import {
  additionalValidations,
  fieldStatusComponentFieldSpread
} from "../form-builder/formBuilder.utils";

type TableInputProps<T extends object, U extends object> = {
  specFields?: InputBuilderFieldSpecs<any>;
  prefix: string[];
  state: T;
  currentEntity: U & { id: string };
  widthStyles?: { minWidth: string; maxWidth: string };
  labelPosition?: string;
  onChange?: (v: OnChangeInput) => void;
};

export const TableInput = <T extends object, U extends object>(
  props: TableInputProps<T, U> & {
    isAggregation?: AggregationType;
    customComponent?: StrictUnionInputBuilderProps["customComponent"];
    hideSelectedOptions?: StrictUnionInputBuilderProps["hideSelectedOptions"];
    isDisabled?: boolean;
  }
) => {
  const {
    specFields,
    prefix,
    state,
    currentEntity,
    widthStyles,
    onChange,
    customComponent,
    hideSelectedOptions,
    labelPosition
  } = props;

  if (!specFields) {
    return <></>;
  }

  const resolveFieldValueParameter = { prefix, specFields, props, state };
  const { fullFieldPath, aggregationFieldPath, currentValue } =
    resolveFieldValue<T>(resolveFieldValueParameter);

  const fieldPath = props.isAggregation
    ? aggregationFieldPath.slice(prefix.length)
    : specFields.fieldKey;

  return (
    <Box minW={widthStyles?.minWidth} maxW={widthStyles?.maxWidth} pr="15px">
      {labelPosition === "up" && (
        <Text pl="3px" fontWeight={"light"}>
          {specFields.label}
        </Text>
      )}
      <InputBuilderAttachedComponentWrapper
        inputComponent={
          <StyledInputBuilder
            shouldDisplayErrorMessage={true}
            isDisabled={props.isDisabled}
            hideSelectedOptions={hideSelectedOptions}
            customComponent={customComponent}
            {...specFields}
            onChange={(e) => {
              currentEntity?.id &&
                onChange &&
                onChange({
                  ...specFields,
                  fieldKey: fullFieldPath,
                  value: e.target.value
                });
            }}
            {...fieldStatusComponentFieldSpread({
              state: state,
              prefix: prefix,
              fieldPath: props.isAggregation
                ? aggregationFieldPath.slice(prefix.length)
                : specFields.fieldKey,
              isReadOnly:
                props.isAggregation &&
                !!currentEntity?.id &&
                get(state, [...aggregationFieldPath, "focused"]) !== "override"
            })}
            shouldMarkEmpty={props.isAggregation && !currentValue}
            isValid={
              specFields?.fieldType &&
              fieldTypeValidation?.[specFields?.fieldType]?.(
                get(state, fullFieldPath)
              ) &&
              additionalValidations(specFields, currentValue)
            }
            currentValue={currentEntity?.id ? currentValue : ""}
          />
        }
        attachedComponent={
          <Box>
            {currentEntity?.id && (
              <Flex>
                <Box>
                  {props.isAggregation ? (
                    <AttachedAggregationFieldComponet
                      onChange={onChange}
                      isDisabled={props.isDisabled}
                      fieldPath={aggregationFieldPath.slice(prefix.length)}
                      prefix={prefix}
                      state={state}
                      aggregationType={props.isAggregation}
                    />
                  ) : null}
                </Box>
                <Box>
                  <AttachedFieldStatusComponet
                    isDisabled={props.isDisabled}
                    onChange={onChange}
                    fieldPath={fieldPath}
                    prefix={prefix}
                    state={state}
                  />
                </Box>
                {fieldPath && (
                  <Box paddingTop={"6px"} paddingLeft={"2px"}>
                    <AuditLogBox
                      type={AllEventsMode.Field}
                      path={fieldPath}
                      title={specFields.label}
                      aggregationType={props.isAggregation}
                      entityId={currentEntity?.id}
                    />
                  </Box>
                )}
              </Flex>
            )}
          </Box>
        }
      />
    </Box>
  );
};
export const resolveFieldValue = <T extends object>(p: {
  prefix: string[];
  specFields: InputBuilderFieldSpecs<any>;
  props: {
    isAggregation?: AggregationType | undefined;
    customComponent?: StrictUnionInputBuilderProps["customComponent"];
    hideSelectedOptions?: StrictUnionInputBuilderProps["hideSelectedOptions"];
  };
  state: T;
}) => {
  const fullFieldPath = [...p.prefix, ...p.specFields.fieldKey];
  const aggregationFieldPath = fullFieldPath.slice(0, -1);
  const currentValue = p.props.isAggregation
    ? getFocusedData(get(p.state, aggregationFieldPath))
    : get(p.state, fullFieldPath);
  return { fullFieldPath, aggregationFieldPath, currentValue };
};
