import {
  DeleteIcon,
  DownloadIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  InfoIcon,
  ViewIcon
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from "@chakra-ui/react";
import {
  BaseDocumentMetadata,
  BoxFileInfo,
  DocumentMetadata,
  FirestoreTimestampFieldUnionType,
  GcpFileInfo,
  StorageProvider,
  Task,
  TaskType,
  TimestampField
} from "@elphi/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { responseHandler } from "../../apis/rtk/response.handler";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { useLosSettingsContextHooks } from "../../features/main/providers/los-settings/LosSettingsProvider";
import { fileTypes } from "../../firebase/constants";
import {
  getTimestampFromFirestoreObject,
  printDateTime
} from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import { useOrgHooks } from "../../hooks/org.hooks";
import useTaskHooks from "../../hooks/task.hooks";
import { getListFromDictionary } from "../../utils/batchUtils";
import { getBaseUrl, getBoxStorageUrl } from "../../utils/envUtils";
import ScrollableSections from "../ScrollableSections";
import { FileUploader } from "../file-uploader/FileUploader";
import { FileIcon } from "../file-uploader/Icons";
import { download, downloadFile } from "../file-uploader/file.utils";
import { AttachedFieldStatusComponet } from "../form-builder/AttachedFieldStatusComponent";
import { SectionHeader } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";
import { newTab } from "../utils/navigationUtils";
import { TaskWithMetaData } from "./common.task.types";
import useDownloadTaskHooks from "./downloadTask.utils";

//NEED TO REFRACTOR THIS FILE
const BoxFileWidget = (props: { file: BaseDocumentMetadata<BoxFileInfo> }) => {
  return (
    <Box>
      <FileWidgetContent
        fileName={props.file.data.fileName}
        createdAt={props.file.createdAt}
        uploadedBy={props.file.uploadedBy}
        size={props.file.size}
      />
    </Box>
  );
};
const GCPFileWidget = (props: { file: BaseDocumentMetadata<GcpFileInfo> }) => {
  return (
    <Box>
      <FileWidgetContent
        fileName={props.file.data.fileName}
        createdAt={props.file.createdAt}
        uploadedBy={props.file.uploadedBy}
        size={props.file.size}
      />
    </Box>
  );
};

const FileWidgetActionsMenu = (props: {
  file: DocumentMetadata;
  selectedTask: Task;
  isDeleteDisabled?: boolean;
  isDisabled?: boolean;
  fileKey?: string;
}) => {
  const {
    deleteTaskFileApi,
    deleteTaskFileApiResponse,
    getDownloadUrlApi,
    getDownloadUrlApiResponse
  } = useTaskHooks();
  const { successToast, errorToast } = useElphiToast();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        bgColor="white"
        variant="outline"
        onClick={(e) => e.stopPropagation()}
        isDisabled={props.isDisabled}
      />
      <MenuList zIndex={5}>
        <MenuItem
          isDisabled={
            !(
              props.file.provider && props.file.provider !== StorageProvider.Box
            )
          }
          icon={<ViewIcon bgColor="transparent" color="blackAlpha.600" />}
          onClick={() => {
            getDownloadUrlApi({
              document: props.file
            })
              .then(responseHandler)
              .then((r) => {
                if (r.status === 200) {
                  newTab(r.data.url);
                }
                if (r.status === 400) {
                  errorToast({
                    title: "failed to download",
                    description: r.data?.description
                  });
                }
              });
          }}
        >
          View File
        </MenuItem>
        <MenuItem
          icon={<DownloadIcon color="blackAlpha.600" />}
          isDisabled={getDownloadUrlApiResponse.isLoading}
          onClick={() => {
            getDownloadUrlApi({
              document: props.file
            })
              .then(responseHandler)
              .then((r) => {
                if (r.status === 200) {
                  download(r.data.url, props.file.data.fileName);
                }
                if (r.status === 400) {
                  errorToast({
                    title: "failed to download",
                    description: r.data?.description
                  });
                }
              });
          }}
        >
          Download File
        </MenuItem>
        {props.file.provider === StorageProvider.Box && (
          <Link
            href={`${getBoxStorageUrl()}/file/${props.file.data.fileId}`}
            isExternal
          >
            <MenuItem icon={<ExternalLinkIcon color="blackAlpha.600" />}>
              View In Box
            </MenuItem>
          </Link>
        )}
        <MenuItem icon={<InfoIcon color="blue.300" />} isDisabled={true}>
          File Info
        </MenuItem>
        <MenuItem
          isDisabled={
            props.isDeleteDisabled || deleteTaskFileApiResponse.isLoading
          }
          icon={<DeleteIcon color="red.400" />}
          onClick={() => {
            deleteTaskFileApi({
              document: props.file,
              taskId: props.selectedTask.id
            })
              .then(responseHandler)
              .then((r) => {
                if (r.status === 200) {
                  successToast({
                    title: "file deleted",
                    description: `${props.file.data.fileName} delete form ${props.selectedTask.name}`
                  });
                } else if (r.status === 400) {
                  errorToast({
                    title: "file deletion failed",
                    description: `${r?.data?.description}`
                  });
                }
              });
          }}
        >
          Delete File
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
export const MultiFileWidgetActionsMenu = (props: {
  tasks: TaskWithMetaData[];
  isDeleteDisabled?: boolean;
  isDisabled?: boolean;
  selectedFiles?: { [a: string]: boolean };
}) => {
  const { selectedOrgId } = useOrgHooks();
  const { getDownloadUrlApi, getDownloadUrlApiResponse } = useTaskHooks();
  const { firstFileOfEachTask } = useDownloadTaskHooks({
    tasks: props.tasks,
    selectedFiles: props.selectedFiles
  });

  const zipWorker = useMemo(() => {
    const worker = new Worker(
      new URL("../../workers/zip.worker.ts", import.meta.url),
      { type: "module" }
    );
    return worker;
  }, []);

  zipWorker.onmessage = (event) => {
    const { type, zipFile, zipName } = event.data;
    if (type === "done") {
      downloadFile(zipFile, zipName);
    }
  };

  useEffect(() => {
    return () => {
      zipWorker.terminate();
    };
  }, []);

  const handleDownloadClick = async () => {
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      return;
    }
    if (!selectedOrgId) {
      return;
    }

    const baseUrl = getBaseUrl(selectedOrgId.toString(), "api/v1/");
    const url = `${baseUrl}/task/v2/document/download-url`;

    const selectedDocs = props.selectedFiles
      ? Object.keys(props.tasks?.[0]?.documents || {})
          .filter((d) => props?.selectedFiles?.[d] || false)
          .map((d) => props.tasks?.[0]?.documents?.[d]!)
      : props.tasks
          .filter((t) => t.documents)
          .flatMap((task) =>
            Object.values(task.documents || {}).map((d) => {
              return {
                ...d,
                folderName: task.identityInformation
                  ? `${task.name} - ${task.identityInformation}`
                  : task.name
              };
            })
          );
    const zippedAt = `${new Date()
      .toLocaleString("en-US")
      .replaceAll("/", ".")
      .replaceAll(":", ".")}`;
    const zipName = props.selectedFiles
      ? `${props.tasks?.[0]?.name || "No Task Name"}- ${
          props.tasks?.[0].identityInformation || ""
        }_${zippedAt}`
      : zippedAt;

    zipWorker.postMessage({
      token: token,
      url: url,
      files: selectedDocs,
      zipName: zipName
    });
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        bgColor="white"
        variant="outline"
        onClick={(e) => e.stopPropagation()}
        isDisabled={props.isDisabled}
      />

      <MenuList zIndex={5}>
        <MenuItem
          icon={<DownloadIcon color="blackAlpha.600" />}
          isDisabled={getDownloadUrlApiResponse.isLoading}
          onClick={handleDownloadClick}
        >
          Download Files
        </MenuItem>

        <MenuItem
          icon={<ExternalLinkIcon color="blackAlpha.600" />}
          onClick={() => {
            firstFileOfEachTask.forEach((file) => {
              if (file.provider === StorageProvider.Box) {
                const url = `${getBoxStorageUrl()}/file/${file.data.fileId}`;
                newTab(url);
              }
            });
            props.tasks.forEach((task) => {
              Object.values(task.documents || {}).forEach((file) => {
                if (file.provider !== StorageProvider.Box) {
                  getDownloadUrlApi({
                    document: file
                  })
                    .then(responseHandler)
                    .then((r) => {
                      if (r.status === 200) {
                        newTab(r.data.url);
                      }
                    });
                }
              });
            });
          }}
        >
          View Files
        </MenuItem>
        <MenuItem icon={<InfoIcon color="blue.300" />} isDisabled={true}>
          File Info
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FileWidgetContent = (props: {
  fileName: string;
  size: string;
  createdAt: FirestoreTimestampFieldUnionType;
  uploadedBy: string;
}) => {
  return (
    <Box>
      <Text fontWeight={"bold"}>{props.fileName}</Text>
      <Text>{props.uploadedBy}</Text>
      <Flex>
        <Text>{props.size} </Text>{" "}
        <Text pl="5px">{printDateTime(props.createdAt)}</Text>
      </Flex>
    </Box>
  );
};

const FileWidget = (props: {
  file: DocumentMetadata;
  selectedTask: Task;
  selectedFile: string;
  setSelectedFile: React.Dispatch<React.SetStateAction<string>>;
  isDeleteDisabled?: boolean;
  fileKey?: string;
}) => {
  return (
    <Box
      borderRadius={"8px"}
      borderWidth={"2px"}
      borderColor={
        props.file.data.fileId === props.selectedFile ? "blue.400" : "grey.100"
      }
      cursor="pointer"
      onClick={() => {
        props.file.data.fileId === props.selectedFile
          ? props.setSelectedFile("")
          : props.setSelectedFile(props.file.data.fileId);
      }}
      bgColor={"white"}
    >
      <Box p="10px" h="100%">
        <Flex h="100%">
          <Box
            h="100%"
            borderColor={"grey.100"}
            borderRadius={"50%"}
            borderWidth={"1px"}
            bgColor="white"
          >
            <Icon w="50px" h="50px" mt="8px" ml="9px" pl="10px">
              <FileIcon />
            </Icon>
          </Box>
          <Box pl="10px" w="100%">
            {props.file.provider === StorageProvider.Box && (
              <BoxFileWidget file={props.file} />
            )}
            {props.file.provider === StorageProvider.GCP && (
              <GCPFileWidget file={props.file} />
            )}
          </Box>
          <Box pl="20px" h="100%" pt="10px">
            <FileWidgetActionsMenu
              isDeleteDisabled={props.isDeleteDisabled}
              file={props.file}
              selectedTask={props.selectedTask}
              fileKey={props.fileKey}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

const FileManagementContainer = (props: TaskFileManagerProps) => {
  return props.snapshotId ? (
    <FileManagementSnapshotContainer {...props} />
  ) : (
    <FileManagementLiveStateContainer {...props} />
  );
};
const FileManagementLiveStateContainer = (props: TaskFileManagerProps) => {
  const { updateBatch: updateTaskBatch } = useTaskHooks();
  const { successToast, errorToast } = useElphiToast();
  const updateTaskFormHandler = async (
    v: Partial<{
      [id: string]: Task;
    }>
  ) => {
    if (!v) return null;

    const tasks = getListFromDictionary(v);
    if (!tasks.length) return null;
    return await updateTaskBatch({ tasks } as {
      tasks: ({
        id: string;
      } & Partial<Task>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Tasks Updated",
          description: `total tasks updated: ${tasks.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };

  return (
    <FileManagement
      hideCheckAll={props.hideCheckAll}
      selectedTask={props.selectedTask}
      updateTaskFormHandler={updateTaskFormHandler}
      fileKey={props.fileKey}
    />
  );
};
const FileManagementSnapshotContainer = (props: TaskFileManagerProps) => {
  return (
    <FileManagement
      hideCheckAll={props.hideCheckAll}
      isDeleteDisabled
      isStatusFieldDisabled
      selectedTask={props.selectedTask}
      fileKey={props.fileKey}
    />
  );
};
const FileManagement = (props: {
  isDeleteDisabled?: boolean;
  isStatusFieldDisabled?: boolean;
  selectedTask: Task;
  hideCheckAll?: boolean;
  fileKey?: string;
  updateTaskFormHandler?: (v: Partial<{ [id: string]: Task }>) => Promise<
    | {
        status: 400;
        data: {
          error: any;
          description: string;
        };
      }
    | {
        status: 200;
        data: {
          batch: string[];
        };
      }
    | null
  >;
}) => {
  const { hideCheckAll } = props;
  const [selectedFile, setSelectedFile] = useState("");

  const { state, onChange, syncState } = useFormBuilderStateHandler({
    initialState: { [props.selectedTask.id]: props.selectedTask } as {
      [id: string]: Task;
    },
    callback: props.updateTaskFormHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  useEffect(() => {
    props.selectedTask &&
      syncState({
        state: props.selectedTask,
        shouldSync: !!props.selectedTask,
        statePath: () => {
          return [props.selectedTask.id];
        }
      });
  }, [props.selectedTask]);

  const taskDocuments = useMemo(() => {
    return props.selectedTask?.documents ? props.selectedTask.documents : {};
  }, [props.selectedTask.documents]);
  type SelectRow = { [s: string]: boolean };
  const resetSelectRowArray = useCallback(() => {
    const newRow: SelectRow = {};
    Object.keys(taskDocuments).forEach((t) => {
      if (t) {
        newRow[t] = false;
      }
    });
    return newRow;
  }, [taskDocuments]);

  const [selectRow, setSelectRow] = useState<SelectRow>(resetSelectRowArray());
  useEffect(() => setSelectRow(resetSelectRowArray()), [taskDocuments.length]);
  const selectAll = useCallback(() => {
    const newRow = {} as SelectRow;
    Object.keys(selectRow).forEach((key) => {
      newRow[key] = true;
    });
    return newRow;
  }, [selectRow]);

  const isAllChecked = !Object.values(selectRow).includes(false);
  const checkAll = () =>
    isAllChecked
      ? setSelectRow(resetSelectRowArray())
      : setSelectRow(selectAll());

  const selectedTasksWithDocumentHasDocument = useMemo(() => {
    return Object.keys(taskDocuments).some((t) => selectRow[t] === true);
  }, [taskDocuments, selectRow]);

  const currentFileId =
    (props?.selectedTask.type === TaskType.Integration &&
      props.fileKey &&
      props?.selectedTask?.integrationType &&
      props.selectedTask?.mapIntegrationFileKeys?.[
        props.selectedTask.integrationType
      ]?.[props.fileKey]) ||
    undefined;

  return (
    <Box>
      {!hideCheckAll && (
        <Flex pl="10p" pt="2px" alignItems="center">
          <Button size="xs" mr="5px" w="110px" onClick={checkAll}>
            {isAllChecked ? "Uncheck All" : "Check All"}
            {` (${Object.values(selectRow).filter((r) => r).length})`}
          </Button>
          {
            <Tooltip
              isDisabled={selectedTasksWithDocumentHasDocument}
              label={"Select one or more files to activate this action"}
            >
              <Box>
                <MultiFileWidgetActionsMenu
                  isDeleteDisabled={true}
                  isDisabled={!selectedTasksWithDocumentHasDocument}
                  tasks={[props.selectedTask]}
                  selectedFiles={selectRow}
                />
              </Box>
            </Tooltip>
          }
        </Flex>
      )}
      {props.selectedTask.documents &&
        Object.keys(props.selectedTask.documents)
          .sort((ak, bk) => {
            const a = props.selectedTask.documents?.[ak];
            const b = props.selectedTask.documents?.[bk];
            const aT = getTimestampFromFirestoreObject(
              a?.createdAt as TimestampField
            )?.toDate();
            const bT = getTimestampFromFirestoreObject(
              b?.createdAt as TimestampField
            )?.toDate();
            return aT && bT && aT < bT ? 1 : -1;
          })
          .map((key: string, i: number) => {
            const d: DocumentMetadata | undefined =
              props.selectedTask.documents?.[key];

            return (
              !!d &&
              (props.fileKey
                ? currentFileId && d.data?.fileId === currentFileId
                : true) && (
                <Box p="10px" key={i} w="100%" h="100%">
                  <Flex w="100%" h="100%">
                    {!hideCheckAll && (
                      <Flex
                        whiteSpace={"normal"}
                        overflow="visible"
                        onChange={(e) => {
                          e.stopPropagation();
                          key &&
                            setSelectRow({
                              ...selectRow,
                              [key]: !selectRow[key]
                            });
                        }}
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Checkbox
                          size="lg"
                          mr="20px"
                          isChecked={selectRow[key || ""]}
                          variant="darkGrayBorderVariant"
                        />
                      </Flex>
                    )}

                    <Box w="15px" h="100%" pt="20px" pr="5px" pl="5px">
                      <AttachedFieldStatusComponet
                        isDisabled={props.isStatusFieldDisabled}
                        fieldPath={["documents", key]}
                        onChange={onChange}
                        prefix={[props.selectedTask.id]}
                        state={state}
                      />
                    </Box>
                    <Box w="100%">
                      <FileWidget
                        isDeleteDisabled={props.isDeleteDisabled}
                        file={d!}
                        selectedTask={props.selectedTask}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        fileKey={props.fileKey}
                      />
                    </Box>
                  </Flex>
                </Box>
              )
            );
          })}
    </Box>
  );
};

export const TaskFileManagerBody = (props: TaskFileManagerProps) => {
  const { hideUploadedNumber, hideCheckAll, fileKey } = props;
  const {
    uploadMultipleTaskFilesApi,
    uploadMultipleTaskFilesApiResponse,
    createTaskFolderApi,
    createTaskFolderApiResponse
  } = useTaskHooks();
  const { successToast, errorToast } = useElphiToast();
  const losSettings = useLosSettingsContextHooks();
  const { taskFileStorageProviderType } = losSettings;
  return (
    <Box>
      {!hideUploadedNumber && (
        <Box>
          <Text fontSize="sm">
            uploads: {Object.keys(props.selectedTask.documents || {}).length}
          </Text>
        </Box>
      )}
      <Box pl="15px">
        {!props.selectedTask.storageMeta?.box?.folderId &&
        taskFileStorageProviderType === StorageProvider.Box ? (
          <Box>
            <Button
              {...elphiTheme.components.light.button.primary}
              isLoading={createTaskFolderApiResponse.isLoading}
              isDisabled={!!props.snapshotId}
              onClick={() => {
                createTaskFolderApi({
                  taskId: props.selectedTask.id
                })
                  .then(responseHandler)
                  .then((r) => {
                    if (r.status === 200) {
                      successToast({
                        title: "folder created",
                        description: `${props.selectedTask.name}`
                      });
                    } else if (r.status === 400) {
                      errorToast({
                        title: "failed to create folder",
                        description: `${r?.data?.description}`
                      });
                    }
                  });
              }}
            >
              Create Task Folder
            </Button>
          </Box>
        ) : (
          <FileUploader
            isDisabled={!!props.snapshotId}
            isLoading={uploadMultipleTaskFilesApiResponse.isLoading}
            isSingleFile={props.isSingleFile}
            types={props.types}
            onClick={(files) => {
              const filesLength =
                Array.isArray(files) || files instanceof FileList
                  ? files.length
                  : files
                  ? 1
                  : 0;
              if (
                taskFileStorageProviderType === StorageProvider.Box &&
                !props.selectedTask.storageMeta?.box?.folderId
              ) {
                errorToast({
                  title: "unable to upload file",
                  description: `task:${props.selectedTask.name} folder is not ready yet, please try again later`
                });
                return;
              }
              uploadMultipleTaskFilesApi({
                files,
                taskId: props.selectedTask.id,
                folderId:
                  props?.selectedTask?.storageMeta?.box?.folderId || "empty",
                fileKey
              })
                .then(responseHandler)
                .then((r) => {
                  if (r.status === 200) {
                    if (r.data.failedResponses.length < filesLength) {
                      successToast({
                        title: "file uploaded",
                        description: `${
                          filesLength - r.data.failedResponses.length
                        } file(s) uploaded to ${props.selectedTask.name}`
                      });
                    }
                    if (r.data.failedResponses) {
                      r.data.failedResponses.forEach((response) => {
                        errorToast({
                          title: "file upload failed",
                          description: response.payload.description
                        });
                      });
                    }
                  } else if (r.status === 400) {
                    errorToast({
                      title: "file upload failed",
                      description: `${r?.data?.description}`
                    });
                  }
                });
            }}
            loader={"progress"}
          />
        )}
      </Box>

      <FileManagementContainer
        hideCheckAll={hideCheckAll}
        snapshotId={props.snapshotId}
        selectedTask={props.selectedTask}
        fileKey={props.fileKey}
      />
    </Box>
  );
};

type TaskFileManagerProps = {
  selectedTask: TaskWithMetaData;
  snapshotId?: string;
  isSingleFile?: boolean;
  types?: fileTypes[] | fileTypes;
  hideUploadedNumber?: boolean;
  hideCheckAll?: boolean;
  fileKey?: string;
};

export const TaskFileManager = (props: TaskFileManagerProps) => {
  return (
    <Box bgColor={"white"}>
      <ScrollableSections
        customKey="fileManagement"
        sections={[
          {
            header: <SectionHeader header={`File Management`} />,
            body: <TaskFileManagerBody {...props} />
          }
        ]}
      />
    </Box>
  );
};
