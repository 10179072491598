import { Box, BoxProps, Tooltip } from "@chakra-ui/react";
import { NONE } from "../../constants/common";

 const InnerHtmlBox = (props: { htmlAsString?: string }) => {
  try {
    return (
      <Box dangerouslySetInnerHTML={{ __html: props.htmlAsString || NONE }} />
    );
  } catch (error) {
    console.error("InnerHtmlBox error:", error);
    return <></>;
  }
};

 const HtmlBox = (props: {
  htmlAsString?: string;
  boxProps?: BoxProps;
}) => {
  return (
    <Box {...props.boxProps}>
      <InnerHtmlBox htmlAsString={props.htmlAsString} />
    </Box>
  );
};

export const TooltipHtmlBox = (props: {
  htmlAsString?: string;
  boxProps?: BoxProps;
}) => {
  return (
    <>
      <Tooltip label={<InnerHtmlBox htmlAsString={props.htmlAsString} />}>
        <Box>
          <HtmlBox {...props} />
        </Box>
      </Tooltip>
    </>
  );
};
