import { SearchApiV2 } from "../components/search/SearchHandler";

export const useSearchApiWithParamsHooks = () => {
  const searchApiWithParams = <T extends { params: object }>(r: {
    additionalRequestData: T;
    searchApi: SearchApiV2["searchApi"];
  }) => {
    const { additionalRequestData, searchApi } = r;
    return (r: { query: string; cursor?: string | null }, cache: boolean) => {
      return searchApi(
        {
          query: r.query,
          cursor: r.cursor,
          ...additionalRequestData
        },
        cache
      );
    };
  };

  return { searchApiWithParams };
};
