import {
  AggregationFocusType,
  FieldType,
  Statement,
  StatementStatusType
} from "@elphi/types";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../utils/formUtils";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type StatementFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<Statement>
>;
const statementFieldBaseSpecs: StatementFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  aggregations: {
    qualifyingBalance: {
      calculated: {
        fieldKey: ["aggregations", "qualifyingBalance", "calculated"],
        fieldType: FieldType.Money,
        label: "Qualifying Balance"
      },
      override: {
        fieldKey: ["aggregations", "qualifyingBalance", "override"],
        fieldType: FieldType.Money,
        label: "Qualifying Balance"
      },
      focused: {
        fieldKey: ["aggregations", "qualifyingBalance", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  status: {
    fieldType: FieldType.SingleSelect,
    label: "Statement Status",
    fieldKey: ["status"],
    options: createOptionsFromMap(StatementStatusType)
  },
  latestStatement: {
    fieldType: FieldType.Boolean,
    label: "Latest Statement?",
    fieldKey: ["latestStatement"],
    options: booleanOptions
  },
  start: {
    fieldType: FieldType.Date,
    label: "Statement Period Start Date",
    fieldKey: ["start"]
  },
  end: {
    fieldType: FieldType.Date,
    label: "Statement Period End Date",
    fieldKey: ["end"]
  },
  statementBalance: {
    fieldType: FieldType.Money,
    label: "Statement Balance",
    fieldKey: ["statementBalance"]
  },
  unverifiedDeposits: {
    fieldType: FieldType.Money,
    label: "Statement Unverified Deposits",
    fieldKey: ["unverifiedDeposits"]
  },
  percentConsidered: {
    fieldType: FieldType.Percentage,
    label: "Statement % Considered",
    fieldKey: ["percentConsidered"]
  },
  assetId: {
    fieldType: FieldType.String,
    label: "Asset Id",
    fieldKey: ["assetId"]
  }
};

export const statementFieldSpecs = createSpecWithFieldMeta({
  spec: statementFieldBaseSpecs
});
