import { createIcon } from "@chakra-ui/icons";

const customIcon = (fill?: string) =>
  createIcon({
    viewBox: "0 0 18 18",
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="18" height="18" rx="9" fill={fill} />
        <path
          d="M8.56658 14.9659V4.05682H9.26545V14.9659H8.56658ZM10.5822 7.54688C10.5424 7.17472 10.3748 6.88494 10.0794 6.67756C9.78675 6.47017 9.40607 6.36648 8.93732 6.36648C8.60778 6.36648 8.32511 6.41619 8.08931 6.51562C7.85352 6.61506 7.67312 6.75 7.54812 6.92045C7.42312 7.09091 7.3592 7.28551 7.35636 7.50426C7.35636 7.68608 7.39755 7.84375 7.47994 7.97727C7.56516 8.1108 7.68022 8.22443 7.82511 8.31818C7.96999 8.40909 8.1305 8.4858 8.30664 8.5483C8.48278 8.6108 8.66033 8.66335 8.83931 8.70597L9.65749 8.91051C9.98704 8.98722 10.3038 9.09091 10.6078 9.22159C10.9146 9.35227 11.1887 9.51705 11.4302 9.71591C11.6745 9.91477 11.8677 10.1548 12.0098 10.4361C12.1518 10.7173 12.2228 11.0469 12.2228 11.4247C12.2228 11.9361 12.0922 12.3864 11.8308 12.7756C11.5694 13.1619 11.1916 13.4645 10.6973 13.6832C10.2058 13.8991 9.61062 14.0071 8.91175 14.0071C8.23278 14.0071 7.64329 13.902 7.14329 13.6918C6.64613 13.4815 6.25692 13.1747 5.97567 12.7713C5.69727 12.3679 5.5467 11.8764 5.52397 11.2969H7.07937C7.1021 11.6009 7.19585 11.8537 7.36062 12.0554C7.52539 12.2571 7.73988 12.4077 8.00408 12.5071C8.27113 12.6065 8.56942 12.6562 8.89897 12.6562C9.24272 12.6562 9.54386 12.6051 9.80238 12.5028C10.0637 12.3977 10.2683 12.2528 10.416 12.0682C10.5637 11.8807 10.639 11.6619 10.6419 11.4119C10.639 11.1847 10.5723 10.9972 10.4416 10.8494C10.3109 10.6989 10.1277 10.5739 9.89187 10.4744C9.65891 10.3722 9.38619 10.2812 9.07369 10.2017L8.08079 9.94602C7.36204 9.76136 6.79386 9.48153 6.37624 9.10653C5.96147 8.72869 5.75408 8.22727 5.75408 7.60227C5.75408 7.08807 5.89329 6.63778 6.1717 6.25142C6.45295 5.86506 6.83505 5.56534 7.318 5.35227C7.80096 5.13636 8.34783 5.02841 8.95863 5.02841C9.57795 5.02841 10.1206 5.13636 10.5865 5.35227C11.0552 5.56534 11.4231 5.86222 11.6902 6.2429C11.9572 6.62074 12.095 7.0554 12.1035 7.54688H10.5822Z"
          fill="#22543D"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const DollarGreen100Icon = customIcon("#C6F6D5");
