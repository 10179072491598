import { DocumentRuleTemplate } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { documentPackageOrderApi } from "../document-package-order/documentPackageOrder.service";
import { documentRuleEntityAdapter as entityAdapter } from "./documentRule.adapter";
import { documentRuleApi } from "./documentRule.service";

export type DocumentRuleSliceState = EntityState<DocumentRuleTemplate> & {
  selectedId?: EntityId;
};

export const documentRuleSlice = createSlice({
  name: "documentRule",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as DocumentRuleSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(documentRuleApi)(builder);
    builder.addMatcher(
      documentRuleApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
    builder.addMatcher(
      documentRuleApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
    builder.addMatcher(
      documentPackageOrderApi.endpoints.getEntitiesData.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.documentRules.ids);
        state.entities = {
          ...state.entities,
          ...payload.documentRules.entities
        };
      }
    );
  }
});
