import { FieldType, mofinPartyBaseFieldsToOmit } from "@elphi/types";
import { MofinEntityPartyFields } from "@elphi/types/entities/mofin/party";
import { mofinEntityType } from "@elphi/types/entities/mofin/party/mofinParty.enumeration";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromMap } from "../../../../../components/utils/formUtils";
import {
  BaseSchemaEntityPartyFieldSpecs,
  baseSchemaEntityPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs, omitFromSpec } from "../../../utils/formsSpec.utils";
import { mofinCommonPartySpecs } from "./party.fields";

export type FullMofinSchemaEntityPartyFieldSpecs =
  Partial<BaseSchemaEntityPartyFieldSpecs>;

type MofinEntityPartyFieldSpecs = EntityFormFieldSpecs<MofinEntityPartyFields>;

const mofinCommonPartyFieldSpecs: MofinEntityPartyFieldSpecs = {
  EntityType: {
    fieldKey: ["EntityType"],
    fieldType: FieldType.SingleSelect,
    label: "Entity Type",
    options: createOptionsFromMap(mofinEntityType)
  }
};

const mofinEntityPartySpecs: FullMofinSchemaEntityPartyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaEntityPartyFieldSpecs,
    paths: mofinPartyBaseFieldsToOmit
  }),
  mofinCommonPartySpecs,
  mofinCommonPartyFieldSpecs
);

export const fullMofinSchemaEntityPartySpecs = createSpecWithFieldMeta({
  spec: mofinEntityPartySpecs
});
