import { ServiceProviderEntityType } from "@elphi/types";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { PropertyInsurancePolicyModal } from "../../../insurance-policy/property/PropertyInsurancePolicyModal";
import { ModalContainer } from "../../../modal-container/ModalContainer";
import { WizardContainer } from "../../../wizard-container/WizardContainer";
import { EntitiesStepContent } from "../wizard/steps/EntitiesStepContent";
import { ProvidersFormStepContent } from "../wizard/steps/provider-step-content/ProvidersFormStepContent";
import { useServiceProviderEditModalHooks } from "./serviceProviderModal.hooks";
import { ServiceProviderDataModal } from "./serviceProviderModal.utils";

export type EditModalProps = {
  dealId: string;
  isShow: boolean;
  onClose: () => void;
  data: ServiceProviderDataModal;
};

export const ServiceProviderEditModal = (props: EditModalProps) => {
  const { isShow, onClose } = props;
  const {
    companyEditForm,
    branchEditForm,
    repEditForm,
    isProviderFormValid,
    handleProviderFormValidation,
    handleOnSubmit,
    isLoading,
    selectedEntities,
    setSelectedEntities
  } = useServiceProviderEditModalHooks(props);
  const { selectedConfiguration } = useRolodexConfiguration();

  const {
    onChange: onChangeCompanyState,
    state: companyState,
    setState: setCompanyState,
    selectedProviderId: selectedCompanyId,
    setSelectedProviderId: setSelectedCompanyId
  } = companyEditForm;

  const {
    onChange: onChangeBranchState,
    state: branchState,
    setState: setBranchState,
    selectedProviderId: selectedBranchId,
    setSelectedProviderId: setSelectedBranchId
  } = branchEditForm;

  const {
    onChange: onChangeRepState,
    state: repState,
    setState: setRepState,
    selectedProviderId: selectedRepId,
    setSelectedProviderId: setSelectedRepId
  } = repEditForm;

  const providersStep = {
    label: "Providers",
    isValid: isProviderFormValid,
    content: (
      <ProvidersFormStepContent
        companyForm={{
          isReadOnly: true,
          state: companyState,
          onChangeState: onChangeCompanyState,
          setState: (selected) => {
            setCompanyState(selected);
          },
          selectedProviderId: selectedCompanyId,
          setSelectedProviderId: (id) => {
            setSelectedCompanyId(id);
          }
        }}
        branchForm={{
          isReadOnly: true,
          state: branchState,
          onChangeState: onChangeBranchState,
          setState: (selected) => {
            setBranchState(selected);
          },
          selectedProviderId: selectedBranchId,
          setSelectedProviderId: (id) => {
            setSelectedBranchId(id);
          }
        }}
        repForm={{
          isReadOnly: true,
          state: repState,
          onChangeState: onChangeRepState,
          setState: (selected) => {
            setRepState(selected);
          },
          selectedProviderId: selectedRepId,
          setSelectedProviderId: (id) => {
            setSelectedRepId(id);
          }
        }}
        onFormValidation={handleProviderFormValidation}
        showAttached={false}
      />
    )
  };

  const entitiesStep = {
    label: "Entities",
    isValid: !!selectedEntities.length,
    content: (
      <EntitiesStepContent
        currentValue={selectedEntities}
        onChange={setSelectedEntities}
      />
    )
  };

  const isInsurancePolicyDomainConfig =
    selectedConfiguration?.entityType ===
    ServiceProviderEntityType.InsurancePolicy;
  if (isInsurancePolicyDomainConfig) {
    const branchRepId = selectedBranchId
      ? `${selectedBranchId}_${selectedRepId}`
      : undefined;
    return (
      <PropertyInsurancePolicyModal
        showDomainStep={false}
        isOpen={props.isShow}
        onClose={props.onClose}
        dealId={props.dealId}
        branchRepId={branchRepId}
      />
    );
  }

  return (
    <ModalContainer
      maxWidth={1110}
      isShow={isShow}
      onCloseModal={onClose}
      header={"Edit a Service Provider"}
      body={
        <WizardContainer
          height={500}
          steps={[providersStep, entitiesStep]}
          onCancel={onClose}
          onSubmit={handleOnSubmit}
          isLoading={isLoading}
        />
      }
    />
  );
};
