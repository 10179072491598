import { createSlice } from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { losUserApi } from "../los-user/losUser.service";
import { partyApi } from "../party/party.service";
import { partyUserEntityAdapter as entityAdapter } from "./partyUserRelation.adapter";
import { partyUserRelationApi } from "./partyUserRelation.service";

export const partyUserRelationSlice = createSlice({
  name: "partyUserRelation",
  initialState: entityAdapter.getInitialState(),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(partyUserRelationApi)(builder);
    builder.addMatcher(
      losUserApi.endpoints.userParties.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.partyUsers.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.relations.ids);
        state.entities = { ...state.entities, ...payload.relations.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.partyUser.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page.partyUser, "id")
        };
      }
    );
  }
});
