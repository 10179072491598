import { ElphiEntityType, IntegrationType, LabelValue } from "@elphi/types";
import { entries, isArray, keys, mergeWith, omit } from "lodash";
import { InputBuilderFieldSpecs } from "../../../components/form-builder/field-specs/fields.types";
import { NOT_AVAILABLE } from "../../../constants/common";
import { DynamicSchema, DynamicSections } from "../types";

type MergeReturnType<T extends object[]> = T extends [
  infer U extends object,
  ...infer R extends object[]
]
  ? U & MergeReturnType<R>
  : {};

export const omitFromSpec = <T extends object>(r: {
  obj: T;
  paths: string[];
}) => {
  return omit(r.obj, r.paths);
};

export const mergeSpecs = <T extends object[]>(
  ...objects: T
): MergeReturnType<T> => {
  return mergeWith({}, ...objects, (_, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue;
    }
  });
};

export const modifyFromSpec = <T extends InputBuilderFieldSpecs<any>>(r: {
  fieldSpecs?: T;
  changes: Partial<InputBuilderFieldSpecs<any>>;
}) => {
  const { fieldSpecs, changes } = r;
  if (!fieldSpecs) {
    console.error("fieldSpecs is not defined");
    return {} as T;
  }

  return { ...fieldSpecs, ...changes };
};

export const getMilestoneOptions = (
  specs: DynamicSchema["specs"]
): Array<LabelValue> => {
  const options =
    specs?.deal?.entitySpecs?.DealMetadata?.milestone?.options || [];

  return options;
};

export const getLoanProgramOptions = (
  specs: DynamicSchema["specs"]
): Array<LabelValue> => {
  const options =
    specs?.deal?.entitySpecs?.aggregations?.LoanProgramType?.override
      ?.options || [];

  return options;
};

export const getOptionLabel = (
  options: Array<LabelValue>,
  selectedOption?: string
): string => {
  const option = options.find((o) => o.value === selectedOption);
  return option?.label || NOT_AVAILABLE;
};

export type IntegrationTypes = Record<
  ElphiEntityType,
  { vendor: string; integrationType: IntegrationType }[]
>;

export const getIntegrationTypes = (sections?: DynamicSections) => {
  return entries(sections?.integrations).reduce(
    (acc, [entityType, section]) => {
      acc[entityType] = keys(section).flatMap((type: IntegrationType) => {
        return keys(section[type]).map((vendor) => ({
          vendor,
          integrationType: type
        }));
      });
      return acc;
    },
    {} as IntegrationTypes
  );
};
