import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  AppraisalValueDerivationType,
  BillingFrequencyType,
  InspectionInitialDelayReasonStatusType,
  InspectionOrderType,
  LeaseStrategyType,
  PortfolioTemplateType,
  Property,
  PropertyLoanPurpose,
  PropertyRightsOwnershipType,
  PropertyZoningComplianceRatingType,
  getPropertyTemplate
} from "../../entities";
import {
  baseAppraisalType,
  baseConditionRating,
  baseConstructionBudgetAssessment,
  baseLeaseStatus,
  basePropertyType
} from "../../entities/base";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { BaseAddressSchema } from "../location.typebox";
import {
  AggregationFieldSchema,
  ArrayField,
  BooleanField,
  DateField,
  DecimalField,
  EmailField,
  EnumField,
  IntegerField,
  MoneyAmountField,
  NumberField,
  PercentField,
  PhoneNumberField,
  StringField
} from "../utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "../utils/fieldMeta.utils";

const propertyAddressFieldsSchema = Type.Object({
  PropertyIdentifier: StringField,
  LegalDescription: StringField,
  FullAddressName: StringField,
  PropertyLotIdentifier: StringField,
  PropertyBlockIdentifier: StringField,
  PropertySubdivisionIdentifier: StringField
});

const propertyAddressSchema = Type.Intersect([
  BaseAddressSchema,
  propertyAddressFieldsSchema
]);

export const BasePropertyInsuranceFieldsSchema = Type.Object({
  InsurancePremiumAmount: MoneyAmountField,
  InsurancePremiumOutstandingAmount: MoneyAmountField,
  NextPaymentDueDate: DateField
});

export const BaseFloodInsuranceFieldsSchema = Type.Object({
  InsurancePremiumAmount: MoneyAmountField,
  InsurancePremiumOutstandingAmount: MoneyAmountField,
  NextPaymentDueDate: DateField
});

const appraisalFieldsSchema = Type.Object({
  AppraisalValueDerivationType: EnumField(AppraisalValueDerivationType),
  AppraisalOrderedDate: DateField,
  AppraisalCompletedDate: DateField,
  AppraisalEffectiveDate: DateField,
  AppraisalExpirationDate: DateField,
  AppraisalType: EnumField(baseAppraisalType),
  ConditionRatingType: EnumField(baseConditionRating),
  TotalBedroomCount: DecimalField,
  TotalBathroomCount: DecimalField,
  PropertyStructureBuiltYear: IntegerField,
  AppraisalDate: DateField,
  PropertyAreaValue: NumberField,
  PropertyBuiltYear: IntegerField,
  SpecificZoningClassification: StringField,
  PropertyZoningDescription: StringField,
  PropertyZoningComplianceRatingType: EnumField(
    PropertyZoningComplianceRatingType
  ),
  PropertyValuationAmount: MoneyAmountField,
  SubjectToPropertyValuationAmount: MoneyAmountField,
  PropertyAccessInformation: StringField,
  AppraisalForm1007RequiredIndicator: BooleanField
});

const appraisalManagementCompanySchema = Type.Object({
  RepresentativeFullName: StringField,
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  AppraisalManagementCompanyName: StringField
});

const sheetMetadataSchema = Type.Object({
  type: EnumField(PortfolioTemplateType)
});

const aggregationsLeasedFinancedUnitTemplateSchema = Type.Object({
  AdjustedRentAmount: AggregationFieldSchema(MoneyAmountField)
});

const aggregationsLeasedFinancedUnitsSchema = Type.Object({
  unit0: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit1: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit2: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit3: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit4: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit5: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit6: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit7: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema),
  unit8: Type.Partial(aggregationsLeasedFinancedUnitTemplateSchema)
});

export const elphiLeasedFinancedUnitTemplateSchema = Type.Object({
  UnitName: StringField,
  MonthlyLeaseRentAmount: MoneyAmountField,
  MonthlyMarketRentAmount: MoneyAmountField,
  AdjustedRentAmount: MoneyAmountField,
  LeaseStatusType: EnumField(baseLeaseStatus),
  LeaseExpirationDate: DateField,
  RentReportConfidenceScore: NumberField
});

const leasedFinancedUnitsSchema = Type.Object({
  unit0: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit1: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit2: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit3: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit4: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit5: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit6: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit7: Type.Partial(elphiLeasedFinancedUnitTemplateSchema),
  unit8: Type.Partial(elphiLeasedFinancedUnitTemplateSchema)
});

const propertyInspectionFieldsSchema = Type.Object({
  InspectionInitialRequestDate: DateField,
  InspectionReportDueDate: DateField,
  InspectionOrderType: EnumField(InspectionOrderType),
  InspectorFullName: StringField,
  InspectionOrderReceivedDate: DateField,
  InspectionOrderProcessedDate: DateField,
  InspectionDocumentsReceivedDate: DateField,
  InspectionInitialDelayReasonStatusType: ArrayField(
    EnumField(InspectionInitialDelayReasonStatusType)
  )
});

//TODO:change the template
const fieldMetaSchema = buildFieldMetadata<Property, TObject<TProperties>>({
  entityTemplate: getPropertyTemplate(),
  target: FieldMetaTarget.Schema
});

export const basePropertyAggregationFieldsSchema = Type.Object({
  CountyInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  CountyInitialTaxEscrowMonthCount: AggregationFieldSchema(IntegerField),
  CityInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  CityInitialTaxEscrowMonthCount: AggregationFieldSchema(IntegerField),
  InitialFloodInsuranceEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  InitialFloodInsuranceEscrowMonthCount: AggregationFieldSchema(IntegerField),
  InitialPropertyInsuranceEscrowAmount:
    AggregationFieldSchema(MoneyAmountField),
  InitialPropertyInsuranceEscrowMonthCount:
    AggregationFieldSchema(IntegerField),
  SpecialAssessmentInitialTaxEscrowAmount:
    AggregationFieldSchema(MoneyAmountField),
  SpecialAssessmentInitialTaxEscrowMonthCount:
    AggregationFieldSchema(IntegerField),
  MonthlyLeaseRentAmount: AggregationFieldSchema(MoneyAmountField),
  TotalInsuranceEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  TotalTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  MonthlyMarketRentAmount: AggregationFieldSchema(MoneyAmountField),
  AdjustedRentAmount: AggregationFieldSchema(MoneyAmountField),
  AnnualTaxAmount: AggregationFieldSchema(MoneyAmountField),
  AllocatedLoanAmount: AggregationFieldSchema(MoneyAmountField),
  TotalCostAmount: AggregationFieldSchema(MoneyAmountField),
  LTCRatePercent: AggregationFieldSchema(PercentField),
  MinimumRentLossCoverageAmount: AggregationFieldSchema(MoneyAmountField),
  LeasedFinancedUnits: Type.Partial(aggregationsLeasedFinancedUnitsSchema)
});

const propertyFieldsSchema = Type.Object({
  FieldMeta: Type.Partial(fieldMetaSchema),
  aggregations: Type.Partial(basePropertyAggregationFieldsSchema),
  Address: Type.Partial(propertyAddressSchema),
  Appraisal: Type.Partial(appraisalFieldsSchema),
  AppraisalManagementCompany: Type.Partial(appraisalManagementCompanySchema),
  PropertyType: EnumField(basePropertyType),
  PropertyInspection: Type.Partial(propertyInspectionFieldsSchema),
  CondoWarrantableIndicator: BooleanField,
  FinancedUnitCount: IntegerField,
  PropertyLoanPurpose: EnumField(PropertyLoanPurpose),
  PurchasePriceAmount: MoneyAmountField,
  ContractClosingDate: DateField,
  OriginalPurchaseDate: DateField,
  OriginalPurchasePriceAmount: MoneyAmountField,
  OutstandingLoanPayoffAmount: MoneyAmountField,
  LeaseStatusType: EnumField(baseLeaseStatus),
  LeaseExpirationDate: DateField,
  AnnualTaxesAmount: MoneyAmountField,
  TaxDueDate: DateField,
  AnnualHomeownersAssociationFeeAmount: MoneyAmountField,
  DeferredMaintenanceAmount: MoneyAmountField,
  ParcelNumber: StringField,
  RenovationCostAmount: MoneyAmountField,
  ConstructionCostAmount: MoneyAmountField,
  ConstructionCostComments: StringField,
  ConstructionHoldbackAmount: MoneyAmountField,
  GroundLeaseExpirationDate: DateField,
  LTARVAmount: MoneyAmountField,
  NetFundAmount: MoneyAmountField,
  NetFundRatePercent: PercentField,
  AllocatedRDSRRatePercent: PercentField,
  PropertyRightsOwnershipType: EnumField(PropertyRightsOwnershipType),
  LeasedFinancedUnits: Type.Partial(leasedFinancedUnitsSchema),
  RentReportConfidenceScorePercent: PercentField,
  TotalRentalIncomeAmount: MoneyAmountField,
  CountyAnnualTaxAmount: MoneyAmountField,
  CountyTaxDueDate: DateField,
  CountyTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  CountyTaxingAuthority: StringField,
  CityAnnualTaxAmount: MoneyAmountField,
  CityTaxDueDate: DateField,
  CityTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  CityTaxingAuthority: StringField,
  SpecialAssessmentAnnualTaxAmount: MoneyAmountField,
  SpecialAssessmentTaxDueDate: DateField,
  SpecialAssessmentTaxBillingFrequencyType: EnumField(BillingFrequencyType),
  SpecialAssessmentTaxingAuthority: StringField,
  CapitalExpenditureAmount: MoneyAmountField,
  PSASignedIndicator: BooleanField,
  PSAExtensionRequestedIndicator: BooleanField,
  PSAAssignmentContractIndicator: BooleanField,
  AssignmentFeeAmount: MoneyAmountField,
  PSAExpirationDate: DateField,
  PayoffExpirationDate: DateField,
  BorrowerRenovationCostsAfterPurchaseAmount: MoneyAmountField,
  NetWireAmount: MoneyAmountField,
  LeaseStrategy: EnumField(LeaseStrategyType),
  RentalLTVPercent: PercentField,
  SheetMetadata: Type.Partial(sheetMetadataSchema),
  ConstructionBudgetAssessment: EnumField(baseConstructionBudgetAssessment),
  AllocatedLoanAmount: MoneyAmountField,
  FloodInsurance: Type.Partial(BaseFloodInsuranceFieldsSchema),
  PropertyInsurance: Type.Partial(BasePropertyInsuranceFieldsSchema),
  InitialFundedAmount: MoneyAmountField,
  PayoffInterestDays: StringField
});

export const elphiPropertySchema = Type.Intersect([
  BaseEntitySchema,
  propertyFieldsSchema
]);
