import { Box, Center, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  FieldType,
  Task,
  TaskFlagType,
  TaskStatusType,
  statusToColor
} from "@elphi/types";

import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { TaskFormState } from "../common/types";
import { OnChangeInput } from "../form-builder/FormBuilder";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { FlagIcon } from "../icons/FlagIcon";
import { createOptionsFromMap } from "../utils/formUtils";

export const TaskStatusDropDown = (props: {
  state: TaskFormState;
  isDisabled?: boolean;
  selectedTask: Task;
  onChange: (v: OnChangeInput) => void;
}) => {
  const currentValue = props.state.tasks?.[props.selectedTask.id]?.taskStatus
    ? props.state.tasks?.[props.selectedTask.id]?.taskStatus
    : TaskStatusType.Created;
  const flaggedValue = props.state.tasks?.[props.selectedTask.id]?.flagged;
  return (
    <Flex w="100%">
      <Box w="300px" onClick={(e) => e.stopPropagation()}>
        <StyledInputBuilder
          label="Status"
          isDisabled={props.isDisabled}
          currentValue={
            props.state.tasks?.[props.selectedTask.id]?.taskStatus
              ? props.state.tasks?.[props.selectedTask.id]?.taskStatus
              : TaskStatusType.Created
          }
          fieldType={FieldType.SingleSelect}
          onChange={(e) =>
            props.onChange({
              fieldType: FieldType.String,
              fieldKey: ["tasks", props.selectedTask.id, "taskStatus"],
              value: e.target.value
            })
          }
          options={
            props.state.tasks?.[props.selectedTask.id]?.availableStatus?.map(
              (k) => {
                return {
                  label: k,
                  value: k
                };
              }
            ) || createOptionsFromMap(TaskStatusType)
          }
          isValid={true}
          chakraStyles={{
            control: (provided) => {
              if (statusToColor[currentValue || "Created"] !== "white") {
                return {
                  ...provided,
                  borderColor: statusToColor[currentValue || "Created"]
                };
              }
              return { ...provided };
            },
            dropdownIndicator: (provided) => ({
              ...provided,
              background: statusToColor[currentValue || "Created"], //"#A015D7",
              color: "black"
            }),
            clearIndicator: (provided) => ({
              ...provided,
              background: "white", //statusToColor[currentValue || "Created"], //"#A015D7",
              color: "black",
              h: "100%",
              p: "10px"
            }),
            placeholder: (provided, _) => ({
              ...provided,
              background: statusToColor[currentValue || "Created"], //"#A015D7",
              color: "black",
              textAlign: "center",
              w: "100%",
              p: 0
            }),
            valueContainer: (provided, _) => ({
              ...provided,
              background: "white", //statusToColor[currentValue || "Created"], //"#A015D7",
              color: "black",
              fontWeight: "bold",
              justifyContent: "center",
              w: "100%",
              p: 0
            })
          }}
        />
      </Box>
      <Center h="100%" pl="10px">
        <Tooltip
          aria-label="flagged-button"
          label={
            props.isDisabled
              ? "not allowed"
              : flaggedValue
              ? "remove flag"
              : "click to flag"
          }
        >
          <IconButton
            bgColor="transparent"
            aria-label="flagged-button"
            isDisabled={props.isDisabled}
            icon={
              <FlaggedTaskIndicator
                w="30px"
                h="30px"
                isFlagged={flaggedValue}
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              props.onChange({
                fieldType: FieldType.String,
                fieldKey: ["tasks", props.selectedTask.id, "flagged"],
                value: flaggedValue ? TaskFlagType.None : TaskFlagType.Attention
              });
            }}
          />
        </Tooltip>
      </Center>
    </Flex>
  );
};

export const FlaggedTaskIndicator = (props: {
  isFlagged?: TaskFlagType;
  w: string;
  h: string;
}) => {
  return (
    <FlagIcon
      fill={
        props.isFlagged
          ? elphiTheme.components.light.icons.warningIcon.on
          : elphiTheme.components.light.icons.warningIcon.off
      }
      w={props.w}
      h={props.h}
    />
  );
};
