import { Box } from "@chakra-ui/react";
import {
  Deal,
  FloodCertificateVendorType,
  IntegrationType,
  Property
} from "@elphi/types";
import { concat } from "lodash";
import { useEffect } from "react";
import { AppConfig } from "../../config/appConfig";
import { EMPTY } from "../../constants/common";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { InsurancePolicySection } from "./InsurancePolicySection";

const SectionsAfterTables = (props: {
  dealState?: Deal;
  selectedProperty?: Property;
  snapshotId?: string;
  navigationPath?: NavigationPath;
}) => {
  const { selectedProperty, navigationPath } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();
  const { sections } = useSections();
  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty && !props.snapshotId) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyState =
    (selectedProperty && state.properties[selectedProperty.id]) || {};

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !props.snapshotId &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };
  const { property: propertySections, integrations: integrationsSections } =
    sections || {};

  const propertyInsuranceSection = [
    propertySections?.floodInsuranceSection?.({
      state: propertyState,
      onChange: propertyOnChangeBatch
    }),
    propertySections?.propertyInsuranceSection?.({
      state: propertyState,
      dealState: props.dealState,
      onChange: propertyOnChangeBatch
    })
  ];

  const floodInsuranceSections = integrationsSections?.property?.[
    IntegrationType.FloodCertificate
  ]?.[FloodCertificateVendorType.ServiceLink]?.floodCertificationSection?.({
    state: propertyState,
    onChange: propertyOnChangeBatch
  });
  const sectionsAfterTables = concat(
    floodInsuranceSections,
    propertyInsuranceSection
  );
  return (
    <Box h="100%" w="100%">
      {selectedProperty && (
        <Box h="100%" w="100%">
          <Box bgColor="white" h="100%" w="100%">
            <FormBuilder
              customKey="sectionsAfterTables"
              onChange={propertyOnChangeBatch}
              sections={sectionsAfterTables}
              navigationPath={navigationPath}
            />
            <InsurancePolicySection
              propertyId={selectedProperty.id}
              dealId={
                props.dealState?.id ||
                selectedProperty?.dealRelation?.at(0) ||
                EMPTY
              }
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default SectionsAfterTables;
