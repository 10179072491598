import {
  booleanValidation,
  dateValidation,
  singleSelectValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";

export const achStatus = buildInput({
  fieldKey: mofinSpecs.deal?.entitySpecs?.ACHStatus?.fieldKey,
  validation: singleSelectValidation
});
export const mERSLoanIndicator = buildInput({
  fieldKey: mofinSpecs.deal?.entitySpecs?.MERSLoanIndicator?.fieldKey,
  validation: booleanValidation
});
export const receivedRecordablesIndicator = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.ReceivedRecordablesIndicator?.fieldKey,
  validation: booleanValidation
});
export const sentRecordablesToCustodianDate = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.SentRecordablesToCustodianDate?.fieldKey,
  validation: dateValidation
});
export const receivedFinalTitlePolicyIndicator = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.ReceivedFinalTitlePolicyIndicator?.fieldKey,
  validation: booleanValidation
});

export const finalTitlePolicyToCustodianDate = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.FinalTitlePolicyToCustodianDate?.fieldKey,
  validation: dateValidation
});
export const insuranceMortgageeStatus = buildInput({
  fieldKey: mofinSpecs.deal?.entitySpecs?.InsuranceMortgageeStatus?.fieldKey,
  validation: singleSelectValidation
});
export const insuranceMortgageeRequestDate = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.InsuranceMortgageeRequestDate?.fieldKey,
  validation: dateValidation
});
export const insuranceMortgageeRequestFollowUpDate = buildInput({
  fieldKey:
    mofinSpecs.deal?.entitySpecs?.InsuranceMortgageeRequestFollowUpDate
      ?.fieldKey,
  validation: dateValidation
});

export const warehouseBankStatusType = buildInput({
  fieldKey: mofinSpecs.deal?.entitySpecs?.WarehouseBankStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const dealMetadatamilestone = buildInput({
  fieldKey: mofinSpecs.deal?.entitySpecs.DealMetadata?.milestone?.fieldKey
});
