import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useMemo } from "react";
import { ElphiTableProps } from "./table.types";
const HEIGHT = "73px";
const useSkeletonTable = (
  props: Pick<ElphiTableProps, "header"> & {
    rowsCount: number;
    minH: string;
    maxH: string;
  }
) => {
  return useMemo(() => {
    const rows = Array(props.rowsCount).fill(0);
    const columns = Array(props.header.length).fill(0);
    return rows.map((_, i) => {
      return (
        <Tr key={i}>
          {columns.map((_, j) => {
            return (
              <Td
                key={j}
                h={props.minH || HEIGHT}
                minH={props.minH || HEIGHT}
                maxH={props.maxH || HEIGHT}
                w={props.header[j].minWidth || "200px"}
              >
                <Skeleton height="10px">
                  <SkeletonText />
                </Skeleton>
              </Td>
            );
          })}
        </Tr>
      );
    });
  }, [props.rowsCount, props.header]);
};
export const ElphiTable = (props: ElphiTableProps) => {
  const skeletonTable = useSkeletonTable({
    rowsCount: props.rowsCount,
    header: props.header,
    maxH: "73px",
    minH: "73px"
  });
  const items = props.rows.map((r, i) => {
    return (
      <Tr
        key={i}
        minH={r.minHeight || HEIGHT}
        maxH={r.maxHeight || HEIGHT}
        h={r.minHeight || HEIGHT}
        bgColor={r.selected ? "#DBE7FF" : "white"}
        cursor={props.rowCursor}
        onClick={() => (r.rowOnClick ? r.rowOnClick() : () => {})}
      >
        {r.cells
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((c, i) => {
            return (
              <Td
                maxW={c.maxWidth || "200px"}
                minW={c.minWidth || "200px"}
                key={i}
              >
                {c.data}
              </Td>
            );
          })}
      </Tr>
    );
  });
  return (
    <Box h="100%" w="100%">
      <TableContainer
        minHeight={props.minHeight || "550px"}
        maxHeight={props.maxHeight || "550px"}
        overflowY="scroll"
        w="100%"
      >
        <Table w="100%">
          <Thead position="sticky" top="0" bgColor="white" zIndex="3" w="100%">
            <Tr w="100%">
              {props.header
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((c) => {
                  return (
                    <Th
                      maxW={c.maxWidth || "200px"}
                      minW={c.minWidth || "200px"}
                      w={c.minWidth || "200px"}
                      verticalAlign={c.verticalAlign || ""}
                      key={c.index}
                    >
                      <Flex direction={"column"}>
                        {c?.topData && <Box w="100%">{c.topData}</Box>}
                        <Center w="100%">{c.data}</Center>
                        {c?.bottomData && <Box w="100%">{c.bottomData}</Box>}
                      </Flex>
                    </Th>
                  );
                })}
            </Tr>
          </Thead>
          <Tbody>
            {
              props.isLoading ? skeletonTable : items
              // <ViewportList items={items} itemMinSize={10}>
              //   {(item) => item}
              // </ViewportList>
            }
          </Tbody>
          <Tfoot>{props.aggregationFooter}</Tfoot>
        </Table>
      </TableContainer>
      {props.footer}
    </Box>
  );
};
