export const rowIndexStyles = {
  maxWidth: "200px",
  minWidth: "200px"
};

export const timestampStyles = {
  maxWidth: "200px",
  minWidth: "200px"
};

export const taskNameStyles = {
  maxWidth: "400px",
  minWidth: "400px"
};

export const taskStatusStyles = {
  maxWidth: "400px",
  minWidth: "400px"
};

export const orderStatusStyles = {
  maxWidth: "250px",
  minWidth: "250px"
};
