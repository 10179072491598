import { Status } from "../common";
import { BaseEntity } from "../db";
import { StateCode } from "./location.types";

export enum TemplateCategory {
  General = "general",
  Closing = "closing",
  Notary = "notary",
  StateClause = "state-clause"
}

export type DocumentTemplateStateCode = StateCode | "default";

export type DocumentTemplate = BaseEntity<{
  name: string;
  stateCode?: DocumentTemplateStateCode;
  templateCategory: TemplateCategory;
  fileName: string;
  folderPath: string;
  fullPath: string;
  status: Status;
}>;
