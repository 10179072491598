import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Input,
  Progress,
  Tooltip
} from "@chakra-ui/react";
import { every, isArray } from "lodash";
import { useEffect, useRef, useState } from "react";
import { fileTypes } from "../../firebase/constants";

import "./css/fileInput.css";

type FileUploaderProps = {
  onClick: (files: FileList | File) => void;
  isLoading: boolean;
  isDisabled?: boolean;
  loader?: "progress";
  isSingleFile?: boolean;
  types?: fileTypes[] | fileTypes;
  shouldUseCancelButton?: boolean;
  label?: string;
};

export const FileUploader = (props: FileUploaderProps) => {
  const [_, setFiles] = useState<FileList | File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isLoading === false) {
      clear();
    }
  }, [props.isLoading]);

  const clear = () => {
    setFiles(undefined);
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = "";
    }
  };

  const isAllFilesHaveTargetTypes = (files) => {
    if (props.types) {
      const targetTypes = isArray(props.types) ? props.types : [props.types];
      const targetFiles = isArray(files) ? files : [files];

      return every(targetFiles, (file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        return targetTypes.includes(fileExtension);
      });
    }
    return true;
  };

  const onChange = (e) => {
    const f =
      (props.isSingleFile ? e?.target?.files?.[0] : e?.target?.files) ||
      undefined;
    const isAllFilesCurrect = isAllFilesHaveTargetTypes(f);
    if (isAllFilesCurrect) {
      setFiles(f);
      f && props.onClick(f);
    } else {
      clear();
    }
  };

  const formatType = (types: fileTypes | fileTypes[]) => {
    if (Array.isArray(types)) {
      return types.map((item) => item.toUpperCase()).join(" or ");
    } else if (typeof types === "string") {
      return types.toUpperCase();
    } else {
      return "Invalid type";
    }
  };

  const enableMessage = !!props.types
    ? `You can only upload ${props.isSingleFile ? "1" : ""} ${formatType(
        props.types
      )} file${!props.isSingleFile ? "s" : ""} `
    : "drag a file or click to choose";

  //should have selected ID
  return (
    <Flex w="100%" p="10px">
      <Box pl="2px" pt="20px" w="100%">
        <Tooltip label={props.isDisabled ? "not allowed" : enableMessage}>
          <Flex direction={"column"} gap={2}>
            <Box as="label">{props.label}</Box>
            <Input
              borderStyle={"dashed"}
              borderWidth={"thin"}
              borderColor={"grey.100"}
              height={"80px"}
              name="files"
              title=""
              isDisabled={props.isDisabled || props.isLoading}
              type="file"
              multiple
              ref={fileInputRef}
              onChange={onChange}
            />
          </Flex>
        </Tooltip>
        {props.isLoading && props.loader === "progress" && (
          <Progress size="xs" isIndeterminate />
        )}
      </Box>
      {props.shouldUseCancelButton && (
        <Box pl="10px" pt="37px">
          <IconButton
            size="sm"
            aria-label="clear-file-select"
            icon={<CloseIcon />}
            bgColor="transparent"
            onClick={() => {
              setFiles(undefined);
              if (fileInputRef?.current?.value) {
                fileInputRef.current.value = "";
              }
            }}
            isDisabled={props.isDisabled}
          />
        </Box>
      )}
    </Flex>
  );
};
