import { startCase, toLower } from "lodash";
import { useEffect, useRef } from "react";
import { NOT_AVAILABLE } from "../constants/common";

export const usePrevious = (value?: string | number) => {
  // The ref object is a generic container whose current property is mutable
  //    and can hold any value, similar to an instance property on a class
  const ref = useRef<undefined | string | number>();
  // Store current value in ref. Only re-run if value changes
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const getStringValueOrDefault = (
  value?: string | null,
  defaultValue = NOT_AVAILABLE
) => {
  return value || defaultValue;
};

export const capitalize = (s: string) => startCase(toLower(s));

export const toFirebaseId = (s: string) => s.toLowerCase().replaceAll(" ", "_");

export const fromFirebaseIdToLabel = (s: string) => {
  return capitalize(s).replaceAll("_", " ");
};

export const capitalizePhrase = (s: string[]) =>
  s.map((x) => startCase(toLower(x))).join(" ");

export const sequentializeIndexes = <T extends { index: number }>(
  data: Record<string, T>
): Record<string, T> => {
  return Object.entries(data)
    .sort(([, a], [, b]) => a.index - b.index)
    .reduce((acc, [key, value], index) => {
      value.index = index;
      acc[key] = value;
      return acc;
    }, {} as Record<string, T>);
};
