import { IntegrationType, Task, TaskType } from "@elphi/types";

import { PropertyReportTaskIcon } from "./integrations/property-report/PropertyReportTaskIcon";
import { WireInsuranceTaskIcon } from "./integrations/wire-insurance/WireInsuranceTaskIcon";
import { TaskStatusIconToolTip } from "./TaskStatusIconToolTip";

type OrderStatusTaskIconProps = {
  task?: Task;
};

const overrideTaskIconComponents = {
  [TaskType.Integration]: {
    [IntegrationType.WireInsuranceCertificate]: WireInsuranceTaskIcon,
    [IntegrationType.PropertyReport]: PropertyReportTaskIcon
  }
};

export const OrderStatusTaskIcon = ({ task }: OrderStatusTaskIconProps) => {
  if (!task) return null;

  const TaskIconIntegrationComponent =
    (task.type === TaskType.Integration &&
      overrideTaskIconComponents?.[task.type]?.[task.integrationType]) ||
    null;

  return task.type === TaskType.Integration ? (
    TaskIconIntegrationComponent ? (
      <TaskIconIntegrationComponent selectedTask={task} />
    ) : (
      <TaskStatusIconToolTip selectedTask={task} />
    )
  ) : null;
};
