import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityState } from "@reduxjs/toolkit";

export const filterEntities = <T>(r: {
  entityState: EntityState<T>;
  key: keyof T;
  value: string | string[];
}): T[] => {
  const { entityState, key, value } = r;
  if (!Array.isArray(value)) {
    return Object.values(entityState.entities)
      .filter((s) => s && s[key] === value)
      .filter(removeEmpty);
  } else {
    return Object.values(entityState.entities)
      .filter((s) => s && value.includes(s[key.toString()]))
      .filter(removeEmpty);
  }
};
