import { FirebaseFilter, RolodexConfiguration } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo, useState } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import { FilterCellComponent } from "../../../../../table/FilterCellComponent";
import { SIZE_FIELD } from "../../../../../table/TableRowSizeComponent";
import {
  CellsFilterStateFlat,
  useFilterHook
} from "../../../../../table/hooks/filter.hook";
import {
  ElphiCellType,
  ElphiTableProps
} from "../../../../../table/table.types";
import { filterStyle } from "../../../../../utils/filter.utils";

const filterState: CellsFilterStateFlat<RolodexConfiguration> = {
  createdAt: {
    column: "Created At",
    orderBy: "asc",
    query: []
  },
  name: {
    column: "Service Domain Name",
    orderBy: "asc",
    query: []
  },
  entityType: {
    column: "Service Provider Entity",
    orderBy: "asc",
    query: []
  },
  companyFields: {
    column: "Company Fields",
    orderBy: "asc",
    query: []
  },
  branchFields: {
    column: "Branch Fields",
    orderBy: "asc",
    query: []
  },
  representativeFields: {
    column: "Representative Fields",
    orderBy: "asc",
    query: []
  },
  status: {
    column: "Status",
    orderBy: "asc",
    query: []
  }
};

export const useTableHeaderHooks = () => {
  const [filteredIds, setFilteredIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<{
    filter?: FirebaseFilter | FirebaseFilter[];
  }>();

  const { paginationHandler, configurationState } = useRolodexConfiguration();

  const {
    setStateDebouncer: tableFilterSetter,
    setStateInputDebouncer: tableInputSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState,
    getFilteredAndSortDataIds
  } = useFilterHook<
    RolodexConfiguration,
    CellsFilterStateFlat<RolodexConfiguration>
  >({
    state: filterState,
    options: {
      debounceRate: 100
    },
    searchApi: () => {},
    onNextFilter: (filter) => {
      setFilters(filter);
      if (filter) {
        paginationHandler.next(filter);
      } else {
        paginationHandler.reset();
      }
    }
  });

  useEffect(() => {
    const ids = getFilteredAndSortDataIds(configurationState);
    setFilteredIds(ids);
  }, [configurationState.ids, tableFilterState]);

  const data = Object.values(configurationState.entities).filter(removeEmpty);

  const headerCells: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "",
        type: ElphiCellType.String,
        size: SIZE_FIELD.S
      },
      {
        index: 1,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            data={[]}
            disabled
            tooltipLabel="Not Supported Yet"
            fieldKey={"createdAt"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 2,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            data={data}
            fieldKey={"name"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 3,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            data={data}
            fieldKey={"entityType"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 4,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            disabled
            tooltipLabel="Not Supported Yet"
            data={[]}
            fieldKey={"companyFields"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 5,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            disabled
            tooltipLabel="Not Supported Yet"
            data={[]}
            fieldKey={"branchFields"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 6,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            disabled
            tooltipLabel="Not Supported Yet"
            data={[]}
            fieldKey={"representativeFields"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 7,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<RolodexConfiguration>
            data={data}
            fieldKey={"status"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            showOrderState={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 8,
        data: EMPTY,
        type: ElphiCellType.String,
        size: SIZE_FIELD.S
      }
    ];
  }, [data, paginationHandler?.pageData?.entities, tableFilterState]);

  return {
    filters,
    filteredIds,
    headerCells
  };
};
