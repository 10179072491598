import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer
} from "@chakra-ui/react";
import { debounce, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { FilterMenuOptions } from "../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../common-components/menu-options/filter-menu-hook";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { statusOptions } from "./list-card/card/utils.ts/card.utils";
import { serviceProviderListHooks } from "./list-card/serviceProviderList.hooks";

type ServiceProviderContainerProps = {
  hideEntitiesCount?: boolean;
  onOpenModal: () => void;
  isLoading: boolean;
  children: JSX.Element;
};

export const ServiceProviderContainer = (
  props: ServiceProviderContainerProps
) => {
  const { onOpenModal } = props;
  const {
    configurationOptions,
    setCurrentFilter,
    currentFilter,
    getPaginateConfigurations,
    getAllHandler
  } = serviceProviderListHooks();
  const filterMenuHook = useFilterMenuHook();
  const { clearFilters } = filterMenuHook;
  const [search, setSearch] = useState<string>(EMPTY);

  useEffect(() => {
    getPaginateConfigurations(
      {
        limit: 300
      },
      true
    );
  }, []);

  useEffect(() => {
    if (currentFilter?.entityId || currentFilter?.partyId) {
      getAllHandler();
    }
  }, [currentFilter?.entityId, currentFilter?.partyId]);

  const searchDebounce = debounce((query: string) => {
    setCurrentFilter({
      ...currentFilter,
      freeText: query
    });
  }, AppConfig.search.debounceRate);

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      ...currentFilter,
      domainConfigurations: [],
      statuses: []
    });
  };

  return (
    <>
      <Flex>
        <InputGroup maxWidth={400}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            variant="outline"
            placeholder={"Search"}
            fontSize={12}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchDebounce(e.target.value);
            }}
          />
          <InputRightElement hidden={!search}>
            <CloseIcon
              w={4}
              h={4}
              cursor={"pointer"}
              color="gray.500"
              onClick={() => {
                setSearch(EMPTY);
                setCurrentFilter({
                  ...currentFilter,
                  freeText: EMPTY
                });
              }}
            />
          </InputRightElement>
        </InputGroup>
        <Spacer />
        <Box pr={"10px"}>
          <Button
            {...elphiTheme.components.light.button.primary}
            onClick={onOpenModal}
          >
            Add a Service Provider
          </Button>
        </Box>
      </Flex>
      <Flex minWidth="max-content" alignItems="center" gap={2} mt={2}>
        <FilterMenuOptions
          customKey={"domainFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Domain"}
          options={configurationOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              domainConfigurations: items
            });
          }}
          selected={currentFilter?.domainConfigurations}
          showClearAll
        />
        <FilterMenuOptions
          customKey={"statusFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Provider Status"}
          options={statusOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              statuses: items
            });
          }}
          selected={currentFilter?.statuses}
          showClearAll
        />
        <Button
          mr={1}
          background={"transparent"}
          fontSize={14}
          fontWeight={"normal"}
          onClick={handleClearClick}
          isDisabled={
            isEmpty(currentFilter?.domainConfigurations) &&
            isEmpty(currentFilter?.statuses)
          }
        >
          Clear filters
        </Button>
      </Flex>
      <>{props.children}</>
    </>
  );
};
