import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import {
  DocumentConfiguration,
  DocumentMetadata,
  DocumentPackageOrder,
  DocumentPackageType,
  FieldType,
  LOSUser,
  OrderStatusType,
  StatusCode
} from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import download from "downloadjs";
import { WritableDraft } from "immer/dist/internal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { printDateTime } from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import { useDocumentPackageOrderHooks } from "../../hooks/documentPackageOrder.hooks";
import useDocumentGenerationHooks from "../../hooks/documentgeneration.hooks";
import useTaskHooks from "../../hooks/task.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { RootState } from "../../redux/store";
import { documentPackageOrderApi } from "../../redux/v2/document-package-order/documentPackageOrder.service";
import { useRTKPagination } from "../../redux/v2/hooks/pagination.hook";
import { ButtonIconDownload, ButtonIconRetry } from "../button-icon/ButtonIcon";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { ModalContainer } from "../modal-container/ModalContainer";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { useElphiToast } from "../toast/toast.hook";
import { createOptionsFromMap } from "../utils/formUtils";
import { DocumentPackageOrderModal } from "./ViewListComponent";
import { isRetryOrderDisabled } from "./utils/order.utils";

const orderTypeToColor = {
  [DocumentPackageType.Manual]: "blackAlpha.400",
  [DocumentPackageType.LongTerm]: "purple.200",
  [DocumentPackageType.ShortTerm]: "blue.200"
};

const statusToColor = {
  [OrderStatusType.Pending]: "grey.200",
  [OrderStatusType.Failed]: "red.300",
  [OrderStatusType.Processing]: "orange.200",
  [OrderStatusType.Ready]: "green.200"
};

enum DocumentFileTypes {
  PDF = "pdf",
  DOCX = "docx"
}

export const DocumentPackageOrderTable = (props: { dealId: string }) => {
  const pageSize = 10;
  const documentPackageOrderState = useSelector(
    (state: RootState) => state.documentPackageOrder
  );
  const documentConfigurationState = useSelector(
    (state: RootState) => state.documentConfiguration
  );
  const documentRuleState = useSelector(
    (state: RootState) => state.documentRule
  );
  const losUserState = useSelector((state: RootState) => state.losUser);
  const { getEntitiesData } = useDocumentPackageOrderHooks();
  const { getDownloadUrlApi } = useTaskHooks();
  const { errorToast, successToast } = useElphiToast();
  const { updatePackageOrder } = useDocumentPackageOrderHooks();
  const { setSelectedDocumentPackageOrder } = useDocumentPackageOrderHooks();
  const { generateDocumentsFromSource } = useDocumentGenerationHooks();
  const [showDownloadDocumentsModal, setDownloadDocumentsModal] =
    useState(false);
  const [selectedDownloadDocumentFiles, setSelectDownloadDocumentFiles] =
    useState([] as string[]);
  const [selectedDownloadDocPackageOrder, setSelectedDownloadDocPackageOrder] =
    useState({} as WritableDraft<DocumentPackageOrder>);

  const { next, prev, pageIndex, pageData, pageResponse } = useRTKPagination({
    useLazyPaginateQuery: documentPackageOrderApi.useLazyPaginateQuery,
    entityState: documentPackageOrderState,
    pageFilter: (v) => {
      return v.dealId === props.dealId;
    },
    options: {
      limit: pageSize,
      filter: { fieldPath: "dealId", opStr: "==", value: props.dealId }
    }
  });
  useEffect(() => {
    if (
      pageResponse.isUninitialized &&
      !pageResponse.isSuccess &&
      !pageResponse.isLoading &&
      !pageResponse.isFetching &&
      auth.currentUser
    ) {
      next();
    }
  }, [auth.currentUser]);

  const [lastIndex, setLastIndex] = useState(pageIndex);
  useEffect(() => {
    if (auth.currentUser) {
      if (lastIndex < pageIndex) {
        setLastIndex(pageIndex);
        const orderDataList = Object.values(pageData.entities);
        getEntitiesData({
          orders: orderDataList as ({
            id: string;
          } & Partial<DocumentPackageOrder>)[]
        });
      }
    }
  }, [pageIndex, pageData, auth.currentUser]);

  const getOrderRowData = useCallback(
    (currentOrderId: EntityId) => {
      const documentPackageOrder =
        documentPackageOrderState.entities[currentOrderId];
      let losUser: WritableDraft<LOSUser> | undefined;
      let documentConfiguration: (
        | WritableDraft<DocumentConfiguration>
        | undefined
      )[] = [];
      if (!!documentPackageOrder?.userId) {
        losUser = losUserState.entities[documentPackageOrder.userId];
      }
      const configurationIds = Object.keys(
        documentPackageOrder?.order?.items ?? {}
      );
      if (configurationIds.length > 0) {
        documentConfiguration = configurationIds.map(
          (configurationId) =>
            documentConfigurationState.entities[configurationId]
        );
      }
      return {
        documentPackageOrder:
          documentPackageOrderState.entities[currentOrderId],
        documentConfiguration: documentConfiguration,
        losUser: losUser
      };
    },
    [
      documentPackageOrderState,
      documentConfigurationState,
      losUserState,
      documentRuleState
    ]
  );

  const pageDataMemo = useMemo(() => {
    return pageData.ids
      .filter(
        (id) => documentPackageOrderState.entities[id]?.dealId === props.dealId
      )
      .map((currentOrderId) => {
        return getOrderRowData(currentOrderId);
      });
  }, [
    pageData,
    documentPackageOrderState,
    documentConfigurationState,
    losUserState,
    documentRuleState
  ]);

  const onOpenDownloadDocumentsModal = (
    selectedDocPackageOrder: WritableDraft<DocumentPackageOrder>
  ) => {
    setSelectedDownloadDocPackageOrder(selectedDocPackageOrder);
    setDownloadDocumentsModal(true);
    setSelectDownloadDocumentFiles([]);
  };

  const onCloseDownloadDocumentsModal = () => {
    setDownloadDocumentsModal(false);
  };

  const onSubmitDownloadDocuments = () => {
    if (selectedDownloadDocumentFiles.includes(DocumentFileTypes.DOCX)) {
      getDownloadUrlApi({
        document: {
          provider: selectedDownloadDocPackageOrder.order.provider,
          data: selectedDownloadDocPackageOrder.order.data
        } as DocumentMetadata
      })
        .then(responseHandler)
        .then((r) => {
          if (r.status === StatusCode.OK) {
            download(r.data.url);
            successToast({
              title: "downloaded docx files",
              description: `document docx package downloaded`
            });
          }
          if (r.status === StatusCode.BadRequest) {
            errorToast({
              title: "failed to download docx files",
              description: r.data?.description
            });
          }
        });
    }
    if (selectedDownloadDocumentFiles.includes(DocumentFileTypes.PDF)) {
      generateDocumentsFromSource({
        dealId: selectedDownloadDocPackageOrder.dealId,
        docPackageSrcId: selectedDownloadDocPackageOrder.id
      })
        .then(responseHandler)
        .then((r) => {
          if (r.status === StatusCode.OK) {
            if (r.data.url) {
              download(r.data.url);
              successToast({
                title: "downloaded pdf files",
                description: "document pdf package downloaded"
              });
            }
            if (r.data.status) {
              successToast({
                title: "Files are in proccess",
                description: "Please try again in a moment"
              });
            }
          }
          if (r.status === StatusCode.BadRequest) {
            errorToast({
              title: "failed to download pdf files",
              description: r.data?.description
            });
          }
        });
    }
  };

  const orderTableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    return (
      pageDataMemo?.flatMap((rowData, i) => {
        return {
          index: rowData?.documentPackageOrder?.index || String(i),
          cells: [
            {
              index: 0,
              data: (
                <Box
                  w="100%"
                  borderWidth="2px"
                  borderColor={
                    rowData?.documentPackageOrder?.type &&
                    orderTypeToColor[rowData?.documentPackageOrder?.type]
                  }
                >
                  <Text align={"center"}>
                    {rowData?.documentPackageOrder?.type}
                  </Text>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 1,
              data: (
                <Text align={"center"}>
                  {Math.max(pageIndex, 0) * pageSize + i + 1}
                </Text>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 2,
              data: (
                <Text align={"center"}>
                  {rowData?.documentPackageOrder?.createdAt &&
                    printDateTime(rowData?.documentPackageOrder?.createdAt)}
                </Text>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 3,
              data: (
                <Text align={"center"}>
                  {rowData?.documentPackageOrder?.modifiedAt &&
                    printDateTime(rowData?.documentPackageOrder?.modifiedAt)}
                </Text>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 4,
              data: (
                <Text align={"center"}>{rowData?.losUser?.name ?? "N/A"}</Text>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 5,
              data: (
                <Box
                  bgColor={
                    rowData?.documentPackageOrder?.order?.status &&
                    statusToColor[rowData?.documentPackageOrder?.order?.status]
                  }
                >
                  <Text align={"center"} fontWeight="bold">
                    {rowData?.documentPackageOrder?.order?.status}
                  </Text>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 6,
              data: (
                <Box>
                  <Center>
                    <ButtonIconDownload
                      onClick={() => {
                        if (rowData?.documentPackageOrder) {
                          onOpenDownloadDocumentsModal(
                            rowData.documentPackageOrder
                          );
                        }
                      }}
                      tooltipLabel={"Download Documents"}
                      isDisabled={
                        rowData?.documentPackageOrder?.order?.status !==
                        OrderStatusType.Ready
                      }
                    />
                    <ButtonIconRetry
                      onClick={() => {
                        rowData?.documentPackageOrder?.id &&
                          updatePackageOrder({
                            id: rowData.documentPackageOrder.id
                          }).then((r) => {
                            if (r.status === 200) {
                              successToast({
                                title: "retry order success",
                                description: `order updated`
                              });
                            }
                            if (r.status === 400) {
                              errorToast({
                                title: "failed to update",
                                description: r.data?.description
                              });
                            }
                          });
                      }}
                      isDisabled={isRetryOrderDisabled(
                        rowData?.documentPackageOrder
                      )}
                      tooltipLabel={"Retry to Order"}
                    />
                    <Box
                      onClick={() =>
                        rowData?.documentPackageOrder?.id &&
                        setSelectedDocumentPackageOrder(
                          rowData?.documentPackageOrder?.id
                        )
                      }
                    >
                      <DocumentPackageOrderModal />
                    </Box>
                  </Center>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            }
          ]
        };
      }) ?? []
    );
  }, [pageDataMemo, documentPackageOrderState]);

  const orderTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "Order Type",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 1,
        data: "Row Index",
        type: ElphiCellType.String,
        maxWidth: "100px",
        minWidth: "100px"
      },
      {
        index: 2,
        data: "Created At",
        type: ElphiCellType.String,
        maxWidth: "200px",
        minWidth: "200px"
      },
      {
        index: 3,
        data: "Modified At",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 4,
        data: "Ordered By",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 5,
        data: "Status",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 6,
        data: "Actions",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      }
    ];
  }, []);

  const isLoading =
    pageResponse.isUninitialized ||
    pageResponse.isFetching ||
    pageResponse.isLoading;

  const orderTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: orderTableHeader,
      rows: orderTableFullDataRows,
      footer: (
        <DocumentPackageOrderTableFooter
          isDisabled={isLoading}
          isNextDisabled={orderTableFullDataRows.length < pageSize}
          next={() => {
            next();
          }}
          prev={prev}
          pageIndex={pageIndex}
        />
      ),
      isLoading: isLoading,
      rowsCount: 1
    };
  }, [orderTableFullDataRows, pageIndex]);

  const { heightOffsetInPx } = useWindowDimensions();
  return (
    <>
      <ElphiTable
        minHeight={heightOffsetInPx(330)}
        maxHeight={heightOffsetInPx(330)}
        header={orderTableProps.header}
        rows={orderTableProps.rows}
        footer={orderTableProps.footer}
        isLoading={isLoading}
        rowsCount={1}
      />
      <DownloadDocumentOrderFilesModal
        isShow={showDownloadDocumentsModal}
        onCloseModal={onCloseDownloadDocumentsModal}
        currentValue={selectedDownloadDocumentFiles}
        onChange={setSelectDownloadDocumentFiles}
        onSubmit={onSubmitDownloadDocuments}
      />
    </>
  );
};

const DownloadDocumentOrderFilesModal = (props: {
  isShow: boolean;
  onCloseModal: Function;
  onSubmit: Function;
  onChange: Function;
  currentValue: string[];
}) => {
  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onCloseModal}
      header={"Download documents"}
      body={
        <StyledInputBuilder
          currentValue={props.currentValue}
          fieldType={FieldType.MultiSelect}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          //ESD-2000 - currently filtering pdf to quickly disable this feature (until we have feature flags - darkly please )
          options={createOptionsFromMap(DocumentFileTypes).filter(
            (v) => v.value !== DocumentFileTypes.PDF
          )}
        />
      }
      submit={{
        confirmTitle: "download",
        onConfirm: props.onSubmit,
        closeTitle: "close",
        isDisabled: props.currentValue.length === 0
      }}
    />
  );
};

const DocumentPackageOrderTableFooter = (props: {
  isDisabled: boolean;
  isNextDisabled: boolean;
  next: () => void;
  prev: () => void;
  pageIndex: number;
}) => {
  return (
    <Flex w="100%" justifyContent={"flex-start"} mb="20px" mt="10px">
      <Box lineHeight={"40px"}>
        <Text fontSize={"16px"} fontWeight={"bold"}>
          Page: {Math.max(props.pageIndex, 0) + 1}
        </Text>
      </Box>
      <Flex>
        <Box pl="20px">
          <Button
            w={"100px"}
            {...elphiTheme.components.light.button.primary}
            isDisabled={props.pageIndex <= 0 || props.isDisabled}
            onClick={() => {
              props.prev();
            }}
          >
            Prev
          </Button>
        </Box>
        <Box pl="10px" mr={"auto"}>
          <Button
            w={"100px"}
            {...elphiTheme.components.light.button.primary}
            isDisabled={props.isDisabled || props.isNextDisabled}
            onClick={() => {
              props.next();
            }}
          >
            Next
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
