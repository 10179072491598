import { AppResponse } from "../appResponse.types";
import { RTKResponse } from "./response.types";

 const extractDescription = (
  e?:
    | {
        data?:
          | { error?: { payload?: { description?: string; error?: string } } }
          | { description: string };
      }
    | any
) => {
  return (
    e?.data?.error?.payload?.description ||
    e?.data?.description ||
    "something went wrong"
  );
};
export const responseHandler = <T>(
  response: RTKResponse<T>
): AppResponse<T> => {
  if ("data" in response) {
    return {
      status: 200,
      data: response.data
    };
  } else {
    console.log(response.error);
    return {
      status: 400,
      data: {
        error: response.error,
        description: extractDescription(response.error)
      }
    };
  }
};
