import { Button, Flex } from "@chakra-ui/react";
import { Building, FieldType } from "@elphi/types";
import { elphiTheme } from "../../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../../constants/common";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { entitiesPath } from "../utils/formUtils";
import { BuildingContainer } from "./Building";
import { buildingsPath } from "./utils/coveragesSection";
type PropertyBuildingsFormListProps = {
  propertyId: string;
  state: PropertyInsurancePolicyState;
  isDisabled: boolean;
  onChange: (value: OnChangeInput) => void;
  sectionName: string;
};

export const PropertyBuildingsFormList = (
  props: PropertyBuildingsFormListProps
) => {
  const { propertyId, state, isDisabled, onChange, sectionName } = props;

  const handleBuildingDelete = (r: {
    propertyId: string;
    buildingIndex: number;
  }) => {
    const { propertyId, buildingIndex } = r;
    const filteredBuilding = state.entities?.[r.propertyId]?.buildings?.filter(
      (_, index) => buildingIndex !== index
    );
    onChange({
      fieldType: FieldType.Array,
      value: filteredBuilding,
      fieldKey: [entitiesPath, propertyId, buildingsPath]
    });
  };

  const onPolicyBuildingsFieldChange = (r: {
    e: OnChangeInput;
    propertyId: string;
    index: number;
  }) => {
    const { e, propertyId, index } = r;

    onChange({
      ...e,
      fieldKey: [
        entitiesPath,
        propertyId,
        buildingsPath,
        index.toString(),
        ...e.fieldKey
      ]
    });
  };

  const addBuilding = (r: { propertyId?: string }) => {
    const { propertyId = EMPTY } = r;
    const buildings = state.entities?.[propertyId]?.buildings || [];
    const newBuilding: Building = {};
    onChange({
      fieldType: FieldType.Array,
      value: [...buildings, newBuilding],
      fieldKey: [entitiesPath, propertyId, buildingsPath]
    });
  };

  return (
    <Flex direction="column" pl="8px">
      <Button
        {...elphiTheme.components.light.button.primary}
        width={"20%"}
        onClick={() => addBuilding({ propertyId })}
        isDisabled={isDisabled}
      >
        + Add building
      </Button>
      {state.entities?.[propertyId]?.buildings && (
        <Flex direction="column" gap="8px" mt="16px">
          {state.entities?.[propertyId]?.buildings?.map((_, buildingIndex) => {
            return (
              <BuildingContainer
                state={state}
                propertyId={propertyId}
                index={buildingIndex}
                sectionName={sectionName}
                isDisabled={isDisabled}
                key={`property-${propertyId}-building-${buildingIndex}`}
                onChange={(e) =>
                  onPolicyBuildingsFieldChange({
                    e,
                    propertyId,
                    index: buildingIndex
                  })
                }
                onDelete={() =>
                  handleBuildingDelete({
                    propertyId,
                    buildingIndex
                  })
                }
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};
