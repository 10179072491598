import { LOSUser } from "@elphi/types";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import { SearchComponentProps } from "../SearchComponent";
import { SearchHandler } from "../search/SearchHandler";
import { getLOSUserTitle } from "./doc-gen.user.utils";

const buildOption = (losUser: LOSUser, showRoles?: string[]) => {
  return {
    label: `${losUser.name} (${losUser.email}) ${
      showRoles ? getLOSUserTitle(losUser, showRoles) : ""
    }`,
    value: losUser.id
  };
};
type LOSUserSearchProps = {
  filter?: (a: LOSUser) => boolean;
  roles?: string[];
  showRoles?: string[];
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "labelPosition"
  | "isReadOnly"
  | "isDisabled"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;

export const LOSUserSearch = (props: LOSUserSearchProps) => {
  const { searchUsers, searchResponse, rankedSort, losUserState } =
    useLOSUserHooks();

  const defaultRoleFilter = (u) => {
    return !!u.roles?.find((r) => props?.showRoles?.includes(r.value));
  };

  const filter =
    props.filter || props?.showRoles ? defaultRoleFilter : undefined;
  return (
    <SearchHandler
      {...props}
      searchApi={searchUsers}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={losUserState}
      buildOption={(losUser: LOSUser) => buildOption(losUser, props?.showRoles)}
      filter={filter}
    />
  );
};
