import { DealProperty, DealUser } from "@elphi/types";
import { getDealPropertyIdsFromRelation } from "@elphi/utils";
import { intersection } from "lodash";
import { useCallback, useMemo } from "react";
import {
  PropertySliceState,
  PropertyTableCursorType
} from "../../../../redux/v2/property";
import { filterEntities } from "../../../utils/filterEntities.utils";
import { PropertyTableState } from "./PropertyRow";
type PropertyListItem = {
  propertyId?: string;
  dealPropertyId?: string;
};

export type QueryParams = {
  userId?: string;
  propertyId?: string;
  dealId?: string;
};

export const getCurrentCursorType = ({ userId, propertyId }: QueryParams) => {
  if (!propertyId && !userId) return PropertyTableCursorType.ALL;
  else if (propertyId && !userId)
    return PropertyTableCursorType.PROPERTY_FILTER;
  else if (!propertyId && userId) return PropertyTableCursorType.USER_FILTER;
  else if (propertyId && userId)
    return PropertyTableCursorType.PROPERTY_USER_FILTER;
  throw "missing case for current cursorType";
};

type PropertyFilterRequest = {
  queryParams: QueryParams;
  propertyState: PropertySliceState;
};

type PropertyListItemMapping = Record<
  keyof typeof PropertyTableCursorType,
  (request: PropertyFilterRequest) => PropertyListItem[]
>;

export const usePropertyItem = (state: PropertyTableState) => {
  const { propertyState, dealPropertyState, dealUserState } = state;

  const getDealPropertyIds = (r: {
    key: keyof DealProperty;
    value: string | string[];
  }): string[] => {
    const { key, value } = r;
    const filteredProperties = filterEntities<DealProperty>({
      entityState: dealPropertyState,
      key,
      value
    });
    return filteredProperties.map((dp) => dp.id);
  };

  const getDealIds = (r: {
    key: keyof DealUser;
    value: string | string[];
  }): string[] => {
    const { key, value } = r;
    const filteredDealUser = filterEntities<DealUser>({
      entityState: dealUserState,
      key,
      value
    });
    return filteredDealUser.map((dp) => dp.dealId);
  };

  const getPropertyPairs = (r: {
    propertyId?: string;
    dealPropertyIds: string[];
  }): PropertyListItem[] => {
    const { propertyId, dealPropertyIds } = r;
    if (dealPropertyIds.length > 0) {
      return dealPropertyIds.map((dpId) => ({
        propertyId:
          propertyId ||
          getDealPropertyIdsFromRelation({ relationId: dpId }).propertyId,
        dealPropertyId: dpId
      }));
    } else if (propertyId) {
      return [{ propertyId }];
    } else {
      return [];
    }
  };

  const getPropertyListItemsDealFilter = useCallback(
    (r: PropertyFilterRequest) => {
      const { queryParams } = r;
      const dealPropertyIds = getDealPropertyIds({
        key: "dealId",
        value: queryParams.dealId!
      });
      return getPropertyPairs({
        propertyId: undefined,
        dealPropertyIds
      });
    },
    [getDealPropertyIds, getPropertyPairs]
  );

  const getPropertyListItemsAll = useCallback(
    (r: PropertyFilterRequest) => {
      const { propertyState } = r;
      const propertyIds = propertyState.ids.map((pid) => pid.toString());
      return propertyIds.flatMap((pid) => {
        const dealPropertyIds = getDealPropertyIds({
          key: "propertyId",
          value: pid.toString()
        });
        return getPropertyPairs({ propertyId: pid, dealPropertyIds });
      });
    },
    [getDealPropertyIds, getPropertyPairs]
  );

  const getPropertyListItemsPropertyFilter = useCallback(
    (r: PropertyFilterRequest) => {
      const { queryParams } = r;
      const propertyId = queryParams.propertyId!;
      const dealPropertyIds = getDealPropertyIds({
        key: "propertyId",
        value: propertyId
      });
      return getPropertyPairs({ propertyId, dealPropertyIds: dealPropertyIds });
    },
    [getDealPropertyIds, getPropertyPairs]
  );

  const getPropertyListItemsPropertyUserFilter = useCallback(
    (r: PropertyFilterRequest) => {
      const { queryParams } = r;
      const propertyId = queryParams.propertyId!;
      const dealIds = getDealIds({
        key: "userId",
        value: queryParams.userId!
      });
      const dealPropertyIdsByDealIds = getDealPropertyIds({
        key: "dealId",
        value: dealIds
      });
      const dealPropertyIdsByPropertyId = getDealPropertyIds({
        key: "propertyId",
        value: propertyId
      });
      const dealPropertyIds = intersection(
        dealPropertyIdsByDealIds,
        dealPropertyIdsByPropertyId
      );
      return getPropertyPairs({ propertyId, dealPropertyIds });
    },
    [getDealPropertyIds, getDealIds, getPropertyPairs]
  );

  const getPropertyListItemsUserFilter = useCallback(
    (r: PropertyFilterRequest) => {
      const { queryParams } = r;
      const propertyId = queryParams.propertyId!;
      const dealIds = getDealIds({
        key: "userId",
        value: queryParams.userId!
      });
      const dealPropertyIds = getDealPropertyIds({
        key: "dealId",
        value: dealIds
      });
      return getPropertyPairs({ propertyId, dealPropertyIds });
    },
    [getDealPropertyIds, getDealIds, getPropertyPairs]
  );

  const propertyListGetterMapper: PropertyListItemMapping = useMemo(() => {
    return {
      ALL: getPropertyListItemsAll,
      USER_FILTER: getPropertyListItemsUserFilter,
      PROPERTY_USER_FILTER: getPropertyListItemsPropertyUserFilter,
      PROPERTY_FILTER: getPropertyListItemsPropertyFilter
    };
  }, [
    getPropertyListItemsAll,
    getPropertyListItemsUserFilter,
    getPropertyListItemsPropertyUserFilter,
    getPropertyListItemsPropertyFilter
  ]);

  const getPropertyItems = useCallback(
    (queryParams: QueryParams) => {
      const { dealId } = queryParams;
      if (!!dealId) {
        return getPropertyListItemsDealFilter({ queryParams, propertyState });
      }
      const cursorType = getCurrentCursorType(queryParams);
      const getPropertyList =
        propertyListGetterMapper[PropertyTableCursorType[cursorType]];
      if (!!getPropertyList) {
        return getPropertyList({ queryParams, propertyState });
      }
      return [];
    },
    [propertyState]
  );

  return { getPropertyItems };
};
