import {
  ActionType,
  CreditPullType,
  CreditReportOrderTask,
  CreditReportVendorType,
  DocumentOrderStatusTaskTemplate,
  DocumentTaskTemplate,
  FloodCertificateOrderTask,
  FloodCertificateVendorType,
  IntegrationType,
  PropertyReportOrderTask,
  PropertyReportVendorType,
  RentalDataOrderTask,
  RentalDataVendorType,
  ServiceLinkTrxStatus,
  TaskStatusType,
  TaskType
} from "@elphi/types";
import { ElphiTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { WireInsuranceCertificateOrderTask } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceCertificate.types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
type DocumentOrderStatusBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.DocumentOrderStatus>;
type IntegrationBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.Integration> & {
    integrationType: IntegrationType;
  };
type BuildTaskTemplateRequest =
  | DocumentOrderStatusBuildTaskTemplateRequest
  | DocumentBuildTaskTemplateRequest
  | IntegrationBuildTaskTemplateRequest;
type BaseBuildTaskTemplateRequest<TType extends TaskType> = {
  type: TType;
};
type DocumentBuildTaskTemplateRequest =
  BaseBuildTaskTemplateRequest<TaskType.Document>;
const creditReportTemplate: CreditReportOrderTask["template"] = {
  vendor: {
    selectedVendor: CreditReportVendorType.Lima,
    lima: {
      type: CreditReportVendorType.Lima,
      pullType: "" as CreditPullType
    }
  },
  pullType: CreditPullType.None,
  assignableBy: [],
  availableActions: [
    ActionType.Complete,
    ActionType.Deny,
    ActionType.UploadFile,
    ActionType.ViewFile,
    ActionType.MarkAsOrdered
  ],
  availableStatus: Object.values(TaskStatusType),
  checklistOf: [],
  documents: {},
  dueDate: "",
  editableBy: [],
  orderedDate: "",
  orderedFrom: "",
  orderStatus: "",
  receivedDate: "",
  taskStatus: TaskStatusType.Created,
  type: TaskType.Integration,
  integrationType: IntegrationType.CreditReport,
  templateName: "",
  vendorName: "",
  vendorInformation: ""
};
const floodCertTemplate: FloodCertificateOrderTask["template"] = {
  assignableBy: [],
  availableActions: [
    ActionType.Complete,
    ActionType.Deny,
    ActionType.UploadFile,
    ActionType.ViewFile,
    ActionType.MarkAsOrdered
  ],
  availableStatus: Object.values(TaskStatusType),
  checklistOf: [],
  documents: {},
  dueDate: "",
  editableBy: [],
  orderedDate: "",
  orderedFrom: "",
  orderStatus: "",
  receivedDate: "",
  taskStatus: TaskStatusType.Created,
  type: TaskType.Integration,
  integrationType: IntegrationType.FloodCertificate,
  templateName: "",
  vendorName: "",
  vendorInformation: "",
  elphiTrxStatus: ElphiTrxStatus.Pending,
  vendorType: FloodCertificateVendorType.ServiceLink,
  floodCheckTrxStatus: ElphiTrxStatus.Pending,
  serviceLinkTrxStatus: ServiceLinkTrxStatus.Open
};

const wireInsuranceCertificateTemplate: WireInsuranceCertificateOrderTask["template"] =
  {
    assignableBy: [],
    availableActions: [
      ActionType.Complete,
      ActionType.Deny,
      ActionType.UploadFile,
      ActionType.ViewFile,
      ActionType.MarkAsOrdered
    ],
    availableStatus: Object.values(TaskStatusType),
    checklistOf: [],
    documents: {},
    dueDate: "",
    editableBy: [],
    orderedDate: "",
    orderedFrom: "",
    orderStatus: "",
    receivedDate: "",
    taskStatus: TaskStatusType.Created,
    type: TaskType.Integration,
    integrationType: IntegrationType.WireInsuranceCertificate,
    vendorType: WireInsuranceVendorType.FundingShield,
    templateName: "",
    vendorName: "",
    vendorInformation: "",
    initializedDate: "",
    elphiTrxStatus: ElphiTrxStatus.Pending,
    retryAttempt: 0,
    transactionId: "",
    fundingShieldTrxStatus: ""
  };

const propertyReportTemplate: PropertyReportOrderTask["template"] = {
  assignableBy: [],
  availableActions: [
    ActionType.Complete,
    ActionType.Deny,
    ActionType.UploadFile,
    ActionType.ViewFile,
    ActionType.MarkAsOrdered
  ],
  availableStatus: Object.values(TaskStatusType),
  checklistOf: [],
  documents: {},
  dueDate: "",
  editableBy: [],
  orderedDate: "",
  orderedFrom: "",
  orderStatus: "",
  receivedDate: "",
  taskStatus: TaskStatusType.Created,
  type: TaskType.Integration,
  integrationType: IntegrationType.PropertyReport,
  vendorType: PropertyReportVendorType.SiteX,
  elphiTrxStatus: ElphiTrxStatus.Pending,
  templateName: "",
  vendorName: "",
  vendorInformation: ""
};

const rentalDataTemplate: RentalDataOrderTask["template"] = {
  assignableBy: [],
  availableActions: [
    ActionType.Complete,
    ActionType.Deny,
    ActionType.UploadFile,
    ActionType.ViewFile,
    ActionType.MarkAsOrdered
  ],
  availableStatus: Object.values(TaskStatusType),
  checklistOf: [],
  documents: {},
  dueDate: "",
  editableBy: [],
  orderedDate: "",
  orderedFrom: "",
  orderStatus: "",
  receivedDate: "",
  taskStatus: TaskStatusType.Created,
  type: TaskType.Integration,
  integrationType: IntegrationType.RentalData,
  vendorType: RentalDataVendorType.RentRange,
  elphiTrxStatus: ElphiTrxStatus.Pending,
  templateName: "",
  vendorName: "",
  vendorInformation: ""
};

export const getIntegrationTemplate = (r: BuildTaskTemplateRequest) => {
  if (r.type === TaskType.Integration) {
    if (r.integrationType === IntegrationType.FloodCertificate) {
      return floodCertTemplate;
    } else if (r.integrationType === IntegrationType.CreditReport) {
      return creditReportTemplate;
    } else if (r.integrationType === IntegrationType.WireInsuranceCertificate) {
      return wireInsuranceCertificateTemplate;
    } else if (r.integrationType === IntegrationType.PropertyReport) {
      return propertyReportTemplate;
    } else if (r.integrationType === IntegrationType.RentalData) {
      return rentalDataTemplate;
    } else {
      throw `${r.integrationType} template is not supported`;
    }
  }
};

export const getDocumentOrderStatusTemplate = (r: BuildTaskTemplateRequest) => {
  if (r.type === TaskType.DocumentOrderStatus) {
    const documentTaskTemplate: DocumentOrderStatusTaskTemplate["template"] = {
      assignableBy: [],
      availableActions: [
        ActionType.Complete,
        ActionType.Deny,
        ActionType.UploadFile,
        ActionType.ViewFile
      ],
      availableStatus: Object.values(TaskStatusType),
      checklistOf: [],
      documents: {},
      dueDate: "",
      editableBy: [],
      orderedDate: "",
      orderedFrom: "",
      orderStatus: "",
      receivedDate: "",
      taskStatus: TaskStatusType.Created,
      type: TaskType.DocumentOrderStatus,
      templateName: "",
      vendorName: "",
      vendorInformation: ""
    };
    return documentTaskTemplate;
  }
};

export const getDocumentTemplate = (r: BuildTaskTemplateRequest) => {
  if (r.type === TaskType.Document) {
    const documentStatusTaskTemplate: DocumentTaskTemplate["template"] = {
      assignableBy: [],
      availableActions: [
        ActionType.Complete,
        ActionType.Deny,
        ActionType.UploadFile,
        ActionType.ViewFile
      ],
      availableStatus: Object.values(TaskStatusType),
      checklistOf: [],
      documents: {},
      editableBy: [],
      taskStatus: TaskStatusType.Created,
      type: TaskType.Document,
      templateName: ""
    };
    return documentStatusTaskTemplate;
  }
};
