import { Party } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { NONE } from "../../../constants/common";
import { assetApi } from "../asset/asset.service";
import sliceBuilder from "../builders/slice.builder";
import { dealApi } from "../deal/deal.service";
import { documentPackageOrderApi } from "../document-package-order";
import { uniqCursorId } from "../utils/filter-table.utils";
import { SearchCursorState } from "../utils/search.utils";
import { partyEntityAdapter as entityAdapter } from "./party.adapter";
import { partyApi } from "./party.service";

export type PartySliceStateFields = {
  selectedId?: EntityId;
  selectedParentId?: EntityId;
  highlightedPartyId?: EntityId;
  selectedTreeNodeKey?: string;
  searchCursor: SearchCursorState;
  partyTab?: {
    highlightedPartyId?: EntityId;
    highlightedPartyParentId?: EntityId;
  };
  partyTable?: {
    cursor: {
      currentId: string;
      all?: string;
      hasMore: {
        [uniqId: string]: boolean;
      };
      userFilter: {
        [userId: string]: string;
      };
      partyFilter: {
        [partyId: string]: string;
      };
      partyUserFilter: {
        [partyUserId: string]: string;
      };
    };
  };
};
export type PartySliceState = EntityState<Party> & PartySliceStateFields;

export enum PartyTableCursorType {
  ALL,
  USER_FILTER,
  PARTY_FILTER,
  PARTY_USER_FILTER
}

const initialState: PartySliceStateFields = {
  selectedId: undefined,
  selectedParentId: undefined,
  highlightedPartyId: undefined,
  selectedTreeNodeKey: undefined,
  partyTab: {
    highlightedPartyId: undefined,
    highlightedPartyParentId: undefined
  },
  searchCursor: {
    query: {}
  },
  partyTable: {
    cursor: {
      all: undefined,
      currentId: NONE,
      hasMore: {},
      userFilter: {},
      partyFilter: {},
      partyUserFilter: {}
    }
  }
};
export const partySlice = createSlice({
  name: "party",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    setPartyTableCurrentCursorIdHasMore: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.partyTable)
        state.partyTable.cursor.hasMore[state.partyTable.cursor.currentId] =
          action.payload;
    },
    setPartyTableCurrentCursorId: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      if (state.partyTable)
        state.partyTable.cursor.currentId = action.payload.id;
    },
    setPartyTableCursor: (
      state,
      action: PayloadAction<{
        cursorType: PartyTableCursorType;
        cursor: string;
        partyId?: string;
        userId?: string;
      }>
    ) => {
      const { partyId, userId } = action.payload;
      const filtersUniqCursorId = uniqCursorId({
        id: partyId,
        userId
      });
      if (!state.partyTable) {
        state.partyTable = {
          cursor: {
            currentId: filtersUniqCursorId,
            hasMore: {},
            all: undefined,
            userFilter: {},
            partyFilter: {},
            partyUserFilter: {}
          }
        };
      }
      if (action.payload.cursorType === PartyTableCursorType.ALL) {
        state.partyTable.cursor.all = action.payload.cursor;
      } else if (
        action.payload.cursorType === PartyTableCursorType.PARTY_FILTER
      ) {
        if (action.payload.partyId) {
          state.partyTable.cursor.partyFilter[action.payload.partyId] =
            action.payload.cursor;
        }
      } else if (
        action.payload.cursorType === PartyTableCursorType.USER_FILTER
      ) {
        if (action.payload.userId) {
          state.partyTable.cursor.userFilter[action.payload.userId] =
            action.payload.cursor;
        }
      } else if (
        action.payload.cursorType === PartyTableCursorType.PARTY_USER_FILTER
      ) {
        if (action.payload.userId && action.payload.partyId) {
          state.partyTable.cursor.partyUserFilter[
            `${action.payload.partyId}_${action.payload.userId}`
          ] = action.payload.cursor;
        }
      }
    },
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    selectedPartyParent: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedParentId = action.payload.id;
    },
    highlightedParty: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.highlightedPartyId = action.payload.id;
    },
    highlightedPartyTabParty: (
      state,
      action: PayloadAction<{ id: EntityId }>
    ) => {
      if (state?.partyTab)
        state.partyTab.highlightedPartyId = action.payload.id;
    },
    highlightedPartyTabPartyParent: (
      state,
      action: PayloadAction<{ id: EntityId }>
    ) => {
      if (state?.partyTab)
        state.partyTab.highlightedPartyParentId = action.payload.id;
    },
    selectedTreeNodeKey: (state, action: PayloadAction<{ id: string }>) => {
      state.selectedTreeNodeKey = action.payload.id;
    },
    resetSelections: (state) => {
      state.selectedId = undefined;
      state.selectedParentId = undefined;
      state.highlightedPartyId = undefined;
      state.selectedTreeNodeKey = undefined;
      if (state.partyTab) {
        state.partyTab.highlightedPartyId = undefined;
        state.partyTab.highlightedPartyParentId = undefined;
      }
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(partyApi)(builder);
    builder.addMatcher(
      partyApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
        state.searchCursor.query[payload.query] = {
          hasMore: payload.hasMore,
          nextCursor: payload.nextCursor
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.dealParties.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.parties.ids);
        state.entities = { ...state.entities, ...payload.parties.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.children.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.children.ids);
        state.entities = { ...state.entities, ...payload.children.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.partyTree.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.parties.ids);
        state.entities = { ...state.entities, ...payload.parties.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.getPartyBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.parties.ids);
        state.entities = { ...state.entities, ...payload.parties.entities };
      }
    );
    builder.addMatcher(
      assetApi.endpoints.getBatchAssetParty.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.parties.ids);
        state.entities = { ...state.entities, ...payload.parties.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.party.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page.party, "id")
        };
      }
    );
    builder.addMatcher(
      documentPackageOrderApi.endpoints.getEntitiesData.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.parties.ids);
        state.entities = { ...state.entities, ...payload.parties.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.page.party.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.page.party, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getAdditionalDataForDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.parties.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.parties, "id")
        };
      }
    );
  }
});
