import { Box, Button, Center, Flex, Image, Text } from "@chakra-ui/react";
import elphi from "../assets/img/elphi/elphi.png";
import { elphiTheme } from "../assets/themes/elphi.theme.default";

export const MissingChunkError = () => {
  return (
    <Center w="100%" h="100%" pt="50px" pb="50px">
      <Box boxShadow={"lg"} h="300px">
        <Image src={elphi} alt="elphi" bgColor="blue.400" />
        <Flex>
          <Text fontSize={"5xl"} p="20px" fontWeight={"bold"}>
            A new version is
          </Text>
          <Text color="blue.400" fontSize={"5xl"} pt="20px" fontWeight={"bold"}>
            NOW AVAILABLE
          </Text>{" "}
          <Text fontSize={"5xl"} p="20px">
            🎉
          </Text>
        </Flex>
        <Text fontSize={"3xl"} pl="20px" pb="20px">
          Click to continue
        </Text>
        <Box>
          <Center>
            <Button
              width="300px"
              variant={elphiTheme.components.light.button.primary.variant}
              colorScheme={
                elphiTheme.components.light.button.primary.colorScheme
              }
              onClick={() => {
                window.location.reload();
              }}
            >
              Get Latest Version
            </Button>
          </Center>
        </Box>
      </Box>
    </Center>
  );
};
