import { Text } from "@chakra-ui/react";
import { PartyType } from "@elphi/types";
import { EMPTY } from "../../../../constants/common";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { ModalContainer } from "../../../modal-container/ModalContainer";
import { RolodexServiceProviderCard } from "../list-card/serviceProviderCard.types";

export const ServiceProviderPartyRelationRemoveModal = (props: {
  show: boolean;
  onClose: () => void;
  data: RolodexServiceProviderCard;
}) => {
  const { show, onClose, data } = props;
  const { removePartyRelationHandler, removePartyRelationApiResponse } =
    useServiceProviderHooks();
  const { selectedOrHighlightedParty: selectedParty } = usePartyHooks();
  const partyName =
    selectedParty?.PartyType === PartyType.Individual
      ? `${selectedParty?.FirstName} ${selectedParty?.LastName}`
      : EMPTY;

  return (
    <ModalContainer
      isShow={show}
      onCloseModal={onClose}
      header={`Removing a Service Provider: ${data.domainConfigurationName}`}
      body={
        <Text>{`Are you sure you want to remove ${data.companyName} | ${data.branchName} | ${data.repName} from ${partyName} personal rolodex?`}</Text>
      }
      submit={{
        isLoading: removePartyRelationApiResponse.isLoading,
        onConfirm: () => {
          removePartyRelationHandler({
            companyId: data.companyId,
            branchId: data.branchId,
            representativeId: data.repId,
            partyId: selectedParty?.id || EMPTY
          }).finally(() => onClose());
        }
      }}
    />
  );
};
