import { Box, Divider, Flex } from "@chakra-ui/react";
import {
  Deal,
  LoanPassPricingAggregationIntergrationFields
} from "@elphi/types";
import { BaseSchemaDeal } from "@elphi/types/entities/base";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get } from "lodash";
import { EMPTY, NOT_AVAILABLE } from "../../../../../constants/common";
import { AggregationsIntegrations } from "../../../../../forms/schemas/integrations/types/integrations.types";
import { DynamicSchema } from "../../../../../forms/schemas/types";
import { useDealFormHandler } from "../../../../../hooks/dealform.hooks";
import { AttachedFieldStatusComponet } from "../../../../form-builder/AttachedFieldStatusComponent";
import { OnChangeInput } from "../../../../form-builder/FormBuilder";
import StyledInputBuilder from "../../../../form-builder/InputBuilder";
import { InputBuilderFieldSpecs } from "../../../../form-builder/field-specs/fields.types";

const comparisonHeaders = [
  "Field",
  "Selected Product Value",
  "Existing LOS Value"
];

const comparisonFields: {
  fieldName: string;
  mappedDealPath: DotNestedKeys<
    AggregationsIntegrations<LoanPassPricingAggregationIntergrationFields>
  >;
  dealPath: DotNestedKeys<BaseSchemaDeal>;
}[] = [
  {
    fieldName: "Final Price",
    mappedDealPath:
      "aggregations.Integrations.Pricing.Loanpass.adjustedPrice.thirdParty",
    dealPath: "FinalPrice"
  },
  {
    fieldName: "Note Rate",
    mappedDealPath:
      "aggregations.Integrations.Pricing.Loanpass.adjustedRate.thirdParty",
    dealPath: "NoteRatePercent"
  },
  {
    fieldName: "Rate Lock Period",
    mappedDealPath:
      "aggregations.Integrations.Pricing.Loanpass.lockPeriod.thirdParty",
    dealPath: "LockPeriod"
  }
];

const ComparisonHeaders = () => (
  <Flex justifyContent={"space-between"}>
    {comparisonHeaders.map((header) => (
      <Box flex="1" key={header}>
        {header}
      </Box>
    ))}
  </Flex>
);

type ComparisonRowInputProps = Pick<
  ComparisonRowProps,
  "fieldSpec" | "dealPath" | "dealOnChange" | "dealState"
>;

const ComparisonRowInput = (props: ComparisonRowInputProps) => {
  const { fieldSpec, dealPath, dealOnChange, dealState } = props;
  const selectedDealValue = get(dealState, dealPath);
  return (
    <Flex w="100%">
      <Box w="100%">
        <StyledInputBuilder
          size={{
            maxW: "100%"
          }}
          currentValue={selectedDealValue}
          {...fieldSpec}
          onChange={(e) =>
            dealOnChange({
              value: e.target.value,
              fieldKey: dealPath.split("."),
              fieldType: fieldSpec.fieldType
            })
          }
          isValid={true}
        />
      </Box>
      <AttachedFieldStatusComponet
        fieldPath={dealPath.split(".")}
        state={dealState}
        prefix={[]}
        onChange={dealOnChange}
      />
    </Flex>
  );
};

type ComparisonRowStateProps = {
  dealOnChange: (e: OnChangeInput) => void;
  dealState: Deal;
};
type ComparisonRowProps = {
  fieldName: string;
  mappedValue: string;
  fieldSpec: InputBuilderFieldSpecs<any>;
  dealPath: string;
} & ComparisonRowStateProps;

const ComparisonRow = (props: ComparisonRowProps) => {
  const { fieldName, mappedValue } = props;
  return (
    <>
      <Flex key={fieldName} w="100%">
        <Box flex="1">{fieldName}</Box>
        <Box flex="1">{mappedValue || NOT_AVAILABLE}</Box>
        <Box flex="1">
          <ComparisonRowInput {...props} />
        </Box>
      </Flex>
      <Divider borderColor={"gray.200"} />
    </>
  );
};

type PricingComparisonProps = {
  mappedDeal: Partial<BaseSchemaDeal>;
  selectedDeal: Deal;
  dealSpec: DynamicSchema["specs"]["deal"]["entitySpecs"];
};

const ComparisonSectionRows = (props: PricingComparisonProps) => {
  const { dealOnChange, dealState } = useDealFormHandler(props.selectedDeal.id);
  return (
    <Flex gap={4} direction="column">
      {comparisonFields.map((field) => {
        const mappedValue =
          get(props.mappedDeal, field.mappedDealPath) || EMPTY;
        const fieldSpec = get(
          props.dealSpec,
          field.dealPath
        ) as InputBuilderFieldSpecs<any>;
        return (
          <ComparisonRow
            key={field.fieldName}
            dealOnChange={dealOnChange}
            dealState={dealState}
            fieldName={field.fieldName}
            mappedValue={mappedValue}
            fieldSpec={fieldSpec}
            dealPath={field.dealPath}
          />
        );
      })}
    </Flex>
  );
};

export const PricingDataComparison = (props: PricingComparisonProps) => {
  return (
    <Flex gap="12px" direction="column">
      <ComparisonHeaders />
      <Divider borderColor={"gray.200"} />
      <ComparisonSectionRows {...props} />
    </Flex>
  );
};
