import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Asset, FieldType, Party } from "@elphi/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { useAssetHooks } from "../../hooks/asset.hooks";
import { RootState } from "../../redux/store";
import { useElphiToast } from "../toast/toast.hook";
import { AssetSearch } from "./AssetSearch";
import { assetToString } from "./utils/printUtils";

export const DeleteAssetModal = (props: {
  callback?: (id: string) => void;
  buttonFn?: (handleOpen: () => void) => JSX.Element;
  selectedParty: Partial<Party>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteAsset, deleteAssetResponse } = useAssetHooks();
  const { successToast, errorToast } = useElphiToast();
  const assetState = useSelector((state: RootState) => state.asset);

  const buttonComponent = props.buttonFn?.(onOpen) ?? null;
  const [selectedAsset, setSelectedAsset] = useState({} as Asset | undefined);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    setDeleteConfirmation(false);
    setSelectedAsset({} as Asset | undefined);
  }, [isOpen]);

  const deleteAssetHandler = async (selectedAsset: Asset) => {
    return await deleteAsset(selectedAsset.id).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Asset Deleted",
          description: `asset: ${r.data?.id}`
        });
        if (r.data.id) {
          props?.callback?.(r.data.id) || setSelectedAsset({} as Asset);
        }
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to delete asset",
          description: r.data.description
        });
      return r;
    });
  };

  const selectAsset = (r: string) => {
    if (!r) {
      setSelectedAsset(undefined);
    } else {
      assetState.entities[r] && setSelectedAsset(assetState.entities[r]);
    }
  };

  const mainModal = (
    <Box>
      <Text fontSize="l" fontWeight="bold">
        Search an Existing Asset
      </Text>
      <AssetSearch
        selectedParty={props.selectedParty}
        onSelect={selectAsset}
        currentValue={selectedAsset?.id ?? ""}
        fieldType={FieldType.SingleSelect}
      />
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={() => setDeleteConfirmation(true)}
        >
          Delete Asset
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  const confirmModal = (
    <Box>
      <Text p="10px">
        Are you sure you want to delete{" "}
        {assetToString({
          asset: (selectedAsset && selectedAsset) || ({} as Asset)
        })}
        ? Deleting this asset will delete all information related to it,
        including tasks.
      </Text>
      <Spacer mb="50px" />
      <Flex justifyContent={"space-between"}>
        <Button
          {...elphiTheme.components.light.button.primary}
          isLoading={deleteAssetResponse.isLoading}
          isDisabled={!selectedAsset || deleteAssetResponse.isLoading}
          onClick={() => {
            selectedAsset &&
              deleteAssetHandler(selectedAsset).then(
                (r) => r.status === 200 && onClose()
              );
          }}
        >
          Yes, delete asset
        </Button>
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={() => setDeleteConfirmation(false)}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Box>
      {props.buttonFn ? (
        buttonComponent
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Delete an Asset
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Deleting an Asset</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{deleteConfirmation ? confirmModal : mainModal}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
