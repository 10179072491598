import {
  IntegrationTask,
  IntegrationTaskTemplate,
  IntegrationType,
  RentalDataVendorType,
  TaskType
} from "@elphi/types";
import { values } from "lodash";
import { useEffect } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { useSections } from "../../../../forms/schemas/providers/SectionsProvider";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { usePropertyReportTaskHooks } from "../../../../hooks/propertyReportTask.hooks";
import { OnChangeInput } from "../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { TaskFormState } from "../../common.task.types";
import { RentRangeForm } from "./rent-range/RentRangeForm";

type IntegrationFormContainerProps<
  T extends IntegrationTask = IntegrationTask
> = {
  snapshotId?: string;
  state?: TaskFormState;
} & IntegrationTaskTemplate<T>;
const RentalDataVendorForm = {
  [RentalDataVendorType.RentRange]: RentRangeForm
};

type RentalDataTaskFormProps = IntegrationFormContainerProps;

export const RentalDataTaskForm = (props: RentalDataTaskFormProps) => {
  const { snapshotId, selectedTask, state } = props;
  const { propertyState: propertyReduxState } = usePropertyHooks();
  const { updatePropertiesHandler } = usePropertyReportTaskHooks();
  const { sections } = useSections();

  const selectedProperty =
    selectedTask?.entityId &&
    propertyReduxState?.entities[selectedTask?.entityId];

  const {
    onChange: propertyOnChange,
    state: propertyState,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler({
    initialState: {
      properties: {}
    },
    callback: updatePropertiesHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    selectedProperty &&
      syncPropertyState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
  }, [selectedProperty]);

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !snapshotId &&
      propertyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const selectedPropertyState =
    selectedProperty && propertyState.properties[selectedProperty.id];

  if (
    selectedPropertyState &&
    selectedTask.type === TaskType.Integration &&
    selectedTask.integrationType === IntegrationType.RentalData
  ) {
    const RentalDataVendorFormComponent =
      RentalDataVendorForm?.[selectedTask.vendorType];

    const rentalDataSections = values(
      sections?.integrations?.property?.[IntegrationType.RentalData]?.[
        selectedTask.vendorType
      ]
    ).map((section) =>
      section({
        state: selectedPropertyState,
        onChange: propertyOnChangeBatch
      })
    );
    return (
      <>
        {RentalDataVendorFormComponent && (
          <RentalDataVendorFormComponent
            snapshotId={snapshotId}
            selectedTask={selectedTask}
            state={state}
            sections={rentalDataSections}
            selectedPropertyState={selectedPropertyState}
            propertyOnChangeBatch={propertyOnChangeBatch}
          />
        )}
      </>
    );
  }
};
