import { Static, Type } from "@sinclair/typebox";
import { StringField } from "../../../typebox";
import { createEntitySchema } from "../../../typebox/utils/entity.utils";

const RoleFields = Type.Object({
  label: StringField,
  description: StringField,
  permissions: Type.Array(StringField)
});

export const {
  Update: RoleUpdate,
  Create: RoleCreate,
  Schema: RoleSchema,
  getOperationSchema: getOperationRoleSchema
} = createEntitySchema(RoleFields);

export type Role = Static<typeof RoleSchema>;
