import { Box, Flex } from "@chakra-ui/react";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import "../../../assets/css/mortgagetable.css";
import { Header } from "../../../common-components/header/Header";
import SidebarContainer from "../../../common-components/sidebar/containers/SidebarContainer";
import { auth } from "../../../firebase/firebaseConfig";
import { useOrgHooks } from "../../../hooks/org.hooks";
import { ElphiRoutes } from "../../../routes";
import PathVariables from "../../../routes/pathVariables";
import { ActivityIdleProvider } from "../providers/ActivitityIdleProvider/ActivityIdleProvider";
import { NavigateHandler } from "./NavigateHandler";
export const Signout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    auth.signOut();
    navigate("/login");
  }, []);
  return <Box>Bye Bye</Box>;
};
export const Dashboard: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const { selectedOrgId, setSelectedOrg } = useOrgHooks();
  const { orgId } = useParams();

  useEffect(() => {
    if (orgId) {
      setSelectedOrg(orgId);
    }
  }, [orgId]);

  // Return user to login page if permissions are not set or user session has expired
  useEffect(() => {
    if (auth.currentUser && !selectedOrgId && !orgId) {
      navigate(PathVariables.SelectOrganization, { replace: true });
    }
  }, [auth.currentUser]);

  return (
    <ActivityIdleProvider>
      <Box h={"100%"} w={"100%"}>
        <Flex h="100%" w="100%">
          {isEmpty(selectedOrgId) ? (
            <></>
          ) : props.userData && props.userData?.roles?.length ? (
            <Flex
              w="100%"
              bgColor="white"
              h="100%"
              flexDir={"row"}
              fontSize="12px"
            >
              <Box h="100%" w="65px" minW="65px" maxW={"65px"}>
                <SidebarContainer {...props} h="100%" />
              </Box>
              <Box h="100%" w={"calc(100% - 65px)"}>
                <Flex flexDir={"column"} h="100%" w="100%">
                  <Header {...props} />
                  <Box h="calc(100% - 50px)" w="100%">
                    <ElphiRoutes
                      roles={props.userData?.roles
                        .map((v: { label: string; value: string }) => v.value)
                        .filter(removeEmpty)}
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          ) : (
            <NavigateHandler location={"../login"} />
          )}
        </Flex>
      </Box>
    </ActivityIdleProvider>
  );
};
