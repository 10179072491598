import {
  LeaseStatusType,
  LongTermPortfolioHeader,
  LongTermPortfolioTemplate,
  PortfolioTemplateToFieldType,
  PortfolioTemplateType,
  Property,
  PropertyCellData,
  PropertyLoanPurpose,
  PropertyType,
  ShortTermPortfolioHeader,
  ShortTermPortfolioTemplate,
  StateCode,
  TemplateToProperty
} from "@elphi/types";
import { FieldType } from "../../form-builder/fieldFormat.types";
import { createOptionsFromMap } from "../../utils/formUtils";

type PortfolioPagePropertiesState = { [id: string]: Property };

const addressFieldMap = {
  AddressLineText: ["Address", "AddressLineText"],
  AddressUnitIdentifier: ["Address", "AddressUnitIdentifier"],
  CityName: ["Address", "CityName"],
  StateCode: ["Address", "StateCode"],
  StateName: ["Address", "StateName"],
  PostalCode: ["Address", "PostalCode"]
};

const basePropertyFieldMap = {
  ParcelNumber: ["ParcelNumber"],
  PropertyType: ["PropertyType"],
  FinancedUnitCount: ["FinancedUnitCount"],
  PropertyLoanPurpose: ["PropertyLoanPurpose"],
  PurchasePriceAmount: ["PurchasePriceAmount"],
  OriginalPurchaseDate: ["OriginalPurchaseDate"],
  OriginalPurchasePriceAmount: ["OriginalPurchasePriceAmount"],
  OutstandingLoanPayoffAmount: ["OutstandingLoanPayoffAmount"],
  LTVRatePercent: ["LTVRatePercent"],
  AllocatedLoanAmount: ["AllocatedLoanAmount"],
  RenovationCostAmount: ["RenovationCostAmount"],
  PropertyValuationAmount: ["Appraisal", "PropertyValuationAmount"]
};

const shortTermToPropertyBaseFieldMap = {
  PropertyLotIdentifier: ["Address", "PropertyLotIdentifier"],
  PropertyBlockIdentifier: ["Address", "PropertyBlockIdentifier"],
  PropertySubdivisionIdentifier: ["Address", "PropertySubdivisionIdentifier"],
  SubjectToPropertyValuationAmount: [
    "Appraisal",
    "SubjectToPropertyValuationAmount"
  ],
  TotalCostAmount: ["TotalCostAmount"],
  ConstructionCostAmount: ["ConstructionCostAmount"],
  ConstructionLTCRatePercent: ["ConstructionLTCRatePercent"],
  ConstructionLTCLoanAmount: ["ConstructionLTCLoanAmount"],
  BlendedLTCRatePercent: ["BlendedLTCRatePercent"],
  BlendedLTCLoanAmount: ["BlendedLTCLoanAmount"],
  LTARVRatePercent: ["LTARVRatePercent"],
  LTARVAmount: ["LTARVAmount"],
  LTVLoanAmount: ["LTVLoanAmount"],
  NetFundAmount: ["NetFundAmount"],
  NetFundRatePercent: ["NetFundRatePercent"],
  SettlementStatementAmount: ["SettlementStatementAmount"]
};
const shortTermToPropertyFieldMap: TemplateToProperty<ShortTermPortfolioTemplate> =
  {
    ...addressFieldMap,
    ...basePropertyFieldMap,
    ...shortTermToPropertyBaseFieldMap
  };

const longTermToPropertyBaseFieldMap = {
  LeaseStatusType: ["LeaseStatusType"],
  MonthlyLeaseRentAmount: ["MonthlyLeaseRentAmount"],
  AnnualTaxesAmount: ["AnnualTaxesAmount"],
  AnnualPropertyInsuranceAmount: [
    "PropertyInsurance",
    "AnnualPropertyInsuranceAmount"
  ],
  AnnualFloodInsuranceAmount: ["FloodInsurance", "AnnualFloodInsuranceAmount"],
  AnnualHomeownersAssociationFeeAmount: [
    "AnnualHomeownersAssociationFeeAmount"
  ],
  ContractClosingDate: ["ContractClosingDate"]
};

const longTermToPropertyFieldMap: TemplateToProperty<LongTermPortfolioTemplate> =
  {
    ...addressFieldMap,
    ...basePropertyFieldMap,
    ...longTermToPropertyBaseFieldMap
  };

export const longTermHeaderObject: LongTermPortfolioHeader = {
  ParcelNumber: {
    index: 1,
    label: "Parcel Number"
  },
  AddressLineText: {
    index: 2,
    label: "Address Line 1"
  },
  AddressUnitIdentifier: {
    index: 3,
    label: "Address Line 2"
  },
  CityName: {
    index: 4,
    label: "City"
  },
  StateCode: {
    index: 5,
    label: "State Code"
  },
  PostalCode: {
    index: 6,
    label: "Zip"
  },
  PropertyType: {
    index: 7,
    label: "Property Type"
  },
  FinancedUnitCount: {
    index: 8,
    label: "Units"
  },
  PropertyLoanPurpose: {
    index: 9,
    label: "Loan Purpose"
  },
  LeaseStatusType: {
    index: 10,
    label: "Lease Status"
  },
  MonthlyLeaseRentAmount: {
    index: 11,
    label: "Contracted Monthly Rent ($)"
  },
  AnnualTaxesAmount: {
    index: 12,
    label: "Annual Taxes ($)"
  },
  AnnualPropertyInsuranceAmount: {
    index: 13,
    label: "Annual Property Insurance ($)"
  },
  AnnualFloodInsuranceAmount: {
    index: 14,
    label: "Annual Flood Insurance ($)"
  },
  AnnualHomeownersAssociationFeeAmount: {
    index: 15,
    label: "Annual HOA Fees ($)"
  },
  PurchasePriceAmount: {
    index: 16,
    label: "Purchase Price ($)"
  },
  ContractClosingDate: {
    index: 17,
    label: "Contract Closing Date"
  },
  OriginalPurchaseDate: {
    index: 18,
    label: "Original Purchase Date"
  },
  OriginalPurchasePriceAmount: {
    index: 19,
    label: "Original Purchase Price ($)"
  },
  RenovationCostAmount: {
    index: 20,
    label: "Borrower Renovation Costs ($)"
  },
  OutstandingLoanPayoffAmount: {
    index: 21,
    label: "Outstanding Mortgage Payoff ($)"
  },
  PropertyValuationAmount: {
    index: 22,
    label: "Appraised Property Value ($)"
  },
  LTVRatePercent: {
    index: 23,
    label: "LTV %"
  },
  AllocatedLoanAmount: {
    index: 24,
    label: "Allocated Loan Amount ($)"
  }
};

export const shortTermHeaderObject: ShortTermPortfolioHeader = {
  ParcelNumber: {
    index: 1,
    label: "Parcel Number"
  },
  AddressLineText: {
    index: 2,
    label: "Address Line 1"
  },
  AddressUnitIdentifier: {
    index: 3,
    label: "Address Line 2"
  },
  CityName: {
    index: 4,
    label: "City"
  },
  StateCode: {
    index: 5,
    label: "State Code"
  },
  PostalCode: {
    index: 6,
    label: "Zip"
  },
  PropertyLotIdentifier: {
    index: 7,
    label: "Lot"
  },
  PropertyBlockIdentifier: {
    index: 8,
    label: "Block"
  },
  PropertySubdivisionIdentifier: {
    index: 9,
    label: "Subdivision"
  },
  PropertyType: {
    index: 10,
    label: "Property Type"
  },
  FinancedUnitCount: {
    index: 11,
    label: "Units"
  },
  PropertyLoanPurpose: {
    index: 12,
    label: "Loan Purpose"
  },
  PurchasePriceAmount: {
    index: 13,
    label: "Purchase Price"
  },
  OriginalPurchaseDate: {
    index: 14,
    label: "Original Purchase Date"
  },
  OriginalPurchasePriceAmount: {
    index: 15,
    label: "Original Purchase Price"
  },
  OutstandingLoanPayoffAmount: {
    index: 16,
    label: "Outstanding Mortgage Payoff"
  },
  RenovationCostAmount: {
    index: 17,
    label: "Renovation Budget"
  },
  ConstructionCostAmount: {
    index: 18,
    label: "Construction Budget"
  },
  PropertyValuationAmount: {
    index: 19,
    label: "As-Is Appraised Property Value"
  },
  SubjectToPropertyValuationAmount: {
    index: 20,
    label: "Subject-To Appraised Property Value"
  },
  TotalCostAmount: {
    index: 21,
    label: "Total Cost"
  },
  ConstructionLTCRatePercent: {
    index: 22,
    label: "Purchase Loan To Cost + Construction Budget (%)"
  },
  ConstructionLTCLoanAmount: {
    index: 23,
    label: "Purchase Loan To Cost + Construction Budget ($)"
  },
  BlendedLTCRatePercent: {
    index: 24,
    label: "Blended LTC (%)"
  },
  BlendedLTCLoanAmount: {
    index: 25,
    label: "Blended LTC Loan Amount ($)"
  },
  LTARVRatePercent: {
    index: 26,
    label: "Loan to After Repair Value (%)"
  },
  LTARVAmount: {
    index: 27,
    label: "Loan to  After Repair Value ($)"
  },
  LTVRatePercent: {
    index: 28,
    label: "Loan To Value (%)"
  },
  LTVLoanAmount: {
    index: 29,
    label: "Loan To Value ($)"
  },
  AllocatedLoanAmount: {
    index: 30,
    label: "Allocated Loan Amount ($)"
  },
  NetFundAmount: {
    index: 31,
    label: "Net Fund ($)"
  },
  NetFundRatePercent: {
    index: 32,
    label: "Net Fund/As-Is (%)"
  },
  SettlementStatementAmount: {
    index: 33,
    label: "Cash to/from Borrower"
  }
};

export const portfolioTemplateToFieldTypes: PortfolioTemplateToFieldType = {
  ParcelNumber: FieldType.String,
  AddressLineText: FieldType.String,
  AddressUnitIdentifier: FieldType.String,
  CityName: FieldType.String,
  StateCode: FieldType.SingleSelect,
  StateName: FieldType.SingleSelect,
  PostalCode: FieldType.String,
  PropertyLotIdentifier: FieldType.String,
  PropertyBlockIdentifier: FieldType.String,
  PropertySubdivisionIdentifier: FieldType.String,
  PropertyType: FieldType.SingleSelect,
  FinancedUnitCount: FieldType.Integer,
  PropertyLoanPurpose: FieldType.SingleSelect,
  PurchasePriceAmount: FieldType.Money,
  OriginalPurchaseDate: FieldType.Date,
  OriginalPurchasePriceAmount: FieldType.Money,
  OutstandingLoanPayoffAmount: FieldType.Money,
  LTVRatePercent: FieldType.Percentage,
  LTVLoanAmount: FieldType.Money,
  AllocatedLoanAmount: FieldType.Money,
  ConstructionCostAmount: FieldType.Money,
  LeaseStatusType: FieldType.SingleSelect,
  MonthlyLeaseRentAmount: FieldType.Money,
  MonthlyMarketRentAmount: FieldType.Money,
  AdjustedRentAmount: FieldType.Money,
  AnnualTaxesAmount: FieldType.Money,
  AnnualPropertyInsuranceAmount: FieldType.Money,
  AnnualFloodInsuranceAmount: FieldType.Money,
  AnnualHomeownersAssociationFeeAmount: FieldType.Money,
  PropertyValuationAmount: FieldType.Money,
  LTCRatePercent: FieldType.Percentage,
  AllocatedRDSRRatePercent: FieldType.Percentage,
  AsIsPropertyValuationAmount: FieldType.Money,
  SubjectToPropertyValuationAmount: FieldType.Money,
  TotalCostAmount: FieldType.Money,
  ConstructionLTCRatePercent: FieldType.Percentage,
  ConstructionLTCLoanAmount: FieldType.Money,
  BlendedLTCRatePercent: FieldType.Percentage,
  BlendedLTCLoanAmount: FieldType.Money,
  NetFundAmount: FieldType.Money,
  NetFundRatePercent: FieldType.Percentage,
  SettlementStatementAmount: FieldType.Money,
  RenovationCostAmount: FieldType.Money,
  LTARVRatePercent: FieldType.Percentage,
  LTARVAmount: FieldType.Money,
  ContractClosingDate: FieldType.Date
};

export const singleSelectFieldToOptions = {
  StateCode: createOptionsFromMap(StateCode),
  PropertyType: createOptionsFromMap(PropertyType),
  PropertyLoanPurpose: createOptionsFromMap(PropertyLoanPurpose),
  LeaseStatusType: createOptionsFromMap(LeaseStatusType)
};

const getLongTermKeysAndLabels = () => {
  const longTermHeaderKeys: string[] = [];
  const longTermHeaderLabels: string[] = [""];
  Object.entries(longTermHeaderObject)
    .sort((a, b) => (a[1].index > b[1].index ? 1 : -1))
    .map(([key, value]) => {
      longTermHeaderKeys.push(key);
      longTermHeaderLabels.push(value.label);
    });
  return [longTermHeaderKeys, longTermHeaderLabels];
};

export const [longTermHeaderKeys, longTermHeaderLabels] =
  getLongTermKeysAndLabels();

const getShortTermKeysAndLabels = () => {
  const shortTermHeaderKeys: string[] = [];
  const shortTermHeaderLabels: string[] = [""];
  Object.entries(shortTermHeaderObject)
    .sort((a, b) => (a[1].index > b[1].index ? 1 : -1))
    .map(([key, value]) => {
      shortTermHeaderKeys.push(key);
      shortTermHeaderLabels.push(value.label);
    });

  return [shortTermHeaderKeys, shortTermHeaderLabels];
};

export const [shortTermHeaderKeys, shortTermHeaderLabels] =
  getShortTermKeysAndLabels();

export const portfolioTypeToHeader = (type: string) => {
  if (PortfolioTemplateType.LongTerm === type) {
    return longTermHeaderLabels;
  } else if (PortfolioTemplateType.ShortTerm === type) {
    return shortTermHeaderLabels;
  }
  throw `portfolio type ${type} not exists`;
};

export const getTemplateFieldMap = (r: {
  type: PortfolioTemplateType | undefined;
}) => {
  if (r.type === PortfolioTemplateType.ShortTerm)
    return shortTermToPropertyFieldMap;
  else if (r.type === PortfolioTemplateType.LongTerm)
    return longTermToPropertyFieldMap;
  throw `portfolio template ${r.type} not exists`;
};

export const getTemplateKeys = (r: {
  type: PortfolioTemplateType | undefined;
}) => {
  if (r.type === PortfolioTemplateType.ShortTerm) {
    return shortTermHeaderKeys;
  } else if (r.type === PortfolioTemplateType.LongTerm) {
    return longTermHeaderKeys;
  }
  throw `portfolio template ${r.type} not exists`;
};

export type FieldToCalculation = {
  [K in keyof Partial<
    ShortTermPortfolioTemplate & LongTermPortfolioTemplate
  >]: (r: {
    state: (Property | undefined)[];
    diffState: Partial<PortfolioPagePropertiesState>;
    changedCell: PropertyCellData;
  }) => string;
};
export const fieldToCalculation: FieldToCalculation = {
  // PropertyValuationAmount: (r) => {
  //   const property = r.state.find((p) => p?.id === r.changedCell.propertyId);
  //   if (!property) return "";
  //   const newValue =
  //     Number(
  //       r.diffState?.[property.id]?.LTCRatePercent || property.LTCRatePercent
  //     ) *
  //     Number(
  //       r.diffState?.[property.id]?.OriginalPurchasePriceAmount ||
  //         property.OriginalPurchasePriceAmount
  //     );
  //   return newValue.toString();
  // }
};

export type FieldEffectFieldMap = {
  [K in keyof Partial<
    ShortTermPortfolioTemplate & LongTermPortfolioTemplate
  >]: Array<
    keyof Partial<ShortTermPortfolioTemplate & LongTermPortfolioTemplate>
  >;
};
export const fieldEffectFieldMap: FieldEffectFieldMap = {
  // LTCRatePercent: ["PropertyValuationAmount"],
  // OriginalPurchasePriceAmount: ["PropertyValuationAmount"]
};
